import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import ImageUploader from '../Form/ImageUploader'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import TextError from '../../TextError'
import { addAlumni} from '../../store/AlumniSetupStore'
import { loadCourseSelect } from '../../store/CourseSetup'
function AlumniAdd({openPopUP,setOpenPopUp,setRefetch}) {
    const course = useSelector(state=> state.entities.courses.result.data)
    const errors = useSelector(state=> state.entities.alumni.error.data)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues={
        alumni_name:'',
        alumni_achievement:'',
        alumni_img:'',
        alumni_desc:'',
        rating:'',
        courses:''
    }
    const validationSchema= Yup.object().shape({
        courses: Yup.string().required(),
        alumni_name: Yup.string().required().min(3).max(50).label("instructors name"),
        alumni_img:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("alumni image"),
        alumni_desc:Yup.string().required().min(10).max(255).label("about instructor"),
        // mobile_no:Yup.number().required().label("mobile no").min(10).max(11),
        alumni_achievement:Yup.string().min(4).max(100).required(),
        rating: Yup.number().required(),

    })
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData();        
        formData.append('alumni_name',values.alumni_name);
        formData.append('alumni_desc',values.alumni_desc);
        formData.append('alumni_img',values.alumni_img,values.alumni_img.name);
        formData.append('rating',values.rating);
        formData.append('courses',values.courses);
        formData.append('alumni_achievement',values.alumni_achievement);
        formData.append('created_by',localStorage.getItem('username'));        
        dispatch(addAlumni(formData));
        submitProps.setSubmitting(false)
        submitProps.resetForm();
        setRefetch(prevState => !prevState)
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
  return (
    <FormPopUp openPopup={openPopUP} setOpenPopup={setOpenPopUp} title="Add Alumni">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(                    
                    <Form>                        
                        <div className='form-group item'>
                                <label className='required-label'>Alumni Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="alumni_name" id="alumni_name" placeholder="alumni name" className='form-control'/>
                                <ErrorMessage name="alumni_name" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Alumni Achievement<span className="errors">*</span>:</label>
                                <FastField  name="alumni_achievement" id="alumni_achievement" placeholder="email" className='form-control'/>
                                <ErrorMessage name="alumni_achievement" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Rating<span className="errors">*</span>:</label>
                                <FastField type="text" name="rating" id="rating" placeholder="rating" className='form-control'/>
                                <ErrorMessage name="rating" component={TextError} />
                        </div>
                        <ImageUploader
                        name="alumni_img"
                        label="Alumni image" 
                        accept="image/jpg, image/gif,image/jpeg,image/png"                       
                        />
                        <div className='form-group item'>
                                <label className='required-label'>About Alumni<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="alumni_desc" id="alumni_desc" placeholder="about alumni" className='form-control'/>
                                <ErrorMessage name="alumni_desc" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="courses" component={TextError} />
                                    {errors?.courses && <div className="errors">{errors?.courses}</div>}
                            </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}Save</button>                    
                    </Form>
                    
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default AlumniAdd