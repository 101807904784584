import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  restoreTagParagraphToModelset } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import MathJax from 'mathjax3-react'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function RestoreDeletedTagParagraph({openPopup,setOpenPopup,modelset}) {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.quiz.loading)

    const initialValues = {
        tag_id: modelset[0]?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(restoreTagParagraphToModelset(values));
        submitProps.setSubmitting(false)
    }
  return (
    <FormPopUp setOpenPopup={setOpenPopup} openPopup={openPopup} title="Restore Tag">
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    
                       <Form>
                        <MathJax.Html html={modelset[0]?.paragraph_title}/>
                        <label>
                        Do you want to restore a tag  from {modelset[0]?.modelset_name}?
                        </label><br/>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}&nbsp; <i className='fa fa-undo'/> Restore
                        </button>
                    </Form>
                    
                )
            }
        </Formik>
        {
            loading && <LoaderSpinner/>
        }
    </FormPopUp>
  )
}

export default RestoreDeletedTagParagraph