import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteTagParagraphToModelset } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import MathJax from 'mathjax3-react'
function ParagraphDelete({openPopup,setOpenPopup,modelset}) {
    const dispatch = useDispatch()
    const initialValues = {
        tag_id: modelset[0]?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(deleteTagParagraphToModelset(values));
        submitProps.setSubmitting(false)
    }
  return (
    <FormPopUp setOpenPopup={setOpenPopup} openPopup={openPopup} title="Delete Tag">
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <MathJax.Html html={modelset[0]?.paragraph_title}/>
                        <label>
                        Do you want to delete a tag  from {modelset[0]?.modelset_name}?
                        </label><br/>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}&nbsp; <i className='fa fa-trash'/> Delete
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ParagraphDelete