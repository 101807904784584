
import {  useFormikContext } from 'formik'
import React from 'react'
import ErrorMessage from './ErrorMessage';

function FormField({requiredField,label,name,width, divSize,...otherProps}) {
    const {setFieldTouched,handleChange, errors, touched} = useFormikContext();
    return (
        <>
            <div className={divSize}>
            {label && <label>{label}{requiredField && <span className="errors">*</span>}</label>}
            <input    
            onBlur={()=>setFieldTouched(name)}
            onChange={handleChange(name)}
            {...otherProps}
            className="form-control"
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
            </div>
            
        </>
    )
}



export default FormField
