import { ErrorMessage, Field, Form, Formik } from 'formik'
import MathJax from 'mathjax3-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactDataTable from '@ashvin27/react-datatable';
import { loadCourseSelect } from '../../store/CourseSetup';
import { loadLessonSelectById } from '../../store/LessonSetup';
import { getMainTopicSelectById } from '../../store/MainTopicSetup';
import { loadSubCourseSelectById } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import BodyContainer from '../DashboardTemplate/BodyContainer'
import {  lessonTagReport} from '../../store/QuizSetupStore';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import QuizTagEdit from './QuizTagEdit';
import ViewQuizTagDetails from './ViewQuizTagDetails';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import { Link } from 'react-router-dom';
import ActivateLessonQuiz from './ActivateLessonQuiz';
import DeactivateLesson from './DeactivateLesson';

function LessonQuizSetup() {    
    const [courses,setCourse] = useState('');
    const [subcourses,setSubCourse] = useState('');
    const [mainTopic,setMainTopic] = useState('');
    const [question,setQuestion] = useState('');
    const [openEditTag,setOpenEditTag] = useState(false);
    const [openViewTag,setOpenViewTag] = useState(false);
    const [openActivate,setOpenActivate] = useState(false);
    const [openDeactivate,setOpenDeactivate] = useState(false);
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result.data);
    const subcourse = useSelector(state => state.entities.subcourse.result.data);
    const mainTopics = useSelector(state => state.entities.mainTopic.getData.data);
    const lesson = useSelector(state => state.entities.lesson.getData.data);
    const quizData = useSelector(state => state.entities.quiz.tagResult?.data);
    // const modelset = useSelector(state => state.entities.quiz.modelset?.data);
    const loading = useSelector(state => state.entities.quiz?.loading);
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const handleCourseClick = (id)=>{
        setCourse(id)
        if(id !== '')
        dispatch(loadSubCourseSelectById(id))      
                 
    }
    const handleSubCourseClick = (id)=>{
        setSubCourse(id)
        if(id !== '')
        dispatch(getMainTopicSelectById(id))
    }
    const handleMainTopicClick = (id)=>{
        setMainTopic(id)
        if(id !== '')
        dispatch(loadLessonSelectById(id))
    }
    const handleSubmit = (values,submitProps) =>{     
        dispatch(lessonTagReport(values))
        submitProps.setSubmitting(false);        
    }
    
    const initialValues = {             
        courses:courses,
        subcourse:subcourses,
        subcourse_main_topic:mainTopic,
        lesson:'',
        lesson_quiz_active:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        subcourse: Yup.string().required(),
        subcourse_main_topic: Yup.string().required().label('main topic'),
        lesson: Yup.string().required(),
        lesson_quiz_active: Yup.string().required().label('status'),

    })
    const clickTagQuiz =(record)=>{
        setQuestion(record);
        setOpenEditTag(true);
    }
    const clickTagViewQuiz = (record)=>{
        setQuestion(record);
        setOpenViewTag(true);
    }
    const handleActivate = (record)=>{
        setQuestion(record)
        setOpenActivate(true)
    }
    const handleDeactivate = (record)=>{
        setQuestion(record)
        setOpenDeactivate(true)
    }
    const COLUMNS = [
      {
          key:'question',
          text:'Question',
          maxWidth:650,
          cell : record => {
              return(
                  <>                  
                      <MathJax.Html html={record?.question}/>                  
                  </>
              )
          }
      },
      {
          key:'correct_option',
          text:'Correct Options',
          cell : record => {
              return(
                  <>                  
                      <MathJax.Html html={record?.correct_option}/>                  
                  </>
              )
          }
      },
      {
          key:'lesson_quiz_active',
          text:'Status',
          cell : record => {
              return(
                  <>                  
                      {
                          record?.lesson_quiz_active===1?<><span>Active</span><br/><Link to={`#`} onClick={()=>handleDeactivate(record)}>deactivate</Link></>:<><span>Not Active</span><br/><Link to={`#`} onClick={()=>handleActivate(record)}>activate</Link></>
                      }                 
                  </>
              )
          }
      },
      {
          key: "action",
          text: "Action",
          className: "action",
          width: 200,
          align: "left",
          sortable: false,
          cell: record => { 
              return (
                  <>
                      <button
                          className="btn btn-success btn-sm"                           
                          style={{marginRight: '5px'}}                         
                          onClick={()=> clickTagQuiz(record)}
                          >
                          <i className="fa fa-edit">&nbsp;edit Tag</i>
                      </button>
                      {/* <button 
                          className="btn btn-danger btn-sm" 
                          onClick={() => console.log(record)}>
                          <i className="fa fa-trash"></i>
                      </button>
                      */}
                      <button 
                          
                          style={{marginLeft:5}}
                          className="btn btn-primary btn-sm" 
                          onClick={()=>clickTagViewQuiz(record)}
                          >                            
                          <i className="fa fa-eye">&nbsp;view</i>
                      </button> 
                  </>
              );
          }
      }
  ]
  const CHOICES = [
      {
          'key':'True',
          'value':'ACTIVE'
      },
      {
          'key':'False',
          'value':'DEACTIVE'
      },
  ]
  return (
    <>
    <BodyContainer pageTitle="Quiz Tag Report">
      <div className='card'>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {
              ({isSubmitting})=>(
                <Form>
                  <div className='row'>
                    <div className='form-group item col-md-4'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="courses" component={TextError} />
                            </div>
                         <div className='form-group item col-md-4'>
                                <label className='required-label'>Select SubCourse<span className="errors">*</span></label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" value={subcourses} className='form-control' onChange={(e)=>handleSubCourseClick(e.target.value)}>
                                    <option name="subcourse" value="">select Options</option>
                                    {
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item?.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse" component={TextError} />
                            </div>
                         <div className='form-group item col-md-4'>
                                <label className='required-label'>Select SubCourse MainTopic<span className="errors">*</span></label>
                                <Field as="select" name="subcourse_main_topic" id="subcourse_main_topic" placeholder="" className='form-control' value={mainTopic} onChange={(e)=>handleMainTopicClick(e.target.value)}>
                                    <option name="subcourse_main_topic" value="">select Options</option>
                                    {
                                        mainTopics && mainTopics?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.topic_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse_main_topic" component={TextError} />
                            </div>
                            </div>
                            <div className='row'>
                         <div className='form-group item col-md-5'>
                                <label className='required-label'>Select Lesson<span className="errors">*</span></label>
                                <Field as="select" name="lesson" id="lesson" placeholder="" className='form-control'>
                                    <option name="lesson" value="">select Options</option>
                                    {
                                        lesson && lesson?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.lesson_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="lesson" component={TextError} />
                            </div>
                            
                            <div className='form-group item col-md-4'>
                                <label className='required-label'>Select Status<span className="errors">*</span></label>
                                <Field as="select" name="lesson_quiz_active" id="lesson_quiz_active" placeholder="" className='form-control'>
                                    <option name="lesson_quiz_active" value="">select Options</option>
                                    {
                                        CHOICES && CHOICES?.map((item,index)=>(
                                            <option key={item.key} value={item.key}>{item.value}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="lesson_quiz_active" component={TextError} />
                            </div>
                            <div className='form-group item col-md-2 float-sm-right'>
                              <label>&nbsp;</label>
                            <button className='btn btn-success btn-flat form-control ' type='submit' disabled={isSubmitting}>
                               {isSubmitting?<Spinner/>:''} <i className='fa fa-search'>&nbsp;&nbsp;Search</i>
                            </button>
                            </div>
                            </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
      <div className='card'>
            <div className='card-body'>
              <ReactDataTable
              records = {quizData}
              columns = {COLUMNS}
              />
            </div>
      </div>
      {
          openActivate &&
          <ActivateLessonQuiz openPopup={openActivate} setOpenPopup={setOpenActivate} data={question}/>
      }
      {
          openDeactivate &&
          <DeactivateLesson openPopup={openDeactivate} setOpenPopup={setOpenDeactivate} data={question}/>
      }
      {
          openEditTag &&
          <QuizTagEdit openPopUp={openEditTag} setOpenPopUp={setOpenEditTag} question={question}/>
      }
      {
          openViewTag &&
          <ViewQuizTagDetails openPopUp={openViewTag} setOpenPopUp={setOpenViewTag} question={question}/>
      }
      {
          loading && <LoaderSpinner/>
      }
    </BodyContainer>
    </>
  )
}

export default LessonQuizSetup