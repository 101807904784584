import React from 'react'
import FormPopUp from '../popup/PopUp'

function ViewFeatureDetails({openPopup,setOpenPopup,feature}) {
  return (
    <FormPopUp title="View Feature Details" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <label>Feature Title:</label>&nbsp;<span>{feature?.features_title?feature?.features_title:'N/A'}</span><br/>
        <label>Feature Description:</label>&nbsp;<span>{feature?.features_desc?feature?.features_desc:'N/A'}</span><br/>
        <label>Feature Details:</label>&nbsp;<span>{feature?.user_get?feature?.user_get:'N/A'}</span><br/>
        <label>Created By</label>&nbsp; : &nbsp;<span>{feature?.created_by? feature?.created_by:'N/A'}</span><br/>
        <label>Created Date</label>&nbsp; : &nbsp;<span>{feature?.created_date? feature?.created_date:'N/A'}</span><br/>
        <label>Modified By</label>&nbsp; : &nbsp;<span>{feature?.modified_by?feature?.modified_by:'N/A'}</span><br/>
        <label>Modified Date</label>&nbsp; : &nbsp;<span>{feature?.modified_date?feature?.modified_date:'N/A'}</span><br/>
        <label>Modified Remarks</label>&nbsp; : &nbsp;<span>{feature?.modified_remarks?feature?.modified_remarks:'N/A'}</span><br/>
    </FormPopUp>
  )
}

export default ViewFeatureDetails