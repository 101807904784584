import React from 'react';

function Card({cardTitle,children}) {
  return (
  <div className='row'>
      <div className='col-md-12'>
        <div className='card'>
            {
                cardTitle &&
            <div className='card-header'>
               <h3 className='card-title'> {cardTitle}</h3>
            </div>
                
            }
           {children}
        </div>
      </div>
  </div>
  );
}

export default Card;
