import React from 'react';
import WebForm from '../Form/WebForm';
import ImageUploader from '../Form/ImageUploader';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addLogo } from '../../store/CompanySetup';
import { CircularProgressbar } from 'react-circular-progressbar';
function LogoAdd({company_id}) {
    const dispatch = useDispatch()
    const percent = useSelector(state => state.entities.company.percent)
    const initialValues = {
        company_logo: ''
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
    const validationSchema = Yup.object().shape({
        company_logo: Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Company Logo")
    })
    const handleSubmit = (values) => {
        let formData = new FormData();
        formData.append('company',company_id)
        formData.append('company_logo',values.company_logo,values.company_logo.name)       
        dispatch(addLogo(formData))
    }
    return (
        <div className="card">
            <div className="card-header">
                <h3 className="card-title">Logo</h3>
            </div>
            <div className="card-body">
                <WebForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                    <ImageUploader
                    name="company_logo"
                    label="Select Logo"
                    accept="image/jpg, image/gif,image/jpeg,image/png"
                    />
                    <button className="btn btn-flat btn-primary">Upload</button>
                </WebForm>
                <div style={{height:100,width:100}}>
               {(percent > 0)? <CircularProgressbar value={percent} text={`${percent}%`} />:null}
                </div>
            </div>
        </div>
    )
}

export default LogoAdd
