import React, { useEffect, useState } from 'react'
import FormPopUp from '../popup/PopUp'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import TextError from '../../TextError'
import { useDispatch, useSelector } from 'react-redux'
import { createArticles, getArticlesById, updateArticles } from '../../store/ArticlesStore';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import ImageUploader from '../Form/ImageUploader'
import CkeditorField from '../Form/CkeditorField'
import Spinner from '../Layout/Spinner'
import { loadCourseSelect } from '../../store/CourseSetup'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
function UpdateArticle({openPopup,setOpenPopup}) {
    const articles = useSelector(state => state.entities.articles?.articleData?.data);
    const errors = useSelector(state => state.entities.articles?.errors?.data);
    const params = useParams()
    const [courses,setCourse] = useState(articles?.course_iid)
    const [year,setYear] = useState()
    const course = useSelector(state => state.entities.courses?.result?.data);
    const dispatch = useDispatch()
    const handleCourseClick = (id)=>{
        setCourse(id)
    }
    useEffect(()=>{
        dispatch(loadCourseSelect())
        dispatch(getArticlesById(params?.slug))
        // getarticle()
    },[params?.slug])
    async function getarticle(){
        await dispatch(getArticlesById(params?.slug))
    }
    const handleSubmit = async (values,submitProps)=>{
        values.publish_date_nep = year;
        await dispatch(updateArticles(values))
        submitProps.setSubmitting(false)
    }
    const handleDate = ({bsDate})=>{
        setYear(bsDate)
    }
    const initialValues = {
        article_id:articles?.iid,
        article_title:articles?.article_title,        
        article:articles?.article,
        author_name:articles?.author_name,
        courses:articles?.course_iid,
        publish_date_nep: articles?.publish_date_nep
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
    const validationSchema = Yup.object().shape({
        article_title: Yup.string().required().min(10).max(100).label("article title"),       
        article: Yup.string().required(),
        author_name: Yup.string().required().min(5).max(100),
        publish_date_nep: Yup.date().required('date is required')
    })
    return (
    // <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <BodyContainer pageTitle={`Edit Published Articles`}  buttonTitle={`cancel`} route={`/articleSetup`}>
        <div className='card'>
        <div className='card-body'>
        <div className='row '>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='row'>
                            <div className='row col-md-12'>
                            <div className='form-group col-md-10'>
                            <label>title:<span className='errors'>*</span></label>
                            <Field name="article_title" className="form-control" placeholder="article title here..."/>
                            <ErrorMessage name='article_title' component={TextError}/>
                            {
                                errors?.article_title &&
                                <div className='errors'>{errors?.article_title}</div>
                            }
                            </div>
                        </div>
                        <div className='row col-md-12'>
                            <div className='form-group col-md-3'>
                            <label className='required-label'> select course<span className="errors">*</span></label>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                    <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                        }
                            </Field>
                            <ErrorMessage name="courses" component={TextError} />
                            {
                                errors?.courses &&
                                <div className='errors'>{errors?.courses}</div>
                            }
                            </div>
                            {initialValues?.publish_date_nep &&
                            <div className='form-group col-md-3'>
                            <label>publish Date(BS)<span className="errors">*</span></label>
                                <Calendar
                                language="en"
                                theme="default"
                                name={`publish_date_nep`}
                                className="form-control"
                                onChange={handleDate}

                                defaultDate={initialValues?.publish_date_nep}
                                />
                                <ErrorMessage name="publish_date_nep" component={TextError}/>
                                {
                                errors?.publish_date_nep &&
                                <div className='errors'>{errors?.publish_date_nep}</div>
                            }
                            </div>
                            }
                            {!initialValues?.publish_date_nep &&
                            <div className='form-group col-md-3'>
                            <label>publish Date(BS)<span className="errors">*</span></label>
                                <Calendar
                                language="en"
                                theme="default"
                                name={`publish_date_nep`}
                                className="form-control"
                                onChange={handleDate}

                                defaultDate={initialValues?.publish_date_nep}
                                />
                                <ErrorMessage name="publish_date_nep" component={TextError}/>
                                {
                                errors?.publish_date_nep &&
                                <div className='errors'>{errors?.publish_date_nep}</div>
                            }
                            </div>}
                            <div className='form-group col-md-3'>
                                <label>author:<span className='errors'>*</span></label>
                                <Field name="author_name" className="form-control" placeholder="author name"/>
                                <ErrorMessage name='author_name' component={TextError}/>
                                {
                                errors?.author_name &&
                                <div className='errors'>{errors?.author_name}</div>
                            }
                            </div>
                        </div>                        
                        <div className='row col-md-12'>
                        <div className='form-group col-md-12' >
                            <CkeditorField name={`article`} data={articles?.article?articles?.article:''}/>
                            {
                                errors?.article &&
                                <div className='errors'>{errors?.article}</div>
                            }
                        </div>
                        </div>
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} publish
                        </button>
                        
                    </Form>
                )
            }
        </Formik>
            </div>
        </div>
        </div>
        </BodyContainer>
    // </FormPopUp>
  )
}

export default UpdateArticle