import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import { useDispatch, useSelector } from 'react-redux'
import TimePicker from 'react-time-picker'
import { editTagLive, getModelSetByYear } from '../../store/QuizSetupStore'
import FormPopUp from '../popup/PopUp';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import * as Yup from 'yup';
import TextError from '../../TextError'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function EditLiveQuizTagStatus({openPopup,setOpenPopup,quiz}) {
    const [value, onChange] = useState(new Date(quiz?.live_start_date_time));
    const [valueEnd, onChangeEnd] = useState(new Date(quiz?.live_end_date_time));
    const [values, onChanges] = useState(quiz?.exam_time_duration);
    const [year,setYear] = useState(quiz?.year)
    let date = new Date();
    const dispatch = useDispatch();
    const modelset = useSelector(state => state.entities.quiz?.getDataById?.data);
    const loading = useSelector(state => state.entities.quiz?.loading);
    useEffect(()=>{       
        dispatch(getModelSetByYear(quiz?.year))
    },[])
    
    const handleDate = ({bsDate})=>{

        setYear(bsDate)
        dispatch(getModelSetByYear(bsDate))
    }
    const handleSubmit = (values,submitProps) => {        
        values.tag_id = quiz?.tag_id 
        values.live_start_date_time =  (new Date(value-date.getTimezoneOffset()*60000)).toISOString();      
        values.live_end_date_time =  (new Date(valueEnd-date.getTimezoneOffset()*60000)).toISOString();      
        dispatch(editTagLive(values));
        submitProps.setSubmitting(false);
    }
   
    const initialValues = {        
        modelset:quiz?.modelset_id,
        live_start_date_time:value,
        live_end_date_time:valueEnd,
        exam_time_duration:values
    }
    const validationSchema = Yup.object().shape({
        modelset: Yup.string().required(),
        live_start_date_time: Yup.string().required(),
        exam_time_duration: Yup.string().required(),
        live_end_date_time: Yup.string().required(),
    })
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Edit Live Quiz Tag">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Modelset : &nbsp;</label><span>{modelset?.modelset_name}</span>
                        </div>
                        <div className='form-group'>
                            <Calendar
                            onChange ={handleDate}
                            dateFormat = "YYYY"
                            language="en"
                            name = "year"
                            className="form-control"
                            />
                        </div>
                        <div className='form-group'>
                            <label>select courses:</label><span className='errors'></span>
                            <Field as="select" name="modelset" id="modelset" placeholder="" className='form-control'  >
                                            <option name="modelset" value="">select Options</option>
                                                {
                                                    modelset && modelset?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.modelset_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="modelset" component={TextError} />                                            
                        </div>
                        <div className='form-group'>
                            <label>Live Start From:</label><span className='errors'>*</span>
                            <DateTimePicker 
                            name='live_start_date_time' 
                            onChange={onChange} 
                            value={value} 
                            className='form-control'                            
                            />
                            <ErrorMessage name='live_start_date_time' component={TextError}/>
                        </div>
                        <div className='form-group'>
                            <label>Live End :</label><span className='errors'>*</span>
                            <DateTimePicker 
                            name='live_end_date_time' 
                            onChange={onChangeEnd} value={valueEnd} 
                            className='form-control'
                            />
                            <ErrorMessage name='live_end_date_time' component={TextError}/>
                        </div>
                        <div className='form-group'>
                            <label>Quiz Duration :</label><span className='errors'>*</span>
                            <TimePicker name='exam_time_duration' onChange={onChanges} value={values} className='form-control' format='hh:mm:ss'/>
                            <ErrorMessage name='exam_time_duration' component={TextError}/>
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                            <i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default EditLiveQuizTagStatus