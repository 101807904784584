import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MathJax from 'mathjax3-react'
import { getQuizModelsetDetails } from '../../store/QuizSetupStore'
import Card from '../Card/Card'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import Pagination from '../Pagination/Pagination'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function ModelsetQuizView() {
  const [currentPage,setCurrentPage] = useState(1)
  const [resPerPage,setResPerPage] = useState(1)
   // Current active button number
  const [currentButton, setCurrentButton] = useState(1)
   // Array of buttons what we see on the page
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])
  const dispatch = useDispatch();
  const params = useParams();
  const modelset = useSelector(state => state.entities.quiz.modelsetReport?.data)
  const loading = useSelector(state => state.entities.quiz.loading)
  useEffect(()=>{
    dispatch(getQuizModelsetDetails(params.slug))
  },[params])
  let questions,options,paragraph,pquestion,poptions,arr;
 
  
   arr =modelset && Object.keys(modelset).map(item => {
    questions = modelset[item]['questions']
    return { 
      id:item,
      modelset_name:modelset[item]?.modelset_name,
      total_marks:modelset[item]?.total_marks,
      pass_marks:modelset[item]?.pass_marks,
      has_passage:modelset[item]?.has_passage,
      exam_time_duration:modelset[item]?.exam_time_duration,
      question:Object.keys(questions).map((qitem)=>{
        options = questions[qitem]['options']
        return{
          quiz_id:questions[qitem]?.quiz_id,
          question:questions[qitem]?.question,
          correct_option:questions[qitem]?.correct_option,
          answer_desc:questions[qitem]?.answer_desc,
          options:options && Object.keys(options).map((oitem)=>{
            return {
              option_id:options[oitem]?.option_id,
              option:options[oitem]?.options,
              correct:options[oitem]?.correct
            }
          })
        }
      })
    }
  })
  // console.log("questions",questions,Object.keys(questions))
  var quizLength = questions && Object.keys(questions).length
  var quiz = questions && Object.keys(questions)
  // quiz.length = quizLength   
  const indexOfLastQuiz = currentPage * resPerPage
  const indexOfFirstQuiz = indexOfLastQuiz - resPerPage
  const currentQuiz = quiz && quiz.slice(indexOfFirstQuiz,indexOfLastQuiz)
  const howManyPages = quizLength && Math.ceil(quizLength/resPerPage)
  const numberOfPages = []
  for (let i=1;i<=howManyPages;i++){
    numberOfPages.push(i)
  }
const nextPage = () =>{
    setCurrentButton(prev => prev >= numberOfPages.length ? prev : prev + 1)
    // dispatch(getQuizAll(currentButton*resPerPage,resPerPage))
}
const prevPage = () =>{
    setCurrentButton(prev => prev <= 1 ? prev : prev - 1)
    // dispatch(getQuizAll((currentButton-2)*resPerPage,resPerPage))
}
const currPage = (item) =>{
    setCurrentButton(item)
    // dispatch(getQuizAll((item-1)*resPerPage,resPerPage))
}

const perPage = [5,10,15,20]
  return (
    <BodyContainer pageTitle="Modelset View" buttonTitle="cancel" route="/modelsetSetup/">
      {loading && <LoaderSpinner/>}
    <Card>
      <div className='card-body'>
        <label>Modelset Details</label>
        {
          modelset &&
          Object.keys(modelset).map(function(key){
            questions = modelset[key]['questions']
            paragraph = modelset[key]['paragraph']
            return(
              <>
              <div className='row'>
                <div className='col-md-3'>
                  {
                    modelset[key]?.modelset_name
                  }
                </div>
                <div className='col-md-2'>
                <label>full marks</label>:&nbsp;
                  {
                    modelset[key]?.total_marks
                  }
                </div>
                <div className='col-md-2'>
                <label>pass marks</label>:&nbsp;
                  {
                    modelset[key]?.pass_marks
                  }
                </div>
                <div className='col-md-3'>
                <label> show per page:</label>&nbsp;
                      <select className='form-control' onChange={(e)=>setResPerPage(e.target.value)}>
                        
                        {
                          perPage &&
                          perPage.map((item,index)=>(
                            <option key={index} value={item} >{item}</option>
                          ))
                        }
                      
                      </select>
                </div>
              </div>
              </>
            )
          })
        }
      </div>
    </Card>
    <div className='row'>
        <div className='col-md-12'>         
           {
             
             currentQuiz &&
             currentQuiz.map((key,index)=>{
              
               options = questions[key]['options']
               return (
                 <>
                 {/* <Card>
                   <div className="card-body">
                      <label> show per page:</label>&nbsp;
                      <select className='form-control' onChange={(e)=>setResPerPage(e.target.value)}>
                        
                        {
                          perPage &&
                          perPage.map((item,index)=>(
                            <option key={index} value={item} >{item}</option>
                          ))
                        }
                      
                      </select>
                    </div>
                 </Card> */}
                 <Card>
                   <div className='card-body'>
                     <div className='row'>
                       <div className='col-md-1'>
                          {questions[key]?.qsn_no}
                       </div>
                     <div className='col-md-9'>
                     <MathJax.Html html={questions[key]?.question}/>                      
                     </div>
                     <div className='col-md-2 float-sm-right'>
                        {questions[key]?.marks_weightage} marks
                     </div>
                     </div>
                   </div>
                 </Card>
                 {
                   options &&
                   Object.keys(options).map((keyOptions)=>(
                     <>
                     <div className='col-md-6'>
                       <Card>
                         <div className='card-body'>
                           <MathJax.Html html={options[keyOptions]?.options}/>
                            
                         </div>
                       </Card>
                     </div>
                     </>
                   ))
                 }
                 </>
               )
             })
           }
        </div>
        <div className='col-md-4'>
            <Card>

            </Card>
        </div>
    </div>
    {
      paragraph && (currentButton === numberOfPages.length)? 
      Object.keys(paragraph).map((pkey)=>{
          pquestion = paragraph[pkey]['questions']
          return (
            <>
            <Card>
              <div className='card-body'>
              <MathJax.Html html={paragraph[pkey]?.paragraph}/>
              </div>
            </Card>
            {
              pquestion &&
              Object.keys(pquestion).map(pqkey=>{
                poptions = pquestion[pqkey]['options']
                return(
                  <>
                  <div className='row'>
                    <div className='col-md-12'>
                    <Card>
                      <div className='card-body'>
                          <MathJax.Html html={pquestion[pqkey]?.question}/>
                      </div>
                    </Card>
                    </div>
                  </div>
                  <div className='row'>
                  {
                poptions &&
                Object.keys(poptions).map((opKey)=>{
                  return (
                    <>
                    
                      <div className='col-md-6'> 
                        <Card>
                          <div className='card-body'>
                            <MathJax.Html html={poptions[opKey]?.options}/>                              
                          </div>
                        </Card>
                      </div>                    
                  
                    </>
                  )
                })
              }
              </div>
                </>
              )
              })
            }
            </>
          )
      })
      :''
    }
     {
            (howManyPages >=1) ?
            <Pagination 
            numberOfPages={numberOfPages} 
            setCurrentPage={setCurrentPage} 
            currentButton={currentButton}
            setCurrentButton={setCurrentButton}
            arrOfCurrButtons={arrOfCurrButtons}
            setArrOfCurrButtons={setArrOfCurrButtons}
            nextPage={nextPage}
            prevPage={prevPage}
            currPage={currPage} 
            />
            :''
        }
    </BodyContainer>
  )
}

export default ModelsetQuizView