import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BodyContainer from '../DashboardTemplate/BodyContainer';
import ReactDataTable from '@ashvin27/react-datatable';
import './QuizTagSetup.css';
import { searchQuiz } from '../../store/QuizSetupStore';
import MathJax from 'mathjax3-react';
import { Link } from 'react-router-dom';
import QuizTag from './QuizTag';
import QuizDetails from './QuizDetails';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import Card from '../Card/Card';
import { ErrorMessage, Form, Formik } from 'formik';
import CkeditorField from '../Form/CkeditorField';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import TextError from '../../TextError';

// import { MathJaxContext,MathJax } from 'better-react-mathjax';
function QuizTagSetup() {
    const [filterData,setFilterData] = useState('');
    const [openPopUp,setOpenPopUp] = useState(false);
    const [openViewPopUp,setViewOpenPopUp] = useState(false);
    const [question,setQuestion] = useState();
    const dispatch = useDispatch();
    const quizData = useSelector(state => state.entities.quiz?.searchResult?.data);
    const loading = useSelector(state => state.entities.quiz?.loading);
    console.log("quizdata",quizData);
    useEffect(()=>{
        let data = {'query_string':filterData}
        if(data?.query_string)
            dispatch(searchQuiz(data))
    },[filterData,dispatch])

    const tableChangeHandler = (data)=>{       
        setFilterData(data?.filter_value)
    }
    const clickTagQuiz=(data)=>{
        setQuestion(data);
        setOpenPopUp(true);
    }
    const clickTagViewQuiz=(data)=>{        
        setQuestion(data);
        setViewOpenPopUp(true);
    }
    const COLUMNS = [
        {
            key:'question',
            text:'Question',
            maxWidth:650,
            cell : record => {
                return(
                    <>                   
                        <MathJax.Html html={record?.question}/>                    
                    </>
                )
            }
        },
        {
            key:'correct_option',
            text:'Correct Options',
            cell : record => {
                return(
                    <>                   
                        <MathJax.Html html={record?.correct_option}/>                      
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <Link
                            className="btn btn-success btn-sm"                           
                            style={{marginRight: '5px'}}
                            to='#'
                            onClick={()=> clickTagQuiz(record)}
                            >
                            <i className="fa fa-plus">&nbsp;&nbsp;Tag Quiz</i>
                        </Link>
                       
                        <Link 
                            to='#'
                            style={{marginLeft:5}}
                            className="btn btn-primary btn-sm" 
                            onClick={()=>clickTagViewQuiz(record)}
                            >                            
                            <i className="fa fa-eye">&nbsp;&nbsp;view</i>
                        </Link> 
                    </>
                );
            }
        }
    ]
    const initialValues = {
        query_string:''
    }
    const validationSchema = Yup.object().shape( {
        query_string: Yup.string().required().label("search text")
    })
   const handleSubmit = (values,submitProps)=>{
       values.query_string = values.query_string.slice(0,-4)
        dispatch(searchQuiz(values));
        submitProps.setSubmitting(false)
    }
  return (   
      
    <BodyContainer pageTitle="Quiz Tag Setup">
        <Card>
           <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit = {handleSubmit}
                >
                    {
                        ({isSubmitting})=>(                            
                            <Form>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <CkeditorField
                                        name="query_string"                                
                                        />
                                        <ErrorMessage name='query_string' component={TextError}/>
                                        </div>
                                    </div>                                
                                <div className='row'>
                                    <div className='col-md-12'>
                                    <button type='submit' className='btn btn-success btn-flat float-right' disabled={isSubmitting}>
                                        {
                                            isSubmitting && <Spinner/>
                                        }
                                        &nbsp;
                                        <i className='fa fa-search'/>&nbsp; search
                                    </button>

                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
           </div>
       </Card>
        {loading && <LoaderSpinner/>}
        <ReactDataTable
        columns = {COLUMNS}
        records={quizData}
        onChange = {tableChangeHandler}
        />
        {
            openPopUp &&
        <QuizTag openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} question={question}/>
        }
        {
            openViewPopUp &&
        <QuizDetails openPopUp={openViewPopUp} setOpenPopUp={setViewOpenPopUp} question={question}/>
        }
    </BodyContainer>    
  )
}

export default QuizTagSetup