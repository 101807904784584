import MathJax from 'mathjax3-react';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { viewQuizDetails } from '../../store/QuizSetupStore';
import FormPopUp from '../popup/PopUp';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function QuizDetails({openPopUp,setOpenPopUp,question}) {     
    const dispatch = useDispatch();
    const quizData = useSelector(state => state.entities.quiz?.getDataById?.data)
    const loading = useSelector(state => state.entities.quiz?.loading)
    useEffect(()=>{
        dispatch(viewQuizDetails(question?.iid))
    },[])
  return (
      <FormPopUp title="Quiz Details" openPopup={openPopUp} setOpenPopup={setOpenPopUp} >       
        {loading && <LoaderSpinner/>}
            <label>Question</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.question?quizData?.question:'N/A'}/></span><br/>
            <label>Correct Option</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.correct_option?quizData?.correct_option:'N/A'}/></span><br/>
            <label>Answer Description</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.answer_desc?quizData?.answer_desc:'N/A'}/></span><br/>
            <label> Options :</label><br/>
            {
                quizData?.quiz_options && quizData?.quiz_options.map((item,index)=>(
                    <>
                    <label>Option {index+1}</label>&nbsp;:&nbsp;<span><MathJax.Html html={item?.options?item?.options:'N/A'}/></span>&emsp;<label>Correct Answer</label>&nbsp;:&nbsp;<span>{item?.correct?'True':'False'}</span><br/>
                    </>
                ))
            }               
   
    </FormPopUp>
  )
}

export default QuizDetails