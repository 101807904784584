import React  from 'react';
import FormPopUp from '../popup/PopUp';
import {Formik,Form} from 'formik';
import { useDispatch } from 'react-redux';
import {deactivateCoursePrice} from '../../store/CoursePriceStore';
function DeactivateCoursePrice({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch();
    
    const initialValues = {        
        cp_id:data?.iid,        
    }
    // const {isSubmitting} = useFormik();
    const handleSubmit = (values,submitProps)=>{
        dispatch(deactivateCoursePrice(values))
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Deactivate Course Price">
        <label>Course Name :</label><span>{data?.course_name}</span><br/>
        <label>Course Price :</label><span>{data?.course_price}</span><br/>
        <p>Deactivate course price?</p>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <button type='submit' className='btn btn-success btn-flat' disabled={isSubmitting}>
                            <i className='fa fa-lock'/>&nbsp; Deactivate
                        </button>
                    </Form>
                )
            }
        </Formik>
        
    </FormPopUp>
  )
}

export default DeactivateCoursePrice