import React,{useEffect,useState} from 'react';
import {Formik,Form,Field,ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextError from '../../TextError';
import axios from '../../axios';
import { useDispatch, useSelector } from 'react-redux';
import { editCourse, getCourseBySlug } from '../../store/CourseSetup';
import { useParams } from 'react-router-dom';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function CourseSetupEdit(props) {
       const {setFetchData,fetchData} = props
       const [category,setCategory] = useState([])      
       const dispatch = useDispatch();
       const params = useParams();
       const course_data = useSelector(state => state.entities.courses.result);
       const errors = useSelector(state => state.entities.courses.error);
       const isLoading = useSelector(state => state.entities.courses.loading)
              
       useEffect(()=>{         
         dispatch(getCourseBySlug(params.slug));
         async function getCategory(){
           const result = await axios.get('/course_category/getCategorySelect')
           setCategory(result?.data?.data)
         }       
        getCategory()
       
       },[params]) 
        const validationSchema = Yup.object().shape({
          course_name: Yup.string().min(3,'must have more than 3 characters').required('This field is required'),
          course_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required'),
          course_categories: Yup.string().required('Course Category must selected'),
          course_preview_url:Yup.string().url().required().label('course preview url'),
          course_banner_description:Yup.string().required('Banner description is required').min(30).label('Banner Description'),
          program_overview:Yup.string().required('Banner description is required').min(20).label('Program Overview'),
          modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks") 
        })
        const initialValues = {          
          course_name : course_data?.data?.course_name,
          course_code: course_data?.data?.course_code,
          course_categories: course_data?.data?.cat_id,
          course_banner_description: course_data?.data?.course_banner_description,
          course_preview_url: course_data?.data?.course_preview_url,
          program_overview: course_data?.data?.program_overview,
          lecture_hours: course_data?.data?.lecture_hours,
          modified_remarks:''
        }
        const changeData = (prevData) => setFetchData(!prevData);
        const handleSubmit = (values,submitProps)=>{
          values.course_id = course_data?.data?.iid         
          dispatch(editCourse(values));          
         submitProps.setSubmitting(false);
         changeData(fetchData);
      }
      const CHOICES = [
        {
          'key':'y',
          'value':'YES'
        },
        {
          'key':'n',
          'value':'NO'
        },
      ]
    return (
        <>            
  <div className="row">
  <div className="col-md-12">
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Course Edit</h3>
    </div>
    <Formik 
    initialValues={initialValues} 
    onSubmit={handleSubmit} 
    validationSchema={validationSchema} 
    enableReinitialize
    >
    {formik=>(
    <Form>
      <div className="card-body">            
        <div className="form-group item">
          <label className="required-label">Course Title <span className="errors">*</span>:</label>
          <Field type="text" name = "course_name" id="course_name" placeholder = "category title"  className="form-control"  />
          <ErrorMessage name="course_name" component={TextError}/>
          <div className="errors">{errors?.data?.course_name && errors?.data?.course_name}</div>
        </div> 
                   
        <div className="form-group item">
          <label className="required-label">Course code <span className="errors">*</span>:</label>
          <Field type="text" name = "course_code" id="course_code" placeholder = "course code"  className="form-control"  />
          <ErrorMessage name="course_code" component={TextError}/>
          <div className="errors">{errors?.data?.course_code && errors?.data?.course_code}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Course Preview Url <span className="errors">*</span>:</label>
          <Field type="text" name = "course_preview_url" id="course_preview_url" placeholder = "url https://"  className="form-control" />
          <ErrorMessage name="course_preview_url" component={TextError}/>
          <div className="errors">{errors?.data?.course_preview_url && errors?.data?.course_preview_url}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Course Banner Description <span className="errors">*</span>:</label>
          <Field as="textarea" rows="7" name = "course_banner_description" id="course_banner_description" placeholder = "banner description"  className="form-control" />
          <ErrorMessage name="course_banner_description" component={TextError}/>
          <div className="errors">{errors?.data?.course_banner_description && errors?.data?.course_banner_description}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Program Overview <span className="errors">*</span>:</label>
          <Field as="textarea" rows="7" name = "program_overview" id="program_overview" placeholder = "program overview"  className="form-control" />
          <ErrorMessage name="program_overview" component={TextError}/>
          <div className="errors">{errors?.data?.program_overview && errors?.data?.program_overview}</div>
        </div> 
        <div className="form-group item">
            <label>Has Exam?</label>
            <Field as="select" id="has_exam" name="has_exam" placeholder="select option" className="form-control">
              <option value=''>select option</option>
              {CHOICES && CHOICES.map(option=>{
                return(
                  <option key={option.key} value={option.key}>{option.value}</option>
                )
              })}
           onChange={value=>{props.setFieldValue('has_exam',value.value)}}
              </Field>
            
           
            <ErrorMessage name="has_exam" component={TextError}/>
            <div className="errors">{errors?.data?.has_exam && errors?.data?.has_exam}</div>
            </div>      
        
        <div className="form-group item">
            <label>Course Category</label>
            <Field as="select" id="course_categories" name="course_categories" placeholder="select option" className="form-control">
              <option value=''>select option</option>
              {category.map(option=>{
                return(
                  <option key={option.value} value={option.iid}>{option.category_name}</option>
                )
              })}
          
              </Field>
              {/* {console.log(categoryDefault)} */}
            {/* <Select placeholder="select course category" name="course_categories" id="course_categories"
            isSearchable={true} 
            options={category} 
            value={props.values.course_categories}
            menuPortalTarget={document.querySelector(".card-body")} 
            menuPosition="fixed" isClearable={true} 
            onChange={value=>{ console.log('value',value);value?props.setFieldValue('course_categories',value.value):props.setFieldValue('course_categories',"")}}
            
            /> */}
            
            <ErrorMessage name="course_categories" component={TextError}/>
            <div className="errors">{errors?.data?.course_categories && errors?.data?.course_categories}</div>
            </div> 
            <div className="form-group item">
          <label className="required-label">Modified Remarks <span className="errors">*</span>:</label>
          <Field type="text" name = "modified_remarks" id="modified_remarks" placeholder = "modified remarks"  className="form-control"  />
          <ErrorMessage name="modified_remarks" component={TextError}/>
          <div className="errors">{errors?.data?.modified_remarks && errors?.data?.modified_remarks}</div>
        </div>      
      </div>
      <div className="card-footer">
        <button type="submit" className="btn btn-flat btn-success" name="save" id="save" disabled={formik.isSubmitting}>Save </button>
      </div>
    </Form>
    )}
    </Formik>
  </div>
</div>
 </div>  
 {isLoading?<LoaderSpinner/>:null}       
 </>
    )
}

export default CourseSetupEdit
