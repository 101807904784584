import React from 'react'
import Tree from 'react-animated-tree';
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {Link } from 'react-router-dom';
 import '../CourseSetup/style.css';
import { Paper } from '@material-ui/core';
import Spinner from '../Layout/Spinner';
const treeStyles = {
    color: 'white',
    fill: 'white',
    width: '100%'
  }
function DesignationTree({data,loading}) {
    
    return (
       <Paper style={{backgroundColor:'#343a40',padding:15,minHeight:300}}>
           {
               loading?<Spinner/>:
               data && data.map((item) => (
                   <Tree
                   key={item.department_code}
                   content={item.department_name+'-'+item.department_code}
                   style={treeStyles}
                   type={[
                       <Link key={item.id} to={`/designation/addDesignation/${item.id}`}><AddRoundedIcon/></Link>,
                    //    <Link to={`/department/editDepartment/${item.id}`}><EditRoundedIcon/></Link>
                   ]}
                  >                       
                       {item.designation && <ChildDesignationTree data={item.designation}/>}

                   </Tree>
               ))
           }
       </Paper>
    )
}

const ChildDesignationTree=({data})=>{
    return(
        <>
    {data && data.map((items) => (
        <Tree 
        key={items.designation_code} 
        content={items.designation+'-'+items.designation_code}
        type={[
    //   <Link  to={`/coursesetup/add_subcourse/${items.id}`} > <AddRoundedIcon /></Link>,
      <Link key={items.id} to={`/designation/editDesignation/${items.id}`}><EditRoundedIcon  /></Link>]}
    style={treeStyles}
      />
    ))}
    </>
    )
}


export default DesignationTree
