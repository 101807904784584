import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { addMenu, getOuterMenuAll } from '../../store/MenuSetupStore'
import TextError from '../../TextError'
import Card from '../Layout/Card'
import Spinner from '../Layout/Spinner'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function MenuAdd({fetchData,setFetchData}) {
    const outerMenu = useSelector(state=>state.entities.menu?.getData?.data);
    const loading = useSelector(state=>state.entities.menu?.loading);
    const dispatch = useDispatch();
    const params = useParams();
    useEffect(()=>{
        dispatch(getOuterMenuAll())
    },[])
    
    const initialValues = {
        menu_types:'',
        menu_name:'',
        menu_route:'',
        menu_icon:'',
        parent: params.slug
    }
    const validationSchema = Yup.object().shape({
        menu_types: Yup.string().required().label("Menu Type"),
        menu_name:Yup.string().required().min(3).max(20).label("Menu Name"),
        menu_route:Yup.string().required().label("Menu Route"),
        menu_icon: Yup.string().required().label("Menu Icon"),
    })
    const changeData = (prev)=>setFetchData(!prev)
    const handleSubmit = async(values,submitProps)=>{
        await dispatch(addMenu(values))
        changeData(fetchData)
        console.log("values",values)
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    const MENUTYPE = [
        {
            'label':'INNER',
            'value':'inner'
        },
        {
            'label':'OUTER',
            'value':'outer'
        }
    ]
    return (
        <>
        {loading && <LoaderSpinner/>}
         <Formik
         initialValues={initialValues}
         validationSchema={validationSchema}
         onSubmit={handleSubmit}
         enableReinitialize
         >
             {
                 ({isSubmitting})=>(
                     <Card cardTitle="Add Menu">
                             <Form>
                            <div className="form-group">
                                <label>Menu Name<span className="errors">*</span>:</label>
                                <FastField name="menu_name" className="form-control" placeholder="Menu Name"/>
                                <ErrorMessage name="menu_name" component={TextError}/>
                            </div>
                            <div className="form-group">
                                <label>Menu Route<span className="errors">*</span>:</label>
                                <FastField name="menu_route" className="form-control" placeholder="Menu Route"/>
                                <ErrorMessage name="menu_route" component={TextError}/>
                            </div>
                            <div className="form-group">
                                <label>Menu Icon<span className="errors">*</span>:</label>
                                <FastField name="menu_icon" className="form-control" placeholder="Menu Icon"/>
                                <ErrorMessage name="menu_icon" component={TextError}/>
                            </div>
                            <div className="form-group">
                                <label>Parent Menu<span className="errors">*</span>:</label>
                                <Field as="select" name="parent" className="form-control" >
                                    <option value="">Select Parent Menu</option>
                                    {
                                        outerMenu && outerMenu.map((item)=>(
                                            <option key={item.menu_name} value={item.id}>{item.menu_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="parent" component={TextError}/>
                            </div>
                            <div className="form-group">
                                <label>Menu Type<span className="errors">*</span>:</label>
                                <Field as="select" name="menu_types" className="form-control" >
                                    <option value="">Select Menu Type</option>
                                    {
                                        MENUTYPE && MENUTYPE.map((item)=>(
                                            <option key={item.value} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="menu_types" component={TextError}/>
                            </div>
                            <button className="btn btn-success" disabled={isSubmitting} type="submit">{ isSubmitting &&<Spinner/>}Save</button>
                        </Form>
                         </Card>
                        
                 )
             }
        </Formik>   
        </>
    )
}

export default MenuAdd
