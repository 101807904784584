import React,{useState,useEffect} from "react";
import FormPopUp from "../popup/PopUp";
import { useDispatch, useSelector } from "react-redux";
import ReactDataTable from '@ashvin27/react-datatable';
import { getEnquiryNotes } from "../../store/EnquiryStore";
function EnquiryNotesDetails({openPopup,setOpenPopup,enquiry}){
    const dispatch = useDispatch()
    const notes = useSelector(state => state.entities?.enquiry?.enquiryNotes?.data)
    useEffect(()=>{
        dispatch(getEnquiryNotes(enquiry?.iid))
    },[enquiry])
    const columns = [
        {
            key:'enquired_notes',
            text:'Notes'
        },
        {
            key:'enquired_date',
            text:'enquired date'
        },       
        {
            key:'enquired_by',
            text:'Enquired By'
        }        
        
    ]
    return(
        <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={`Enquiry Status`}>
            <ReactDataTable
            columns= {columns}
            records = {notes}
            />
        </FormPopUp>
    )
}
export default EnquiryNotesDetails