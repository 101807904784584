import MathJax from 'mathjax3-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { viewTagQuizDetails } from '../../store/QuizSetupStore'
import FormPopUp from '../popup/PopUp'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'

function ViewQuizTagDetails({openPopUp,setOpenPopUp,question}) {
    const dispatch = useDispatch()
    const quizTag = useSelector(state => state.entities.quiz.getDataById?.data)
    const loading = useSelector(state => state.entities.quiz.loading)
    useEffect(()=>{
        dispatch(viewTagQuizDetails(question?.tag_id));
    },[question])
  return (
    <FormPopUp openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
      {loading && <LoaderSpinner/>}
        <MathJax.Provider>
            <label>Course Name</label>&nbsp;:&nbsp;<span>{quizTag?.course_name?quizTag?.course_name:'N/A'}</span><br/>
            <label>Subcourse Name</label>&nbsp;:&nbsp;<span>{quizTag?.subcourse_name?quizTag?.subcourse_name:'N/A'}</span><br/>
            <label>Main Topic</label>&nbsp;:&nbsp;<span>{quizTag?.topic_title?quizTag?.topic_title:'N/A'}</span><br/>
            <label>Lesson</label>&nbsp;:&nbsp;<span>{quizTag?.lesson_title?quizTag?.lesson_title:'N/A'}</span><br/>
            <label>Question</label>&nbsp;:&nbsp;<span>{quizTag?.question?<MathJax.Html html={quizTag?.question}/>:'N/A'}</span><br/>
            <label>Correct Option</label>&nbsp;:&nbsp;<span>{quizTag?.correct_option?<MathJax.Html html={quizTag?.correct_option}/>:'N/A'}</span><br/>
            <label>Answer Description</label>&nbsp;:&nbsp;<span>{quizTag?.answer_desc?<MathJax.Html html={quizTag?.answer_desc}/>:'N/A'}</span><br/>
            <label>Created By</label>&nbsp;:&nbsp;<span>{quizTag?.created_by?quizTag?.created_by:'N/A'}</span><br/>
            <label>Created Date</label>&nbsp;:&nbsp;<span>{quizTag?.created_date?quizTag?.created_date:'N/A'}</span><br/>
            <label>Modified By</label>&nbsp;:&nbsp;<span>{quizTag?.modified_by?quizTag?.modified_by:'N/A'}</span><br/>
            <label>Modified Date</label>&nbsp;:&nbsp;<span>{quizTag?.modified_date?quizTag?.modified_date:'N/A'}</span><br/>
            <label>Modified Remarks</label>&nbsp;:&nbsp;<span>{quizTag?.modified_remarks?quizTag?.modified_remarks:'N/A'}</span><br/>
        </MathJax.Provider>
    </FormPopUp>
  )
}

export default ViewQuizTagDetails