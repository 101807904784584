import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {addOnboardMobile} from '../../store/OnboardMobileSetupStore'
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import ImageUploader from '../Form/ImageUploader';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ProgressBar from '../ProgressBar/ProgressBar'
function OnboardAdd({openPopUp,setOpenPopUp}) {
    const dispatch = useDispatch();
    const errors = useSelector(state => state.entities.onboard?.error?.data);
    const progress = useSelector(state => state.entities.onboard?.progress)
    const loading = useSelector(state => state.entities.onboard?.loading);
    const initialValues = {
        onboard_title:'',
        onboard_img:'',
        onboard_priority:'',        
    }
    const handleSubmit = (values,submitProps) =>{
        let formData = new FormData()
        formData.append('onboard_title',values.onboard_title);        
        formData.append('onboard_img',values.onboard_img,values.onboard_img.name);        
        formData.append('onboard_priority',values.onboard_priority);
        formData.append('created_by',localStorage.getItem('username'));
        dispatch(addOnboardMobile(formData));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
    const validationSchema = Yup.object().shape({
        onboard_title: Yup.string().required().min(4).max(30).label("onboard title"),
        onboard_priority:Yup.number().required().min(1).max(2).label("onboard priority"),
        onboard_img:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("onboard img"),

    })
  return (
    <FormPopUp title="Onboard Mobile Banner Add" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=> (
                    <Form>
                        <div className='form-group'>
                            <label>Onboard Title </label><span className='errors'>*</span>
                            <Field name="onboard_title" className='form-control' placeholder="onboard title"/>
                            <ErrorMessage name='onboard_title' component={TextError}/>
                            {errors?.onboard_title && <div className='errors'>{errors?.onboard_title}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Onboard priority </label><span className='errors'>*</span>
                            <Field type="number"  name="onboard_priority" className='form-control' placeholder="onboard priority"/>
                            <ErrorMessage name='onboard_priority' component={TextError}/>
                            {errors?.onboard_priority && <div className='errors'>{errors?.onboard_priority}</div>}
                        </div>
                        <ImageUploader
                        name="onboard_img"
                        required="required"
                        label="onboard img"
                        />                        
                        <div>                            
                            {                            
                            progress &&
                            progress?.percent > 0?<ProgressBar percentage={progress}/>:''
                            }
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/> &nbsp; save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default OnboardAdd