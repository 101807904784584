import { FastField, Form, Formik,ErrorMessage } from 'formik'
import React from 'react'
import Card from '../Card/Card'
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner'
import * as Yup from 'yup' 
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../store/UserSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function ChangePassword() {
    // const user = useSelector(state => state.entities.users?.result?.data)
    const error = useSelector(state => state.entities.users?.error)
    const loading = useSelector(state => state.entities.users?.loading)
    const dispatch = useDispatch()
    const initialValues = {
        old_password:'',
        new_password:'',
        re_password:''
    }
    const validationSchema = Yup.object().shape({
        old_password: Yup.string().required("This field is required").label("old password"),
        new_password: Yup.string().required("This field is required").label("new password").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          ),
        re_password: Yup.string().when("new_password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "Both password need to be the same"
          )
        })
      });
      const handleSubmit = (values,submitProps) =>{
          dispatch(changePassword(values))
          submitProps.setSubmitting(false);
      }
  return (
    <Card cardTitle={`Change Password`}>
        {loading && <LoaderSpinner/>}
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='card-body'>
                            <div className='row'>
                                <div className='col-md-12 form-group'>
                                    <label>old password:</label><span className='errors'>*</span>
                                    <FastField type="password" className="form-control" placeholder="old password" name="old_password"/>
                                    <ErrorMessage name="old_password" component={TextError}/>
                                    {error?.old_password && <div className='errors'>{error?.old_password}</div>}
                                </div>
                                <div className='col-md-12 form-group'>
                                    <label>new password:</label><span className='errors'>*</span>
                                    <FastField type="password" className="form-control" placeholder="new password" name="new_password"/>
                                    <ErrorMessage name="new_password" component={TextError}/>
                                </div>
                                <div className='col-md-12 form-group'>
                                    <label>Re-password:</label><span className='errors'>*</span>
                                    <FastField type="password" className="form-control" placeholder="retype password" name="re_password"/>
                                    <ErrorMessage name="re_password" component={TextError}/>
                                </div>
                            </div>
                            </div>
                            <div className='card-footer'>
                            <button type='submit' className='btn btn-flat btn-success' disabled={isSubmitting}>
                                {isSubmitting&&<Spinner/>} <i className='fa fa-key'/>&nbsp;change password
                            </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
    </Card>
  )
}

export default ChangePassword