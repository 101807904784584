import { Form, Formik } from 'formik'
import React from 'react'

function WebForm({initialValues,onSubmit,validationSchema,children,enableReinitialize}) {
    return (
        <>
           <Formik
           initialValues={initialValues}
           onSubmit={onSubmit}
           validationSchema={validationSchema}
           enableReinitialize
           >
               {()=>(
                   <Form>
                   {children}
                   </Form>
               )}
            </Formik> 
        </>
    )
}

export default WebForm
