

 
const requests = {
    //account controls endpoint
    userCreate:'/auth/users/',
    // login:'/auth/jwt/create/',
    login:'/auth/login',
    socialAuth: `/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_URL}/google`,
    facebookAuth:`/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_BASE_URL}/facebook`,
    // login:'/auth/api/token',
    users: '/auth/users/me/',
    refreshToken:'/auth/refresh',
    // refreshToken: '/auth/api/refresh',
    resetPassword: '/auth/users/reset_password/',
    changePassword: '/auth/changePassword',
    logout: '/auth/logout',
    fetchMenu: '/api/menu',    
    // category urls
    fetchCategory: '/course_category/getAllCourseCategory',
    fetchSelectCategory: '/course_category/getCategorySelect',
    createCategory: '/course_category/createCategory',
    getCategory: '/course_category/getCategoryById',
    editCategory: '/course_category/updateCategory',
    getInstructorCourse: '/course_category/getInstructorCourse',
    getCoursePlayList: '/course_category/getCoursePlaylist',
    // course urls
    fetchCourse: '/course_category/getAllCourse',
    fetchSelectCourse: '/course_category/getCourseSelect',
    fetchSelectCourseById: '/course_category/getCourseSelectById',
    createCourse: '/course_category/createCourse',
    getCourseBySlug: '/course_category/getCourseById',
    editCourse: '/course_category/updateCourse',
    editCourseBannerImg: '/course_category/updateCourseBannerImg',
    editCoursePreviewImg: '/course_category/updateCoursePreviewImg',
    updateCertificate:'/course_category/updateCertificate',
    getCertificateById:'/course_category/getCertificateById',
    // subcourse url
    fetchSubCourse: '/course_category/getAllSubCourse',
    fetchSelectSubCourse: '/course_category/getSubCourseSelect',
    fetchSelectSubCourseById: '/course_category/getSubCourseSelectById',
    createSubCourse: '/course_category/createSubCourse',
    getSubCourseBySlug: '/course_category/getSubCourseById',
    editSubCourse: '/course_category/updateSubCourse',
    getSubCourseDetails:'/course_category/viewSubCourseDetails',
    editSubCourseIcon:'/course_category/editSubCourseIcon',
    activateCourse:'/course_category/activateCourse',
    deactivateCourse:'/course_category/deactivateCourse',
    updateCourseDate:'/course_category/updateCourseDate',
    //MainTopic url
    fetchMainTopic: '/course_setup/getAllMainTopic',
    fetchMainTopicSelect: '/course_setup/getAllMainTopicSelect',   
    fetchMainTopicSelectById: '/course_setup/getAllMainTopicSelectById',
    getMainTopic:'/course_setup/getMainTopicById',
    createMainTopic:'/course_setup/createMainTopic',
    updateMainTopic:'/course_setup/updateMainTopic',
    viewMainTopicDetails:'/course_setup/viewMainTopicDetails',
    //lesson url
    fetchLesson: '/course_setup/getAllLesson',
    fetchLessonSelect: '/course_setup/getAllLessonSelect',
    fetchLessonSelectById: '/course_setup/getAllLessonSelectById',
    getLesson:'/course_setup/getLessonById',
    createLesson:'/course_setup/createLesson',
    updateLesson:'/course_setup/updateLesson',
    viewLessonDetails:'/course_setup/viewLessonDetails',
    //SubTopic url
    fetchSubTopic: '/course_setup/getAllSubTopic',
    fetchSubTopicSelect: '/course_setup/getAllSubTopicSelect',
    getSubTopic:'/course_setup/getSubTopicById',
    createSubTopic:'/course_setup/createSubTopic',
    updateSubTopic:'/course_setup/updateSubTopic',
    viewSubTopicDetails:'/course_setup/viewSubTopicDetails',
    addResource:'/course_setup/addResources',
    editResources:'/course_setup/editResources',
    deleteResources:'/course_setup/deleteResources',    
    //company setup url
    getCompany: '/company/getCompany',
    addCompany:'/company/add_all',
    updateCompany:'/company/updateCompany',
    addCompanyLogo: '/company/addLogo',
    getCompanyLogo: '/company/get_logo/',
    //quiz setup url
    createQuiz :'/quiz/createQuiz',
    editQuiz :'/quiz/editQuiz',
    deleteQuiz :'/quiz/deleteQuizByID',
    getQuizById :'/quiz/getQuizById',
    getQuizAll :'/quiz/getQuizAll',
    searchQuestion :'/quiz/searchQuestion',
    quizTag :'/quiz/quizTag',
    quizTagUpdate :'/quiz/quizTagUpdate',
    quizTagReport:'/quiz/quizTagReport',
    viewQuizTagDetails:'/quiz/quizTagDetails',
    getModelsetAll:'/quiz/getQuizModelSetAll',
    getModelsetById:'/quiz/getModelSetById',
    getModelsetByCourse:'/quiz/getModelSetByCourses',
    getModelSetByCoursesYear:'/quiz/getModelSetByCoursesYear',
    createModelSet:'/quiz/createModelSet',
    updateModelSet:'/quiz/updateModelSet',
    activateModelSet:'/quiz/activateModelSet',
    deactivateModelSet:'/quiz/deactivateModelSet',
    getQuizLiveTagAll:'/quiz/getLiveTagAll',
    getQuizLiveTagById:'/quiz/getLiveTagById',
    tagQuizLive:'/quiz/tagQuizLive',
    updatetagQuizLive:'/quiz/updateTagQuizLive',
    setActiveLive:'/quiz/setLive',
    unsetLive:'/quiz/unsetLive',
    filterLiveQuizTag:'/quiz/filterSearch',
    filterModelSetByYear:'/quiz/filterModelsetByYear',
    filterModelSet:'/quiz/filterModelset',
    createPassage:'/quiz/createPassage',
    updatePassage:'/quiz/updatePassage',
    getPassageById:'/quiz/getPassageById',
    filterPassageByCourse:'/quiz/filterPassageByCourse',
    filterModelsetCourseyear:'/quiz/filterModelsetByCourseYear',
    tagQuizToParagraph:'/quiz/tagQuizToParagraph',
    createTagPassageModelset:'/quiz/createTagPassageModelset',
    updateTagPassageModelset:'/quiz/updateTagPassageModelset',
    deleteTagPassageModelset:'/quiz/deleteTagPassageModelset',
    restoreTagPassageModelset:'/quiz/restoreTagPassageModelset',
    getPassageTagModelset:'/quiz/getPassageTagModelset',
    filterPassageModelset:'/quiz/filterPassageModelset',
    getPassageTaggedModelsetById:'/quiz/getPassageTaggedModelsetById',
    getQuizLevelAll:'/quiz/getQuizLevelAll',    
    getQuizLevelById:'/quiz/getQuizLevelById',    
    createQuizLevel:'/quiz/createQuizLevel',    
    updateQuizLevel:'/quiz/updateQuizLevel',   
    getQuizLevelAllSelect:'/quiz/getQuizLevelAllSelect',   
    filterLessonQuiz:'/quiz/filterLessonQuiz',   
    activateLessonQuiz:'/quiz/activateLessonQuiz',   
    deactivateLessonQuiz:'/quiz/deactivateLessonQuiz',
    getModelsetReport:'/quiz/getModelsetReport',   
    getModelsetQuizDetails:'/quiz/getModelsetQuizDetails',   
    getModelsetKUQuizDetails:'/quiz/getModelsetKUQuizDetails',   
    getUntaggedQuizDetails:'/quiz/getUntaggedQuizDetails',   
    deleteTagToModelset:'/quiz/deleteTagToModelset',   
    deleteTagToParagraph:'/quiz/deleteTagToParagraph',   
    createModelsetBatchTag:'/quiz/createModelsetBatchTag',   
    updateModelsetBatchTag:'/quiz/updateModelsetBatchTag',   
    getModelsetBatchTag:'/quiz/getModelsetBatchTag',   
    deleteModelsetBatchTag:'/quiz/deleteModelsetBatchTag',   
    //programoverview url
    fetchProgramOverview:'/course_program/getProgramOverviewAll',
    fetchProgramOverviewById:'/course_program/getProgramOverviewById',
    getProgramOverviewByCourseId:'/course_program/getProgramOverviewByCourseId',
    createProgramOverview:'/course_program/createProgramOverview',
    editProgramOverview:'/course_program/editProgramOverview',
    //alumni url
    fetchAlumniAll:'/alumni/getAlumniAll',
    fetchAlumniByCourseId:'/alumni/getAlumniByCourseId',
    fetchAlumniById:'/alumni/getAlumniById',
    createAlumni:'/alumni/createAlumni',
    editAlumni:'/alumni/editAlumni',
    deleteAlumni:'/alumni/deleteAlumni',
    updateAlumniImg:'/alumni/updateAlumniImg',
    //faq url
    fetchFAQ:'/course_program/getFaqAll',
    fetchFAQById:'/course_program/getFaqById',
    fetchFAQByCourseId:'/course_program/getFaqByCourseId',
    createFAQ:'/course_program/createFaq',
    editFAQ:'/course_program/editFaq', 
    //alumni url   
    //employeeUrl
    getZoneAll:'/places/get_zone_all',
    getDistrictAll:'/places/get_district_all',
    getDistrictByZone:'/places/get_district_by_zone',
    addEmployee:'/employee/create',
    //department
    getDepartment: '/department/getDepartment',
    getDepartmentAll:'/department/getDepartmentAll',
    createDepartment:'/department/createDepartment', 
    getDepartmentById:'/department/getDepartmentById',   
    updateDepartment:'/department/updateDepartment',  
    
    //designation
    getDesignationAll:'/designation/getDesignationAll',
    addDesignation:'/designation/addDesignation',
    editDesignation:'/designation/editDesignation',
    getDesignationById:'/designation/getDesignationById',

    //menu
    getTreeMenu:'/menu/getTreeMenu',
    getMenuAll:'/menu/getMenuAll',
    getOuterMenuAll:'/menu/getOuterMenuAll',
    addMenu:'/menu/addMenu',
    editMenu:'/menu/editMenu',
    getMenuById:'/menu/getMenuById',

    //instructors
    getInstructorById:'/instructor/getInstructorById',
    createInstructor:'/instructor/createInstructor',
    editInstructor:'/instructor/editInstructor',
    editInstructorImg:'/instructor/editInstructorImage',
    fetchInstructorSelect:'/instructor/getInstructorSelect',
    fetchInstructor:'/instructor/getInstructorAll',
    fetchInstructorTagAll:'/instructor/getInstructorTagAll',
    fetchInstructorTagByID:'/instructor/getInstructorTagById',
    fetchInstructorTagByCourse:'/instructor/getInstructorByCourseId',
    createInstructorTag:'/instructor/createInstructorTag',
    editInstructorTag:'/instructor/updateInstructorTag',
    deleteInstructorTag:'/instructor/deleteInstructorTag',
    getAllDeletedTag:'/instructor/getDeletedTagAll',
    restoreDeletedTag:'/instructor/restoreTag',
    //feature url
    fetchFeatureAll:'/feature/getFeaturesAll',
    fetchFeatureSelect:'/feature/getSelectFeature',
    fetchFeatureById:'/feature/getFeatureById',
    fetchFeatureTagByCourseId:'/feature/getFeatureTagByCourseId',
    createFeature:'/feature/createFeature',
    editFeature:'/feature/editFeature',
    deleteFeature:'/feature/deleteFeature',
    fetchFeatureTagAll:'/feature/getFeatureTagAll',
    fetchFeatureTagById:'/feature/getFeatureByTagId',
    createFeatureTag:'/feature/createFeatureTag',
    editFeatureTag:'/feature/editFeatureTag',
    restoreFeatureTag:'/feature/restoreFeatureTag',
    deleteFeatureTag:'/feature/deleteFeatureTag',
    getDeletedFeatureTag:'/feature/getDeletedFeatureTag',
    updateFeatureIcon:'/feature/updateFeatureIcon',
    //banner url
    fetchAllMobileBanner:'/banner/getMobileBannerAll',
    fetchMobileBannerById:'/banner/getMobileBannerById',
    createMobileBanner:'/banner/createMobileBanner',
    updateMobileBanner:'/banner/updateMobileBanner',
    updateMobileBannerImg:'/banner/updateMobileBannerImg',
    fetchAllHomeBanner:'/banner/getHomeBannerAll',
    fetchHomeBannerById:'/banner/getHomeBannerById',
    createHomeBanner:'/banner/createHomeBanner',
    updateHomeBanner:'/banner/updateHomeBanner',
    updateHomeBannerImg:'/banner/updateHomeBannerImg',
    deleteMobileBanner:'/banner/deleteMobileBanner',
    deleteHomeBanner:'/banner/deleteHomeBanner',
    //course_price
    getCoursePriceAll:'/course_price/getCoursePriceAll',
    getCoursePriceById:'/course_price/getCoursePriceById',
    createCoursePrice:'/course_price/createCoursePrice',
    updateCoursePrice:'/course_price/updateCoursePrice',
    filterCoursePrice:'/course_price/filterCoursePrice',
    deleteCoursePrice:'/course_price/deleteCoursePrice',
    activateCoursePrice:'/course_price/activateCoursePrice',
    deactivateCoursePrice:'/course_price/deactivateCoursePrice',
    getCouponAll:'/course_price/getCouponAll',
    getCouponById:'/course_price/getCouponById',
    createCoupon:'/course_price/createCoupon',
    updateCoupon:'/course_price/updateCoupon',
    activateCoupon:'/course_price/activateCoupon',
    deactivateCoupon:'/course_price/deactivateCoupon',
    deleteCoupon:'/course_price/deleteCoupon',
    getCoursePriceSelect:'/course_price/getCoursePriceSelect',
    //mobile onboard urls
    createOnboardBanner:'/onboard/createOnboardBanner',
    getOnboardBannerAll:'/onboard/getOnboardBannerAll',
    updateOnboardBanner:'/onboard/updateOnboardBanner',
    updateOnboardBannerImg:'/onboard/updateOnboardBannerImg',
    deleteOnboardBanner:'/onboard/deleteOnboardBanner',
    restoreOnboardImg:'/onboard/restoreOnboardImg',
    // create Batch
    createBatch:'/enroll/createBatch',
    updateBatch:'/enroll/updateBatch',
    deleteBatch:'/enroll/deleteBatch',
    getBatchAll:'/enroll/getBatchAll',
    filterStudent:'/enroll/filterStudent',
    filterBatch:'/enroll/filterBatch',
    tagBatch:'/enroll/tagBatch',
    filterBatchTag:'/enroll/filterBatchTag',
    updateBatchTag:'/enroll/updateBatchTag',
    deleteBatchTag:'/enroll/deleteBatchTag',
    recoverBatchTag:'/enroll/recoverBatchTag',
    getLectureRecording:'/course_setup/getLectureRecording',
    createRecording:'/course_setup/createRecording',
    updateRecording:'/course_setup/updateRecording',    
    deleteRecording:'/course_setup/deleteLectureRecording',    
    getLectureRecordingById:'/course_setup/getLectureRecordingById',
    //Article url
    getArticleByCourse:'/articles/getArticles',
    createArticles:'/articles/createArticles',
    updateArticleBanner:'/articles/updateArticleBanner',
    updateArticles:'/articles/updateArticles',
    deleteArticles:'/articles/deleteArticles',
    getArticlesById:'/articles/getArticlesById',
    //discussion
    discussion: '/webapi/discussion',
    createDiscussion: '/webapi/createDiscussion',
    updateDiscussion: '/webapi/updateDiscussion',
    //enquiry
    getEnquiryFilter:'/enquiry/getEnquiry',
    updateEnquiryStatus:'/enquiry/updateEnquiry',
    createEnquiryNotes:'/enquiry/createEnquiryNotes',
    getEnquiryNotes:'/enquiry/getEnquiryNotes',

}

export default requests;