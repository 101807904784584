import React, {useState} from 'react';
import * as Yup from 'yup';
import './Login.css';
import { useHistory,  } from 'react-router-dom';
import WebForm from '../Form/WebForm';
import FormField from '../Form/FormField';
import requests from '../../ApiRequests/Request';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
var CryptoJS = require("crypto-js");
function Login() {   
    const history = useHistory();    
    const [loading,setLoading] = useState(false)
    const [errors,setErrors] = useState()    
    const [response,setRespo] = useState()   
    
    
    const initialValues = {
        email: '',
        password:'',             
    }
    
    const validationSchema = Yup.object().shape({
        email: Yup.string().required("").email().label("Email"),
        password: Yup.string().required("")
       
    })
    
  
    const handleSubmit = (values)=>{       
        if(localStorage.getItem('tkn')){
            localStorage.clear();
        }       
        
            axios.defaults.withCredentials = true;
            values.grant_type = 'password'
            values.client_ip = response?.IPv4
            values.city = response?.city
            values.country_code = response?.country_code
            values.country = response?.country_name
            values.state = response?.state
            values.lat = response?.latitude
            values.long = response?.longitude
            values.postal = response?.postal            
            
                 axios.post(`${process.env.REACT_APP_BASE_URL}${requests.login}`,values).then(response => {
                        // dispatch(tokenVerify(response.data?.access))
                        setLoading(false)
                        let string = JSON.stringify(response?.data?.data)                        
                        let uu = CryptoJS.AES.encrypt(string,process.env.REACT_APP_SECRET).toString()
                        localStorage.setItem("Block:Usr",uu)                      
                        history.push('/dashboard')
                 },error=>{
                    setLoading(false);
                     setErrors(error.response?.data?.detail)
                     if(error?.message === "Network Error"){
                         toast.error(`${error?.message} !! Please contact DB Administrators`)
                     }
                 })           
        
        //dispatch(login(values));         
       
               
    }   

    
  

    return (
        <div className="login">
            <div className="login_form">        
        <div className="card">
            <div className="card-header">                
                <h3 className="card-title">VeedhyaPeeth-Admin</h3>            
            </div>
            <div className="card-body">
                {
                    errors &&
                    <div className='errors'>
                        <p>{errors}</p>
                    </div>                

                }
                <WebForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                >
                    <div className="row">
                        <FormField
                        divSize="col-md-12 col-xs-12 col-sm-12 form-group"
                        type="text"
                        placeholder="email"
                        name="email"
                        label="Email"
                        />
                        <FormField
                        type="password"
                        divSize="col-md-12 col-xs-12 col-sm-12 form-group"
                        placeholder="password"
                        name="password"
                        label="Password"
                        />                        
                    </div>
                    {errors && <div className="errors">Invalid Email or Password</div>}
                       <button className="btn btn-success btn-flat" type="submit">Login</button>
                       
                       {/* <GoogleLogin
                        clientId="926898328180-kbrek6u5s4a76kb7tsmqhlv6t94eert8.apps.googleusercontent.com"
                        buttonText="continue with google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                       /> */}
                       {/* <Facebook/> */}
                </WebForm>
            </div>
        </div>
        </div>
        {
            loading && 
            <LoaderSpinner/>
        }
        </div>        
    )
}

export default Login
