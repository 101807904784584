import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCourseSelect } from '../../store/CourseSetup'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { editInstructorTag, getInstructorSelect } from '../../store/InstructorSetup'
import { loadSubCourseSelectById } from '../../store/SubCourseSetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function InstructorTagEdit({openPopUp,setOPenPopUp,instructor}) {
    const [courses,setCourse] = useState(instructor?.course_iid);
    const dispatch = useDispatch()
    const instructors = useSelector(state=>state.entities.instructor?.getData?.data)
    const course = useSelector(state=>state.entities.courses?.result?.data)
    const subcourse = useSelector(state=>state.entities.subcourse?.result?.data)
    const subcourseLoading = useSelector(state=>state.entities.subcourse?.loading)
    const errors = useSelector(state=>state.entities.instructor?.error?.data)
    const loading = useSelector(state=>state.entities.instructor?.loading)
    useEffect(()=>{
        dispatch(getInstructorSelect())
        dispatch(loadCourseSelect());
        dispatch(loadSubCourseSelectById(instructor?.course_iid))
    },[])
    const initialValues = {
        tag_id: instructor?.iid,
        instructor:instructor?.instructor_iid,
        courses:instructor?.course_iid,
        subcourse:instructor?.subcourse_iid,
        modified_remarks:''
    }
    
    const validationSchema = Yup.object().shape({
        instructor:Yup.string().required(),
        courses: Yup.string().required(),
        subcourse: Yup.string().required(),
        modified_remarks: Yup.string().required().max(100).min(5).label('modified remarks')
    })
    const handleSubmit = (values,submitProps)=>{
        values.modified_by = localStorage.getItem('username');
        dispatch(editInstructorTag(values));
        submitProps.setSubmitting(false);
    }
    const handleCourse = (e)=>{
        setCourse(e.target.value);
        dispatch(loadSubCourseSelectById(courses))
    }
  return (
    <FormPopUp title="Tag Instructor" openPopup={openPopUp} setOpenPopup={setOPenPopUp}>
        {loading && <LoaderSpinner/>}
        {subcourseLoading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        {errors?.non_field_errors && <div className="errors">instructor for this course and subject has neen already tagged</div>}                        
                        <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="instructor" id="instructor" placeholder="" className='form-control'  >
                                    <option name="instructor" value="">select Options</option>
                                        {
                                            instructors && instructors?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.instructor_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="instructor" component={TextError} />
                                    {errors?.instructor && <div className="errors">{errors?.instructor}</div>}
                            </div>
                        <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses} onChange={(e)=>handleCourse(e)}  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="courses" component={TextError} />
                                    {errors?.courses && <div className="errors">{errors?.courses}</div>}
                            </div>
                        <div className='form-group item'>
                                <label className='required-label'>Select Subcourse<span className="errors">*</span></label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" className='form-control'  >
                                    <option name="subcourse" value="">select Options</option>
                                        {
                                            subcourse && subcourse?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.subcourse_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="subcourse" component={TextError} />
                                    {errors?.subcourse && <div className="errors">{errors?.subcourse}</div>}
                            </div>
                            <div className='form-group'>
                                <label>Modified Remarks:</label><span className='errors'>*</span>
                                <Field name="modified_remarks" placeholder="modified remarks"  className='form-control'/>
                                <ErrorMessage name="modified_remarks" component={TextError} />
                                    {errors?.modified_remarks && <div className="errors">{errors?.modified_remarks}</div>}
                            </div>
                            <button type='submit' className='btn btn-flat btn-success' disabled={isSubmitting}>
                                {isSubmitting?<Spinner/>:''}<i className='fa fa-save'>&nbsp;save</i>
                            </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default InstructorTagEdit