import React from 'react'
import FormPopUp from '../popup/PopUp'
import MathJax from 'mathjax3-react';
function ViewParagraph({openPopup,setOpenPopup,data}) {
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="View Paragraph Details">
        <label>Course Name:</label>&nbsp;<span>{data?.course_name?data?.course_name:'N/A'}</span><br/>
        <label>Paragraph Title:</label>&nbsp;<span>{data?.paragraph_title?data?.paragraph_title:'N/A'}</span><br/>
        <label>Paragraph</label>&nbsp;<span>{data?.paragraph?<MathJax.Provider><MathJax.Html html={data?.paragraph}/></MathJax.Provider> :'N/A'}</span><br/>
        <label>created by</label>&nbsp;<span>{data?.created_by?data?.created_by:'N/A'}</span><br/>
        <label>created_date</label>&nbsp;<span>{data?.created_date?data?.created_date:'N/A'}</span><br/>
        <label>modified by</label>&nbsp;<span>{data?.modified_by?data?.modified_by:'N/A'}</span><br/>
        <label>modified date</label>&nbsp;<span>{data?.modified_date?data?.modified_date:'N/A'}</span><br/>
        <label>modified remarks</label>&nbsp;<span>{data?.modified_remarks?data?.modified_remarks:'N/A'}</span><br/>
    </FormPopUp>
  )
}

export default ViewParagraph