import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import requests from "../ApiRequests/Request";
import { AuthHeader,  } from "../Config/HeaderType";
import { toast } from "react-toastify";

const slice = createSlice({
    name:'designation',
    initialState:{
        result:[],
        getData:[],
        getDataById:[],
        errors:[],
        loading: false
    },
    reducers:{
        designationRequested:(designation,action)=>{
            designation.loading = true;
        },
        designationReceived: (designation,action) => {
            designation.loading = false;
            designation.result = action.payload;
        },
        designationReceivedById: (designation,action)=>{
            designation.loading = false;
            designation.getDataById = action.payload;
        },
        designationRequestFailed:(designation,action)=>{
            designation.loading = false;
            designation.errors = action.payload;
        },
        designationAdded:(designation,action) => {
            designation.loading = false;
            if(designation.errors) designation.errors = []
            toast.success(action.payload.msg)
        }
    }
})

const {
    designationRequested,
    designationReceived,
    designationReceivedById,
    designationRequestFailed,
    designationAdded
} = slice.actions;

export default slice.reducer;

export const getDesignationAll = ()=>apiCallBegan({
    url: requests.getDesignationAll,
    headers: AuthHeader,
    onStart: designationRequested.type,
    onSuccess: designationReceived.type,
    onError: designationRequestFailed.type
})

export const addDesignation = (data)=>apiCallBegan({
    url: requests.addDesignation,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: designationRequested.type,
    onSuccess: designationAdded.type,
    onError: designationRequestFailed.type
})
export const updateDesignation = (data)=>apiCallBegan({
    url: requests.editDesignation,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: designationRequested.type,
    onSuccess: designationAdded.type,
    onError: designationRequestFailed.type
})
export const getDesignationById = (id)=>apiCallBegan({
    url: requests.getDesignationById+'/'+id,
    headers:AuthHeader,
    onStart: designationRequested.type,
    onSuccess: designationReceivedById.type,
    onError: designationRequestFailed.type
})