import { FastField, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Card from '../Layout/Card';

function LanguageSkillInfo({handleSubmit,prev,data}) {
    const rating = [
        {
            'value':'Excellent',
            'label':'Excellent'
        },
        {
            'value':'Very Good',
            'label':'Very Good'
        },
        {
            'value':'Good',
            'label':'Good'
        },
        {
            'value':'Good',
            'label':'Good'
        },
        {
            'value':'Basic',
            'label':'Basic'
        },
    ]
    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    // const initialValues={
    //     language:[

    //     ]
    // }
    return (
        <Card cardTitle="Language Skill Info">
            <Formik
            initialValues={data}
            onSubmit={handleSubmit}
            >
                {
                    ({values})=>(
                        <Form>
                            <div className="table-responsive">
                                <table className="table m-0">
                                    <thead>
                                        <tr>
                                            <th>S.N.</th>
                                            <th>Language</th>
                                            <th>Reading </th>
                                            <th>Writing</th>
                                            <th>Speaking</th>
                                        </tr>
                                    </thead>
                                    <FieldArray
                                    name="language"
                                    render={
                                        arrayHelpers => (
                                            <tbody>
                                                {
                                                    values.language && values.language.length > 0 ?
                                                    values.language.map((_,index)=>(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <FastField
                                                                name={`language[${index}].language`}
                                                                placeholder="Language Name" 
                                                                className="form-control"                                                               
                                                                />
                                                            </td>
                                                            <td>
                                                                <FastField
                                                                as="select"
                                                                name={`language[${index}].reading_rating`}
                                                                className="form-control"
                                                                >
                                                                    
                                                                        <option value="">Select Rating</option>
                                                                        {
                                                                        rating.map(item => (
                                                                            <option key={item.value} value={item.value}>{item.label}</option>
                                                                        ))  
                                                                        }
                                                                </FastField>
                                                            </td>
                                                            <td>
                                                                <FastField
                                                                as = "select"
                                                                name={`language[${index}].writing_rating`}
                                                                className="form-control"
                                                                >
                                                                    
                                                                        <option value="">Select Rating</option>
                                                                        {
                                                                        rating.map(item => (
                                                                            <option key={item.value} value={item.value}>{item.label}</option>
                                                                        ))  
                                                                        }
                                                                </FastField>
                                                            </td>
                                                            <td>
                                                                <FastField
                                                                as="select"
                                                                name={`language[${index}].speaking_rating`}
                                                                className="form-control"
                                                                >
                                                                    
                                                                        <option value="">Select Rating</option>
                                                                        {
                                                                        rating.map(item => (
                                                                            <option key={item.value} value={item.value}>{item.label}</option>
                                                                        ))  
                                                                        }
                                                                </FastField>
                                                            </td>
                                                            <td>
                                                                {
                                                                    index > 0?
                                                                    <button 
                                                                    type="button"
                                                                    onClick={()=>arrayHelpers.remove(index)}
                                                                    style={buttonStyles}
                                                                    >
                                                                        <DeleteIcon/>
                                                                    </button>
                                                                    :null
                                                                }
                                                                <button 
                                                                type="button"
                                                                style={buttonStyles}
                                                                onClick={()=> arrayHelpers.push({language:'',reading_rating:'',writing_rating:'',speaking_rating:''})}
                                                                >
                                                                    <AddIcon/>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )):(
                                                        <button 
                                                                type="button"
                                                                style={buttonStyles}
                                                                onClick={()=> arrayHelpers.push({language:'',reading_rating:'',writing_rating:'',speaking_rating:''})}
                                                                >
                                                                    <AddIcon/>
                                                                </button>
                                                    )
                                                }
                                            </tbody>
                                        )
                                    }
                                    />
                                </table>
                            </div>
                            <button className="btn btn-success btn-flat" onClick={prev}>Prev</button>
                            <button className="btn btn-success btn-flat">Finish</button>
                        </Form>
                    )
                }
            </Formik>
        </Card>
    )
}

export default LanguageSkillInfo
