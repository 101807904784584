import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { AuthHeader, NormalHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name: 'users',
    initialState:{
        result: [],
        userData:[],
        loading: false,
        verify: false,
        error:[]
    },
    reducers:{
        userRequested: (users,action) => {            
            users.loading = true;
        },
        userReceived: (users,action) =>{
            users.loading = false;
            users.result = action.payload;
            if(users.error) users.error = [];
        },
        userRequestFailed: (users,action) => {
            users.loading = false;
            users.error = action.payload;
            users.verify = false;
        },
        userAdded: (users,action) => {
            users.loading = false;
            users.result = action.payload;
            if(users.error) users.error = [];            
            toast.success(action.payload?.msg)
        },
        userLogin: (users,action) => {
            users.loading= false;
            users.result = action.payload;
            users.verify = true; 
            // localStorage.setItem('tkn',action.payload.access); 
            // dispatch(tokenVerify());         
            if(users.error) users.error = [];
        },
        userData:(users,action) => {
            users.loading = false;
            users.userData = action.payload;
            users.verify = false;            
            if(users.error) users.error = [];
        },
        userResetPassword: (users,action) => {
            users.loading = false;
            users.result = action.payload;
            if(users.error) users.error = [];
        },
        userChangePassword: (users,action) => {
            users.loading = false;
            users.result = action.payload;
            if(users.error) users.error = [];
            toast.success(action.payload?.msg)
            window.location = '/login'
        },
        userLogout: (users,action)=>{
            users.loading = false;
            users.result = action.payload;
            if(users.error) users.error = [];
            localStorage.clear()
            window.location='/login'
        }
    }
})

//destructuring actions
const {
    userRequested,   
    userRequestFailed,
    userAdded,
    userLogin,
    userLogout,
    userData,
    userResetPassword, 
    userChangePassword   
} = slice.actions;

export default slice.reducer;


export const addUser = (user) => apiCallBegan({
    url:requests.userCreate,
    method: 'post',
    headers: AuthHeader,
    data: user,
    onStart:userRequested.type,
    onSuccess:userAdded.type,
    onError: userRequestFailed.type
});

export const login = (user) => apiCallBegan({
    url: requests.login,
    method: 'post',
    headers: NormalHeader,
    data: user,
    onStart: userRequested.type,
    onSuccess: userLogin.type,
    onError: userRequestFailed.type
});
export const logout = (user) => apiCallBegan({
    url: requests.logout,
    method: 'post',
    headers: AuthHeader,
    data: user,
    onStart: userRequested.type,
    onSuccess: userLogout.type,
    onError: userRequestFailed.type
});

export const tokenVerify = (tkn) => apiCallBegan({
    url: requests.users,
    headers: `Bearer ${tkn}`,   
    onStart: userRequested.type,
    onSuccess: userData.type,
    onError: userRequestFailed.type
})

export const resetPassword = (email)=> apiCallBegan({
    url: requests.resetPassword,
    method: 'post',
    data: email,
    onStart: userRequested.type,
    onSuccess: userResetPassword.type,
    onError: userRequestFailed.type
});
export const changePassword = (data)=> apiCallBegan({
    url: requests.changePassword,
    method: 'patch',
    data: data,
    headers:AuthHeader,
    onStart: userRequested.type,
    onSuccess: userChangePassword.type,
    onError: userRequestFailed.type
});
// export const logout = () => {
//     localStorage.removeItem('tkn');

// }