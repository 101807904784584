import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { editCompanyDetails } from '../../store/CompanySetup';
import { ErrorMessage, FastField, Field, FieldArray, Form, Formik} from 'formik';
import TextError from '../../TextError';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import LogoAdd from './LogoAdd';
function CompanyEdit({companyData}) {  
    const errors = useSelector(state=>state.entities.company.error);  
    const dispatch = useDispatch();
    let company_contacts = [];
    useEffect(()=>{     
      companyData && companyData[0]?.company_contact?.map((item)=>{
      company_contacts.push(item?.company_contact_no)      
    })
    })
    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    
    console.log("con",company_contacts)
    const initialValues = {      
        company_id : companyData && companyData[0]?.id,
        company_name:companyData && companyData[0]?.company_name,
        company_address:companyData && companyData[0]?.company_address,
        company_email:companyData && companyData[0]?.company_email,
        company_contact: companyData && companyData[0]?.company_contact,
        company_logo:'',
        contact_id:['']
    }

   

    
    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required().label('Company Name'),
        company_address: Yup.string().required().label('Company Address'),
        company_email:Yup.string().required().email().label("company Email"), 
        company_contact: Yup.array(
          Yup.object({
            company_contact_no: Yup.number().required().label("Company Contact No").min(7,"Contact no should be greater than 7").max(10,"Contact no shouldnot be more than 10 digits")
          })).min(1)       
    });
    const onSubmit = (values) => {     
        dispatch(editCompanyDetails(values))         
    }
    return (
      <>
        <div className="col-md-12">
           <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
            >
                {({values})=>(
                <Form>
                <div className="form-group item">
                    <label>Company Name<span className="errors">*</span></label>
                    <FastField type="text" name="company_name" className="form-control"/>
                    <ErrorMessage name="company_name" component={TextError}/>
                    {errors?.data?.company_name && <div className="errors">{errors?.data?.company_name}</div>}
                </div>
                <div className="form-group item">
                    <label>Company Email<span className="errors">*</span></label>
                    <FastField type="email" name="company_email" className="form-control"/>
                    <ErrorMessage name="company_email" component={TextError}/>
                    {errors?.data?.company_email && <div className="errors">{errors?.data?.company_email}</div>}
                </div>
                <div className="form-group item">
                    <label>Company Address</label>
                    <FastField type="text" name="company_address" className="form-control"/>
                    <ErrorMessage name="company_address" component={TextError}/>                    
                </div>
                
                <div className="form-group item">
                <label>Contact No<span className="errors">*</span></label> 
                <FieldArray
                 name="company_contact"
                 
                 render={arrayHelpers => (
                 <div>
                 {values.company_contact && values.company_contact.length > 0 ? (
                   values?.company_contact.map((contact, index) => (
                     <div key={index}>
                       
                       <Field name={`company_contact[${index}].company_contact_no`} value={contact.company_contact_no}/>&nbsp;&nbsp;
                       { 
                       (index>0 && !contact.id)?
                       
                       <button
                         type="button"
                        //  className="btn btn-flat btn-sm btn-danger"
                        style={buttonStyles}
                         onClick={() => arrayHelpers.remove(index)} // remove a contact from the list
                       >                           
                         <DeleteIcon/>
                       </button>
                       :null
                        }
                       <button
                         type="button"
                         style={buttonStyles}
                        //  className="btn btn-flat btn-sm btn-success"
                         onClick={() => arrayHelpers.push({company_contact_no:''})} // insert an empty string at a position
                       >
                         <AddIcon/>
                       </button>
                       <ErrorMessage name={`company_contact.[${index}].company_contact_no`} component={TextError}/>
                       {errors?.data?.company_contact[index].company_contact_no && <div className="errors">{errors?.data?.company_contact[index].company_contact_no}</div>}
                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push('')}>
                     {/* show this when user has removed all company_contact from the list */}
                     Add a contact
                   </button>
                 )}
                
               </div>
             )}
           />
           </div>
           <div className="form-group item">
                <button className="btn btn-flat btn-sm btn-success">Save</button>
                 </div>
                 <pre>
                 {JSON.stringify(values,null,4)}
               </pre>
                </Form>
                 
            )}
           
           </Formik>
            
        </div>
        <div className="col-md-12">
                <div className="display_logo">
                  <img alt='imgss'></img>
                </div>
                <LogoAdd company_id={companyData && companyData[0]?.id}/>
        </div>
        </>
    )
}

export default CompanyEdit
