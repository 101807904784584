import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  filterCoursePrice} from '../../store/CoursePriceStore';
import TextError from '../../TextError';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ReactDataTable from '@ashvin27/react-datatable';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { loadCourseSelect } from '../../store/CourseSetup';
import ActivateCoursePrice from './ActivateCoursePrice';
import DeactivateCoursePrice from './DeactivateCoursePrice';
import DeleteCoursePrice from './DeleteCoursePrice';
import ViewCoursePrice from './ViewCoursePrice';
function CoursePriceSetup() {
    const dispatch = useDispatch();
    const [edata,setData] = useState()
    const [activateOpen,setActivateOpen] = useState(false);
    const [deactivateOpen,setDeactivateOpen] = useState(false);
    const [deleteOpen,setDeleteOpen] = useState(false);
    const [viewOpen,setViewOpen] = useState(false);
    const loading = useSelector(state => state.entities.courseprice.loading);
    const course = useSelector(state => state.entities.courses.result.data);
    const errors = useSelector(state => state.entities.courses.error.data);
    const priceData = useSelector(state => state.entities.courseprice.result.data);
    useEffect(()=>{
        // dispatch(getCoursePriceAll())
        dispatch(loadCourseSelect())
    },[])
    const handleActivate = (data)=>{
        setData(data);
        setActivateOpen(true);
    }
    const handleDeActivate = (data)=>{
        setData(data);
        setDeactivateOpen(true);
    }
    const handleDelete = (data)=>{
        setData(data);
        setDeleteOpen(true)
    }
    const handleView = (data)=>{
        setData(data);
        setViewOpen(true);
    }
    const IS_ACTIVE = [
        {
            'key':true,
            'value':'TRUE'
        },
        {
            'key':false,
            'value':'FALSE'
        }
    ]
    const COLUMNS = [
        {
            'key':'course_name',
            'text':'Course Name'
        },
        {
            'key':'package_name',
            'text':'Package Name'
        },        
        {
            'key':'course_price',
            'text':'Course Price'
        },
        {
            'key':'course_exam_fee',
            'text':'Exam Fee'
        },
        {
            'key':'access_days',
            'text':'Access Days'
        },
        // {
        //     'key':'discount_offer',
        //     'text':'Discount Offer'
        // },
        // {
        //     'key':'cp_after_discount',
        //     'text':'CP After Discount'
        // },
        {
            'key':'is_active',
            'text':'Status',
            cell:record =>{
                return(
                    <>
                    <span>{record?.is_active?<> <span>Active</span>&nbsp; <Link to="#" onClick={()=> handleDeActivate(record)}>deactivate</Link></>:<><span>InActive</span> <Link to="#" onClick={()=> handleActivate(record)}>Activate</Link></>}</span>
                    </>
                    )
            }
        },
        {
            'key':'iid',
            'text':'Action',
            maxWidth:350,
            cell: record => {
                return(
                    <>
                    <Link
                    to={`/coursePriceSetup/edit/${record?.iid}`}
                    className='btn btn-sm btn-primary'
                    style={{marginRight:'5px'}}
                    >
                        <i className='fa fa-edit'/>
                    </Link>
                    <button className='btn btn-sm btn-danger' style={{marginRight:'5px'}} onClick={()=>handleDelete(record)}>
                        <i className='fa fa-trash'/>
                    </button>
                    <button className='btn btn-sm btn-success' style={{marginRight:'5px'}} onClick={()=>handleView(record)}>
                        <i className='fa fa-eye'/>
                    </button>
                    </>
                )
            }
        }
    ]
    const initialValues = {
        courses:'',
        is_active:''
    }
    const validationSchema = Yup.object().shape({
        courses:Yup.string().required(),
        is_active:Yup.string().required().label('is Active?')
    })
    const handleSearch = (values,submitProps) =>{
        dispatch(filterCoursePrice(values))
        submitProps.setSubmitting(false);
    }
  return (  
          
    <>
    <BodyContainer pageTitle="Course Price Setup" buttonTitle="Add Price" route="/coursePriceSetup/add">
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validateOnChange={validationSchema}
                onSubmit={handleSearch}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Select Course:</label><span className='errors'>*</span>
                                            <Field as="select" name="courses" className="form-control">
                                                <option value="">select options</option>
                                                {
                                                    course && course.map((item)=>(
                                                        <option key={item?.iid} value={item?.iid}>{item?.course_name}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name='courses' component={TextError}/>
                                            {errors?.courses && <div className='errors'>{errors?.courses}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>IS_ACTIVE?:</label><span className='errors'>*</span>
                                            <Field as="select" name="is_active" className="form-control">
                                                <option value="">select options</option>
                                                {
                                                    IS_ACTIVE && IS_ACTIVE.map((item)=>(
                                                        <option key={item?.key} value={item?.key}>{item?.value}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name='is_active' component={TextError}/>
                                            {errors?.courses && <div className='errors'>{errors?.courses}</div>}
                                        </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='form-group'>
                                            <label>&nbsp;</label>                                            
                                            <button className='btn btn-success btn-flat form-control' type='submit' disabled={isSubmitting}>
                                               {isSubmitting?<Spinner/>:''} <i className='fa fa-search'/>&nbsp;search
                                            </button>                                            
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        {
            loading && <LoaderSpinner/>
        }
        <ReactDataTable
        columns = {COLUMNS}
        records = {priceData}
        />
        {
            activateOpen &&
            <ActivateCoursePrice openPopup={activateOpen} setOpenPopup={setActivateOpen} data={edata}/>
        }
        {
            deactivateOpen &&
            <DeactivateCoursePrice openPopup={deactivateOpen} setOpenPopup={setDeactivateOpen} data={edata}/>
        }
        {
            deleteOpen &&
            <DeleteCoursePrice openPopup={deleteOpen} setOpenPopup={setDeleteOpen} data={edata}/>
        }
        {
            viewOpen && 
            <ViewCoursePrice openPopup={viewOpen} setOpenPopup={setViewOpen} data={edata}/>
        }
    </BodyContainer>
    </>
  )
}

export default CoursePriceSetup