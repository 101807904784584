import React from 'react'

function RightMenu() {
    return (
        <>
           {/* Control Sidebar */}
<aside className="control-sidebar control-sidebar-dark">
  {/* Control sidebar content goes here */}
</aside>
{/* /.control-sidebar */}

        </>
    )
}

export default RightMenu
