import React from 'react';
import * as Yup from 'yup';
import FormPopUp from '../popup/PopUp';
import ImageUploader from '../Form/ImageUploader';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { editAlumniImg } from '../../store/AlumniSetupStore';
import Spinner from '../Layout/Spinner';
function AlumniImgUpdate({openPopUp,setOpenPopUp,alumni}) {
    const initialValues = {        
        alumni_img:''
    }
    const dispatch = useDispatch()
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const validationSchema = Yup.object().shape({
        alumni_img:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("alumni image"),
    })
    const handleSubmit = (values,submitProps)=>{
            let formData = new FormData()
            formData.append('alumni_id',alumni)
            formData.append('alumni_img',values.alumni_img,values.alumni_img.name) 
            dispatch(editAlumniImg(formData))
            submitProps.setSubmitting(false);
    } 
  return (
    <FormPopUp openPopup={openPopUp} setOpenPopup={setOpenPopUp} title="Alumni Image Update">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
          {
            ({isSubmitting})=>(
              <Form>
                  <ImageUploader
                  name="alumni_img"
                  required="required"
                  />
                  <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}<i className='fa fa-save'></i>&nbsp;Save</button>
              </Form>
            )
          }
        </Formik>
    </FormPopUp>
  )
}

export default AlumniImgUpdate