import React from 'react'
import ReactPlayer from 'react-player'
function ReactVideoPlayers({videoUrl}) {
  return (
    <ReactPlayer
    url={videoUrl}
    height='100%'
    width="100%"
    controls={true}
//     config={
//         {
//        vimeo:{
//            playerOptions:{
//                title:true,
//                responsive:true,
//                transparent:true,
//                byline:true,
               
//            }
//        }
//    }
// }
    />
  )
}

export default ReactVideoPlayers