import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react'

function PopupModal({title,children,openPopup,setOpenPopup,setRefreshData}) {
    // const {title,children,openPopup,setOpenPopup} = props;
    const handleChange=()=>{
        setOpenPopup(false)
        setRefreshData(prev => !prev);
    }
    return (
        <Dialog open={openPopup} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <div style={{display:'flex'}}>
                    <Typography variant="h6" component="div" style={{flexGrow:1}}>
                        {title}
                    </Typography>
                    <Button variant="outlined" onClick={handleChange}>X</Button>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default PopupModal
