import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BodyContainer from '../DashboardTemplate/BodyContainer';
import ReactDataTable from '@ashvin27/react-datatable';
import { useDispatch, useSelector } from 'react-redux';
import { getPassageById, searchQuiz } from '../../store/QuizSetupStore';
import MathJax from 'mathjax3-react';
import TagQuizParagraph from './TagQuizParagraph';
function TagQuizToParagraph() {
    const [filterData,setFilterData] = useState('');
    const params = useParams();
    const [data,setData] = useState();
    const dispatch = useDispatch();
    const [openPopUp,setOpenPopUp] = useState(false);
    const quizData = useSelector(state => state.entities.quiz.searchResult?.data)
    const passage = useSelector(state => state.entities.quiz.getDataById?.data)

    useEffect(()=>{
        let data = {'query_string':filterData}
        if(data?.query_string)
            dispatch(searchQuiz(data))
        },[filterData])
        useEffect(()=>{
            
            dispatch(getPassageById(params.slug))
    },[params.slug])
    useEffect(()=>{

    },[])
    
    const COLUMNS = [
        {
            key:'question',
            text:'Question',
            maxWidth:650,
            cell : record => {
                return(
                    <>
                    <MathJax.Provider>
                        <MathJax.Html html={record?.question}/>
                    </MathJax.Provider>
                    </>
                )
            }
        },
        {
            key:'correct_option',
            text:'Correct Options',
            cell : record => {
                return(
                    <>
                    <MathJax.Provider>
                        <MathJax.Html html={record?.correct_option}/>
                    </MathJax.Provider>
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <Link
                            className="btn btn-success btn-sm"                           
                            style={{marginRight: '5px'}}
                            to='#'
                            onClick={()=> clickTagQuiz(record)}
                            >
                            <i className="fa fa-plus">&nbsp;&nbsp;Tag Quiz</i>
                        </Link>
                       
                    </>
                );
            }
        }
    ]
    const tableChangeHandler = (data)=>{       
        setFilterData(data?.filter_value)
    }
    const clickTagQuiz = (data)=>{
        setData(data);
        setOpenPopUp(true);
    }
  return (
    <BodyContainer pageTitle="Tag Quiz To Paragraph">
        <div className='card'>
        <div className='card-body'>
            <MathJax.Provider>
                <MathJax.Html html={`<label>${passage?.paragraph}</label>`}/>
            </MathJax.Provider>            
        </div>
        </div>
        <ReactDataTable
            columns={COLUMNS}
            records = {quizData}
            onChange = {tableChangeHandler}
        />
        {
            openPopUp &&
            <TagQuizParagraph openPopup={openPopUp} setOpenPopup={setOpenPopUp} paragraph={passage} quiz={data}/>
        }
    </BodyContainer>
  )
}

export default TagQuizToParagraph