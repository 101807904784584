import React from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { ADYEAR,NEPALIYEAR } from '../Calendar/NPYear'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { loadCourseSelect } from '../../store/CourseSetup'
import { useState } from 'react'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import DataTable from 'react-data-table-component';
import { getBatchSelect, getFilterBatch } from '../../store/EnrollStore';
import * as Yup from 'yup';
import PopupModal from '../Material-uiControls/PopupModal'
import BatchTagEdit from './BatchTagEdit'
import BatchTagDeleteConfirm from './BatchTagDeleteConfirm'
import BatchTagRecover from './BatchTagRecover'
function BatchTagReport() {
    const [editOpen,setEditOpen] = useState(false);
    const [deleteOpen,setDeleteOpen] = useState(false);
    const [recoverOpen,setRecoverOpen] = useState(false);
    const [refreshData,setRefreshData] = useState(false);
    const [selectedData,setSelectedData] = useState([])
    const [recoverData,setRecoverData] = useState()
    const [year,setYear] = useState(ADYEAR)
    const [courses,setCourse] = useState('');
    const [batch,setBatch] = useState()
    const [isDeleted,setIsDeleted] = useState('n')
    const course = useSelector(state => state.entities.courses.result.data);
    const isLoading = useSelector(state => state.entities.enroll.loading);
    const filterBatch = useSelector(state=>state.entities.enroll?.batchSelect?.data)
    const filterData = useSelector(state=>state.entities.enroll?.batchFilter?.data)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const is_deleted_data = [
        {
            'label':'yes',
            'value':'y'
        },
        {
            'label':'no',
            'value':'n'
        }

    ]
    const onChange = (bsDate) => {
		setYear(bsDate);
        if(courses){
        dispatch(getBatchSelect({'year':bsDate,'courses':courses}))
        }        
	};
    const handleCourseClick = (id)=>{
        setCourse(id) 
        if(year){
        dispatch(getBatchSelect({'year':year,'courses':id}))
        }       
    }
    const handleIsDeleted = (value)=>{
        setIsDeleted(value)
    }
    const handleBatch = (value)=>{
        setBatch(value)
    }
    const initialValues = {
        year:year,
        courses:courses,
        group:batch,
        is_deleted: isDeleted
    }
    const validationSchema = Yup.object().shape({
        group: Yup.string().required().label('batch'),
        is_deleted: Yup.string().required()
    })
    const handleFilter = (values)=>{
        dispatch(getFilterBatch(values))
    }
    const handleEdit = ()=>{
        setEditOpen(true)
    }
    const handleDelete = ()=>{
        setDeleteOpen(true)
    }
    const handleRecover = (row)=>{
        setRecoverOpen(true)
        setRecoverData(row)
    }
    const handleSelectedRow = (row)=>{
        console.log("selected row",row)
        setSelectedData(row.selectedRows)
      }
    const columns = [
        {
            name:'Student Name',
            selector: row=>row.student_name,
            sortable: true
        },
        {
            name:'course',
            selector: row=>row.course_name,
            sortable: true
        },
        {
            name:'Batch',
            selector: row=>row.batch_name,
            sortable: true
        },
        {
            name:'Action',
            cell: row =>row.is_deleted === 'y'?
                               
                <button className='btn btn-sm btn-warning' type='button' onClick={()=>handleRecover(row)} >
                    Recover
                </button>
                :null
            
            
        },
    ]
  return (
    <BodyContainer
    pageTitle={`Batch Tag Report`}
    >
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFilter}
                enableReinitialize
                >
                    {
                        ()=>(
                            <Form>
                                <div className='row'>                                    
                                    <div className='form-group col-md-4'>
                                    <label className='required-label'> select course<span className="errors">*</span></label>
                                    <Field as="select" name="course_id" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }               
                                    </Field>
                                    <ErrorMessage name="course_id" component={TextError} />
                                    </div>
                                    <div className='form-group col-md-3'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="year" component={TextError}/>
                                </div>
                                <div className='form-group col-md-4'>
                                <label className='required-label'> select batch<span className="errors">*</span></label>
                                <Field as="select" name="group" id="group" placeholder="" className='form-control' value={batch}  onChange={(e)=>handleBatch(e.target.value)} >
                                    <option name="group" value="">select Options</option>
                                            {
                                                filterBatch && filterBatch?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.batch_name}</option>
                                                    ))
                                            }
                                </Field>
                                <ErrorMessage name="group" component={TextError} />
                                </div> 
                                <div className='form-group col-md-4'>
                                <label className='required-label'> is deleted?:<span className="errors">*</span></label>
                                <Field as="select" name="is_deleted" id="is_deleted" placeholder="" className='form-control' value={isDeleted}  onChange={(e)=>handleIsDeleted(e.target.value)} >
                                    <option name="is_deleted" value="">select Options</option>
                                            {
                                                is_deleted_data && is_deleted_data?.map((item,index)=>(
                                                        <option key={index} value={item.value}  >{item.label}</option>
                                                    ))
                                            }
                                </Field>
                                <ErrorMessage name="is_deleted" component={TextError} />
                                </div>
                                <div className='form-group col-md-2'>
                                    <label>&nbsp;</label>
                                    <button className='btn btn-flat btn-success form-control' type='submit' disabled={isLoading}>
                                        {isLoading && <><Spinner/>&nbsp;</>} <i className='fa fa-search'>&nbsp;&nbsp;Filter</i>
                                    </button>
                                </div> 
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-1'>
                        <button className='btn btn-info btn-sm' onClick={()=>handleEdit()} disabled={selectedData?.length>0?false:true}>
                            <i className='fa fa-edit'/>&nbsp;&nbsp;edit
                        </button>
                    </div>
                    <div className='col-md-2'>
                        <button className='btn btn-danger btn-sm' onClick={()=>handleDelete()} disabled={selectedData?.length>0?false:true}>
                            <i className='fa fa-trash'/>&nbsp;&nbsp;delete
                        </button>
                    </div>
                </div>
                <DataTable
                title='Batch Tag List'
                columns={columns}
                data={filterData}
                highlightOnHover
                fixedHeader
                pagination
                fixedHeaderScrollHeight='450px'
                selectableRows
                selectableRowsHighlight
                onSelectedRowsChange={handleSelectedRow}
                />
            </div>
        </div>
        {
            editOpen &&
            <PopupModal
            title={`Edit Batch Tag`} 
            openPopup={editOpen}
            setOpenPopup={setEditOpen}
            setRefreshData={setRefreshData}
            >
                <BatchTagEdit 
                course={course} 
                studentList={selectedData}
                batch_id={batch}
                course_id={courses}
                years={year}
                />
            </PopupModal>
        }
        {
            deleteOpen &&
            <PopupModal
            title={`Confirm Delete`}
            openPopup={deleteOpen}
            setOpenPopup={setDeleteOpen}
            setRefreshData={setRefreshData}
            >
                <BatchTagDeleteConfirm studentList={selectedData}/>
            </PopupModal>
        }
        {
            recoverOpen &&
            <PopupModal
            title={'Confirm Recover'}
            openPopup={recoverOpen}
            setOpenPopup={setRecoverOpen}
            setRefreshData={setRefreshData}
            >
                <BatchTagRecover row={recoverData}/>
            </PopupModal>
        }
    </BodyContainer>
  )
}

export default BatchTagReport