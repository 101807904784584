import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import { getQuizLevelAll } from '../../store/QuizSetupStore';
import QuizLevelAdd from './QuizLevelAdd';
import QuizLevelEdit from './QuizLevelEdit';
import ViewQuizLevel from './ViewQuizLevel';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function QuizLevelSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  const [openPopUpView,setOpenPopUpView] = useState(false)
  const [openPopUpTag,setOpenPopUpTag] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const quizLevel = useSelector(state => state.entities.quiz.quizLevel?.data);  
  const loading = useSelector(state => state.entities.quiz.loading);  
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  const handleView = (data)=>{
    setEData(data);
    setOpenPopUpView(true)
  }
  useEffect(()=>{
    dispatch(getQuizLevelAll());
  },[openPopUp])
  const columns = [
    {
      key:'level_name',
      text:"Level Title"
    },
    
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleView(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-eye"></i>
                  </button>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  {/* <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => console.log(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  <Link 
                  to={`#`}
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      >                      
                      <i className="fa fa-eye"></i>
                  </Link> */}
              </>
          );
      }
  }
  ]
  return (
    <>
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Quiz Level Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add Level</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <ReactDataTable        
        columns = {columns}
        records = {quizLevel}
        />
        {
            openPopUp &&
        <QuizLevelAdd openPopup={openPopUp} setOpenPopup={setOpenPopUp}/>
        }
        {
            openPopUpEdit &&
        <QuizLevelEdit openPopup={openPopUpEdit} setOpenPopup={setOpenPopUpEdit} level={edata}/>
        }
        {
          openPopUpView &&
          <ViewQuizLevel openPopup={openPopUpView} setOpenPopup={setOpenPopUpView} level={edata}/>
        }
    </div>
    </div>
    {loading && <LoaderSpinner/>}
    </>
  )
}

export default QuizLevelSetup