import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'subTopic',
    initialState: {
        result: [],
        getData:[],
        getDataById:[],
        loading: false,       
        error: []
    },
    reducers:{
        subTopicRequested:(subTopic,action)=>{
            subTopic.loading = true;
        },
        subTopicReceived:(subTopic,action)=>{
            subTopic.loading = false;
            subTopic.result = action.payload
        },
        subTopicRequestFailed:(subTopic,action)=>{
            subTopic.loading = false;
            subTopic.result = action.payload
            toast.error(action.payload?.msg)
        },
        subTopicAddedEdited:(subTopic,action)=>{
            subTopic.loading = false;
            subTopic.result = action.payload
            toast.success(action.payload?.msg)
        },
        getSubTopic:(subTopic,action)=>{
            subTopic.loading = false;
            subTopic.getData = action.payload
        },
        getSubTopicById:(subTopic,action)=>{
            subTopic.loading = false;
            subTopic.getDataById = action.payload
        },
    }
})

const {
    subTopicAddedEdited,
    getSubTopic,
    subTopicRequested,
    subTopicRequestFailed,   
    getSubTopicById,
} = slice.actions

export default slice.reducer;

export const loadSubTopic = () => apiCallBegan({
    url:requests.fetchSubTopic,
    headers:AuthHeader,
    onStart:subTopicRequested.type,
    onSuccess:getSubTopic.type,
    onError: subTopicRequestFailed.type
})
export const getSubTopicByIds = (id) => apiCallBegan({
    url:requests.getSubTopic+'/'+id,
    headers:AuthHeader,
    onStart:subTopicRequested.type,
    onSuccess:getSubTopicById.type,
    onError: subTopicRequestFailed.type
})
export const viewSubTopicByIds = (id) => apiCallBegan({
    url:requests.viewSubTopicDetails+'/'+id,
    headers:AuthHeader,
    onStart:subTopicRequested.type,
    onSuccess:getSubTopicById.type,
    onError: subTopicRequestFailed.type
})
export const addSubTopic = (data) => apiCallBegan({
    url:requests.createSubTopic,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:subTopicRequested.type,
    onSuccess:subTopicAddedEdited.type,
    onError: subTopicRequestFailed.type
})
export const editSubTopic = (data) => apiCallBegan({
    url:requests.updateSubTopic,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:subTopicRequested.type,
    onSuccess:subTopicAddedEdited.type,
    onError: subTopicRequestFailed.type
})