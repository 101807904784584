import { FastField, Form, Formik } from 'formik';
import React from 'react';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import { tagPassage } from '../../store/QuizSetupStore';
import Spinner from '../Layout/Spinner';
import MathJax from 'mathjax3-react';
import { useDispatch } from 'react-redux';
function TagQuizParagraph({openPopup,setOpenPopup,paragraph,quiz}) {
    const dispatch = useDispatch();
    const initialValues = {
        marks_weightage:''
    }
    const validationSchema = Yup.object().shape({
        marks_weightage: Yup.number().required().label('marks weightage')
    })
    const handleSubmit = (values,submitProps)=>{
        values.quiz = quiz?.iid
        values.paragraph = paragraph?.iid
        dispatch(tagPassage(values))
        submitProps.setSubmitting(false);
    }
    return (
    <>
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Paragraph Quiz">
        <label>{paragraph?.paragraph_title}</label>        
            <MathJax.Html html={`<label>${quiz?.question}</label>`}/>               
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Marks weightage:</label><span className='errors'>*</span>
                            <FastField type="number" name="marks_weightage" className="form-control" />
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {
                                isSubmitting ? <Spinner/>:''
                            }
                            <i className="fa fa-save"/>&nbsp;save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
    </>
  )
}

export default TagQuizParagraph