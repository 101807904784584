import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { viewLessonByIds } from '../../store/LessonSetup';
import Card from '../Card/Card';
import PDFIMAGE from '../../assets/img/pdf_icon.png';
import ResourceEdit from './ResourceEdit';
import ResourceDelete from './ResourceDelete';
import FileIcon from '../FileIcon/FileIcon';
function LessonViewDetail() {
  const [openEdit,setOpenEdit] = useState(false)
  const [openDelete,setOpenDelete] = useState(false)
  const [resource,setResource] = useState()
  const [resourceName,setResourceName] = useState()
  const [refetch,setRefetch] = useState(false)
  const params = useParams()
  const dispatch = useDispatch();
  const lesson = useSelector(state => state.entities.lesson.getDataById.data)
  useEffect(()=>{
    dispatch(viewLessonByIds(params?.slug));
  },[params,refetch])
  let resources;
  const handleDelete = (res_id,pdf_name)=>{
    setResource(res_id)
    setResourceName(pdf_name)
    setOpenDelete(true)
  }
  const handleEdit = (res_id)=>{
    setResource(res_id)    
    setOpenEdit(true)
  }
  return (
    <>
       <Card cardTitle="Lesson Details">
         <div className='card-body'>
        {
          lesson &&
          Object.keys(lesson).map((item)=>{            
            resources = lesson[item]['resources']            
            return(
            <>
            <div className='row'><label>Category Name</label>&nbsp; : &nbsp;<span>{lesson[item]['category_name']?lesson[item]['category_name']:'N/A'}</span></div>
            <div className='row'><label>Course Name</label>&nbsp; : &nbsp;<span>{lesson[item]['course_name']?lesson[item]['course_name']:'N/A'}</span></div>
            <div className='row'><label>Subcourse Name</label>&nbsp; : &nbsp;<span>{lesson[item]['subcourse_name']?lesson[item]['subcourse_name']:'N/A'}</span></div>
            <div className='row'> <label>MainTopic Title</label>&nbsp; : &nbsp;<span>{lesson[item]['topic_title']?lesson[item]['topic_title']:'N/A'}</span></div>
            <div className='row'><label>Lesson Title</label>&nbsp; : &nbsp;<span>{lesson[item]['lesson_title']?lesson[item]['lesson_title']:'N/A'}</span></div>
            <div className='row'> <label>Lecture Hours</label>&nbsp; : &nbsp;<span>{lesson[item]['lecture_hours']?lesson[item]['lecture_hours']+' hrs':'N/A'}</span></div>
            <div className='row'> <label>Created By</label>&nbsp; : &nbsp;<span>{lesson[item]['created_by']?lesson[item]['created_by']:'N/A'}</span></div>
            <div className='row'><label>Created Date</label>&nbsp; : &nbsp;<span>{lesson[item]['created_date']?lesson[item]['created_date']:'N/A'}</span></div>
            <div className='row'><label>Modified By</label>&nbsp; : &nbsp;<span>{lesson[item]['modified_by']?lesson[item]['modified_by']:'N/A'}</span></div>
            <div className='row'><label>Modified Date</label>&nbsp; : &nbsp;<span>{lesson[item]['modified_date']?lesson[item]['modified_date']:'N/A'}</span></div>
            <div className='row'><label>Modified Remarks</label>&nbsp; : &nbsp;<span>{lesson[item]['modified_remarks']?lesson[item]['modified_remarks']:'N/A'}</span></div>

             {
               Object.keys(resources).map((res)=>(
                 
                 <>
                 <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center',gap:'1em'}} key={resources[res]['resources_id']}>
                   <div>
                    {/* <img src={PDFIMAGE} style={{height:100,width:100}} alt='pdf'/> */}
                    <FileIcon filePath={resources[res]['resources']}/>
                      <Link to={{pathname:`${process.env.REACT_APP_BASE_MEDIA_URL}${resources[res]['resources']}`}} rel="noreferer noopener" target={`_blank`}>{(resources[res]['resources']).split("/")[1]}</Link>
                   </div>
                      <Link to="#" className='btn btn-primary btn-sm' onClick={()=>handleEdit(resources[res]['resources_id'])}><i className='fa fa-edit'/></Link>
                      <Link to="#" className='btn btn-danger btn-sm' onClick = {()=>handleDelete(resources[res]['resources_id'],(resources[res]['resources']).split("/")[1])}><i className='fa fa-trash'/></Link>
                 </div>
                 </>
               ))
             }
             </>
             )
          })
         }
        </div>
        {
          openEdit &&
          <ResourceEdit openPopup={openEdit} setOpenPopup={setOpenEdit} setRefetch={setRefetch} resource_id={resource}/>
        }
        {
          openDelete &&
          <ResourceDelete openPopup={openDelete} setOpenPopup={setOpenDelete} setRefetch={setRefetch} resource_id={resource} resource= {resourceName}/>
        }
      </Card>
    </>
  );
}

export default LessonViewDetail;
