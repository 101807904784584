import React, {useEffect,useState} from "react";
import BodyContainer from '../DashboardTemplate/BodyContainer';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { getInstructorCourseSelect } from "../../store/CourseSetup";
import TextError from "../../TextError";
import Spinner from "../Layout/Spinner";
import ReactDataTable from '@ashvin27/react-datatable';
import { getenquirysByCourse } from "../../store/EnquiryStore";
import EnquiryStatusEdit from "./EnquiryStatusEdit";
import EnquiryNoteAdd from "./EnquiryNoteAdd";
import EnquiryNotesDetails from "./EnquiryNotesDetails";

function EnquirySetup(){
    const [courseId,setCourseId] = useState();
    const [estatus,setEStatus] = useState();
    const [openPopup,setOPenPopUp] = useState(false);
    const [openPopupNote,setOPenPopUpNote] = useState(false);
    const [openPopupViewNote,setOPenPopUpViewNote] = useState(false);
    const [enData,setEnData] = useState()
    const dispatch = useDispatch();
    const course=useSelector(state=>state.entities.courses?.courseSelect?.data);
    const enquiryData = useSelector(state => state.entities.enquiry?.enquiry?.data)
    useEffect(()=>{
        dispatch(getInstructorCourseSelect())
    },[])
    const status = [
        {
            'value':'requested',
            'label':'REQUESTED',
        },
        {
            'value':'pending',
            'label':'PENDING',
        },
        {
            'value':'converted',
            'label':'CONVERTED',
        },
        {
            'value':'not-converted',
            'label':'NOT-CONVERTED',
        },
    ]
    const handleCourseClick = (id)=>{        
        setCourseId(id);        
    }
    const handleStatusClick = (data)=>{        
        setEStatus(data);        
    }
    const editData = (data)=>{
        setOPenPopUp(true);
        setEnData(data);
        console.log("endata",data)
    }
    const addNote = (data)=>{
        setEnData(data);
        setOPenPopUpNote(true);
    }
    const viewNote = (data)=>{
        setEnData(data);
        setOPenPopUpViewNote(true);
    }
    const initialValues = {
        courses:courseId,
        en_status:estatus,
        
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        en_status: Yup.string().required().label('status'),        
    })
    const handleSubmit = async(values,submitProps)=>{
            await dispatch(getenquirysByCourse(values))
            submitProps.setSubmitting(false);
    }
    const columns = [
        {
            key:'name',
            text:'Name'
        },
        {
            key:'email',
            text:'email'
        },
        {
            key:'mobile_no',
            text:'Mobile no'
        },
        {
            key:'enquiry_desc',
            text:'Enquiry Description'
        },
        {
            key:'enquiry_status',
            text:'Enquiry Status'
        },
        {
            key:'enquiry_requested_date',
            text:'Requested Date'
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <button
                            className="btn btn-primary btn-sm"
                            style={{marginRight: '5px'}}
                            key={record?.iid}
                            onClick={()=>editData(record)}
                            >
                            <i className="fa fa-edit"></i>
                        </button>
                        <button
                            className="btn btn-success btn-sm"
                            style={{marginRight: '5px'}}
                            key={record?.iid}
                            onClick={()=>addNote(record)}
                            >
                            <i className="fa fa-plus"></i>
                        </button>
                        <button
                            className="btn btn-warning btn-sm"
                            style={{marginRight: '5px'}}
                            key={record?.iid}
                            onClick={()=>viewNote(record)}
                            >
                            <i className="fa fa-eye"></i>
                            view 
                        </button>
                                              
                    </>
                );
            }
        }
    ]
    return(
        <BodyContainer pageTitle={`Enquiry Setup`}>
            <div className="card">
                <div className="card-body">
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    >
                        {
                            ({isSubmitting})=>(
                                <Form>
                                    <div className="row">
                                        <div className="col-md-3">
                                        <div className='form-group'>
                                            <label>Select Course:</label><span className='errors'>*</span>
                                            <Field as="select" name="courses" className="form-control" value={courseId} onChange={(e)=>handleCourseClick(e.target.value)}>
                                                <option value="">select options</option>
                                                {
                                                  course && course.map((item)=>(
                                                    <option key={item?.courses} value={item?.courses}>{item?.course_name}</option>
                                                ))  
                                                }
                                            </Field>
                                            <ErrorMessage name='courses' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                        </div>
                                        <div className="col-md-3">
                                        <div className='form-group'>
                                            <label>Select Status:</label><span className='errors'>*</span>
                                            <Field as="select" name="en_status" className="form-control" value={estatus} onChange={(e)=>handleStatusClick(e.target.value)}>
                                                <option value="">select options</option>
                                                {
                                                  status && status.map((item)=>(
                                                    <option key={item?.value} value={item?.value}>{item?.label}</option>
                                                ))  
                                                }
                                            </Field>
                                            <ErrorMessage name='en_status' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                        </div>
                                        <div className='col-md-2'>
                                            <div className='form-group'>
                                            <label>&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control'  disabled={isSubmitting}>
                                                {isSubmitting?<Spinner/>:''} <i className="fa fa-search"/>filter
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <ReactDataTable
                     columns = {columns}
                     records = {enquiryData}
                     />
                </div>
            </div>
            {
                openPopup &&
                <EnquiryStatusEdit openPopup={openPopup} setOpenPopup={setOPenPopUp} enquiry={enData}/>
            }
            {
                openPopupNote &&
            <EnquiryNoteAdd openPopup={openPopupNote} setOpenPopup={setOPenPopUpNote} enquiry={enData}/>
            }
            {
                openPopupViewNote &&
            <EnquiryNotesDetails openPopup={openPopupViewNote} setOpenPopup={setOPenPopUpViewNote} enquiry={enData}/>
            }
        </BodyContainer>
    )
}
export default EnquirySetup