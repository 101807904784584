import React from 'react'
import FormPopUp from '../popup/PopUp'
import { Form, Formik } from 'formik'
import ImageUploader from '../Form/ImageUploader'
import Spinner from '../Layout/Spinner'
import * as Yup from 'yup';
import { useDispatch } from 'react-redux'
import { updateArticleBanner } from '../../store/ArticlesStore'
function ArticleBannerEdit({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch()
    const initialValues = {
        article_id:data?.iid,
        article_banner:''
    }

    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
    const validationSchema = Yup.object().shape({
        article_banner: Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("article banner"),
    }) 
    const handleSubmit = async(values,submitProps)=>{
        let formData = new FormData()
        formData.append('article_id',data?.iid)
        formData.append('article_banner',values.article_banner,values.article_banner.name)
        await dispatch(updateArticleBanner(formData));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={`Update article Banner`}>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <ImageUploader
                        name={`article_banner`}
                        label={'article banner'}
                        required={`required`}
                        accept="image/jpg, image/gif,image/jpeg,image/png" 
                        />
                        <button className='btn btn-flat btn-success flex aic' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} upload
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ArticleBannerEdit