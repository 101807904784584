import React, { useEffect, useState } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import PopupModal from '../Material-uiControls/PopupModal'
import BatchTagAdd from '../BatchSetup/BatchTagAdd'
import { getLectureRecording, loadCourseSelect } from '../../store/CourseSetup'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux'
import LiveRecordingAdd from './LiveRecordingAdd'
import { getBatchSelect } from '../../store/EnrollStore'
import { ADYEAR, NEPALIYEAR } from '../Calendar/NPYear'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Spinner from '../Layout/Spinner'
import TextError from '../../TextError'
import LiveRecordingEdit from './LiveRecordingEdit'
import ConfirmDelete from './ConfirmDelete'
function LiveRecordingSetup() {
    const [year,setYear] = useState(ADYEAR)
    const [courses,setCourse] = useState('');
    const [batch,setBatch] = useState()
    const [openAdd,setOpenAdd] = useState(false)
    const [openEdit,setOpenEdit] = useState(false)
    const [openDelete,setOpenDelete] = useState(false)
    const [selectedData,setSelectedData] = useState()
    const dispatch = useDispatch()
    const [refreshRate,setRefreshRate] = useState(false)
    const course = useSelector(state => state.entities.courses.result?.data);
    const lectureRecording = useSelector(state => state.entities.courses.lectureRecordingData?.data);
    const filterBatch = useSelector(state=>state.entities.enroll?.batchSelect?.data)
    const isLoading = useSelector(state => state.entities.enroll.loading);
    const addButtonClick = ()=>{
        setOpenAdd(true)
    }
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const onChange = (bsDate) => {
		setYear(bsDate);
        if(courses){
        dispatch(getBatchSelect({'year':bsDate,'courses':courses}))
        }        
	};
    const handleCourseClick = (id)=>{
        setCourse(id) 
        if(year){
        dispatch(getBatchSelect({'year':year,'courses':id}))
        }       
    }
    const handleBatch = (value)=>{
        setBatch(value)
    }
    const initialValues = {
        year:year,
        courses:courses,
        group:batch,        
    }
    const validationSchema = Yup.object().shape({
        group: Yup.string().required().label('batch'),
       
    })
    const handleFilter = (values)=>{
        dispatch(getLectureRecording(values))
    }
    const handleSelectedRow = (row)=>{
        setSelectedData(row)
        setOpenEdit(true)
    }
    const handleSelectedRowDelete = (row)=>{
        setSelectedData(row)
        setOpenDelete(true)
    }
    const columns = [
        {
            name:'batch',
            selector: row=>row.batch_name,
            
        },
        {
            name:'course',
            selector: row=>row.course_name,
            
        },
        {
            name:'topics',
            selector: row=>row.topics,
            
        },
        {
            name:'class date',
            selector: row=>row.class_date_nep,
            
        },
        {
            name:'video url',
            selector: row=>row.video_url,
            
        },
        {
            name:'actions',
            cell:row=><>
            <button className='btn btn-sm btn-info' type='button' onClick={()=>handleSelectedRow(row)}>
                <i className='fa fa-edit'/>
            </button> &nbsp;
            <button className='btn btn-sm btn-danger' type='button' onClick={()=>handleSelectedRowDelete(row)}>
                <i className='fa fa-trash'/>
            </button> &nbsp;
            </>
            
        },
    ]
   
  return (
    <BodyContainer
    pageTitle={`Live Class Recording Setup`}
    buttonTitle={`Add Live Recording`}
    onClick={addButtonClick}

    >
        <div className='card'>
            <div className='card-body'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFilter}
            enableReinitialize
            >
                    {
                        ()=>(
                            <Form>
                                <div className='row'>                                    
                                    <div className='form-group col-md-4'>
                                    <label className='required-label'> select course<span className="errors">*</span></label>
                                    <Field as="select" name="course_id" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }               
                                    </Field>
                                    <ErrorMessage name="course_id" component={TextError} />
                                    </div>
                                    <div className='form-group col-md-3'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="year" component={TextError}/>
                                </div>
                                <div className='form-group col-md-4'>
                                <label className='required-label'> select batch<span className="errors">*</span></label>
                                <Field as="select" name="group" id="group" placeholder="" className='form-control' value={batch}  onChange={(e)=>handleBatch(e.target.value)} >
                                    <option name="group" value="">select Options</option>
                                            {
                                                filterBatch && filterBatch?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.batch_name}</option>
                                                    ))
                                            }
                                </Field>
                                <ErrorMessage name="group" component={TextError} />
                                </div> 
                                
                                </div>
                                <div className='form-group col-md-2 float-sm-right'>
                                    {/* <label>&nbsp;</label> */}
                                    <button className='btn btn-flat btn-success form-control' type='submit' disabled={isLoading}>
                                        {isLoading && <><Spinner/>&nbsp;</>} <i className='fa fa-search'>&nbsp;&nbsp;Filter</i>
                                    </button>
                                </div> 
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
                <div className='card-body'>
                    <DataTable
                    columns={columns}
                    data={lectureRecording}
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    pagination
                    highlightOnHover
                    striped                    
                    />
                </div>
        </div>
        {
            openAdd &&
            <PopupModal
            title={`Add Live Recording`}
            openPopup={openAdd}
            setOpenPopup={setOpenAdd}
            setRefreshData={setRefreshRate}
            >
                <LiveRecordingAdd course={course}/>
            </PopupModal>
        }
        {
            openEdit &&
            <PopupModal
            title={`Edit Live Recording`}
            openPopup={openEdit}
            setOpenPopup={setOpenEdit}
            setRefreshData={setRefreshRate}
            >
                <LiveRecordingEdit course={course} rowData={selectedData}/>
            </PopupModal>
        }
        {
            openDelete &&
            <PopupModal
            title={'Delete Confirm'}
            openPopup={openDelete}
            setOpenPopup={setOpenDelete}
            setRefreshData={setRefreshRate}
            >
               <ConfirmDelete rowData={selectedData}/> 
            </PopupModal>
        }
    </BodyContainer>
  )
}

export default LiveRecordingSetup