import React from 'react'
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
function LoaderSpinner() {
    const style = { display:'flex',position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (
        <div style={style}>
        <div style={{flex:1}}>
            <Loader type="BallTriangle" color="#00BFFF" secondaryColor="Red" height={100} width={100} timeout={5000} />
        </div>
        </div>
    )
}

export default LoaderSpinner
