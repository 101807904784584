import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BodyContainer from '../DashboardTemplate/BodyContainer';
import ReactDataTable from '@ashvin27/react-datatable';
import { filterSearch,} from '../../store/QuizSetupStore';
import { Link } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner';
import * as Yup from 'yup';
import { loadCourseSelect } from '../../store/CourseSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ChangeStatusDialog from './ChangeStatusDialog';
import EditLiveQuizTagStatus from './EditLiveQuizTagStatus';
import {ADYEAR,NEPALIYEAR} from '../Calendar/NPYear'
function QuizLiveTagSetup() {
    const [,setFilterData] = useState('');
    const [year,setYear] = useState(ADYEAR);
    const [openPopUp,setOpenPopUp] = useState(false);
    const [openPopUpEdit,setOpenPopUpEdit] = useState(false);
    const [openViewPopup,setViewOpenPopUp] = useState(false);
    const [data,setData] = useState();
    const dispatch = useDispatch();
    const quizData = useSelector(state => state.entities.quiz.liveQuizSearchResult?.data);
    const isLoading = useSelector(state => state.entities.quiz.loading);
    const course = useSelector(state => state.entities.courses.result.data);
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])

    
    const handleStatus=(datas)=>{
        setData(datas);
        setOpenPopUp(true);
    }
    const handleEdit = (data)=>{
        setData(data);
        setOpenPopUpEdit(true);
    }
    const clickTagViewQuiz=(data)=>{        
        setData(data);
        setViewOpenPopUp(true);
    }
    const onChange = (bsDate)=>{
        setYear(bsDate);
    }
    const COLUMNS = [
        {
            key:'modelset_name',
            text:'ModelSet Name',                        
        },
        {
            key:'live_start_date_time',
            text:'start date time',            
        },
        {
            key:'live_end_date_time',
            text:'end date time',            
        },
        {
            key:'exam_time_duration',
            text:'quiz duration',            
        },
        {
            key:'live_exam_status',
            text:'Status', 
            cell: record => {
                return(
                    <>
                    <span>{record?.live_exam_status}</span><br/>
                    <Link to='#' onClick={()=>handleStatus(record)}>change status</Link>
                    </>
                )
            }           
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <Link
                            className="btn btn-success btn-sm"                           
                            style={{marginRight: '5px'}}
                            to='#'
                            onClick={()=> handleEdit(record)}
                            >
                            <i className="fa fa-edit">&nbsp;edit</i>
                        </Link>
                        {/* <button 
                            className="btn btn-danger btn-sm" 
                            onClick={() => console.log(record)}>
                            <i className="fa fa-trash"></i>
                        </button>
                        */}
                        <Link 
                            to='#'
                            style={{marginLeft:5}}
                            className="btn btn-primary btn-sm" 
                            onClick={()=>clickTagViewQuiz(record)}
                            >                            
                            <i className="fa fa-eye">&nbsp;view</i>
                        </Link> 
                    </>
                );
            }
        }
    ]
    const exam_status = [
        {
            'key':'pending',
            'value':'PENDING',            
        },
        {
            'key':'running',
            'value':'RUNNING',            
        },
        {
            'key':'completed',
            'value':'COMPLETED',            
        },
    ]
    const initialValues = {
        courses:'',
        year:year,
        live_exam_status:''
    }
    const handleSearch =(values,submitProps)=>{
        dispatch(filterSearch(values));
        submitProps.setSubmitting(false)
    }
    const validationSchema = Yup.object().shape({
        courses:Yup.string().required(),
        year:Yup.string().required(),
        live_exam_status:Yup.string().required(),
    })
  return (
    <BodyContainer pageTitle="Quiz Tag Setup">
        {
            isLoading && <LoaderSpinner/>
        }
        <div className='card'>
            <div className='card-body'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='form-group item'>
                                        <label className='required-label'>Select Course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            
                                    </div>
                                    </div>
                                    <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>Year</label>:<span className='errors'>*</span>
                                        <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                          <option name="year" value="">select Options</option>
                                          {
                                              NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                                  <option key={index} value={item}>{item}</option>
                                              ))
                                          }
                                        </Field> 
                                        <ErrorMessage name='year' component={TextError}/>
                                    </div>
                                    </div>
                                    <div className='col-md-3'>
                                         <div className='form-group'>
                                            <label>Live Status :</label><span className='errors'>*</span>
                                            <Field as="select" name="live_exam_status" className="form-control">
                                                    <option value=''>Select Options</option>
                                                    {
                                                        exam_status && exam_status.map((item)=>(
                                                            <option key={item.key} value={item.key}>{item.value}</option>
                                                        ))
                                                    }

                                            </Field>
                                            <ErrorMessage name="live_exam_status" component={TextError} />                                            
                                        </div>               
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='form-group'>
                                                <label>&nbsp;</label>
                                                <button type='submit' className='btn btn-success btn-flat form-control' disabled={isSubmitting}>
                                                    {isSubmitting?<Spinner/>:''}&nbsp;<i className='fa fa-search'/>&nbsp;Search
                                                    </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        
        <ReactDataTable
        columns = {COLUMNS}
        records={quizData}
        // onChange = {tableChangeHandler}
        />
        {
            openPopUpEdit &&
            <EditLiveQuizTagStatus openPopup={openPopUpEdit} setOpenPopup={setOpenPopUpEdit} quiz={data}/>
        }
         {
            openPopUp &&
        <ChangeStatusDialog openPopup={openPopUp} setOpenPopup={setOpenPopUp} status={data}/>
        }
       {/* {
            openViewPopup &&
        <QuizDetails openPopUp={openViewPopup} setOpenPopUp={setViewOpenPopUp} question={question}/>
        } */}
    </BodyContainer>
  )
}

export default QuizLiveTagSetup