import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCoursePrice } from '../../store/CoursePriceStore'
import { loadCourseSelect } from '../../store/CourseSetup'
import TextError from '../../TextError'
import Card from '../Card/Card'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import * as Yup from 'yup';

function AddCoursePrice() {
    const dispatch = useDispatch()
    const course = useSelector(state => state.entities.courses.result.data)
    const errors = useSelector(state => state.entities.courseprice.errors.data)
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues = {
        package_name:'',
        courses:'',
        course_price:'',
        course_exam_fee:'',
        access_days:'',
        has_course_offer:false,
        discount_offer:0,
        cp_after_discount:0.00,
        has_exam_fee_offer:false,
        exam_fee_discount_offer:0,
        fee_price_after_disc:0.00,
        is_life_time:false

    }
    const validationSchema = Yup.object().shape({
        package_name: Yup.string().required().min(4).max(30).label('package name'),
        courses: Yup.string().required(),
        course_price: Yup.number().required().label('course price'),
        course_exam_fee: Yup.number().required().label('course exam fee'),
        access_days: Yup.number().required().label('access days'),
        discount_offer:Yup.number().when("has_course_offer",{
            is:(courseOffer)=> courseOffer === true,
            then:Yup.number().required().label('discount %')
        }),
        cp_after_discount:Yup.number().when("has_course_offer",{
            is:(courseOffer)=> courseOffer === true,
            then:Yup.number().required().label('cp after discount')
        }),
        exam_fee_discount_offer:Yup.number().when("has_exam_fee_offer",{
            is:(courseOffer)=> courseOffer === true,
            then:Yup.number().required().label('discount %')
        }),
        // exam_fee_discount_offer:Yup.number().when("has_exam_fee_offer",{
        //     is:(courseOffer)=> courseOffer === true,
        //     then:Yup.number().required().label('discount %')
        // }),
        fee_price_after_disc:Yup.number().when("has_exam_fee_offer",{
            is:(courseOffer)=> courseOffer === true,
            then:Yup.number().required().label('exam fee after discount')
        }),
    })
    const handleSubmit = (values,submitProps)=>{
        values.created_by = localStorage.getItem('username');
        dispatch(addCoursePrice(values));
        submitProps.setSubmitting(false);
    }
  return (
    <BodyContainer pageTitle="Add Course Price" buttonTitle="cancel" route="/coursePriceSetup">
       <div className='row'>
            <div className='col-md-6'>
                <Card cardTitle="Add Course Price">
                    <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                    >
                        {
                            ({isSubmitting,values})=>(
                                <Form>
                                    <div className='card-body'>                                    
                                    <div className='form-group'>
                                        <label>courses :</label><span className='errors'>*</span>
                                        <Field as="select" name="courses" className="form-control" >
                                            <option value="">select options</option>                                    {
                                                course && course.map((item)=>(
                                                    <option key={item.iid} value={item.iid}>{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                        <ErrorMessage name='courses' component={TextError}/>
                                        {errors?.courses && <div className='errors'>{errors?.courses}</div>}
                                    </div>
                                    <div className='form-group'>
                                        <label>Package Name :</label><span className='errors'>*</span>
                                        <FastField className="form-control" name="package_name" placeholder="package name"/>
                                        <ErrorMessage name='package_name' component={TextError}/>
                                        {errors?.package_name && <div className='errors'>{errors?.package_name}</div>}
                                    </div>
                                    <div className='form-group'>
                                        <label>Course Price :</label><span className='errors'>*</span>
                                        <FastField className="form-control" name="course_price" placeholder="0.00"/>
                                        <ErrorMessage name='course_price' component={TextError}/>
                                        {errors?.course_price && <div className='errors'>{errors?.course_price}</div>}
                                    </div>
                                    <div className='form-group'>
                                        <label>Course Exam Fee :</label><span className='errors'>*</span>
                                        <FastField className="form-control" name="course_exam_fee" placeholder="0.00"/>
                                        <ErrorMessage name='course_exam_fee' component={TextError}/>
                                        {errors?.course_exam_fee && <div className='errors'>{errors?.course_exam_fee}</div>}
                                    </div>
                                    <div className='form-group'>
                                        <label>Access Days :</label><span className='errors'>*</span>
                                        <FastField className="form-control" name="access_days" placeholder="0.00"/>
                                        <ErrorMessage name='access_days' component={TextError}/>
                                        {errors?.access_days && <div className='errors'>{errors?.access_days}</div>}
                                    </div>
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-1'>
                                            <Field type="checkbox" className="form-control" name="has_course_offer"/>
                                            </div>
                                            <div className='col-md-4'>
                                            <label>&nbsp;has course Offer?</label><span className='errors'>*</span>              
                                            </div>
                                        </div>
                                        <ErrorMessage name='has_course_offer' component={TextError}/>
                                        {errors?.has_course_offer && <div className='errors'>{errors?.has_course_offer}</div>}
                                    </div>
                                    {
                                        values.has_course_offer &&
                                        <>
                                        <div className='form-group'>
                                            <label>Discount % :</label><span className='errors'>*</span>
                                            <FastField name="discount_offer" className="form-control" placeholder="0"/>
                                            <ErrorMessage name='discount_offer' component={TextError}/>
                                            {errors?.discount_offer && <div className='errors'>{errors?.discount_offer}</div>}
                                        </div>
                                        <div className='form-group'>
                                            <label>Course Price After Discount :</label><span className='errors'>*</span>
                                            <FastField name="cp_after_discount" className="form-control" placeholder="0.00"/>
                                            <ErrorMessage name='cp_after_discount' component={TextError}/>
                                            {errors?.cp_after_discount && <div className='errors'>{errors?.cp_after_discount}</div>}
                                        </div>
                                        </>
                                    }
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-1'>
                                             <Field type="checkbox" className="form-control" name="has_exam_fee_offer" />
                                            </div>
                                            <div className='col-md-5'>
                                            <label>&nbsp;has Exam Fee Offer?</label><span className='errors'>*</span> 
                                                                                      </div>
                                            </div>                                            
                                        <ErrorMessage name='has_exam_fee_offer' component={TextError}/>
                                        {errors?.has_exam_fee_offer && <div className='errors'>{errors?.has_exam_fee_offer}</div>}
                                    </div>
                                    {
                                        values.has_exam_fee_offer &&
                                        <>
                                        <div className='form-group'>
                                            <label>Discount % :</label><span className='errors'>*</span>
                                            <FastField name="exam_fee_discount_offer" className="form-control" placeholder="0"/>
                                            <ErrorMessage name='exam_fee_discount_offer' component={TextError}/>
                                            {errors?.exam_fee_discount_offer && <div className='errors'>{errors?.exam_fee_discount_offer}</div>}
                                        </div>
                                        <div className='form-group'>
                                            <label>Course Price After Discount :</label><span className='errors'>*</span>
                                            <FastField name="fee_price_after_disc" className="form-control" placeholder="0.00"/>
                                            <ErrorMessage name='fee_price_after_disc' component={TextError}/>
                                            {errors?.fee_price_after_disc && <div className='errors'>{errors?.fee_price_after_disc}</div>}
                                        </div>
                                        </>
                                    }
                                    <div className='form-group'>
                                        <div className='row'>
                                            <div className='col-md-1'>
                                            <Field type="checkbox" className="form-control"  name="is_life_time"/>
                                            </div>
                                            <div className='col-md-4'>
                                            <label>&nbsp;is life time?</label><span className='errors'>*</span>                              

                                            </div>
                                        </div>                                           
                                        <ErrorMessage name='is_life_time' component={TextError}/>
                                        {errors?.is_life_time && <div className='errors'>{errors?.is_life_time}</div>}
                                    </div>
                                    </div>
                                    <div className='card-footer'>
                                    <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                                    <i className='fa fa-save'/>&nbsp;Save
                                    </button>
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </Card>
            </div>
       </div>
    </BodyContainer>
  )
}

export default AddCoursePrice