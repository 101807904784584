import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader, MultiPartHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'courses',
    initialState: {
        result: [],
        loading: false,
        popup: false,
        error: [],
        getData:[],
        progress:[],
        lectureRecordingData:[],
        coursePlaylist:[],
        courseSelect:[]

    },
    reducers:{
    courseRequested: (courses,action)=> {
        courses.loading = true;
        
    },
    courseReceived: (courses,action)=>{
        courses.loading = false;        
        courses.result = action.payload;
    },
    courseRequestFailed: (courses,action) => {
        courses.loading = false;
        courses.error = action.payload;
        if(action.payload?.msg)
        toast.error(action.payload?.msg);
    },
    courseAdded: (courses,action) => {
        // action.payload
        courses.loading = false;        
        courses.result = action.payload;
        courses.error = [];
        toast.success(action.payload?.msg);

    },
    getCourse: (courses,action) => {
        courses.loading = false;
        courses.result = action.payload;
    },
    getCertificate: (courses,action) => {
        courses.loading = false;
        courses.getData = action.payload;
    },
    courseEdited:(courses,action) => {
        
    },   
    fileUpload: (courses,action)=>{        
        courses.progress = action.payload;
    },
    lectureRecordingRequested:(courses,action)=>{
        courses.lectureRecordingData = [];
        courses.loading = true;
    },
    lectureRecordingReceived:(courses,action)=>{
        courses.loading = false;
        courses.lectureRecordingData = action.payload
    },
    lectureRecordingActionRequested:(courses)=>{
        courses.loading = true;
    },
    lectureRecordingActionReceived:(courses,action)=>{
        courses.loading = false;
        toast.success(action.payload?.msg)
    },
    coursePlaylistRequested: (courses)=> {
        courses.loading = true;
        courses.coursePlaylist = []
    },
    coursePlaylistReceived: (courses,action)=>{
        courses.loading = false;        
        courses.coursePlaylist = action.payload;
    },
    courseInstructorSelectRequested: (courses)=> {
        courses.loading = true;
        courses.courseSelect = []
    },
    courseInstructorSelectReceived: (courses,action)=>{
        courses.loading = false;        
        courses.courseSelect = action.payload;
    },
    
    }
})


const {
    courseAdded,
    getCourse,   
    courseReceived,
    courseRequested,
    courseRequestFailed,   
    getCertificate,
    fileUpload,
    lectureRecordingActionReceived,
    lectureRecordingActionRequested,
    lectureRecordingReceived,
    lectureRecordingRequested,
    coursePlaylistRequested,
    coursePlaylistReceived,
    courseInstructorSelectReceived,
    courseInstructorSelectRequested
} = slice.actions
export default slice.reducer;

export const loadCourse = () => apiCallBegan({
    url: requests.fetchCourse,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: courseReceived.type,
    onError: courseRequestFailed.type
})
export const loadCourseSelect = () => apiCallBegan({
    url: requests.fetchSelectCourse,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: courseReceived.type,
    onError: courseRequestFailed.type
})
export const loadCourseSelectById = (id) => apiCallBegan({
    url: requests.fetchSelectCourseById+'/'+id,
    headers:AuthHeader,
    onStart: courseRequested.type,
    onSuccess: courseReceived.type,
    onError: courseRequestFailed.type
})

 export const addCourse = (course) => apiCallBegan({
     url: requests.createCourse,
     method:"post",
     headers:AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })

 export const getCourseBySlug = (slug) => apiCallBegan({
     url: requests.getCourseBySlug +'/'+ slug,
     headers:AuthHeader,
     onStart: courseRequested.type,
     onSuccess: getCourse.type,
     onError: courseRequestFailed.type
 })
 export const getCourseCertificateById = (slug) => apiCallBegan({
     url: requests.getCourseCertificateById +'/'+ slug,
     headers:AuthHeader,
     onStart: courseRequested.type,
     onSuccess: getCertificate.type,
     onError: courseRequestFailed.type
 })
 export const editCourse = (course) => apiCallBegan({
     url: requests.editCourse,
     method:"patch",
     headers:AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
 export const addCourseResource  = (course) => apiCallBegan({
     url: requests.addResource,
     method:"post",
     headers: MultiPartHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type,
     onUpload:fileUpload.type,
     options:true
     
 })
 export const editCourseBannerImg = (course) => apiCallBegan({
     url: requests.editCourseBannerImg,
     method:"patch",
     headers: MultiPartHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
 export const editResource = (course) => apiCallBegan({
     url: requests.editResources,
     method:"patch",
     headers: MultiPartHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type,
     onUpload:fileUpload.type,
     options:true
 })
 export const deleteResource = (course) => apiCallBegan({
     url: requests.deleteResources,
     method:"patch",
     headers: AuthHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type,     
 })
 export const updateCertificateImg = (course) => apiCallBegan({
     url: requests.updateCertificate,
     method:"patch",
     headers: MultiPartHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })
 export const editCoursePreviewImg = (course) => apiCallBegan({
     url: requests.editCoursePreviewImg,
     method:"patch",
     headers: MultiPartHeader,
     data: course,
     onStart: courseRequested.type,
     onSuccess: courseAdded.type,
     onError: courseRequestFailed.type
 })

 export const createLectureRecording = (data)=>apiCallBegan({
    url:requests.createRecording,
    data:data,
    method:'POST',
    onStart: lectureRecordingActionRequested.type,
    onSuccess: lectureRecordingActionReceived.type,
    onError: courseRequestFailed.type
 })
 export const updateLectureRecording = (data)=>apiCallBegan({
    url:requests.updateRecording,
    data:data,
    method:'PATCH',
    onStart: lectureRecordingActionRequested.type,
    onSuccess: lectureRecordingActionReceived.type,
    onError: courseRequestFailed.type
 })
 export const deleteLectureRecording = (data)=>apiCallBegan({
    url:requests.deleteRecording,
    data:data,
    method:'PATCH',
    onStart: lectureRecordingActionRequested.type,
    onSuccess: lectureRecordingActionReceived.type,
    onError: courseRequestFailed.type
 })
 export const getLectureRecording = (data)=>apiCallBegan({
    url:requests.getLectureRecording,
    data:data,
    method:'POST',
    onStart: lectureRecordingRequested.type,
    onSuccess: lectureRecordingReceived.type,
    onError: courseRequestFailed.type
 })
 export const getLectureRecordingById = (data)=>apiCallBegan({
    url:requests.getLectureRecordingById+'/'+data,    
    method:'GET',
    onStart: lectureRecordingRequested.type,
    onSuccess: lectureRecordingReceived.type,
    onError: courseRequestFailed.type
 })
 export const getInstructorCourseSelect = ()=>apiCallBegan({
    url:requests.getInstructorCourse,    
    method:'GET',
    headers:AuthHeader,
    onStart: courseInstructorSelectRequested.type,
    onSuccess: courseInstructorSelectReceived.type,
    onError: courseRequestFailed.type
 })
 export const getCoursePlayList = (slug)=>apiCallBegan({
    url:requests.getCoursePlayList+'/'+slug,    
    method:'GET',
    headers:AuthHeader,
    onStart: coursePlaylistRequested.type,
    onSuccess: coursePlaylistReceived.type,
    onError: courseRequestFailed.type
 })
  