import React, { useEffect, useState } from 'react'
// import * as Yup from 'yup'
import { Gender } from '../../Constants'
import SelectField from '../Form/SelectField'
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import TextError from '../../TextError'
import Card from '../Layout/Card'
import axios from '../../axios'
import requests from '../../ApiRequests/Request'
function Generalnfo({handleSubmit,prev,data}) {
  const [,setDobEng] = useState('');
  const [dobNep,setDOBNep] = useState('');
  const [initValues,setInitialValues] = useState(data)
  const [zone,setZone] = useState()
  const [district,setDistrict] = useState()
  const [t_district,setTDistrict] = useState()
  const [count,setCount] = useState(0);
  const [hideCalendarValue,setHideCalendarValue] = useState(true)
  // let count = 0;
  useEffect(()=>{
    async function getZoneAll(){
      try{
      const result = await axios.get(`${requests.getZoneAll}`)      
      setZone(result?.data?.data)
      }catch(errors){

      }
    }
    getZoneAll()
  },[])
  const handleDateChange= async ({ bsDate, adDate })=>{
    setCount(count+1)   
    // if(count>0){
      setDOBNep(bsDate)
      
      setDobEng(adDate)
      setHideCalendarValue(false)
      setInitialValues({...initValues,emp_dob_nep:bsDate,emp_dob_eng:adDate})
    // }
  }
  

  // console.log(initValues)
  // const cardTitle = {
  //   backgroundColor:"grey"
  // }
  // const initialValues = {
  //   emp_first_name :"",
  //   emp_middle_name:"",
  //   emp_last_name:"",
  //   nationality:"",
  //   gender:"",
  //   emp_dob_nep:dobNep,
  //   emp_dob_eng:dobEng,
  //   blood_group_id:"",
  //   id_card_issue_status:"",
  //   id_card_no:"",
  //   phone_no:"",
  //   mobile_no:"",
  //   mobile_no_official:"",
  //   emp_email:"",
  //   email_official:"",
  //   religion_id:"",
  //   country:"",
  //   state:"",
  //   district:"",
  //   vdc_municipality:"",
  //   ward_no:"",
  //   tole:"",
  //   house_no:"",
  //   t_country:"",
  //   t_state:"",
  //   t_district:"",
  //   t_vdc_municipality:"",
  //   t_ward_no:"",
  //   t_tole:"",
  //   t_house_no:"",
  //   emg_name:"",
  //   contact_no:"",
  //   relation:"",
  //   address:""

  // }
 
  // const validationSchema = Yup.object().shape({
  //   emp_first_name: Yup.string().max(30).min(2).required().label("First Name"),
  //   emp_last_name:Yup.string().min(2).max(30).required().label("Last Name"),
  //   nationality: Yup.string().required().min(2).max(30).label("Nationality"),
  //   gender: Yup.string().required().label("Gender"),
  //   emp_dob_nep: Yup.string().required().label("Date of Birth"),
  //   emp_dob_eng: Yup.string().required().label("Date Of Birth A.D."),
  //   mobile_no: Yup.string().required().label("Mobile No"),
  //   emp_email: Yup.string().email().required().label("Email"),
  //   email_official: Yup.string().email().label("Official Email"),
  //   country: Yup.string().max(50).min(3).required().label("Country"),
  //   state: Yup.string().required().label("State"),
  //   district: Yup.string().required().label("District"),
  //   vdc_municipality: Yup.string().required().label("VDC/Municipality"),
  //   t_ward_no: Yup.string().required().label("Ward"),
  //   t_country: Yup.string().required().min(2).max(50).label("Country"),
  //   t_state: Yup.string().required().label("State"),
  //   t_district: Yup.string().required().label("District"),
  //   t_vdc_municipality: Yup.string().required().label("VDC/Municipality"),
  //   t_ward_no: Yup.string().required().label("Ward No"),
  //   emg_name: Yup.string().required().label("Name"),
  //   contact_no: Yup.number().required(),
  //   relation: Yup.string().required().label("Relation"),
  //   address: Yup.string().required().min(3).max(150).label("Address")




  // })
  const handleZoneChange = async (zone_id)=>{
      const result = await axios.get(`${requests.getDistrictByZone}/${zone_id}`)
      setDistrict(result?.data?.data)
  }
  const handleTZoneChange = async (zone_id)=>{
      const result = await axios.get(`${requests.getDistrictByZone}/${zone_id}`)
      setTDistrict(result?.data?.data)
  }
  
    return (
      <>
     <Formik
     initialValues={initValues}
     onSubmit={handleSubmit}
     enableReinitialize
     >
       {({})=>(
         <>
         <Form>
        
              <Card cardTitle="Basic Information">
                <div className="row"> 
                {/* <FormField
                divSize ="col-md-3 col-xs-12 col-sm-12 form-group"
                name="emp_first_name"
                label="First Name"
                type="text"
                requiredField="requiredField"
                placeholder="First Name"
                value={data?.emp_first_name}
              /> */}
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>First Name<span className="errors">*</span>:</label>
              <FastField name="emp_first_name" className="form-control" placeholder="First Name" />
              <ErrorMessage name="emp_first_name" component={TextError}/>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Middle Name:</label>
              <FastField name="emp_middle_name" className="form-control" placeholder="Middle Name" />
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Last Name<span className="errors">*</span>:</label>
              <FastField name="emp_last_name" className="form-control" placeholder="First Name" />
              <ErrorMessage name="emp_last_name" component={TextError}/>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Nationality<span className="errors">*</span>:</label>
              <FastField name="nationality" className="form-control" placeholder="Nationality" />
              <ErrorMessage name="nationality" component={TextError}/>
              </div>            
              
                </div>

                <div className="row">
                  <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name="gender"
                    label="Gender"
                    requiredField="requiredField"
                    data={Gender}
                  />
                  <div className="col-md-4 col-xs-12 col-sm-12 form-group">
                  <label>DOB(B.S.)<span className="errors">*</span></label>
                  <Calendar
                  name="emp_dob_nep"
                  language="en"
                  theme="default"
                  onChange={handleDateChange}
                  placeholder="Selct DoB"
                  className="form-control"
                  hideDefaultValue={hideCalendarValue}
                  defaultDate={dobNep}
                  />
                  </div>
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>DOB(A.D)<span className="errors">*</span>:</label>
              <FastField name="emp_dob_eng" value={initValues.emp_dob_eng} className="form-control" placeholder="DOB" />
              <ErrorMessage name="emp_dob_eng" component={TextError}/>
              </div> 
                  
                  <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name="blood_group_id"
                    label="Blood Group"
                    requiredField="requiredField"
                    
                  />
                  
                </div>
                <div className="row">
                <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name="religion_id"
                    label="Religion"
                    requiredField="requiredField"
                    
                  />
                <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Mobile No:<span className="errors">*</span>:</label>
                <FastField name="mobile_no" type="number" className="form-control" placeholder="mobile no" />
                <ErrorMessage name="emp_dob_eng" component={TextError}/>
                </div> 
                <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Telephone No:</label>
                <FastField name="telephone_no" type="number" className="form-control" placeholder="telephone no" />
                <ErrorMessage name="telephone_no" component={TextError}/>
                </div> 
                <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Email:<span className="errors">*</span>:</label>
                <FastField name="emp_email" type="email" className="form-control" placeholder="Email" />
                <ErrorMessage name="emp_email" component={TextError}/>
                </div>                
                </div>
                </Card>          

              <Card cardTitle="Permanent Address">
              <div className="row">
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Country<span className="errors">*</span>:</label>
              <FastField name={`p_address.country`} className="form-control" placeholder="Country" />
              <ErrorMessage name={`p_address.country`} component={TextError}/>
              </div> 
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Zone<span className="errors">*</span>:</label>
              <Field as="select" id={`p_address.zone`} name={`p_address.zone`} className="form-control" onChange={(e)=>handleZoneChange(e.target.value)}>
               <option value="">Select Zone</option>
                    {zone && zone.map((item)=>(
                    <option key={item.encoded_id} value={item.encoded_id}>{item.zone_name}</option>
                    ))}
                </Field>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>District<span className="errors">*</span>:</label>
              <Field as="select" id={`p_address.district`} name={`p_address.district`} className="form-control" >
               <option value="">Select District</option>
                    {district && district.map((item)=>(
                    <option key={item.encoded_id} value={item.encoded_id}>{item.district_name}</option>
                    ))}
                </Field>
              </div>
                  
                  <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name={`p_address.vdc_municipality`}
                    label="VDC/Municipality"
                    requiredField="requiredField"
                    
                  />
                  
                  </div>
                  <div className="row">
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>Ward No<span className="errors">*</span>:</label>
                  <FastField name={`p_address.ward_no`} className="form-control" placeholder="Ward No" />
                  <ErrorMessage name={`ward_no`} component={TextError}/>
                  </div> 
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>Street:</label>
                  <FastField name={`p_address.tole`} className="form-control" placeholder="Street" />
                  <ErrorMessage name={`p_address.tole`} component={TextError}/>
                  </div> 
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>House NO:</label>
                  <FastField name={`p_address.house_no`} className="form-control" placeholder="House No" />
                  <ErrorMessage name={`p_address.house_no`} component={TextError}/>
                  </div>
                  </div> 

                  </Card>
             
     
              <Card cardTitle="Temporary Address">
              <div className="row">
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Country<span className="errors">*</span>:</label>
              <FastField name={`t_address.t_country`} className="form-control" placeholder="Country" />
              <ErrorMessage name={`t_address.t_country`} component={TextError}/>
              </div> 
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>Zone<span className="errors">*</span>:</label>
              <Field as="select" id={`t_address.t_zone`} name={`t_address.t_zone`} className="form-control" onChange={(e)=>handleTZoneChange(e.target.value)}>
               <option value="">Select Zone</option>
                    {zone && zone.map((item)=>(
                    <option key={item.encoded_id} value={item.encoded_id}>{item.zone_name}</option>
                    ))}
                </Field>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
              <label>District<span className="errors">*</span>:</label>
              <Field as="select" id={`t_address.t_district`} name={`t_address.t_district`} className="form-control" >
               <option value="">Select District</option>
                    {t_district && t_district.map((item)=>(
                    <option key={item.encoded_id} value={item.encoded_id}>{item.district_name}</option>
                    ))}
                </Field>
              </div>
                  <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name={`t_address.t_vdc_municipality`}
                    label="VDC/Municipality"
                    requiredField="requiredField"
                    
                  />
                  
                  </div>
                  <div className="row">
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>Ward No<span className="errors">*</span>:</label>
                  <FastField name={`t_address.t_ward_no`} className="form-control" placeholder="Ward No" />
                  <ErrorMessage name={`t_address.t_ward_no`} component={TextError}/>
                  </div> 
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>Street:</label>
                  <FastField name={`t_address.t_tole`} className="form-control" placeholder="Street" />
                  <ErrorMessage name={`t_address.t_tole`} component={TextError}/>
                  </div> 
                  <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>House NO:</label>
                  <FastField name={`t_address.t_house_no`} className="form-control" placeholder="House No" />
                  <ErrorMessage name={`t_address.t_house_no`} component={TextError}/>
                  </div>
                  </div> 

                  </Card>            
        
        
            <Card cardTitle="Official Contact Details">
            <div className="row">
            <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                  <label>Official Mobile No:</label>
                  <FastField name="mobile_no_official" type="number" className="form-control" placeholder="Official Mobile NO" />
                  <ErrorMessage name="mobile_no_official" component={TextError}/>
                  </div>
            <div className="col-md-5 col-xs-12 col-sm-12 form-group">
                  <label>Official Email:</label>
                  <FastField name="email_official" type="email" className="form-control" placeholder="Email Official" />
                  <ErrorMessage name="email_official" component={TextError}/>
                  </div>         
                  </div>
                  </Card>          
        
            <Card cardTitle="Emergency Contact Details">
            <div className="row">
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Contact Person Name<span className="errors">*</span>:</label>
                <FastField name={`emergency_contact.emg_name`} placeholder="Contact Person Name" className="form-control"/>
                <ErrorMessage name={`emergency_contact.emg_name`} component={TextError}/>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Relation<span className="errors">*</span>:</label>
                <FastField name={`emergency_contact.relation`} placeholder="Relation" className="form-control"/>
                <ErrorMessage name={`emergency_contact.relation`} component={TextError}/>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Contact No<span className="errors">*</span>:</label>
                <FastField name={`emergency_contact.contact_no`} placeholder="Contact Person Name" className="form-control"/>
                <ErrorMessage name={`emergency_contact.contact_no`} component={TextError}/>
              </div>
              <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                <label>Address<span className="errors">*</span>:</label>
                <FastField name={`emergency_contact.address`} placeholder="Address" className="form-control"/>
                <ErrorMessage name={`emergency_contact.address`} component={TextError}/>
              </div>          
                </div>
                </Card>
         
        
        {/* <div className="float-right"> */}
          <button className="btn btn-success btn-flat"  type="submit">Next</button>
          {/* </div> */}
          </Form>
          </>
          )}
          </Formik>
       
        </>
    )
}

export default Generalnfo
