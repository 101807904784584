
import { Field, Form, Formik, FieldArray } from 'formik'
import React from 'react'
import Card from '../Layout/Card'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

function AcademicInfo({handleSubmit,prev,data}) {
//     const [initialValue,setInitialValue] = useState(data);
//     const initialValues = {
//         academic_info:
//     [{
//         degree_type:"",
//         degree_name:"",
//         specialization:"",
//         institute_name:"",
//         university_board_name:"",
//         passed_status:"",
//         percentage_gpa:"",
//         passed_year_eng:"",
//         passed_year_nep:"",        
//     }]
// }
    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    return (
        <Card
        cardTitle="Academic Info"
        >
            <div className="table-responsive">
                <Formik
                initialValues={data}
                onSubmit={handleSubmit}
                >
                    {
                        ({values}) => (
                            <Form>
                                <FieldArray
                                name="academic_info"
                                render={
                                    arrayHelpers => (
                                        <>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>S.N.</th>
                                                    <th>Degree Type</th>
                                                    <th>Degree Name</th>
                                                    <th>specialization</th>
                                                    <th>School/College Name</th>
                                                    <th>University/Board</th>
                                                    <th>Status</th>
                                                    <th>GPA/Percent</th>
                                                    <th>Passed Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    values.academic_info && values.academic_info.length>0 ?
                                                    (
                                                        values.academic_info.map((academic,index) => (
                                                            <tr>
                                                                <td>{index+1}</td>
                                                                <td>
                                                                    <Field
                                                                    as="select"
                                                                    name= {`academic_info[${index}].degree_type`}
                                                                    className="form-control"
                                                                    >
                                                                        <option value="">Select Academic Info</option>
                                                                        </Field>
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].degree_name`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].specialization`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].institute_name`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].university_board_name`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].passed_status`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].percentage_gpa`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].passed_year_nep`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                    name={`academic_info[${index}].passed_year_eng`}
                                                                    className="form-control"
                                                                    />                                                                    
                                                                </td>
                                                                <td>
                                                                { 
                                                        index>0?
                       
                                                            <button
                                                                type="button"
                                                                //  className="btn btn-flat btn-sm btn-danger"
                                                                style={buttonStyles}
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >                           
                                                                <DeleteIcon/>
                                                            </button>
                                                            :null
                                                                }
                                                            <button
                                                                type="button"
                                                                style={buttonStyles}
                                                                //  className="btn btn-flat btn-sm btn-success"
                                                                onClick={() => arrayHelpers.push({degree_type:'',degree_name:'',specialization:'',institute_name:'',university_board_name:'',passed_status:'',percentage_gpa:'',passed_year_eng:'',passed_year_nep:''})} // insert an empty string at a position
                                                            >
                                                                <AddIcon/>
                                                            </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ):null
                                                }
                                            </tbody>
                                        </table>
                                        </>
                                    )
                                }
                                />
                                <button className="btn btn-success btn-flat" onClick={()=>prev(values)}>Prev</button>
                                <button className="btn btn-success btn-flat">Next</button>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </Card>
    )
}

export default AcademicInfo

