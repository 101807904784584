import React from 'react'
import {baseUrl} from '../../axios'
import './ImageViewer.css';
function ImageViewer({image}) {
    console.log("baseurl",baseUrl)
    let imageSrc = baseUrl + image
    return (
        <div className="image_container">
            
            {image && <img src={imageSrc} alt="avtar" style={{objectFit:"cover"}}/>}
            
        </div>
    )
}

export default ImageViewer
