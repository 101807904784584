import React, { useEffect, useState } from 'react'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup'
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { addModelSet } from '../../store/QuizSetupStore'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import { loadCourseSelect } from '../../store/CourseSetup'
import {NEPALIYEAR,ADYEAR} from '../Calendar/NPYear'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import TimePicker from 'react-time-picker';
import DateTimePicker from 'react-datetime-picker'
function ModelsetAdd({openPopup,setOpenPopup}) {
    const dispatch = useDispatch()
    const course = useSelector(state=>state.entities.courses.result?.data)
    const errors = useSelector(state=>state.entities.courses.error?.data)
    const loading = useSelector(state => state.entities.quiz.loading)
    const [year,setYear] = useState(ADYEAR);
    const [timevalues, setTimeValues] = useState();
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues = {
        courses:'',
        year:year,
        modelset_name:'',
        modelset_type:'',
        total_marks:'',
        pass_marks:'',
        has_negative_marking:'',
        has_passage:'',
        negative_marking:'',
        exam_time_duration:timevalues
        
    }
    const onChanges = (value)=>{
        console.log("value",value)
        setTimeValues(value)
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        year:Yup.string().required(),
        modelset_type:Yup.string().required(),
        exam_time_duration:Yup.string().required(),
        modelset_name:Yup.string().required().min(4).max(100).label('Modelset Name'),
        total_marks:Yup.number().required().label('Full Marks'),
        pass_marks:Yup.number().required().label('Pass Marks'),
        has_negative_marking: Yup.string().required().label('has negative marking'),
        has_passage: Yup.string().required().label('has passage'),
        negative_marking: Yup.number().when('has_negative_marking',{
            is:(negative_marking) => negative_marking === 'TRUE',
            then:Yup.number().required().label('negative marking')
        })
    })
    const CHOICES = [
        {
            'value':'TRUE'
        },
        {
            'value':'FALSE'
        }
    ]
    const MODELSETTYPE = [
        {
            'value':'normal',
            'label':'NORMAL'
        },
        {
            'value':'ku',
            'label':'KU'
        }
    ]
    const handleSubmit = (values,submitProps) =>{
        console.log("values su",values)
        values.exam_time_duration_cpy = values.exam_time_duration
        values.exam_time_duration = formatTime(values.exam_time_duration)
        dispatch(addModelSet(values));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    const formatTime = (time) => {
        if (!time) return '';
        const hours = String(time.getHours()).padStart(2, '0');
        const minutes = String(time.getMinutes()).padStart(2, '0');
        const seconds = String(time.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      };
    const onChange = (bsDate)=>{
        setYear(bsDate);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Modelset Add">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
         >
            {
                ({isSubmitting,values})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Year</label>:<span className='errors'>*</span>
                            <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                        </div>
                        <div className='form-group'>
                            <label>select courses:</label><span className='errors'></span>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            {errors?.courses && <div className="errors">{errors?.courses}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Select ModelSet Type:</label><span className='errors'></span>
                            <Field as="select" name="modelset_type" id="modelset_type" placeholder="" className='form-control'  >
                                            <option name="modelset_type" value="">select Options</option>
                                                {
                                                    MODELSETTYPE && MODELSETTYPE?.map((item,index)=>(
                                                        <option key={index} value={item?.value}  >{item?.label}</option>
                                                        ))
                                                    }
                                    </Field>
                                <ErrorMessage name="modelset_type" component={TextError} />
                                {errors?.modelset_type && <div className="errors">{errors?.modelset_type}</div>}
                        </div>
                        
                        <div className='form-group'>
                            <label>Modelset Name :</label><span className='errors'>*</span>
                            <FastField name="modelset_name" className="form-control" placeholder="modelset name"/>
                            <ErrorMessage name='modelset_name' component={TextError}/>
                            {errors?.modelset_name && <div className='errors'>{errors?.modelset_name}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Full Marks :</label><span className='errors'>*</span>
                            <FastField type="number" name="total_marks" className="form-control" placeholder="full marks"/>
                            <ErrorMessage name='total_marks' component={TextError}/>
                            {errors?.total_marks && <div className='errors'>{errors?.total_marks}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Pass Marks :</label><span className='errors'>*</span>
                            <FastField type="number" name="pass_marks" className="form-control" placeholder="pass marks"/>
                            <ErrorMessage name='pass_marks' component={TextError}/>
                            {errors?.pass_marks && <div className='errors'>{errors?.pass_marks}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Quiz Duration :</label><span className='errors'>*</span>
                            <DateTimePicker name='exam_time_duration' onChange={onChanges} value={timevalues} className='form-control' format="HH:mm:ss"/>
                            <ErrorMessage name='exam_time_duration' component={TextError}/>
                        </div>
                        <div className='form-group'>
                            <label>has passage?:</label><span className='errors'></span>
                            <Field as="select" name="has_passage" id="has_passage" placeholder="" className='form-control'  >
                                            <option name="has_passage" value="">select Options</option>
                                                {
                                                    CHOICES && CHOICES?.map((item,index)=>(
                                                        <option key={item.value} value={item.value}  >{item.value}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="has_passage" component={TextError} />
                                            {errors?.has_passage && <div className="errors">{errors?.has_passage}</div>}
                        </div>
                        <div className='form-group'>
                            <label>has negative marking?:</label><span className='errors'></span>
                            <Field as="select" name="has_negative_marking" id="has_negative_marking" placeholder="" className='form-control'  >
                                            <option name="has_negative_marking" value="">select Options</option>
                                                {
                                                    CHOICES && CHOICES?.map((item,index)=>(
                                                        <option key={item.value} value={item.value}  >{item.value}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="has_negative_marking" component={TextError} />
                                            {errors?.has_negative_marking && <div className="errors">{errors?.has_negative_marking}</div>}
                        </div>
                        {
                            values.has_negative_marking === 'TRUE'?
                            <div className='form-group'>
                                <label>Negative Marking:</label><span className='errors'>*</span>
                                <Field name="negative_marking" className="form-control" type="number" placeholder="0.00"/>
                                <ErrorMessage name="negative_marking" component={TextError} />
                                {errors?.negative_marking && <div className="errors">{errors?.negative_marking}</div>}
                            </div>
                            :''
                        }
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''} &nbsp;<i className='fa fa-save'/> &nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ModelsetAdd