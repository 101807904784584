import React,{useState,useEffect} from "react";
import FormPopUp from "../popup/PopUp";
import { Form, Formik,Field, ErrorMessage } from "formik";
import Spinner from "../Layout/Spinner";
import * as Yup from 'yup';
import TextError from "../../TextError";
import { useDispatch } from "react-redux";
import { updateenquirys } from "../../store/EnquiryStore";
function EnquiryStatusEdit({openPopup,setOpenPopup,enquiry}){
    const [estatus,setEStatus] = useState(enquiry?.enquiry_status)
    
    const dispatch = useDispatch()
    
    const status = [
        {
            'value':'requested',
            'label':'REQUESTED',
        },
        {
            'value':'pending',
            'label':'PENDING',
        },
        {
            'value':'converted',
            'label':'CONVERTED',
        },
        {
            'value':'not-converted',
            'label':'NOT-CONVERTED',
        },
    ]
    const handleStatusClick = (data)=>{
        setEStatus(data)
    }
    const initialValues = {
        enquiry_id: enquiry?.iid,
        enquiry_status: estatus
    }
    const validationSchema = Yup.object().shape({
        enquiry_id: Yup.string().required(),
        enquiry_status: Yup.string().required().label('status')

    })
    const handleSubmit = async(values,submitProps)=>{
        await dispatch(updateenquirys(values))
        submitProps.setSubmitting(false);
        setOpenPopup(false)
    }
    return(
        <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={`Enquiry Status`}>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className="row">
                                <div className="col-md-4">
                                <div className='form-group'>
                                            <label>Select Status:</label><span className='errors'>*</span>
                                            <Field as="select" name="enquiry_status" className="form-control" value={estatus} onChange={(e)=>handleStatusClick(e.target.value)}>
                                                <option value="">select options</option>
                                                {
                                                  status && status.map((item)=>(
                                                    <option key={item?.value} value={item?.value}>{item?.label}</option>
                                                ))  
                                                }
                                            </Field>
                                            <ErrorMessage name='enquiry_status' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                </div>
                                <div className='col-md-2'>
                                        <div className='form-group'>
                                            <label>&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control'  disabled={isSubmitting}>
                                                {isSubmitting?<Spinner/>:''} <i className="fa fa-search"/>save status
                                            </button>
                                            </div>
                                        </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </FormPopUp>
    )
}
export default EnquiryStatusEdit