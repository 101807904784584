import { FastField, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  editBanner } from '../../store/BannerSetupStore';
import { loadCourseSelect } from '../../store/CourseSetup';
import Spinner from '../Layout/Spinner';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
function EditMobileBanner({openPopup,setOpenPopup,banner,setRefetch}) {
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result.data);
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const initialValues = {
        banner_id:banner?.iid,
        banner_tag_line:banner?.banner_tag_line,
        banner_desc:banner?.banner_desc,
        courses:banner?.course_iid,
        has_enroll:banner?.has_enroll,
        modified_remarks:''
    }   
    const validationSchema = Yup.object().shape({
        has_enroll:Yup.string().required(),
        modified_remarks:Yup.string().min(5).max(100).required().label('modified remarks')
    })
    const handleSubmit = (values,submitProps)=>{       
        dispatch(editBanner(values));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
    }
    
  return (
    <FormPopUp title="Edit Mobile Banner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit} 
        validationSchema={validationSchema}      
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>                       
                        <div className='form-group'>
                            <label>Banner Title :</label>
                            <Field name="banner_tag_line" className="form-control" placeholder="banner title"/>
                        </div>
                        <div className='form-group'>
                            <label>Banner Desc :</label>
                            <Field as="textarea" rows="2" name="banner_desc" className="form-control" placeholder="banner description"/>
                        </div>
                        <div className='form-group'>
                        <label className='required-label'>Select course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }
                                 </Field>
                        </div>
                        <div className='form-group'>
                            <label>Modified Remarks </label><span className='errors'>*</span>
                            <FastField name="modified_remarks" placeholder="modified remarks*" className="form-control"/>
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default EditMobileBanner