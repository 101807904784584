
import './App.css';
import Header from './components/DashboardTemplate/Header';
import Menu from './components/DashboardTemplate/Menu';
import MainContent from './components/DashboardTemplate/MainContent';
import RightMenu from './components/DashboardTemplate/RightMenu';
import Footer from './components/DashboardTemplate/Footer';
import { Route, Switch } from 'react-router-dom';
import SubCourseSetup from './components/SubcourseSetup/SubCourseSetup';
import CourseSetupTree from './components/CourseSetup/CourseSetupTree';
import {Provider} from 'react-redux';
import configureStore from "./store/ConfigureAppStore";
import QuizSetup from './components/QuizSetup/QuizSetup';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import Dashboard from './components/DashboardTemplate/Dashboard';
import Login from './components/Login/Login';
import SignUp from './components/Login/SignUp';

import CompanySetup from './components/CompanySetup/CompanySetup';

import EmployeeAdd from './components/EmployeeSetup/EmployeeAdd';
import DepartmentSetup from './components/DepartmentSetup/DepartmentSetup';
import DesignationSetup from './components/DesignationSetup/DesignationSetup';
import MenuSetup from './components/MenuSetup/MenuSetup';
import ResetPassword from './components/Login/ResetPassword';
import PasswordResetComplete from './components/Login/PasswordResetComplete';
import InstructorSetup from './components/InstructorSetup/InstructorSetup';
import QuizAdd from './components/QuizSetup/QuizAdd';
import QuizEdit from './components/QuizSetup/QuizEdit';
import VIewQuizDetails from './components/QuizSetup/VIewQuizDetails';
import QuizTagSetup from './components/QuizTagSetup.js/QuizTagSetup';
import QuizTagReport from './components/QuizTagSetup.js/QuizTagReport';
import ProgramOverviewSetup from './components/ProgramOverview/ProgramOverviewSetup'
import ProgramOverviewAdd from './components/ProgramOverview/ProgramOverviewAdd';
import ProgramOverviewEdit from './components/ProgramOverview/ProgramOverviewEdit';
import FAQSetup from './components/FAQ/FAQSetup';
import FAQAdd from './components/FAQ/FAQAdd';
import FAQEdit from './components/FAQ/FAQEdit';
import AlumniSetup from './components/Alumni/AlumniSetup';
import ViewAlumniDetails from './components/Alumni/ViewAlumniDetails';
import InstructorTagSetup from './components/InstructorTagSetup/InstructorTagSetup';
import ViewInstructorsDetails from './components/InstructorSetup/ViewInstructorsDetails';
import InstructorTagRestoreSetup from './components/InstructorTagSetup/InstructorTagRestoreSetup';
import FeatureSetup from './components/Feature/FeatureSetup';
import FeatureTagSetup from './components/FeatureTagSetup/FeatureTagSetup';
import RestoreFeatureTagSetup from './components/FeatureTagSetup/RestoreFeatureTagSetup';
import MobileBannerSetup from './components/Banner/MobileBannerSetup';
import HomeBannerSetup from './components/HomeBannerSetup/HomeBannerSetup';
import QuizModelSetup from './components/QuizModelSetSetup/QuizModelSetup';
import QuizLiveTagSetup from './components/QuizLiveTag/QuizLiveTagSetup';
import CoursePriceSetup from './components/CoursePriceSetup/CoursePriceSetup';
import AddCoursePrice from './components/CoursePriceSetup/AddCoursePrice';
import EditCoursePrice from './components/CoursePriceSetup/EditCoursePrice';
import QuizParagraphSetup from './components/QuizParagraphSetup/QuizParagraphSetup';
import PassageAdd from './components/QuizParagraphSetup/PassageAdd';
import PassageEdit from './components/QuizParagraphSetup/PassageEdit';
import TagQuizToParagraph from './components/QuizParagraphSetup/TagQuizToParagraph';
import MathJax from 'mathjax3-react';

import QuizLevelSetup from './components/QuizLevelSetup/QuizLevelSetup';
import LessonQuizSetup from './components/QuizTagSetup.js/LessonQuizSetup';
import QuizModelsetReport from './components/QuizModelSetSetup/QuizModelsetReport';
import ModelsetQuizView from './components/QuizModelSetSetup/ModelsetQuizView';
import UntaggedQuizReport from './components/QuizTagSetup.js/UntaggedQuizReport';
import WebSocketChatApp from './components/WS/WebSocketChatApp';
import ParagraphTagReport from './components/QuizParagraphSetup/ParagraphTagReport';
import ModelsetKuQuizView from './components/QuizModelSetSetup/ModelsetKuQuizView';
import OnBoardMobileSetup from './components/OnboardMobileSetup/OnBoardMobileSetup';
import MainSettings from './components/DashSettings/MainSettings';
import UnauthorizedAccess from './components/UnauthorizedAccess/UnauthorizedAccess';
import BatchSetup from './components/BatchSetup/BatchSetup';
import BatchTagSetup from './components/BatchSetup/BatchTagSetup';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import BatchTagReport from './components/BatchSetup/BatchTagReport';
import LiveRecordingSetup from './components/LiveRecordingSetup/LiveRecordingSetup';
import ModelsetBatchTagSetup from './components/QuizModelSetSetup/ModelsetBatchTagSetup';
import ArticleSetup from './components/Articles/ArticleSetup';
import AddArticle from './components/Articles/AddArticle';
import UpdateArticle from './components/Articles/UpdateArticles';
import CoursePlayListSetup from './components/CoursePlaylist/CoursePlayListSetup';
import CoursePlayList from './components/CoursePlaylist/CoursePlayList';
import EnquirySetup from './components/EnquirySetup/EnquirySetup';
const store = configureStore();

function App() { 
  
  return (
    <>
    <Provider store={store}>
    <MathJax.Provider>
    <ToastContainer/>   
    
    <Switch>       
        <Route path="/enquirySetup">
        <Dashboard pageTitle="Enquiry Setup" childComponent={<EnquirySetup/> }/>
        </Route>
        <Route path="/department">
        <Dashboard pageTitle="Department Setup" childComponent={<DepartmentSetup/> }/>
        </Route>
        <Route path="/designation">
        <Dashboard pageTitle="Designation Setup" childComponent={<DesignationSetup/> }/>
        </Route>
        <Route path="/menu">
        <Dashboard pageTitle="Menu Setup" childComponent={<MenuSetup/> }/>
        </Route>
        <Route path="/employee">
        <Dashboard pageTitle="" childComponent={<EmployeeAdd/> }/>
        </Route>
        
    <Route path="/company">
     
      <Dashboard pageTitle="CompanySetup" childComponent={<CompanySetup/>} buttonTitle="AddQuiz"/>
      
    </Route> 

      <Route path="/chat">
        <WebSocketChatApp/>
      </Route>
      <Route path="/courseplaylistsetup">
          {/* <Dashboard childComponent={<CoursePlayList/>}/> */}
          <Dashboard childComponent={<CoursePlayListSetup/>}/>
      </Route>
      <Route path="/articleSetup/editArticles/:slug">
        <Dashboard childComponent={<UpdateArticle/>}/>        
      </Route>  
      <Route path="/articleSetup/addArticle">
        <Dashboard childComponent={<AddArticle/>}/>        
      </Route>  
      <Route path="/articleSetup">
        <Dashboard childComponent={<ArticleSetup/>}/>        
      </Route> 
      
      <Route path={'/batchModelsetTagSetup'}>
        <Dashboard childComponent={<ModelsetBatchTagSetup/>}/>
      </Route>
      <Route path={"/liveRecordingSetup"}>
        <Dashboard childComponent={<LiveRecordingSetup/>}/>
      </Route>    
      <Route path={"/batchTagReport"}>
        <Dashboard childComponent={<BatchTagReport/>}/>
      </Route>    
      <Route path={"/batchTag"}>
        <Dashboard childComponent={<BatchTagSetup/>}/>
      </Route>    
      <Route path={"/batchSetup"}>
        <Dashboard childComponent={<BatchSetup/>}/>
      </Route>    
     <Route path="/settings" >
      <Dashboard  childComponent={<MainSettings/>} />               
      </Route> 
     <Route path="/onBoardMobileSetup" >
      <Dashboard  childComponent={<OnBoardMobileSetup/>} />               
      </Route> 
     <Route path="/coursePriceSetup/edit/:slug" >
      <Dashboard  childComponent={<EditCoursePrice/>} />               
      </Route> 
     <Route path="/coursePriceSetup/add" >
      <Dashboard  childComponent={<AddCoursePrice/>} />               
      </Route> 
     <Route path="/coursePriceSetup" >
      <Dashboard  childComponent={<CoursePriceSetup/>} />               
      </Route> 
     <Route path="/modelsetSetup" >
      <Dashboard  childComponent={<QuizModelSetup/>} />               
      </Route> 
     <Route path="/homeBannerSetup" >
      <Dashboard  childComponent={<HomeBannerSetup/>} />               
      </Route> 
     <Route path="/mobileBannerSetup" >
      <Dashboard  childComponent={<MobileBannerSetup/>} />               
      </Route> 
     <Route path="/featureTagRestore" >
      <Dashboard  childComponent={<RestoreFeatureTagSetup/>} />               
      </Route> 
     <Route path="/featureTag" >
      <Dashboard  childComponent={<FeatureTagSetup/>} />               
      </Route> 
     <Route path="/feature" >
      <Dashboard  childComponent={<FeatureSetup/>} />               
      </Route> 
     <Route path="/restoreInstructor" >
      <Dashboard  childComponent={<InstructorTagRestoreSetup/>} />               
      </Route> 
     <Route path="/tagInstructor" >
      <Dashboard  childComponent={<InstructorTagSetup/>} />               
      </Route> 
     <Route path="/alumni/viewDetails/:slug" >
      <Dashboard  childComponent={<ViewAlumniDetails/>} />               
      </Route> 
     <Route path="/alumni" >
      <Dashboard  childComponent={<AlumniSetup/>} />               
      </Route> 
     <Route path="/faq/edit/:slug" >
      <Dashboard  childComponent={<FAQEdit/>} />               
      </Route> 
     <Route path="/faq/add" >
      <Dashboard  childComponent={<FAQAdd/>} />               
      </Route> 
     <Route path="/faq" >
      <Dashboard  childComponent={<FAQSetup/>} />               
      </Route> 
     <Route path="/course_overview/edit/:slug" >
      <Dashboard pageTitle="Course Overview Setup" childComponent={<ProgramOverviewEdit/>} />               
      </Route> 
     <Route path="/course_overview/add" >
      <Dashboard pageTitle="Course Overview Setup" childComponent={<ProgramOverviewAdd/>} />               
      </Route> 
     <Route path="/course_overview" >
      <Dashboard pageTitle="Course Overview Setup" childComponent={<ProgramOverviewSetup/>} />               
      </Route> 
     <Route path="/paragraphTagReport" >
      <Dashboard childComponent={<ParagraphTagReport/>} />               
      </Route> 
     <Route path="/paragraphSetup/tag/:slug" >
      <Dashboard  childComponent={<TagQuizToParagraph/>} />               
      </Route> 
     <Route path="/paragraphSetup/edit/:slug" >
      <Dashboard  childComponent={<PassageEdit/>} />               
      </Route> 
     <Route path="/paragraphSetup/add" >
      <Dashboard  childComponent={<PassageAdd/>} />               
      </Route> 
     <Route path="/paragraphSetup" >
      <Dashboard  childComponent={<QuizParagraphSetup/>} />               
      </Route> 
     <Route path="/tagLiveQuiz" >
      <Dashboard  childComponent={<QuizLiveTagSetup/>} />               
      </Route> 
     <Route path="/modelsetQuizkuView/:slug" >
      <Dashboard  childComponent={<ModelsetKuQuizView/>} />             
      </Route> 
     <Route path="/modelsetQuizView/:slug" >
      <Dashboard  childComponent={<ModelsetQuizView/>} />             
      </Route> 
     <Route path="/modelsetQuizReport" >
      <Dashboard pageTitle="Modelset Quiz Report" childComponent={<QuizModelsetReport/>} />               
      </Route> 
     <Route path="/lessonQuizReport" >
      <Dashboard pageTitle="Lesson Quiz Setup" childComponent={<LessonQuizSetup/>} />               
      </Route> 
     <Route path="/untaggedquizreport" >
      <Dashboard childComponent={<UntaggedQuizReport/>} />               
      </Route> 
     <Route path="/quiztagreport" >
      <Dashboard pageTitle="Quiz Tag Setup" childComponent={<QuizTagReport/>} />               
      </Route> 
     <Route path="/quizLevelSetup" >
      <Dashboard pageTitle="Quiz Tag Setup" childComponent={<QuizLevelSetup/>} />               
      </Route> 
     <Route path="/quiztagsetup" >
      <Dashboard pageTitle="Quiz Tag Setup" childComponent={<QuizTagSetup/>} />               
      </Route> 
     <Route path="/quizsetup/view/:slug" >
      <Dashboard pageTitle="QuizSetup" childComponent={<VIewQuizDetails/>} />               
      </Route> 
     <Route path="/quizsetup/edit/:slug" >
      <Dashboard pageTitle="QuizSetup" childComponent={<QuizEdit/>} />               
      </Route> 
     <Route path="/quizsetup/add" >
      <Dashboard pageTitle="QuizSetup" childComponent={<QuizAdd/>} />
               
      </Route> 
     <Route path="/quizsetup" >
      <Dashboard pageTitle="QuizSetup" childComponent={<QuizSetup/>} buttonTitle="AddQuiz"/>               
      </Route> 
      
      <Route path="/coursesetup">        
        <Dashboard childComponent={<CourseSetupTree/>}/>        
      </Route>
      <Route path="/instructorsetup/view/:slug">        
        <Dashboard childComponent={<ViewInstructorsDetails/>}/>        
      </Route>
      <Route path="/instructorsetup">        
        <Dashboard childComponent={<InstructorSetup/>}/>        
      </Route>
      
      <Route path="/coursesetup/subcoursesetup" >
        <Header/>
        <Menu/>
        <SubCourseSetup/>
        <Footer/>        
      </Route>
       <Route path="/coursesetup/coursesetup" >
        <Header/>
        <Menu/>
        {/* <CourseSetup/> */}
        <CourseSetupTree/>
        <Footer/>
        
      </Route>
      <Route path="/passwordreset">
        <ResetPassword/>
        </Route>
      <Route path="/passwordresetcomplete">
        <PasswordResetComplete/>
        </Route>
      <Route path="/coursesetup/coursecategorysetup" >
        <Header/>
        <Menu/>
        {/* <CourseCategorySetup fetchUrl="/api/get_all_course_category"/> */}
        <CourseSetupTree/>
        <Footer/>
        
      </Route>
    <Route exact path="/dashboard">
      <Header/>
      <Menu/>
      <MainContent/>
      <RightMenu/>
      <Footer/>
    </Route>  
      <Route exact path="/signup">
        <SignUp/>
        </Route> 
      <Route exact path="/login">
        <Login/>
        </Route> 
        <Route path="/unauthorized">
        <UnauthorizedAccess/>
        </Route>
        <Route exact path="">
      <Login/>
      </Route>
    </Switch>
    </MathJax.Provider>
    </Provider>
  </>
  );
}

export default App;
