import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { MultiPartHeader, AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name : 'company',
    initialState : {
        result: [],
        error:[],
        logoData:[],
        loading: false,  
        percent:0      
    },
    reducers:{
        companyRequested: (company,action) => {
            company.loading = true;
        },
        companyReceived:  (company,action) =>{
            company.loading = false;
            company.result = action.payload;
        },
        companyRequestFailed: (company,action)=>{
            company.loading = false;
            company.error = action.payload;
        },
        companyAdded: (company,action)=>{
            company.loading = false;
            toast.success(action.payload.msg);
        },
        logoReceived: (company,action)=>{
            company.loading = false;
            company.logoData = action.payload;
        },
        fileUpload: (company,action)=>{
            console.log("percent",action.payload)
            // company.percent = action.payload;
        }       
    }
})

const {
    companyRequested,
    companyReceived,
    companyRequestFailed,
    companyAdded,
    logoReceived,
    fileUpload
} = slice.actions;

export default slice.reducer;

export const addCompany = (companyData) => apiCallBegan({
    url: requests.addCompany,
    method:'POST',
    data: companyData,
    headers:AuthHeader,
    onStart: companyRequested.type,
    onSuccess: companyAdded.type,
    onError: companyRequestFailed.type
});

export const getCompany = ()=> apiCallBegan({
    url: requests.getCompany,
    method: 'GET',
    headers:AuthHeader,
    onStart: companyRequested.type,
    onSuccess: companyReceived.type,
    onError: companyRequestFailed.type
});

export const updateCompany= (companyData) => apiCallBegan({
    url: requests.updateCompany,
    method: 'PATCH',
    data: companyData,
    headers:AuthHeader,
    onStart: companyRequested.type,
    onSuccess: companyAdded.type,
    onError: companyRequestFailed.type
})


export const getCompanyLogo = (company_id)=>apiCallBegan({
    url: requests.getCompanyLogo + company_id,
    headers:AuthHeader,
    onStart:companyRequested.type,
    onSuccess:logoReceived.type,
    onError: companyRequestFailed.type
})
export const addLogo = (logoData) => apiCallBegan({
    url: requests.addCompanyLogo ,
    method:'POST',
    data: logoData,
    headers: MultiPartHeader,
    onStart: companyRequested.type,
    onSuccess: companyAdded.type,
    onError: companyRequestFailed.type,
    onUpload: fileUpload.type,
    options: true,
})

export const getCompanyDetailsById = (id) => apiCallBegan({
    url:`/company/getAll/${id}`,
    method:'GET',
    headers:AuthHeader,
    onStart:companyRequested.type,
    onSuccess:companyReceived.type,
    onError:companyRequestFailed.type
})

export const editCompanyDetails = (companyData)=> apiCallBegan({
    url:'/company/edit_details',
    method : 'PATCH',
    headers:AuthHeader,
    data: companyData,
    onStart: companyRequested.type,
    onSuccess:companyReceived.type,
    onError: companyAdded.type
})