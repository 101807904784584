import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { loadCourseSelect } from '../../store/CourseSetup';
import * as Yup from 'yup';
import {  getModelSetByCourseYear } from '../../store/QuizSetupStore';
import Spinner from '../Layout/Spinner';
import ModelsetAdd from './ModelsetAdd';
import TextError from '../../TextError';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ActivateModelset from './ActivateModelset';
import DeactivateModelset from './DeactivateModelset';
import TagLiveQuiz from '../QuizLiveTag/TagLiveQuiz';
import ModelsetEdit from './ModelsetEdit';
import {ADYEAR,NEPALIYEAR} from '../Calendar/NPYear'
import ModelsetBatchTag from './ModelsetBatchTag';
function QuizModelSetup() {
  const [year,setYear] = useState(ADYEAR)
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  const [,setOpenPopUpView] = useState(false)
  const [openPopUpTag,setOpenPopUpTag] = useState(false)
  const [openPopUpDeactivate,setOpenPopUpDeactivate] = useState(false)
  const [openPopUpLive,setOpenPopUpLive] = useState(false)
  const [openPopUpModelsetTag,setOpenPopUpModelsetTag] = useState(false)
  const [edata,setEData] = useState()  
  const [modData,setModData] = useState()  
  const dispatch = useDispatch();
  const course = useSelector(state => state.entities.courses.result?.data);
  const errors = useSelector(state => state.entities.courses.error?.data);
  const modelset = useSelector(state => state.entities.quiz.modelset?.data)
  const loader = useSelector(state=>state.entities.quiz?.loading)
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  const handleView = (data)=>{
    setEData(data);
    setOpenPopUpView(true)
  }
  // const handleTag = (data)=>{
  //   setEData(data);
  //   setOpenPopUpTag(true)
  // }
  const handleLive = (data)=>{
    setEData(data);
    setOpenPopUpLive(true)
  }
  const handleModelsetTag = (data)=>{
    setModData(data);
    setOpenPopUpModelsetTag(true)
  }
  const handleActivate = (data)=>{
    setEData(data);
    setOpenPopUpTag(true)
  }
  const handleDeactivate = (data)=>{
    setEData(data);
    setOpenPopUpDeactivate(true)
  }
  useEffect(()=>{
    dispatch(loadCourseSelect());
  },[])
  const columns = [
    {
      key:'modelset_name',
      text:"Modelset Name"
    },
    {
      key:'modelset_type',
      text:"Modelset Type"
    },
    {
      key:'year',
      text:"Year"
    },
    {
      key:'course_name',
      text:"Course Name"
    },   
    {
      key:'is_Active',
      text:"Active Status",
      cell: record => {
          return(
              <>
              {record?.is_active?'active':'not active'}&nbsp;&nbsp;
              {record?.is_active? <Link to='#' onClick={()=>handleDeactivate(record)}>deactivate</Link>:<Link to='#' onClick={()=>handleActivate(record)}>activate</Link>}
              </>
          )
      }
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 300,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  {/* <button
                      className="btn btn-warning btn-sm"
                      onClick={() => handleLive(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-stream"></i>
                  </button> */}
                  <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleModelsetTag(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-tag"></i>
                  </button>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => console.log(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  <Link 
                    to={record?.modelset_type==='normal'?`/modelsetQuizView/${record?.iid}`:`/modelsetQuizkuView/${record?.iid}`}
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      onClick={()=>handleView(record)}
                      >                      
                      <i className="fa fa-eye"></i>
                  </Link>
              </>
          );
      }
  }
  ]
  const initialValues = {
      courses:'',
      year:year
  }
  const handleSearch =(values,submitProps)=>{
      dispatch(getModelSetByCourseYear(values));
      submitProps.setSubmitting(false)
  }
  const validationSchema = Yup.object().shape({
      courses:Yup.string().required(),
      year: Yup.string().required()
  })
  const onChange = (bsDate)=>{
    setYear(bsDate);
  }
  return (
    <>
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Modelset Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add Modelset</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSearch}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='form-group item'>
                                        <label className='required-label'>Select Course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={index} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            {errors?.courses && <div className="errors">{errors?.courses}</div>}
                                    </div>
                                    </div>
                                    <div className='col-md-4'>
                                      <div className='form-group'> 
                                        <label>Year:</label><span className='errors'>*</span>
                                        <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                          <option name="year" value="">select Options</option>
                                          {
                                              NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                                  <option key={index} value={item}>{item}</option>
                                              ))
                                          }
                                        </Field> 
                                        <ErrorMessage name="year" component={TextError} />
                                            {errors?.year && <div className="errors">{errors?.year}</div>}
                                      </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='form-group'>
                                                <label>&nbsp;</label>
                                                <button type='submit' className='btn btn-success btn-flat form-control' disabled={isSubmitting}>
                                                    {isSubmitting?<Spinner/>:''}&nbsp;<i className='fa fa-search'/>&nbsp;Search
                                                    </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <ReactDataTable        
        columns = {columns}
        records = {modelset}
        />
        {
            openPopUp &&
            <ModelsetAdd openPopup={openPopUp} setOpenPopup={setOpenPopUp}/>
        }
        {
            openPopUpEdit &&
            <ModelsetEdit openPopup={openPopUpEdit} setOpenPopup={setOpenPopUpEdit} data={edata}/>
        }
        {/* {
            openPopUpView &&
            <ViewFeatureDetails openPopup={openPopUpView} setOpenPopup={setOpenPopUpView} feature={edata}/>
        }*/}
        {
          openPopUpLive && 
          <TagLiveQuiz openPopup={openPopUpLive} setOpenPopup={setOpenPopUpLive} modelset={edata}/>
        }
        {
            openPopUpTag &&
            <ActivateModelset openPopup={openPopUpTag} setOpenPopup={setOpenPopUpTag} modelset={edata}/>
        } 
        {
            openPopUpDeactivate &&
            <DeactivateModelset openPopup={openPopUpDeactivate} setOpenPopup={setOpenPopUpDeactivate} modelset={edata}/>
        } 
        {
          openPopUpModelsetTag &&
          <ModelsetBatchTag openPopup={openPopUpModelsetTag} setOpenPopup={setOpenPopUpModelsetTag} modelsetId={modData?.iid} course={course} title={modData?.modelset_name}/>
        }
        {/* <InstructorEdit openPopUP={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} data={edata}/> */}
        {/* {
          openPopUpTag &&
          <InstructorTag openPopUp={openPopUpTag} setOPenPopUp={setOpenPopUpTag} instructor={edata}/>
        } */}
    </div>
    </div>
    {
        loader && <LoaderSpinner/>
    }
    </>
  )
}

export default QuizModelSetup