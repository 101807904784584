import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'mainTopic',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: [],
        percent:0
    },
    reducers:{
        mainTopicRequested:(mainTopic,action)=>{
            mainTopic.loading = true;
        },
        mainTopicReceived:(mainTopic,action)=>{
            mainTopic.loading = false;
            mainTopic.result = action.payload;
        },
        mainTopicRequestFailed:(mainTopic,action)=>{
            mainTopic.loading = false;
            mainTopic.error = action.payload;
            toast.error(action.payload?.msg)
        },
        mainTopicAddedEdited:(mainTopic,action)=>{
            mainTopic.loading = false;
            mainTopic.result = action.payload
            toast.success(action.payload?.msg)
        },
        getMainTopic:(mainTopic,action)=>{
            mainTopic.loading = false;
            mainTopic.getData = action.payload
        },
        getMainTopicById:(mainTopic,action)=>{
            mainTopic.loading = false;
            mainTopic.getDataById = action.payload
        },
       
    }
})

const {
    mainTopicAddedEdited,
    getMainTopic,
    getMainTopicById,
    mainTopicRequested,
    mainTopicRequestFailed,
    mainTopicReceived,
    
} = slice.actions

export default slice.reducer;

export const loadMainTopic = () => apiCallBegan({
    url:requests.fetchMainTopic,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:mainTopicReceived.type,
    onError: mainTopicRequestFailed.type
})
export const getMainTopicSelect = () => apiCallBegan({
    url:requests.fetchMainTopicSelect,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:getMainTopic.type,
    onError: mainTopicRequestFailed.type
})
export const getMainTopicSelectById = (id) => apiCallBegan({
    url:requests.fetchMainTopicSelectById+'/'+id,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:getMainTopic.type,
    onError: mainTopicRequestFailed.type
})

export const getMainTopicByIds = (id) => apiCallBegan({
    url:requests.getMainTopic+'/'+id,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:getMainTopicById.type,
    onError: mainTopicRequestFailed.type
})
export const viewMainTopicDetails = (id) => apiCallBegan({
    url:requests.viewMainTopicDetails+'/'+id,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:getMainTopicById.type,
    onError: mainTopicRequestFailed.type
})
export const addMainTopic = (data) => apiCallBegan({
    url:requests.createMainTopic,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:mainTopicRequested.type,
    onSuccess:mainTopicAddedEdited.type,
    onError: mainTopicRequestFailed.type
})
export const editMainTopic = (data) => apiCallBegan({
    url:requests.updateMainTopic,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:mainTopicRequested.type,
    onSuccess:mainTopicAddedEdited.type,
    onError: mainTopicRequestFailed.type
})