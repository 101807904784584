import { Form, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import  { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import Card from '../Card/Card'
import Spinner from '../Layout/Spinner'
import './style.css'
import PdfIcon from '../../assets/img/pdf_icon.png'
import { useDispatch,useSelector } from 'react-redux'
import { addCourseResource } from '../../store/CourseSetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import ProgressBar from '../ProgressBar/ProgressBar'
function ResourceAdd() {
    const [selectedFile,setSelectedFile] = useState([])
    const params = useParams()
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.courses?.loading)
    const progress = useSelector(state => state.entities.courses?.progress)
    const onDrop = useCallback((acceptedFiles,rejectedFiles) => {
      //Do something with files      
      
      acceptedFiles.forEach((file) => {      
          
          setSelectedFile(prevState => [...prevState,file])
       
      });
    },[])
    const {getInputProps,getRootProps,isDragActive} = useDropzone({onDrop,maxFiles:4,maxSize:26214400,accept:['.zip','application/pdf']})
    const removeFile = (index)=>{
          
      setSelectedFile(selectedFile.filter((_,i) => i!==index))
    }    
    useEffect(()=>{
      
    },[selectedFile])

    const initialValues ={
      lesson_id:params.slug
    }
    const handleSubmit = (values,submitProps)=>{
      let formData = new FormData()
      formData.append('lesson',values.lesson_id)
      selectedFile && selectedFile?.map((item,index)=>{
        // console.log("index",index,values,item?.name)
          formData.append('resources',item)
      })
      dispatch(addCourseResource(formData))
      submitProps.setSubmitting(false)
      console.log("formData",formData)
    }
    
  return (
    <Card cardTitle="Add Resources">
      {
        loading && <LoaderSpinner/>
      }
        <div className='card-body'>
            <div className='dropzone-container'>
              <label>Drag or add pdf files only:</label>
            <div className='dropzone'>           
                    <section>
                    <div {...getRootProps()} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <input {...getInputProps()} />
                        {isDragActive?<p>Dragging Files Active...</p>:<p>Drag 'n' drop some files here, or click to select files</p>}
                    </div>
                    </section>                
          </div>
        </div>
        <div className='file-container'>
            <div className='display-file'>
                {
                  selectedFile.map((item,index)=>(
                    <div className='image-icon'>
                      <img src={PdfIcon}/>
                      <label>{item?.name}</label>
                      <button className='btn btn-danger ' type='button' onClick={()=>removeFile(index)}>
                         <i className='fa fa-trash'/> Remove
                      </button>
                    </div>
                  )
                  )
                }
            </div>
          <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          >
            {
              ({isSubmitting})=>(
                <Form>
                  {
                    selectedFile.length>0&&
                   <button className='btn btn-success btn-flat' disabled={isSubmitting} style={{marginTop:10}}>
                     {isSubmitting&&<Spinner/>} Save
                    </button> 
                  }
                </Form>
              )
            }
          </Formik>
          </div>
          <div>
            {
              progress &&
              progress?.percent > 0?
            <ProgressBar percentage={progress}/>:''
            }
            
          </div>
        </div>
    </Card>
  )
}

export default ResourceAdd