import React from 'react'

function Card({cardTitle,children}) {
    return (
        <div className="card">
            {
                cardTitle && 
            <div className="card-header text-left">           
                <h5>&nbsp;&nbsp;{cardTitle}</h5>           
            </div>
            }
            <div className="card-body">
                 {children}
            </div>           
        </div>
    )
}

export default Card
