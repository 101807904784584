import React from 'react'
import MathJax from 'mathjax3-react'
import Card from '../Card/Card'
function LessonQuiz({lessonQuiz}) {
  return (
    <div className='row'>
    <div className='col-md-12'>         
       {
         
         lessonQuiz &&
         lessonQuiz.map((qitem,index)=>{          
           
           return (
             <div className='row' key={index}>
             
             <Card>
               <div className='card-body'>
                 <div className='row'>
                   <div className='col-md-1'>
                      {index+1}
                   </div>
                 <div className='col-md-9'>
                 <MathJax.Html html={qitem?.question}/>                      
                 </div>
                 
                 </div>
               </div>
             </Card>
             {
               qitem?.options &&
               qitem?.options.map((item,index)=>(
                 
                 <div className='col-md-6' key={`${index}-H`}>
                   <Card>
                     <div className='card-body'>
                       <MathJax.Html html={item?.option}/>
                        
                     </div>
                   </Card>
                 </div>
                 
               ))
             }
             </div>
           )
         })
       }
    </div>
</div>
  )
}

export default LessonQuiz