import { Field, Form, Formik } from 'formik';
import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  addHomeBanner } from '../../store/BannerSetupStore';
import ImageUploader from '../Form/ImageUploader';
import Spinner from '../Layout/Spinner';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function AddHomeBanner({openPopup,setOpenPopup}) {
    const dispatch = useDispatch();   
    const loading = useSelector(state => state.entities.banner?.loading)
    const initialValues = {
        banner_img:'',
        banner_tag_line:'',
        banner_desc:'',
        banner_intro:''
        
    }
    const validationSchema = Yup.object().shape({
        banner_img:Yup.lazy(value =>{
            if(value)
            return Yup.mixed().test(
                "fileFormat",
                "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
                value => value && SUPPORTED_FORMATS.includes(value.type)
            ).label("banner image")
            else 
            return Yup.mixed().notRequired()
            
        })
        ,
        banner_intro:Yup.lazy(value=>{ if(value) 
            return Yup.string().url().label('intro video url')
            else
            return Yup.string().notRequired()
        }),
    })
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData();
        formData.append('banner_img',values.banner_img,values.banner_img.name);
        formData.append('banner_tag_line',values.banner_tag_line);
        formData.append('banner_desc',values.banner_desc); 
        formData.append('banner_intro',values.banner_intro);       
        dispatch(addHomeBanner(formData));
        submitProps.setSubmitting(false);
    }
    
  return (
    <FormPopUp title="Add Home Banner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
       {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <ImageUploader
                            name="banner_img"
                            label="Banner Img"
                            required="required"
                            />                            
                        </div>
                        <div className='form-group'>
                            <label>Banner Title :</label>
                            <Field name="banner_tag_line" className="form-control" placeholder="banner title"/>
                        </div>
                        <div className='form-group'>
                            <label>Banner Desc :</label>
                            <Field as="textarea" rows="2" name="banner_desc" className="form-control" placeholder="banner description"/>
                        </div>                        
                        <div className='form-group'>
                            <label>Video Intro Url :</label>
                            <Field  name="banner_intro" className="form-control" placeholder="Url: https://"/>
                        </div>                        
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default AddHomeBanner