import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request'; 
import { AuthHeader, } from '../Config/HeaderType';
import { toast } from 'react-toastify';


const slice = createSlice({
    name:'categorys',
    initialState:{
        result:[],
        loading: false,
        popup:false,
        error: []
    },
    reducers:{
    courseCategoryRequested: (categorys,action)=> {
        categorys.loading = true;
        
    },
    courseCategoryReceived: (categorys,action)=>{
        categorys.loading = false;
        categorys.result = action.payload;
        categorys.error = [];
    },
    courseCategoryRequestFailed: (categorys,action) => {        
        categorys.loading = false;
        categorys.error = action.payload;
        
    },
    courseCategoryAdded: (categorys,action) => {
        // action.payload
        categorys.loading = false;        
        categorys.result = action.payload;
        categorys.error = [];
        toast.success(action.payload.msg)
    },
    getCourseCategoryBySlug: (categorys,action) => {
        categorys.loading = false;
        categorys.result = action.payload;        
    },
    courseCategoryActionRequested:(categorys)=>{
        categorys.loading = true;
    },
    courseCategoryActionReceived:(categorys,action)=>{
        categorys.loading = false;
        toast.success(action?.payload?.msg);
    },
    courseCategoryActionFailed:(categorys,action)=>{
        categorys.loading = false;
        toast.error(action?.payload?.msg);
    },
    
    }
})

const {
    courseCategoryAdded,
    getCourseCategoryBySlug,   
    courseCategoryReceived,
    courseCategoryRequested,
    courseCategoryRequestFailed,
    courseCategoryActionRequested,
    courseCategoryActionReceived,
    courseCategoryActionFailed
   
} = slice.actions
export default slice.reducer;


export const loadCategory = () => apiCallBegan({
        url: requests.fetchCategory,
        headers:AuthHeader,
        onStart:courseCategoryRequested.type,
        onSuccess: courseCategoryReceived.type,
        onError: courseCategoryRequestFailed.type
 });

 export const addCategory =  (category) => apiCallBegan({
     url: requests.createCategory,
     method:"post",
     headers:AuthHeader,
     data: category,
     onStart: courseCategoryRequested.type,
     onSuccess: courseCategoryAdded.type,
     onError: courseCategoryRequestFailed.type
 })

 export const getCategoryBySlug = (slug) => apiCallBegan({
     url: requests.getCategory+'/'+slug,
     headers:AuthHeader,
     onStart: courseCategoryRequested.type,
     onSuccess: getCourseCategoryBySlug.type,
     onError: courseCategoryRequestFailed.type
 })
 export const editCategory = (category) => apiCallBegan({
     url: requests.editCategory,
     method:"patch",
     headers:AuthHeader,
     data: category,
     onStart: courseCategoryRequested.type,
     onSuccess: courseCategoryAdded.type,
     onError: courseCategoryRequestFailed.type
 })
export const activateCourse = (course)=>apiCallBegan({
    url: requests.activateCourse,
    method:'PATCH',
    headers: AuthHeader,
    data:course,
    onStart:courseCategoryActionRequested.type,
    onSuccess:courseCategoryActionReceived.type,
    onError:courseCategoryActionFailed.type
})
export const deactivateCourse = (course)=>apiCallBegan({
    url: requests.deactivateCourse,
    method:'PATCH',
    headers: AuthHeader,
    data:course,
    onStart:courseCategoryActionRequested.type,
    onSuccess:courseCategoryActionReceived.type,
    onError:courseCategoryActionFailed.type
})
export const updateCourseDate = (course)=>apiCallBegan({
    url: requests.updateCourseDate,
    method:'PATCH',
    headers: AuthHeader,
    data:course,
    onStart:courseCategoryActionRequested.type,
    onSuccess:courseCategoryActionReceived.type,
    onError:courseCategoryActionFailed.type
})
// export const error = categorys.error;