import React, { useEffect } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import Card from '../Card/Card'
import {useParams} from 'react-router-dom';
import {viewSubTopicByIds} from '../../store/SubTopicSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function ViewSubTopicDetails() {
    const params = useParams()
    const dispatch = useDispatch()
    const subtopic = useSelector(state => state.entities.subTopic?.getDataById?.data);
    const loading = useSelector(state => state.entities.subTopic?.loading);
    useEffect(()=>{
      dispatch(viewSubTopicByIds(params.slug))
    },[params])
  return (
    <>
    {loading && <LoaderSpinner/>}
     <Card cardTitle="SubTopic Details">
       <div className='card-body'>
        <label>Category Name</label>&nbsp; : &nbsp;<span>{subtopic?.category_name?subtopic?.category_name:'N/A'}</span><br/>
        <label>Course Name</label>&nbsp; : &nbsp;<span>{subtopic?.course_name?subtopic?.course_name:'N/A'}</span><br/>
        <label>Subcourse Name</label>&nbsp; : &nbsp;<span>{subtopic?.subcourse_name?subtopic?.subcourse_name:'N/A'}</span><br/>
        <label>MainTopic Title</label>&nbsp; : &nbsp;<span>{subtopic?.main_topic_title?subtopic?.main_topic_title:'N/A'}</span><br/>
        <label>Lesson Title</label>&nbsp; : &nbsp;<span>{subtopic?.lesson_title?subtopic?.lesson_title:'N/A'}</span><br/>
        <label>SubTopic Title</label>&nbsp; : &nbsp;<span>{subtopic?.topic_title?subtopic?.topic_title:'N/A'}</span><br/>
        <label>Instructor Name</label>&nbsp; : &nbsp;<span>{subtopic?.instructor_name?subtopic?.instructor_name:'N/A'}</span><br/>
        <label>Video Duration</label>&nbsp; : &nbsp;<span>{subtopic?.video_duration?subtopic?.video_duration:'N/A'}</span><br/>
        <label>Video Url</label>&nbsp; : &nbsp;<span>{subtopic?.video_url?subtopic?.video_url:'N/A'}</span><br/>
        <label>App Video Url</label>&nbsp; : &nbsp;<span>{subtopic?.mob_video_url?subtopic?.mob_video_url:'N/A'}</span><br/>
        <label>Resource Url</label>&nbsp; : &nbsp;<span>{subtopic?.resource_url?subtopic?.resource_url:'N/A'}</span><br/>
        <label>created_by</label>&nbsp; : &nbsp;<span>{subtopic?.created_by?subtopic?.created_by:'N/A'}</span><br/>
        <label>Created Date</label>&nbsp; : &nbsp;<span>{subtopic?.created_date?subtopic?.created_date:'N/A'}</span><br/>
        <label>Modified By</label>&nbsp; : &nbsp;<span>{subtopic?.modified_by?subtopic?.modified_by:'N/A'}</span><br/>
        <label>Modified Date</label>&nbsp; : &nbsp;<span>{subtopic?.modified_date?subtopic?.modified_date:'N/A'}</span><br/>
        <label>Modified Remarks</label>&nbsp; : &nbsp;<span>{subtopic?.modified_remarks?subtopic?.modified_remarks:'N/A'}</span><br/>
        </div>
    </Card>
    </>
  )
}

export default ViewSubTopicDetails