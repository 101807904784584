export const Gender = [
    {
        'value':'Male',
        'label':"Male",
    },
    {
        'value':'Female',
        'label':'Female'
    },
    {
        'value':'Others',
        'label':'Others'
    }
]


export const MaritalStatus= [
    {
        'value':'Unmarried',
        'label':'Unmarried'
    },
    {
        'value':'Married',
        'label':'Married'
    },
    {
        'value':'Divorced',
        'label':'Divorced'
    },
    {
        'value':'Widowed',
        'label':'Widowed'
    }
]

export const SkillLevel = [
    {
        'value':'Expert',
        'label':'Expert'
    },
    {
        'value': 'Intermediate',
        'label': 'Intermediate',
    },
    {
        'value': 'Average',
        'label': 'Average',
    },
    {
        'value': 'Beginner',
        'label': 'Beginner',
    }
]