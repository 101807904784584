import React, { useEffect } from 'react'
import { useDispatch,useSelector  } from 'react-redux'
import { useParams } from 'react-router-dom'
import { viewMainTopicDetails } from '../../store/MainTopicSetup'
import Card from '../Card/Card'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'

function VIewMainTopicDetails() {
    const params = useParams()
    const dispatch = useDispatch()
    const maintopic = useSelector(state => state.entities.mainTopic?.getDataById?.data)
    const loading = useSelector(state => state.entities.mainTopic?.loading)
    useEffect(()=>{
      dispatch(viewMainTopicDetails(params.slug));
    },[params])
  return (
    <>
     <Card cardTitle="Main Topic Details">
       {loading && <LoaderSpinner/>}
       <div className='card-body'>
          <label>Category Name</label>&nbsp; : &nbsp;<span>{maintopic?.category_name?maintopic?.category_name:'N/A'}</span><br/>
          <label>Course Name</label>&nbsp; : &nbsp;<span>{maintopic?.course_name?maintopic?.course_name:'N/A'}</span><br/>
          <label>SubCourse Name</label>&nbsp; : &nbsp;<span>{maintopic?.subcourse_name?maintopic?.subcourse_name:'N/A'}</span><br/>
          <label>MainTopic Title</label>&nbsp; : &nbsp;<span>{maintopic?.topic_title?maintopic?.topic_title:'N/A'}</span><br/>
          <label>Lecture Hours</label>&nbsp; : &nbsp;<span>{maintopic?.lecture_hours?maintopic?.lecture_hours:'N/A'}</span><br/>
          <label>created by</label>&nbsp; : &nbsp;<span>{maintopic?.created_by?maintopic?.created_by:'N/A'}</span><br/>
          <label>created date</label>&nbsp; : &nbsp;<span>{maintopic?.created_date?maintopic?.created_date:'N/A'}</span><br/>
          <label>modified by</label>&nbsp; : &nbsp;<span>{maintopic?.modified_by?maintopic?.modified_by:'N/A'}</span><br/>
          <label>modified date </label>&nbsp; : &nbsp;<span>{maintopic?.modified_date?maintopic?.modified_date:'N/A'}</span><br/>
          <label>modified remarks</label>&nbsp; : &nbsp;<span>{maintopic?.modified_remarks?maintopic?.modified_remarks:'N/A'}</span><br/>
       </div>
    </Card>
    </>
  )
}

export default VIewMainTopicDetails