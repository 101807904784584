import React from 'react'
import { useDispatch } from 'react-redux'
import { recoverTagBatch } from '../../store/EnrollStore'

function BatchTagRecover({row}) {
    const dispatch = useDispatch()
    const handleOk = (id)=>{
        dispatch(recoverTagBatch(id))
    }
  return (
    <div>
        <div className='row'>
            <h3>Are you sure to recover data?</h3>
        </div>
        <div className='row'>
            <label>student name:</label>&nbsp;&nbsp;<span>{row?.student_name}</span>
        </div>
        <div className='row'>
            <label>batch:</label>&nbsp;&nbsp;<span>{row?.batch_name}</span>
        </div>
        <div className='row'>
            <label>course:</label>&nbsp;&nbsp;<span>{row?.course_name}</span>
        </div>
    <div className=''>
        <button className='btn btn-flat btn-success' type='button' onClick={()=>handleOk(row.iid)}>
            ok
        </button>
    </div>       
    </div>
  )
}

export default BatchTagRecover