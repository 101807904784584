import {  Field, FieldArray, Form, Formik } from 'formik'
import React,{useState} from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Card from '../Layout/Card';


function TrainingInfo({handleSubmit,prev,data}) {
    const[initialValue] = useState(data)
   

    // const handleStartDate = ({bsDate})=>{        
    //     setInitialValue({
    //         ...initialValue,
    //         start_date_nep:bsDate
    //     })
    // }
    // const handleEndDate = ({bsDate})=>{
        
    //     setInitialValue({
    //         ...initialValue,
    //         end_date_nep:bsDate
    //     })
    // }
    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    const initialValues = {
        training : [
            {
        training_name:"",
        training_institute:"",        
        duration:"",
        start_date_nep:"",
        end_date_nep:"" 
            }
        ]
    }
    return (
        <Card>                 
            <div className="table-responsive">                               
                                    <Formik
                                     initialValues={initialValue}
                                     onSubmit={handleSubmit}
                                     enableReinitialize
                                    >
                                    {
                                    ({values,errors})=>(
                                    <Form>
                                        <FieldArray
                                        name="training"
                                        render = {arrayHelpers => (
                                            <>                                            
                                                {
                                                    <table className="table m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>S.N</th>
                                                                <th>Training Name</th>
                                                                <th>Training Institute</th>
                                                                <th>Duration</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>
                                                            </tr>
                                                        </thead>
                                                   <tbody>
                                                       {
                                                    values.training && values.training.length > 0 ?(
                                                    values.training.map((skill,index)=>(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <Field 
                                                                name={`training.[${index}].training_name`}
                                                                className="form-control"
                                                                placeholder="Training Name"                                                                
                                                                />                                                               
                                                            </td>
                                                            <td>
                                                                <Field
                                                                placeholder="Training Institute"
                                                                name={`training.[${index}].training_institute`}
                                                                className="form-control"
                                                                />
                                                                                                                                  
                                                            </td>
                                                            <td>
                                                                <Field
                                                                placeholder="Duration"
                                                                name={`training.[${index}].duration`}
                                                                className="form-control"
                                                                />
                                                                                                                                  
                                                            </td>
                                                            <td>
                                                                <Field
                                                                placeholder="Start Date"
                                                                name={`training.[${index}].start_date_nep`}
                                                                className="form-control"
                                                               />                                                                                                                               
                                                            </td>
                                                            <td>
                                                                <Field
                                                                placeholder="End Date"
                                                                name={`training.[${index}].end_date_nep`}
                                                                className="form-control"    
                                                                />                                                                                                                                  
                                                            </td>
                                                            <td>
                                                            { 
                                                        index>0?
                       
                                                            <button
                                                                type="button"
                                                                //  className="btn btn-flat btn-sm btn-danger"
                                                                style={buttonStyles}
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >                           
                                                                <DeleteIcon/>
                                                            </button>
                                                            :null
                                                                }
                                                            <button
                                                                type="button"
                                                                style={buttonStyles}
                                                                //  className="btn btn-flat btn-sm btn-success"
                                                                onClick={() => arrayHelpers.push({
                                                                    training_name:"",
                                                                    training_institute:"",        
                                                                    duration:"",
                                                                    start_date_nep:"",
                                                                    end_date_nep:"" 
                                                                        })} // insert an empty string at a position
                                                            >
                                                                <AddIcon/>
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    ):(
                                                        <button className="btn btn-success btn-flat" type="button" onClick={() => arrayHelpers.push({ training_name:"",
                                                        training_institute:"",        
                                                        duration:"",
                                                        start_date_nep:"",
                                                        end_date_nep:"" })}>
                                                          {/* show this when user has removed all company_contact from the list */}
                                                          Add training
                                                        </button>
                                                      )
                                                }
                                                      </tbody>
                                                      </table>
                                                }
                                            </>
                                        )
                                       
                                        }
                                        />
                                        <button className="btn btn-success btn-flat" onClick={prev}>Prev</button>
                                        <button className="btn btn-success btn-flat">Next</button>
                                        </Form>
                                    )
                                }
                                        </Formik>
                                   
                                </div>                           
                       
                
            
        </Card>
    )
}

export default TrainingInfo
