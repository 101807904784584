import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from '../Card/Card';
import MathJax from 'mathjax3-react';
import { Link, useParams } from 'react-router-dom';
import { viewQuizDetails } from '../../store/QuizSetupStore';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function VIewQuizDetails() {
    const params = useParams();
    const dispatch = useDispatch();
    const quizData = useSelector(state => state.entities.quiz?.getDataById?.data)
    const loading = useSelector(state => state.entities.quiz?.loading)
    useEffect(()=>{
        dispatch(viewQuizDetails(params.slug))
    },[params])
  return (
      
      <BodyContainer pageTitle="Quiz Details" buttonTitle="Back" route={`/quizsetup/`}>
        {loading && <LoaderSpinner/>}
        <Card cardTitle='Quiz Details'>
        <div className='card-body'>
            
            <Link
            className="btn btn-success btn-flat"                           
            style={{marginRight: '5px'}}
            to={`/quizsetup/edit/${quizData?.iid}`}
            >
            <i className="fa fa-edit">Edit</i>
            </Link><br/>
            <label>Question</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.question?quizData?.question:'N/A'}/></span><br/>
            <label>Correct Option</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.correct_option?quizData?.correct_option:'N/A'}/></span><br/>
            <label>Answer Description</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.answer_desc?quizData?.answer_desc:'N/A'}/></span><br/>
            <label>has video solution</label>&nbsp;:&nbsp;<span><MathJax.Html html={quizData?.has_video_solution?quizData?.has_video_solution:'N/A'}/></span><br/>
            <label>Video Solution</label>&nbsp;:&nbsp;<span>{quizData?.video_solution?quizData?.video_solution:'N/A'}</span><br/>
            <label>App Video Solution</label>&nbsp;:&nbsp;<span>{quizData?.app_video_solution?quizData?.app_video_solution:'N/A'}</span><br/>
            <label> Options :</label><br/>
            {
                quizData?.quiz_options && quizData?.quiz_options.map((item,index)=>(
                    <>
                    <label>Option {index+1}</label>&nbsp;:&nbsp;<span><MathJax.Html html={item?.options?item?.options:'N/A'}/></span>&emsp;<label>Correct Answer</label>&nbsp;:&nbsp;<span>{item?.correct?'True':'False'}</span><br/>
                    </>
                ))
            }
          
        </div>
    </Card>
    </BodyContainer>
   
  )
}

export default VIewQuizDetails