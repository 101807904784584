import React from 'react'
import FormPopUp from '../popup/PopUp';
function ViewFAQ({openPopup,setOpenPopup,faq}) {
  return (
    <FormPopUp title="faq Details" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <label>FAQ Title </label>&nbsp;:&nbsp;<span>{faq?.faq_title?faq?.faq_title:'N/A'}</span><br/>
        <label>FAQ Description </label>&nbsp;:&nbsp;<span>{faq?.faq_desc?faq?.faq_desc:'N/A'}</span><br/>
        <label>Course Name </label>&nbsp;:&nbsp;<span>{faq?.course_name?faq?.course_name:'N/A'}</span><br/>
        <label>Priority Order </label>&nbsp;:&nbsp;<span>{faq?.priority_order?faq?.priority_order:'N/A'}</span><br/>
        <label>Created By </label>&nbsp;:&nbsp;<span>{faq?.created_by?faq?.created_by:'N/A'}</span><br/>
        <label>Created Date </label>&nbsp;:&nbsp;<span>{faq?.created_date?faq?.created_date:'N/A'}</span><br/>
        <label>Modified By </label>&nbsp;:&nbsp;<span>{faq?.modified_by?faq?.modified_by:'N/A'}</span><br/>
        <label>Modified Date </label>&nbsp;:&nbsp;<span>{faq?.modified_date?faq?.modified_date:'N/A'}</span><br/>        
        <label>Modified Remarks </label>&nbsp;:&nbsp;<span>{faq?.modified_remarks?faq?.modified_remarks:'N/A'}</span><br/>        
    </FormPopUp>
  )
}

export default ViewFAQ