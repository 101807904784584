import React,{ useState } from 'react'
import FormPopUp from '../popup/PopUp';
import LinearProgress from '@material-ui/core/LinearProgress';
function CourseResources({resources}) {
    const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
    const handleDownload = (file) => {
        let fileName = file.split('/').pop()
        let fileUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/${file}`; // Replace with the actual path to your PDF file
        setDownloading(true);
    fetch(fileUrl)
      .then((response) => {
        const totalSize = response.headers.get('content-length');
        const reader = response.body.getReader();
        let receivedSize = 0;
        let chunks = [];

        const pump = () => {
          return reader.read().then(({ done, value }) => {
            if (done) {
              const blob = new Blob(chunks);
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
              setDownloading(false);
              setDownloadProgress(0);
              return;
            }

            chunks.push(value);
            receivedSize += value.length;

            // Calculate download progress percentage
            const progress = Math.round((receivedSize / totalSize) * 100);
            setDownloadProgress(progress);

            // Continue pumping chunks
            return pump();
          });
        };

        return pump();
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        setDownloading(false);
        setDownloadProgress(0);
      });
      }
  return (
    <div className={`row`}>
        {
            resources && resources.map((item,index)=>(
            <div className={`col-md-12`} key={index}>
                <div className={`row`}>
                    {/* <img src='/pdf.png' className={styles.resource__img} alt='pdf'/> */}
                    <i className='fa fa-pdf'/>
                    <p  onClick={()=>handleDownload(item?.resources)} style={{color:'#42a5ff',cursor:'pointer'}}>{item?.resources && item?.resources.split('/').pop()}</p>
                </div>
            </div>
            ))
        }
        {
            downloading && 
            <FormPopUp
            openPopup={downloading}
            title={`Downloading...`}
            >
                <div style={{width:'100%',height:'100%',flex:1,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                <p>Download {downloadProgress}%</p>
                <LinearProgress variant="buffer"  value={downloadProgress}  valueBuffer={100} style={{backgroundColor:'#42a5ff',height:20,width:300}}  />

                </div>
            </FormPopUp>
        }
    </div>
  )
}

export default CourseResources