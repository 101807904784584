import React from 'react';
import { FaFilePdf, FaFileArchive } from 'react-icons/fa'; // Import icons from react-icons library

const FileIcon = ({ filePath }) => {
  const fileExtension = filePath.split('.').pop(); // Extract file extension

  // Determine which icon to render based on the file extension
  let IconComponent;
  if (fileExtension.toLowerCase() === 'pdf') {
    IconComponent = 'pdf' // Use PDF icon if the extension is .pdf
  } else if (fileExtension.toLowerCase() === 'zip') {
    IconComponent = 'zip'; // Use ZIP icon if the extension is .zip
  } else {
    // Default to a generic file icon if the extension is neither .pdf nor .zip
    IconComponent = 'pdf'; // You can change this to any other generic file icon
  }

  // Render the icon component
  return (
    <>
    {
        IconComponent === 'pdf'?
        <FaFilePdf size={24} color='tomato' style={{margin:3}}/>
        :
        <FaFileArchive size={24} color='maroon' style={{margin:3}}/>
    }
    </>
  );
};

export default FileIcon;
