import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { editCourseBannerImg, editCoursePreviewImg, getCourseBySlug } from '../../store/CourseSetup';
import Card from '../Card/Card';
import ImageUploader from '../Form/ImageUploader';
import ImageViewer from '../Form/ImageViewer';
import Spinner from '../Layout/Spinner';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import CertificateUpdate from './CertificateUpdate';
function ViewCourseDetails() {
    const [openBannerImg,setBannerImg]=useState(false)
    const [openPreviewImg,setPreviewImg]=useState(false)
    const [openCertificate,setOpenCertificate] = useState(false)
    const [data,setData] = useState();
    const params = useParams();
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data)
    useEffect(()=>{
      dispatch(getCourseBySlug(params.slug));
    },[params])

    const handleBannerEditClick = ()=>{      
      setBannerImg(true);
    }
    const handleCertificateClick = (id)=>{
      setData(id)      
      setOpenCertificate(true);
    }
    const handlePreviewImgEdit = ()=>{
      setPreviewImg(true)
    }
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    const initialBanner = {
      course_banner_img:''
    }
    const validateBanner = Yup.object().shape({
      course_banner_img: Yup.mixed().required().test(
        "fileFormat",
        "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
        value => value && SUPPORTED_FORMATS.includes(value.type))
    })
    const handleBannerSubmit = (values,submitProps)=>{
      let formData = new FormData()
      formData.append('course_id',course?.iid)
      formData.append('course_banner_img',values.course_banner_img,values.course_banner_img.name)
      dispatch(editCourseBannerImg(formData));
      submitProps.setSubmitting(false);
    }
    const initialpreview = {
      course_preview_img:''
    }
    const validatepreview = Yup.object().shape({
      course_preview_img: Yup.mixed().required().test(
        "fileFormat",
        "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
        value => value && SUPPORTED_FORMATS.includes(value.type))
    })
   
    const handlepreviewSubmit = (values,submitProps)=>{
      let formData = new FormData()
      formData.append('course_id',course?.iid)
      formData.append('course_preview_img',values.course_preview_img,values.course_preview_img.name)
      dispatch(editCoursePreviewImg(formData));
      submitProps.setSubmitting(false);
    }
  return (
    <>
    <Card cardTitle="Course Details">
        <div className='card-body'>
            <label>Course Categories</label> &nbsp;:&nbsp; <span>{course?.category_name}</span><br/>
            <label>Course Title</label> &nbsp;:&nbsp; <span>{course?.course_name}</span><br/>
            <label>Course Code</label>&nbsp; :&nbsp; <span>{course?.course_code}</span><br/>
            <label>Banner Description</label> &nbsp;: &nbsp; <span>{course?.course_banner_description}</span><br/>
            <label>Banner Img</label> &nbsp;:&nbsp; <ImageViewer image={course?.course_banner_img}/><br/><button className='btn btn-flat btn-success' type="button" onClick={handleBannerEditClick}>Edit</button><br/>
            <label>Preview Url</label>&nbsp; :&nbsp; <span>{course?.course_preview_url}</span><br/>
            <label>Preview Img</label> &nbsp;:&nbsp; <ImageViewer image={course?.course_preview_img}/><br/><button className='btn btn-flat btn-success' type="button" onClick={handlePreviewImgEdit} >Edit</button><br/>
            <label>Program OverView</label>&nbsp; : &nbsp;<span>{course?.program_overview}</span><br/>
            <label>lecture Hrs</label>&nbsp; :&nbsp; <span>{course?.lecture_hours}</span><br/>
            <label>Has Exam?</label>&nbsp; :&nbsp; <span>{course?.has_exam==='y'?'YES':'NO'}</span><br/>
            <label>Has Certification?</label>&nbsp; :&nbsp; <span>{course?.has_certification ==='y'?'YES':'NO'}&nbsp; {course?.has_certification ==='n'?<Link to="#" onClick={()=>handleCertificateClick(course)}>Add Certificate</Link>:''} </span><br/>
            {
              course?.has_certification ==='y'?
              <>
              <ImageViewer image={course?.certificate_img}/><br/>
              <Link to="#" onClick={()=>handleCertificateClick(course)}><i className='fa fa-edit'/>Edit</Link><br/>
              </>:''
            }
            <label>Created By</label>&nbsp; :&nbsp; <span>{course?.created_by}</span><br/>
            <label>Created Date</label>&nbsp; :&nbsp; <span>{course?.created_date}</span><br/>
            <label>Modified By</label>&nbsp; :&nbsp; <span>{course?.modified_by?course?.modified_by:'N/A'}</span><br/>
            <label>Modified Date</label>&nbsp; :&nbsp; <span>{course?.modified_date?course?.modified_date:'N/A'}</span><br/>
            <label>Modified Remarks</label>&nbsp; :&nbsp; <span>{course?.modified_remarks?course?.modified_remarks:'N/A'}</span><br/>
        </div>
        {
          openCertificate && 
          <CertificateUpdate openPopup={openCertificate} setOpenPopup={setOpenCertificate} data={data}/>
        }
    </Card>
    <FormPopUp title="Course Banner Image" openPopup={openBannerImg} setOpenPopup={setBannerImg}>
      <Formik
      initialValues={initialBanner}
      validationSchema={validateBanner}
      onSubmit={handleBannerSubmit}
      enableReinitialize
      >
        {
          ({isSubmitting})=>(
            <Form>
              <ImageUploader
              name="course_banner_img"
              label="Course Banner Img"
              accept="image/jpg, image/gif,image/jpeg,image/png"
              required="required"
              />
             <button className='btn btn-success btn-flat' disabled={isSubmitting?'disabled':''}>{isSubmitting?<Spinner/>:''}Change</button>
            </Form>
          )
        }
      </Formik>
    </FormPopUp>
    <FormPopUp title="Course Preview Image" openPopup={openPreviewImg} setOpenPopup={setPreviewImg}>
      <Formik
      initialValues={initialpreview}
      validationSchema={validatepreview}
      onSubmit={handlepreviewSubmit}
      enableReinitialize
      >
        {
          ({isSubmitting})=>(
            <Form>
              <ImageUploader
              name="course_preview_img"
              label="Course Preview Img"
              accept="image/jpg, image/gif,image/jpeg,image/png"
              required="required"
              />
              <button className='btn btn-success btn-flat' disabled={isSubmitting?'disabled':''}>{isSubmitting?<Spinner/>:''}Change</button>
            </Form>
          )
        }
      </Formik>
    </FormPopUp>
    </>
  )
}

export default ViewCourseDetails