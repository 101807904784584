import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import { toast } from "react-toastify";
import requests from "../ApiRequests/Request";
import { AuthHeader } from "../Config/HeaderType";

const slice = createSlice({
    name:'articles',
    initialState:{
        article:[],
        errors:[],
        articleData:[],
        loading:false
    },
    reducers:{
        articleRequested:(article)=>{
            article.loading = true;
            article.article = [];
        },
        articleReceived:(article,action)=>{
            article.loading = false;
            article.article = action.payload
        },
        articleRequestFailed:(article,action)=>{
            article.loading = false;
            article.errors = action.payload?.data
            if(action.payload?.msg){
                toast.error(action.payload?.msg);
            }
        },
        articleActionRequested:(article)=>{
            article.loading = true;            
        },
        articleActionReceived:(article,action)=>{
            article.loading = false;
            toast.success(action.payload?.msg)
        },
        articleRequesteById:(article)=>{
            article.loading = true;
            article.articleData = [];
        },
        articleReceivedById:(article,action)=>{
            article.loading = false;
            article.articleData = action.payload;
        }
    
    }
})

const {
    articleReceived,
    articleRequested,
    articleRequestFailed,
    articleActionReceived,
    articleActionRequested,
    articleRequesteById,
    articleReceivedById
} = slice.actions

export default slice.reducer;


export const getArticlesByCourse = (course)=>apiCallBegan({
    url: requests.getArticleByCourse,
    method:'POST',
    data: course,
    headers:AuthHeader,
    onStart: articleRequested.type,
    onSuccess: articleReceived.type,
    onError: articleRequestFailed.type
})

export const createArticles = (data)=>apiCallBegan({
    url: requests.createArticles,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: articleActionRequested.type,
    onSuccess: articleActionReceived.type,
    onError: articleRequestFailed.type
})
export const updateArticles = (data)=>apiCallBegan({
    url: requests.updateArticles,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: articleActionRequested.type,
    onSuccess: articleActionReceived.type,
    onError: articleRequestFailed.type
})
export const updateArticleBanner = (data)=>apiCallBegan({
    url: requests.updateArticleBanner,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: articleActionRequested.type,
    onSuccess: articleActionReceived.type,
    onError: articleRequestFailed.type
})
export const deleteArticles = (data)=>apiCallBegan({
    url: requests.deleteArticles+'/'+data,
    method:'DELETE',
    headers:AuthHeader,
    onStart: articleActionRequested.type,
    onSuccess: articleActionReceived.type,
    onError: articleRequestFailed.type
})
export const getArticlesById = (data)=>apiCallBegan({
    url: requests.getArticlesById+'/'+data,
    method:'GET',
    headers:AuthHeader,
    onStart: articleRequesteById.type,
    onSuccess: articleReceivedById.type,
    onError: articleRequestFailed.type
})