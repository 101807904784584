import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import requests from './ApiRequests/Request';
// import {refreshToken} from './Config/HeaderType';
import { get_refresh_token } from './EncDec';
var CryptoJS = require("crypto-js");
const baseUrl = process.env.REACT_APP_BASE_URL
const instance = axios.create({
    baseURL: baseUrl
});
const tokenRefresh = async failedRequest => {
    try {   
          
        const refresh = get_refresh_token()  
        await instance.post(requests.refreshToken,{refresh:refresh}).then(response => {
            
            failedRequest.response.config.headers['Authorization'] = 'Bearer '+response.data?.access            
            if (localStorage.getItem('Block:Usr')){
                var cipherte = localStorage.getItem('Block:Usr')    
                CryptoJS.AES.decrypt(cipherte,process.env.REACT_APP_SECRET)
                var bytes = CryptoJS.AES.decrypt(cipherte, process.env.REACT_APP_SECRET);
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                decryptedData.tokens.access = response.data?.access;
                let stringify = JSON.stringify(decryptedData)
                let uu = CryptoJS.AES.encrypt(stringify,process.env.REACT_APP_SECRET).toString()
                localStorage.setItem("Block:Usr",uu)

            }else{
                window.location = '/login'
            }       
        },error=>{           
            if(error.response?.status === 400){   
                
            }
            if(error.response?.status === 401){ 
                localStorage.removeItem('Block:Usr')
                window.location='/login'
            }
        })       
        
    } catch (error) {    
        return error
    }

    // return Promise.resolve();
}

createAuthRefreshInterceptor(
    instance,
    tokenRefresh,
    {
        skipWhileRefreshing: false,
        
    }
)

instance.interceptors.request.use(request => {
    
    return request;
}, (error) => {
      console.log("err",error)
})
export { baseUrl };
export default instance;