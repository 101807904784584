import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name: 'subcourse',
    initialState: {
        result: [],
        loading: false,
        popup: false,
        error:[]
    },
    reducers:{
    subcourseRequested: (subcourse,action)=> {
        subcourse.loading = true;
        
    },
    subcourseReceived: (subcourse,action)=>{
        subcourse.loading = false;
        subcourse.result = action.payload;        
    },
    subcourseRequestFailed: (subcourse,action) => {
        subcourse.loading = false;
        subcourse.error = action.payload;
        toast.error(action.payload?.msg);
    },
    subcourseAdded: (subcourse,action) => {
        // action.payload
        subcourse.loading = false
        subcourse.error = []
        toast.success(action.payload?.msg);
    },
    getSubCourse: (subcourse,action) => {
        subcourse.loading = false;
        subcourse.result = action.payload;        

    },
    subcourseEdited:(subcourse,action) => {
        
    }
    }
})


const {
    subcourseAdded,
    getSubCourse,    
    subcourseReceived,
    subcourseRequested,
    subcourseRequestFailed
} = slice.actions
export default slice.reducer;

export const loadSubCourse = () => apiCallBegan({
    url: requests.fetchSubCourse,
    headers:AuthHeader,
    onStart: subcourseRequested.type,
    onSuccess: subcourseReceived.type,
    onError: subcourseRequestFailed.type
})
export const loadSubCourseSelect = () => apiCallBegan({
    url: requests.fetchSelectSubCourse,
    headers:AuthHeader,
    onStart: subcourseRequested.type,
    onSuccess: subcourseReceived.type,
    onError: subcourseRequestFailed.type
})
export const loadSubCourseSelectById = (id) => apiCallBegan({
    url: requests.fetchSelectSubCourseById+'/'+id,
    headers:AuthHeader,
    onStart: subcourseRequested.type,
    onSuccess: subcourseReceived.type,
    onError: subcourseRequestFailed.type
})
export const getSubCourseDetails = (slug) => apiCallBegan({
    url: requests.getSubCourseDetails+'/'+slug,
    headers:AuthHeader,
    onStart: subcourseRequested.type,
    onSuccess: subcourseReceived.type,
    onError: subcourseRequestFailed.type
})

 export const addSubCourse = (subcourse) => apiCallBegan({
     url: requests.createSubCourse,
     method:"post",
     headers:AuthHeader,
     data: subcourse,
     onStart: subcourseRequested.type,
     onSuccess: subcourseAdded.type,
     onError: subcourseRequestFailed.type
 })

 export const getSubCourseBySlug = (slug) => apiCallBegan({
     url: requests.getSubCourseBySlug +'/'+ slug,
     headers:AuthHeader,
     onStart: subcourseRequested.type,
     onSuccess: getSubCourse.type,
     onError: subcourseRequestFailed.type
 })
 export const editSubCourse = (subcourse) => apiCallBegan({
     url: requests.editSubCourse,
     method:"patch",
     headers:AuthHeader,
     data: subcourse,
     onStart: subcourseRequested.type,
     onSuccess: subcourseAdded.type,
     onError: subcourseRequestFailed.type
 })
 export const editSubCourseIcon = (subcourse) => apiCallBegan({
     url: requests.editSubCourseIcon,
     method:"patch",
     headers:AuthHeader,
     data: subcourse,
     onStart: subcourseRequested.type,
     onSuccess: subcourseAdded.type,
     onError: subcourseRequestFailed.type
 })