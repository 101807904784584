import React from 'react';
import FormPopUp from '../popup/PopUp';
import {Formik,Form,ErrorMessage,Field} from 'formik';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { updateCertificateImg} from '../../store/CourseSetup';
import TextError from '../../TextError';
import ImageUploader from '../Form/ImageUploader';
function CertificateUpdate({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch();
    const errors = useSelector(state => state.entities.courses.error.data)
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ];
      const CHOICES = [
        {
          'key':'y',
          'value':'YES'
        },
        {
          'key':'n',
          'value':'NO'
        },
      ]
    const initialValues = {
        has_certification: data?.has_certification,
        certificate_img:''
    }
    const validationSchema = Yup.object().shape({
        has_certification :Yup.string().required().label('has certification'),
        certificate_img: Yup.mixed().when('has_certification',{
          is:(certification)=>certification==='y',
          then:Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Certificate")
        })
    }) 
    const handleSubmit = (values,submitProps) =>{
        let formData = new FormData();
        formData.append('course_id',data?.iid)
        formData.append('has_certification',values.has_certification)
        formData.append('certificate_img',values.certificate_img,values.certificate_img.name)
        dispatch(updateCertificateImg(formData))
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Certificate Update">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting,values,setFieldValue})=>(
                    <Form>
                    <div className="form-group item">
                    <label>Has Certification?</label>
                    <Field as="select" id="has_certification" name="has_certification" placeholder="select option" className="form-control">
                    <option value=''>select option</option>
                    {CHOICES && CHOICES.map(option=>{
                        return(
                        <option key={option.key} value={option.key}>{option.value}</option>
                        )
                    })}
                    onChange={value=>{setFieldValue('has_certification',value.value)}}
                    </Field>         
                
                    <ErrorMessage name="has_certification" component={TextError}/>
                    <div className="errors">{errors?.data?.has_certification && errors?.data?.has_certification}</div>
                    </div>
                    {
                    values.has_certification ==='y'?
                    <>
                    <ImageUploader
                    name="certificate_img"
                    label="Certificate"
                    required="required"
                    />
                    </>
                    :''
                    }
                    <button type='submit' className='btn btn-success btn-flat' disabled={isSubmitting}>
                        {isSubmitting && <Spinner/>} <i className='fa fa-edit'/>&nbsp; edit
                    </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default CertificateUpdate