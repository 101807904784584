import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFeatureTag } from '../../store/FeatureSetupStore';
import FormPopUp from '../popup/PopUp'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function DeleteFeatureTag({openPopUp,setOpenPopUp,feature}) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.feature?.loading)
    const initialValues = {
        tag_id:feature?.iid
    }
    const handleSubmit = (values,submitProps) =>{
        dispatch(deleteFeatureTag(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp title="Delete Feature Tag" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <label>Feature Title :</label>&nbsp;<span>{feature?.features_title}</span><br/>
        <label>Course Name :</label>&nbsp;<span>{feature?.course_name}</span><br/>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <button type='submit' className='btn btn-success btn-flat' disabled={isSubmitting}><i className='fa fa-trash'>&nbsp;Delete</i></button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default DeleteFeatureTag