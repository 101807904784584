import React, { useEffect, useState } from 'react'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup'
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { editModelSet } from '../../store/QuizSetupStore'
import {NEPALIYEAR} from '../Calendar/NPYear'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import { loadCourseSelect } from '../../store/CourseSetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import TimePicker from 'react-time-picker';
function ModelsetEdit({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch()
    const course = useSelector(state=>state.entities.courses.result?.data)
    const errors = useSelector(state=>state.entities.courses.error?.data)
    const loading = useSelector(state => state.entities.quiz.loading)
    const [year,setYear] = useState(data?.year);
    const [values, onChanges] = useState(new Date());
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues = {
        modelset_id : data?.iid,
        modelset_type:data?.modelset_type,
        courses:data?.course_iid,
        year:year,
        modelset_name:data?.modelset_name,
        total_marks:data?.total_marks,
        pass_marks:data?.pass_marks,
        has_negative_marking:data?.has_negative,
        negative_marking:data?.negative_marking,
        exam_time_duration:data?.exam_time_duration,
        modified_remarks:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        year:Yup.string().required(),
        modelset_type:Yup.string().required(),
        exam_time_duration:Yup.string().required(),
        modelset_name:Yup.string().required().min(4).max(100).label('Modelset Name'),
        total_marks:Yup.number().required().label('Full Marks'),
        pass_marks:Yup.number().required().label('Pass Marks'),
        has_negative_marking: Yup.string().required().label('has negative marking'),
        negative_marking: Yup.number().when('has_negative_marking',{
            is:(negative_marking) => negative_marking === 'TRUE',
            then:Yup.number().required().label('negative marking')
        }),
        modified_remarks: Yup.string().min(4).max(100).required().label('modified remarks')
    })
    const handleSubmit = (values,submitProps) =>{
        values.modified_by = localStorage.getItem('username')
        dispatch(editModelSet(values));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    const onChange = (bsDate)=>{
        setYear(bsDate);
    }
    const CHOICES = [
        {
            'value':'TRUE'
        },
        {
            'value':'FALSE'
        }
    ]
    const MODELSETTYPE = [
        {
            'value':'normal',
            'label':'NORMAL'
        },
        {
            'value':'ku',
            'label':'KU'
        }
    ]
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Modelset Add">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
         >
            {
                ({isSubmitting,values})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Year</label>:<span className='errors'>*</span>
                            <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                        </div>
                        <div className='form-group'>
                            <label>select courses:</label><span className='errors'></span>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            {errors?.courses && <div className="errors">{errors?.courses}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Select ModelSet Type:</label><span className='errors'></span>
                            <Field as="select" name="modelset_type" id="modelset_type" placeholder="" className='form-control'  >
                                            <option name="modelset_type" value="">select Options</option>
                                                {
                                                    MODELSETTYPE && MODELSETTYPE?.map((item,index)=>(
                                                        <option key={index} value={item?.value}  >{item?.label}</option>
                                                        ))
                                                    }
                                    </Field>
                                <ErrorMessage name="modelset_type" component={TextError} />
                                {errors?.modelset_type && <div className="errors">{errors?.modelset_type}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Modelset Name :</label><span className='errors'>*</span>
                            <FastField name="modelset_name" className="form-control" placeholder="modelset name"/>
                            <ErrorMessage name='modelset_name' component={TextError}/>
                            {errors?.modelset_name && <div className='errors'>{errors?.modelset_name}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Full Marks :</label><span className='errors'>*</span>
                            <FastField type="number" name="total_marks" className="form-control" placeholder="full marks"/>
                            <ErrorMessage name='total_marks' component={TextError}/>
                            {errors?.total_marks && <div className='errors'>{errors?.total_marks}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Pass Marks :</label><span className='errors'>*</span>
                            <FastField type="number" name="pass_marks" className="form-control" placeholder="pass marks"/>
                            <ErrorMessage name='pass_marks' component={TextError}/>
                            {errors?.pass_marks && <div className='errors'>{errors?.pass_marks}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Quiz Duration :</label><span className='errors'>*</span>
                            <TimePicker name='exam_time_duration' onChange={onChanges} value={values} className='form-control' format='hh:mm:ss'/>
                            <ErrorMessage name='exam_time_duration' component={TextError}/>
                        </div>
                        
                        <div className='form-group'>
                            <label>has negative marking?:</label><span className='errors'></span>
                            <Field as="select" name="has_negative_marking" id="has_negative_marking" placeholder="" className='form-control'  >
                                            <option name="has_negative_marking" value="">select Options</option>
                                                {
                                                    CHOICES && CHOICES?.map((item,index)=>(
                                                        <option key={item.value} value={item.value}  >{item.value}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="has_negative_marking" component={TextError} />
                                            {errors?.has_negative_marking && <div className="errors">{errors?.has_negative_marking}</div>}
                        </div>
                        {
                            values.has_negative_marking === 'TRUE'?
                            <div className='form-group'>
                                <label>Negative Marking:</label><span className='errors'>*</span>
                                <Field name="negative_marking" className="form-control" type="number" placeholder="0.00"/>
                                <ErrorMessage name="negative_marking" component={TextError} />
                                {errors?.negative_marking && <div className="errors">{errors?.negative_marking}</div>}
                            </div>
                            :''
                        }
                        <div className='form-group'>
                            <label>Modified Remarks:</label><span className='errors'>*</span>
                            <FastField name="modified_remarks" className="form-control" placeholder="modified remarks"/>
                            <ErrorMessage name='modified_remarks' component={TextError}/>
                            {errors?.modified_remarks && <div className='errors'>{errors?.modified_remarks}</div>}
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''} &nbsp;<i className='fa fa-save'/> &nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ModelsetEdit