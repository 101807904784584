import React,{useEffect, useState} from 'react';
import {Formik,Form,Field,ErrorMessage} from 'formik';
import {useDispatch,useSelector} from 'react-redux';
import FormPopUp from '../popup/PopUp';
import MathJax from 'mathjax3-react';
import { loadCourseSelect } from '../../store/CourseSetup';
import TextError from '../../TextError';
import * as Yup from 'yup'; 
import { loadSubCourseSelectById } from '../../store/SubCourseSetup';
import { getMainTopicSelectById } from '../../store/MainTopicSetup';
import { filterPassageModelset,  tagParagraphToModelset } from '../../store/QuizSetupStore';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import {NEPALIYEAR,ADYEAR} from '../Calendar/NPYear';
function TagParagraphModelset({openPopUp,setOpenPopUp,paragraph}) {

    const [courses,setCourse] = useState('');
    const [subcourses,setSubCourse] = useState('');    
    const [year,setYear] = useState(ADYEAR);
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data);
    const subcourse = useSelector(state => state.entities.subcourse.result?.data);    
    const modelset = useSelector(state => state.entities.quiz.filterparagraphModelset?.data)
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const handleCourseClick = (id)=>{
        setCourse(id)
        if(id !== '')
        dispatch(loadSubCourseSelectById(id))
        if(year){
            let data = {
                'courses':id,
                'year':year
            }
            dispatch(filterPassageModelset(data))
        }

    }
    const handleSubCourseClick = (id)=>{
        setSubCourse(id)
        if(id !== '')
        dispatch(getMainTopicSelectById(id))
    }
   
    const handleSubmit = (values,submitProps) =>{
        values.created_by = localStorage.getItem('username');       
        dispatch(tagParagraphToModelset(values))
        submitProps.setSubmitting(false);        
    }
    const onChange = ( bsDate ) => {
        setYear(bsDate);
       let  data = {
            'courses':courses,
            'year':bsDate
        }       
        if(courses)
            dispatch(filterPassageModelset(data));
	};
    const initialValues = {
        paragraph:paragraph?.iid,
        courses:courses,
        subcourse:subcourses,
        // subcourse_main_topic:mainTopic,
        // lesson:'',
        modelset:''
    }
    const validationSchema = Yup.object().shape({
        paragraph:Yup.string().required().label('paragraph'),
        courses:Yup.string().required().label('course'),
        subcourse:Yup.string().required().label('subcourse'),
        // subcourse_main_topic:Yup.string().required().label('main topic'),
        // lesson:Yup.string().required().label('lesson')
    })
  return (
    <>
    <FormPopUp title="Tag Quiz" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        <label>
            Question:&nbsp;&nbsp;            
                <MathJax.Html html={paragraph?.paragraph}/>
        </label>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema} 
        onSubmit={handleSubmit}       
        enableReinitialize 
        >
            {
                ({isSubmitting})=>(
                    <Form>
                         <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="courses" component={TextError} />
                            </div>
                         <div className='form-group item'>
                                <label className='required-label'>Select SubCourse<span className="errors">*</span></label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" value={subcourses} className='form-control' onChange={(e)=>handleSubCourseClick(e.target.value)}>
                                    <option name="subcourse" value="">select Options</option>
                                    {
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item?.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse" component={TextError} />
                            </div>
                        
                            <div className='form-group'>
                                <label>Year :</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                            </div>
                         <div className='form-group item'>
                                <label className='required-label'>Select ModelSet<span className="errors">*</span></label>
                                <Field as="select" name="modelset" id="modelset" placeholder="" className='form-control'>
                                    <option name="modelset" value="">select Options</option>
                                    {
                                        modelset && modelset?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.modelset_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="modelset" component={TextError} />
                            </div>
                            <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                                <i className='fa fa-save'>&nbsp;&nbsp;Save</i>
                            </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
    </>
  )
}

export default TagParagraphModelset