import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader, } from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'banner',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: []
    },
    reducers:{
        bannerRequested:(banner,action)=>{
            banner.loading = true;
        },
        bannerReceived:(banner,action)=>{
            banner.loading = false;
            banner.result = action.payload;
        },
        bannerRequestFailed:(banner,action)=>{
            banner.loading = false;
            banner.error = action.payload;
            toast.error(action.payload.msg)
        },
        bannerAddedEdited:(banner,action)=>{
            banner.loading = false;
            toast.success(action.payload.msg)
        },
        getBanner:(banner,action)=>{
            banner.loading = false;
            banner.getData = action.payload
        },
        getBannerById:(banner,action)=>{
            banner.loading = false;
            banner.getDataById = action.payload
        },
    }
})

const {
    bannerAddedEdited,    
    getBannerById,
    bannerRequested,
    bannerRequestFailed,
    bannerReceived
} = slice.actions

export default slice.reducer;

export const loadBanner = () => apiCallBegan({
    url:requests.fetchAllMobileBanner,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerReceived.type,
    onError: bannerRequestFailed.type
})


export const getBannerByIds = (id) => apiCallBegan({
    url:requests.fetchHomeBannerById+'/'+id,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:getBannerById.type,
    onError: bannerRequestFailed.type
})

export const addBanner = (data) => apiCallBegan({
    url:requests.createMobileBanner,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const editBanner = (data) => apiCallBegan({
    url:requests.updateMobileBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const deleteBanner = (data) => apiCallBegan({
    url:requests.deleteMobileBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const loadBannerHome = () => apiCallBegan({
    url:requests.fetchAllHomeBanner,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerReceived.type,
    onError: bannerRequestFailed.type
})


export const getHomeBannerByIds = (id) => apiCallBegan({
    url:requests.fetchHomeBannerById+'/'+id,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:getBannerById.type,
    onError: bannerRequestFailed.type
})

export const addHomeBanner = (data) => apiCallBegan({
    url:requests.createHomeBanner,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const editHomeBanner = (data) => apiCallBegan({
    url:requests.updateHomeBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const deleteHomeBanner = (data) => apiCallBegan({
    url:requests.deleteHomeBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const editBannerImg = (data) => apiCallBegan({
    url:requests.updateMobileBannerImg,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const editHomeBannerImg = (data) => apiCallBegan({
    url:requests.updateHomeBannerImg,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const addBannerTag = (data) => apiCallBegan({
    url:requests.createbannerTag,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const editBannerTag = (data) => apiCallBegan({
    url:requests.editbannerTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const deleteBannerTag = (data) => apiCallBegan({
    url:requests.deletebannerTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})
export const restoreBannerTag = (data) => apiCallBegan({
    url:requests.restoreDeletedTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})


export const loadBannerTag = () => apiCallBegan({
    url:requests.fetchbannerTagAll,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerReceived.type,
    onError: bannerRequestFailed.type
})
export const loadBannerDeleteTag = () => apiCallBegan({
    url:requests.getAllDeletedTag,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:bannerReceived.type,
    onError: bannerRequestFailed.type
})

export const getBannerByTagId = (id) => apiCallBegan({
    url:requests.fetchbannerTagByID+'/'+id,
    headers:AuthHeader,
    onStart:bannerRequested.type,
    onSuccess:getBannerById.type,
    onError: bannerRequestFailed.type
})
export const getBannerTagByCourse = (data) => apiCallBegan({
    url:requests.createbannerTag,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:bannerRequested.type,
    onSuccess:bannerAddedEdited.type,
    onError: bannerRequestFailed.type
})