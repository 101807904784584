import React,{useState,useEffect} from "react";
import FormPopUp from "../popup/PopUp";
import { Form, Formik,Field, ErrorMessage } from "formik";
import Spinner from "../Layout/Spinner";
import * as Yup from 'yup';
import TextError from "../../TextError";
import { useDispatch } from "react-redux";
import { createEnquiryNotes} from "../../store/EnquiryStore";
import { get_user_name } from "../../EncDec";
function EnquiryNoteAdd({openPopup,setOpenPopup,enquiry}){
    const dispatch = useDispatch()
    const initialValues = {
        enquiry: enquiry?.iid,
        enquired_notes: '',
        enquired_by: get_user_name()
    }
    const validationSchema = Yup.object().shape({
        enquiry: Yup.string().required(),
        enquired_notes: Yup.string().required().label('notes')

    })
    const handleSubmit = async(values,submitProps)=>{
        await dispatch(createEnquiryNotes(values))
        submitProps.setSubmitting(false);
        setOpenPopup(false)
    }
    return(
        <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={`Enquiry Status`}>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className="row">
                                <div className="col-md-12">
                                <div className='form-group'>
                                            <label>Enquiry Notes:</label><span className='errors'>*</span>
                                            <Field type="text" name = "enquired_notes" id="enquired_notes" placeholder = "enquiry notes"  className="form-control"  />
                                            <ErrorMessage name='enquiry_status' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                </div>
                                <div className='col-md-2'>
                                        <div className='form-group'>
                                            <label>&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control'  disabled={isSubmitting}>
                                                {isSubmitting?<Spinner/>:''} <i className="fa fa-search"/>save status
                                            </button>
                                            </div>
                                        </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </FormPopUp>
    )
}
export default EnquiryNoteAdd