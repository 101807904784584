import React, { useEffect } from 'react'
import Card from '../Layout/Card'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartmentAll, getDepartmentById, updateDepartment } from '../../store/DepartmentSetupStore'
import { ErrorMessage, FastField,  Form, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'


function DepartmentEdit() {
    const department = useSelector(state => state.entities.department.getData.data)
    const errors = useSelector(state => state.entities.department.errors)
    const dataById = useSelector(state=> state.entities.department.getDataById.data)
    const dispatch = useDispatch()
    const params = useParams()
    useEffect(()=>{
        dispatch(getDepartmentAll())
        
        dispatch(getDepartmentById(params.slug))
    },[params.slug])
    const initialValues = {
        department_name: dataById?.department_name,
        department_code:dataById?.department_code,
        parent:dataById?.parent== null?'':dataById?.parent,
        modified_remarks:'',
        department_id:params.slug
    }
    const validationSchema = Yup.object().shape({
        department_name: Yup.string().required().min(3).max(50).label('Department Name'),
        department_code: Yup.string().required().min(2).max(7).label("Department Code"),
        modified_remarks:Yup.string().required().max(70).min(5).label("Modified Remarks")
        })
        const handleSubmit = (values,submitProps)=>{            
            dispatch(updateDepartment(values))
            submitProps.setSubmitting (false) ;
        }
    return (
        <div>            
                <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>

                <Card cardTitle="Edit Department">
                <div className="form-group">
                    <label>Department Name<span className="errors">*</span></label>
                    <FastField name="department_name" className="form-control"/>
                    <ErrorMessage name="department_name" component={TextError}/>
                <div className="errors">{errors?.data?.department_name && errors?.data.department_name}</div>
                </div>
                <div className="form-group">
                    <label>Department Code<span className="errors">*</span></label>
                    <FastField name="department_code" className="form-control"/>
                    <ErrorMessage name="department_code" component={TextError}/>
                <div className="errors">{errors?.data?.department_code && errors?.data.department_code}</div>
                </div>
                
                <div className="form-group">
                    <label>Parent Department</label>
                <FastField as="select" name="parent" className="form-control">
                    <option value="">Select Parent Department</option>
                    {department && department.map((item)=>(
                        <option key={item.department_code} value={item.id}>{item.department_name+'-'+item.department_code}</option>
                    ))}
                </FastField>
                </div>
                <div className="form-group">
                    <label>Remarks<span className="errors">*</span></label>
                    <FastField name="modified_remarks" className="form-control" placeholder="Modified Remarks"/>
                    <ErrorMessage name="modified_remarks" component={TextError}/>
                <div className="errors">{errors?.data?.modified_remarks && errors?.data.modified_remarks}</div>
                </div>                
                <button className="btn btn-flat btn-success" disabled={isSubmitting}>{ isSubmitting &&<Spinner/>} Save</button>
                </Card>
                </Form>
                        )
                    }
                </Formik>
            {/* </WebForm> */}
        </div>
    )
}

export default DepartmentEdit
