import React from 'react';
import WebForm from '../Form/WebForm';
import {useDispatch} from 'react-redux';
import * as Yup from 'yup';
import { addUser } from '../../store/UserSetup';
import FormField from '../Form/FormField';
import { Form } from 'formik';

function SignUp() {
    const dispatch = useDispatch();
    // const result = useSelector(state => state.entities.users.result);
    // const errors = useSelector(state => state.entities.users.error);
    const initialValues = {
        email:'',
        first_name:'',
        last_name:'',
        password:''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required().email().label('Email'),
        first_name:Yup.string().min(2,"must have more than 2 characters").max(30,"shouldnot have more than 30 characters").required(),
        last_name:Yup.string().min(2,"must have more than 2 characters").max(30,"shouldnot have more than 30 characters").required(),
        password: Yup.string().required()
    });

    const handleSubmit = (values) => {
        dispatch(addUser(values));
    }
    return (
        <div className="row">
            <div className="col-md-5">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Signup</h3>
                    </div>
                    <div className="card-body">
                        <WebForm
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        >
                            <Form>
                            <FormField
                            type="email"
                            name="email"
                            placeholder="email"
                            label="Email"
                            />
                            <FormField
                            type="text"
                            name="first_name"
                            placeholder="first name"
                            label="First Name"
                            />
                            <FormField
                            type="text"
                            name="last_name"
                            placeholder="last name"
                            label="Last Name"
                            />
                            <FormField
                            type="password"
                            name="password"
                            placeholder="password"
                            label="Password"
                            />
                           
                            <button className="btn btn-success" type="submit">Signup</button>
                            </Form>
                        </WebForm>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp
