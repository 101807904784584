import React, { useState } from 'react'
import FormPopUp from '../popup/PopUp'
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { Form, Formik } from 'formik';
import Spinner from '../Layout/Spinner';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateCourseDate } from '../../store/CategorySetup';
function UpdatedDateCourseModal({openPopup,setOpenPopUp,title,data}) {
    const [dateEng, onChangeEnd] = useState(new Date());
    const [dateNep, setValueEndNep] = useState();
    const handleEndDate = ({bsDate,adDate})=>{
        setValueEndNep(bsDate)
        onChangeEnd(adDate) 
    }
    const dispatch = useDispatch()
    const initialValues = {
        courses:data,
        updated_date_nep:dateNep,
        updated_date_eng:dateEng,
    }
    const handleSubmit = async(values,submitProps)=>{
            await dispatch(updateCourseDate(values))
            submitProps.setSubmitting(false);
    }
    const validationScema = Yup.object().shape({
        updated_date_nep: Yup.string().required().label('course date')
    })
    return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopUp} title={title}>
        <Formik
        initialValues={initialValues}
        validationSchema={validationScema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                        <label>course updated Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`live_start_nep_date`}
                            className="form-control"
                            onChange={handleEndDate}
                            defaultDate={initialValues.live_start_nep_date}
                            />
                        </div>
                        <button className='btn btn-flat btn-success' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default UpdatedDateCourseModal