
import React,{useState,useEffect} from 'react';
import {Formik,Form,Field,ErrorMessage} from 'formik';
import * as Yup from 'yup';
import TextError from '../../TextError';
import {useDispatch, useSelector} from 'react-redux';
import {editCategory, getCategoryBySlug} from '../../store/CategorySetup';
import { useParams } from 'react-router-dom';


function CourseCategoryEdit(props) {
     
     const dispatch = useDispatch();
     const params = useParams();
     const [,setCategory] = useState([]);        
     const result = useSelector(state => state.entities.categorys.result);
     const errors = useSelector(state => state.entities.categorys.error) ;    
     useEffect(()=>{     
      dispatch(getCategoryBySlug(params.slug));
      setCategory(result.data);
     },[params])      
      const validationSchema = Yup.object().shape({
          category_name: Yup.string().min(3,'must have more than 3 characters').required('This field is required'),
          course_categories_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required'),
          modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks")          
        })
        
        const initialValues = {
          category_name : result?.data?.category_name,
          course_categories_code: result?.data?.course_categories_code,
          modified_remarks:'',
          // category_id:result?.data?.iid
        }
        const handleSubmit = (values,submitProps)=>{
            values.category_id = result?.data?.iid;
            values.modified_by = localStorage.getItem('username')  
             dispatch(editCategory(values))
              submitProps.setSubmitting(false);            
        }
    return (
        <>
        <div className="row">
      <div className="col-md-12">
      <div className="card">
      <div className="card-header">
      <h3 className="card-title">Category Edit</h3>
    </div>
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
    { formik => 
    (<Form>
      <div className="card-body">            
        <div className="form-group item">
          <label className="required-label">Category Title <span className="errors">*</span>:</label>
          <Field type="text" name = "category_name" id="category_name" placeholder = "category title"  className="form-control" />
          <ErrorMessage name="category_name" component={TextError}/>
           {errors?.data?.category_name && <div className="errors">{errors?.data?.category_name}</div>}
        </div>  
        <div className="form-group item">
          <label className="required-label">Category Code <span className="errors">*</span>:</label>
          <Field type="text" name = "course_categories_code" id="course_categories_code" placeholder = "category title"  className="form-control" />
          <ErrorMessage name="course_categories_code" component={TextError}/>
           {errors?.data?.course_categories_code && <div className="errors">{errors?.data?.course_categories_code}</div>}
        </div>     
        <div className="form-group item">
          <label className="required-label">Modified Remarks <span className="errors">*</span>:</label>
          <Field type="text" name = "modified_remarks" id="modified_remarks" placeholder = "modified remarks"  className="form-control" />
          <ErrorMessage name="modified_remarks" component={TextError}/>
           {errors?.data?.modified_remarks && <div className="errors">{errors?.data?.modified_remarks}</div>}
        </div>     
      </div>
      <div className="card-footer">
        <button type="submit" className="btn btn-flat btn-success" name="save" id="save" disabled={formik.isSubmitting}>Save </button>
      </div>
    </Form>)
    }
    </Formik>
  </div>
</div>
 </div>  
      
 </>
    )
}

export default CourseCategoryEdit
