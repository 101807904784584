import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import MathJax from 'mathjax3-react'
import { getKUQuizModelsetDetails } from '../../store/QuizSetupStore'
import Card from '../Card/Card'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import Pagination from '../Pagination/Pagination'

function ModelsetKuQuizView() {
  const [currentPage,setCurrentPage] = useState(1)
  const [resPerPage,setResPerPage] = useState(1)
   // Current active button number
  const [currentButton, setCurrentButton] = useState(1)
   // Array of buttons what we see on the page
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])
  const dispatch = useDispatch();
  const params = useParams();
  const modelset = useSelector(state => state.entities.quiz.modelsetReport?.data)
  const loading = useSelector(state => state.entities.quiz.laoding)
  useEffect(()=>{
    dispatch(getKUQuizModelsetDetails(params.slug))
  },[params])
  let questions,quiz,qz,options,paragraph,pquestion,poptions,arr;
  
  arr = modelset && Object.keys(modelset).map(msitem => {
    questions = modelset[msitem]['questions']
    paragraph = modelset[msitem]['paragraph']
    return {
      id:msitem,
      modelset_name:modelset[msitem]?.modelset_name,
      question: questions && Object.keys(questions).map(qsnitem => {
        quiz = questions[qsnitem]['quiz']
        return {
          qsn_no:qsnitem,
          quiz: quiz && Object.keys(quiz).map(qzitem => {
            options = quiz[qzitem]['options']
            return{
            quiz_id:quiz[qzitem]?.quiz_id,
            level:quiz[qzitem]?.level,
            question:quiz[qzitem]?.question,
            correct_option:quiz[qzitem]?.correct_option,
            answer_desc:quiz[qzitem]?.answer_desc,
            }
          })
        }
      })
    }

  })
  var quizLength = questions && Object.keys(questions).length
  var quizzes = questions && Object.keys(questions)
  // quiz.length = quizLength   
  const indexOfLastQuiz = currentPage * resPerPage
  const indexOfFirstQuiz = indexOfLastQuiz - resPerPage
  const currentQuiz = quizzes && quizzes.slice(indexOfFirstQuiz,indexOfLastQuiz)
  const howManyPages = quizLength && Math.ceil(quizLength/resPerPage)
  const numberOfPages = []
  for (let i=1;i<=howManyPages;i++){
    numberOfPages.push(i)
  }
  console.log("current quiz",currentQuiz)
const nextPage = () =>{
    setCurrentButton(prev => prev >= numberOfPages.length ? prev : prev + 1)
    // dispatch(getQuizAll(currentButton*resPerPage,resPerPage))
}
const prevPage = () =>{
    setCurrentButton(prev => prev <= 1 ? prev : prev - 1)
    // dispatch(getQuizAll((currentButton-2)*resPerPage,resPerPage))
}
const currPage = (item) =>{
    setCurrentButton(item)
    // dispatch(getQuizAll((item-1)*resPerPage,resPerPage))
}
  return (
    <BodyContainer pageTitle={'Modelset KU Quiz View'} buttonTitle="cancel" route={'/modelsetSetup'}>
        {
          loading && <LoaderSpinner/>
        }
        <Card>
          <div className='card-body'>
          <label>Modelset Details</label>
              {
                modelset &&
                Object.keys(modelset).map(msitem => {
                  questions = modelset[msitem]['questions']
                  paragraph = modelset[msitem]['paragraph']
                  return(
                    <>
                    <div className='row'>
                      <div className='col-md-3'>
                        {
                          modelset[msitem]?.modelset_name
                        }
                      </div>
                      <div className='col-md-2'>
                      <label>full marks</label>:&nbsp;
                        {
                          modelset[msitem]?.total_marks
                        }
                      </div>
                      <div className='col-md-2'>
                      <label>pass marks</label>:&nbsp;
                        {
                          modelset[msitem]?.pass_marks
                        }
                      </div>
                    </div>
                    </>
                  )
                })
              }
          </div>
        </Card>
        <div className='row'>
              <div className='col-md-12'>
                {
                  currentQuiz &&
                  currentQuiz.map((key)=>{
                      qz = questions[key]['quiz']
                    
                        return(
                          <>
                          {                        
                          Object.keys(qz).map((qzitem)=>{
                            options = qz[qzitem]['options']                       
                          return(
                          <>                          
                           <Card key={qzitem}>
                            <div className='card-body'>
                              <div className='row'>
                                <div className='col-md-1'>
                                    qn.{qz[qzitem]?.qsn_no}
                                </div>
                                 <div className='col-md-9'>
                                <MathJax.Html html={qz[qzitem]?.question}/>                      
                                </div>
                                 <div className='col-md-2 float-sm-right'>
                                  {qz[qzitem]?.marks_weightage} marks
                                </div>
                                 <div className='col-md-2 float-sm-right'>
                                  {qz[qzitem]?.level} 
                                </div>
                              </div>  
                            </div>
                          </Card>
                          {
                              options &&
                              Object.keys(options).map((keyOptions)=>(
                                <>
                                <div className='col-md-6'>
                                  <Card>
                                    <div className='card-body'>
                                      <MathJax.Html html={options[keyOptions]?.options}/>
                                        
                                    </div>
                                  </Card>
                                </div>
                                </>
                              ))
                            }
                          </> 
                          )                       
                      })
                        }
                     </>
                  )
                  })
                }
               
               {
          paragraph && (currentButton === numberOfPages.length)? 
          Object.keys(paragraph).map((pkey)=>{
              pquestion = paragraph[pkey]['questions']
              return (
                <>
                <Card>
                  <div className='card-body'>
                  <MathJax.Html html={paragraph[pkey]?.paragraph}/>
                  </div>
                </Card>
                {
                  pquestion &&
                  Object.keys(pquestion).map(pqkey=>{
                    poptions = pquestion[pqkey]['options']
                    return(
                      <>
                      <div className='row'>
                        <div className='col-md-12'>
                        <Card>
                          <div className='card-body'>
                              <MathJax.Html html={pquestion[pqkey]?.question}/>
                          </div>
                        </Card>
                        </div>
                      </div>
                      <div className='row'>
                      {
                    poptions &&
                    Object.keys(poptions).map((opKey)=>{
                      return (
                        <>
                        
                          <div className='col-md-6'> 
                            <Card>
                              <div className='card-body'>
                                <MathJax.Html html={poptions[opKey]?.options}/>                              
                              </div>
                            </Card>
                          </div>                    
                      
                        </>
                      )
                    })
                  }
                  </div>
                    </>
                  )
                  })
                }
                </>
              )
          })
          :''
        }
                
              </div>
        </div>
        {
            (howManyPages >=1) ?
            <Pagination 
            numberOfPages={numberOfPages} 
            setCurrentPage={setCurrentPage} 
            currentButton={currentButton}
            setCurrentButton={setCurrentButton}
            arrOfCurrButtons={arrOfCurrButtons}
            setArrOfCurrButtons={setArrOfCurrButtons}
            nextPage={nextPage}
            prevPage={prevPage}
            currPage={currPage} 
            />
            :''
        }
    </BodyContainer>
  )
}

export default ModelsetKuQuizView