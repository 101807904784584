import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ADYEAR,NEPALIYEAR } from '../Calendar/NPYear'
import * as Yup from 'yup';
import FormPopUp from '../popup/PopUp';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { getBatchSelect } from '../../store/EnrollStore';
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner';
import { batchModelsetTag } from '../../store/QuizSetupStore';
function ModelsetBatchTag({openPopup,setOpenPopup,course,modelsetId,title}) {
    const [year,setYear] = useState(ADYEAR)
    const [courses,setCourse] = useState(course && course[0]?.value)
    const [batch,setBatch] = useState()
    const filterBatch = useSelector(state=>state.entities.enroll?.batchSelect?.data)
    const errors = useSelector(state =>state.entities.quiz?.errors?.data)
    const isLoading = useSelector(state => state.entities.quiz?.loading)
    const dispatch = useDispatch()
    const initialValues = {
        modelset:modelsetId,
        batch_year:year,
        courses:courses,
        batch:batch
    }
    const validationSchema = Yup.object().shape({
        modelset:Yup.string().required(),
        batch: Yup.string().required()
    })
    const handleSubmit =(values,submitProps)=>{
        dispatch(batchModelsetTag(values))
        submitProps.resetForm()
    }
    const onYearChange = (value)=>{
        setYear(value)
        if(courses)
        dispatch(getBatchSelect({'year':value,'course':courses}))
    }
    const onCourseChange = (value) =>{
        setCourse(value) 
        if(year){
        dispatch(getBatchSelect({'year':year,'courses':value}))
        }

    }
    const handleBatch = (value)=>{
        setBatch(value)
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={title}>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
            ({})=>(
                <Form>
                    {errors?.error &&<div className='errors'>{errors?.error}</div>}
                    <div className='form-group'>
                            <label>select courses:</label><span className='errors'></span>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control' onChange={(e)=>onCourseChange(e.target.value)} >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            {errors?.courses && <div className="errors">{errors?.courses}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Batch Year</label>:<span className='errors'>*</span>
                            <Field as="select" name="batch_year" id="batch_year" placeholder="" className='form-control' value={year} onChange={(e)=>onYearChange(e.target.value)}>
                                    <option name="batch_year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="batch_year" component={TextError} />
                                         
                        </div>
                        
                        
                    <div className='form-group '>
                        <label className='required-label'> select batch<span className="errors">*</span></label>
                        <Field as="select" name="batch" id="batch" placeholder="" className='form-control' value={batch}  onChange={(e)=>handleBatch(e.target.value)} >
                            <option name="batch" value="">select Batch</option>
                                    {
                                        filterBatch && filterBatch?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.batch_name}</option>
                                            ))
                                    }
                        </Field>
                        <ErrorMessage name="group" component={TextError} />
                        </div>
                        
                        <div className='form-group'>
                                    <button className='btn btn-flat btn-success' type='submit' disabled={isLoading}>
                                    {isLoading && <><Spinner/>&nbsp;</>}save
                                    </button>
                        </div>
                </Form>
            )
        }
        </Formik>
    </FormPopUp>
  )
}

export default ModelsetBatchTag