import React from 'react'

function ErrorMessage({error,visible}) {
    if(!error || !visible)
    return null;
    return (
        <p style={{color:'red'}}>
            {error}
        </p>
    )
}

export default ErrorMessage
