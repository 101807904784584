import React from 'react';
import * as Yup from 'yup';
import FormPopUp from '../popup/PopUp';
import ImageUploader from '../Form/ImageUploader';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Layout/Spinner';
import { editOnboardMobileImg } from '../../store/OnboardMobileSetupStore';
import ProgressBar from '../ProgressBar/ProgressBar';
function OnboardImgUpdate({openPopUp,setOpenPopUp,banner}) {
    const initialValues = {        
        image:''
    }
    const dispatch = useDispatch()
    const progress = useSelector(state => state.entities.onboard?.progress)
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const validationSchema = Yup.object().shape({
        image:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("image"),
    })
    const handleSubmit = (values,submitProps)=>{
            let formData = new FormData()
            formData.append('banner_id',banner?.iid)
            formData.append('onboard_img',values.image,values.image.name) 
            dispatch(editOnboardMobileImg(formData))
            submitProps.setSubmitting(false);
    } 
  return (
    <FormPopUp openPopup={openPopUp} setOpenPopup={setOpenPopUp} title="Onboard Mobile Image Update">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
          {
            ({isSubmitting})=>(
              <Form>
                  <ImageUploader
                  name="image"
                  label="Onboard image"
                  required="required"
                  />
                  <div>
                    {
                    progress &&
                    progress?.percent >0?<ProgressBar percentage={progress}/>:''
                    }
                </div>
                  <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}<i className='fa fa-save'></i>&nbsp;Save</button>
              </Form>
            )
          }
        </Formik>
    </FormPopUp>
  )
}

export default OnboardImgUpdate