import React from 'react'
import {Formik,Form,Field,ErrorMessage} from 'formik'
import * as Yup from 'yup'
import TextError from '../../TextError'
import { useDispatch, useSelector } from 'react-redux'
import { addCategory } from '../../store/CategorySetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'

function CourseCategoryAdd(props) {      
      const {fetchData,setFetchData} = props;      
      const errors = useSelector(state => state.entities.categorys.error);
      // const result = useSelector(state => state.entities.categorys.result );
      const loader = useSelector(state=> state.entities.categorys.loading);
      // const popup = useSelector(state=> state.entities.categorys.popup);
      const dispatch = useDispatch();            
      const validationSchema = Yup.object().shape({
          category_name: Yup.string().min(3,'must have more than 3 characters').max(50,'cannot exceed from 50 characters').required('This field is required'),
          course_categories_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required')
        })
        const initialValues = {
          category_name : '',
          course_categories_code: ''
        }
        const handleSubmit = (values,submitProps)=>{ 
          values.created_by = localStorage.getItem('username')      
         const changeData = (prevData) => setFetchData(!prevData) 
          async function saveData(){
            await dispatch(addCategory(values)); 
             submitProps.setSubmitting(false);
             submitProps.resetForm();  
             changeData(fetchData);         
          }          
            saveData();             
        }
    
    return (
        <>  
         {/* {visible?<Toaster response={response} msg={msg}/>:null}            */}
  <div className="row">
  <div className="col-md-12">
  <div className="card">
    <div className="card-header">
      <h3 className="card-title">Category Add</h3>
    </div>
    <Formik 
    initialValues={initialValues} 
    onSubmit={handleSubmit} 
    validationSchema={validationSchema}
    enableReinitialize    
    >
   {formik => { 
     return(
       <Form>  
      <div className="card-body">            
        <div className="form-group item">
          <label className="required-label">Category Title <span className="errors">*</span>:</label>
          <Field type="text" name = "category_name" id="category_name" placeholder = "category title"  className="form-control" />
          <ErrorMessage name="category_name" component={TextError}/>
          {errors?.data?.category_name && <div className="errors">{errors?.data?.category_name}</div>}
        </div>  
        <div className="form-group item">
          <label className="required-label">Category Code <span className="errors">*</span>:</label>
          <Field type="text" name = "course_categories_code" id="course_categories_code" placeholder = "category code"  className="form-control" />
          <ErrorMessage name="course_categories_code" component={TextError}/>
          {errors?.data?.course_categories_code && <div className="errors">{errors?.data?.course_categories_code}</div>}
        </div>      
      </div>
      <div className="card-footer">
        <button type="submit" disabled={formik.isSubmitting} className="btn btn-flat btn-success" name="save" id="save">Save </button>
      </div>
    </Form>)}}
    </Formik>
  </div>
</div>
 </div>  
 {loader?<LoaderSpinner/>:null}   
 
 </>
    )
}

export default CourseCategoryAdd
