import React, { useEffect, useState } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { getCompany, getCompanyDetailsById, getCompanyLogo } from '../../store/CompanySetup';
import { useDispatch, useSelector } from 'react-redux';
import { Route} from 'react-router-dom'
import CustomizedTable from '../Material-uiControls/CustomizedTable';
import PopupModal from '../Material-uiControls/PopupModal';
import CompanyEdit from './CompanyEdit';
import ImageViewer from '../Form/ImageViewer';
import LogoAdd from './LogoAdd';
import CompanyAdd from './CompanyAdd';

function CompanySetup() {
    const dispatch = useDispatch()
    const [openPopUp,setOpenPopUp] = useState(false);
    const [editOpen,setEditOpen] = useState(false);
    const [refreshData,setRefreshData] = useState(false);
    const [logoPopup,setLogoPopup] = useState(false);
   
    
    const companyData = useSelector(state => state.entities.company.result.data)
    const logoData = useSelector(state => state.entities.company.logoData.data)
    useEffect(()=>{        
        dispatch(getCompany())
    },[refreshData])
    
    const colHeaders = [
        'Company Name','Company Address','Company Email','Logo','Action'
    ]  

    const colName = ['company_name','company_address','company_email']
      
    console.log("company Data",companyData)  
    console.log("logoData",logoData) 
   
    const editData = (id)=>{
        console.log("id clicked:",id)
        dispatch(getCompanyDetailsById(id));
        setEditOpen(true)
    }
    const viewData=(id)=>{
        dispatch(getCompanyDetailsById(id));
        setOpenPopUp(true);
    }
    const viewLogo=(id)=>{
        dispatch(getCompanyLogo(id));
        setLogoPopup(true);
    }
    return (
        <>
        <Route path="/company/add">
                <BodyContainer
                pageTitle="Company Add"
                buttonTitle="Cancel"
                route="/company/"
                >
                <CompanyAdd/>
                </BodyContainer>
            </Route>
        <Route exact path="/company/">
           <BodyContainer 
           pageTitle="Company Setup" 
           buttonTitle="Create Company"
           route="/company/add"
           >
             {companyData && <CustomizedTable
              rows={companyData} 
              colHeader={colHeaders} 
              colName={colName}
              action={true}
              edit={true}
              view={true}
              viewOrEditBy="id"
              editFunc={editData}
              viewFunc={viewData}
              viewLogo={true}
              logoFunc={viewLogo} 
              />}             
            </BodyContainer>
            </Route>
            
                <PopupModal 
                title="Company Details" 
                openPopup={openPopUp} 
                setOpenPopup={setOpenPopUp}
                setRefreshData={setRefreshData}
                >
                   {companyData && ( 
                       <>
                     <p>
                        <label><strong>Company Name:&nbsp;</strong></label><label>{companyData[0]?.company_name}</label>
                    </p>
                    <p>
                        <label><strong>Company Adress:&nbsp;</strong></label><span>{companyData[0]?.company_address}</span>
                    </p>
                    <p>
                        <label><strong>Company Email:&nbsp;</strong></label><span>{companyData[0]?.company_email?companyData[0]?.company_email:'N/A'}</span>
                    </p>
                    <p>
                        <label><strong>Company contact:&nbsp;</strong></label><span>{
                        companyData[0]?.company_contact && companyData[0]?.company_contact.map((item)=>(
                            <>
                            <label>{item.company_contact_no}</label>
                            
                            </>
                    ) )
                        }</span>
                    </p>
                    <p>
                        <label><strong>Company Logo:&nbsp;</strong></label><span>{companyData[0]?.company_contact && companyData[0]?.company_contact.map((item)=>item.company_contact_no)}</span>
                    </p>
                    </>
                   )    
                }
                </PopupModal>
                <PopupModal 
                title="Company Edit"
                openPopup={editOpen}
                setOpenPopup={setEditOpen}
                setRefreshData={setRefreshData}
                >
                    <CompanyEdit companyData={companyData}/>
                </PopupModal>
                <PopupModal
                title="Logo"
                openPopup={logoPopup}
                setOpenPopup={setLogoPopup}
                setRefreshData={setRefreshData}
                >
                      <ImageViewer image={logoData && logoData[0]?.company_logo}/>
                      <LogoAdd/>  
                </PopupModal>
        </>
    )
}


export default CompanySetup
