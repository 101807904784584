import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadSubCourse } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup';
import { addMainTopic } from '../../store/MainTopicSetup';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function MainTopicAdd({fetchData,setFetchData}) {
    const params = useParams()
    const subcourse = useSelector(state => state.entities.subcourse?.result?.data)
    const loading = useSelector(state => state.entities.mainTopic?.loading)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadSubCourse())
    },[params])
    const initialValues = {
        topic_title:'',
        subcourse:params.slug,
        lecture_hours:'',
        created_by:localStorage.getItem('username')
    }
    const validationSchema = Yup.object().shape({
        topic_title:Yup.string().required().max(50).min(4).label('Topic Title'),
        subcourse:Yup.string().required(),
        lecture_hours:Yup.string().required().label('Lecture hours'),
    })
    const changeData = (prevData) => setFetchData(!prevData)
    const handleSubmit = (values,submitProps)=>{
        dispatch(addMainTopic(values))
        submitProps.setSubmitting(false);
        submitProps.resetForm()        
        changeData(fetchData);
    }
  return (
      <Card cardTitle="Main Topic Add">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='form-group item'>
                                <label className='required-label'>Main Topic Title<span className="errors">*</span>:</label>
                                <FastField type="text" name="topic_title" id="topic_title" placeholder="topic title" className='form-control'/>
                                <ErrorMessage name="topic_title" component={TextError} />
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Lecture Hours<span className="errors">*</span>:</label>
                                <FastField type="text" name="lecture_hours" id="lecture_hours" placeholder="lecture hours" className='form-control'/>
                                <ErrorMessage name="lecture_hours" component={TextError} />
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent<span className="errors">*</span>:</label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" className='form-control'>
                                    <option name="subcourse" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse" component={TextError} />
                            </div>
                            <div className='card-footer'>
                                 <button className='btn btn-flat btn-success' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}save</button>       
                            </div>
                        </Form>
                    )
                }
            </Formik>
          </div>
      </Card>
  );
}

export default MainTopicAdd;
