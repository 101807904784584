import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getLessonByIds,editLesson } from '../../store/LessonSetup';
import { loadSubCourse } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function LessonEdit({fetchData,setFetchData}) {
    const params = useParams()   
    const subcourse = useSelector(state => state.entities.subcourse.result?.data)
    const lesson = useSelector(state => state.entities.lesson.getDataById?.data)
    // const errors = useSelector(state => state.entities.lesson.error?.data)
    const loading = useSelector(state => state.entities.lesson.loading)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadSubCourse())
        dispatch(getLessonByIds(params?.slug))
    },[])
    const initialValues = {
        lesson_title:lesson?.lesson_title,
        main_topic_id:params?.slug
    }
    const handleSubmit = (values,submitProps) => {
        dispatch(editLesson(values))        
        if(!loading){
            submitProps.setSubmitting(false);
        }
    }
    const validationSchema = Yup.object().shape({
        lesson_title:Yup.string().required().label('lesson title').min(3).max(50),
        main_topic_id:Yup.string().required().label('parent'),
    })
  return (
      <Card cardTitle="Lesson Edit">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='form-group item'>
                                <label className='required-label'>Lesson Title</label>
                                <FastField type="text" name="lesson_title" id="lesson_title" placeholder="lesson title" className='form-control'/>
                                <ErrorMessage name="lesson_title" component={TextError} />
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent</label>
                                <Field as="select" name="main_topic_id" id="main_topic_id" placeholder="" className='form-control'>
                                    <option name="main_topic_id" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="main_topic_id" component={TextError} />
                            </div>
                            <div className='card-footer'>
                                 <button className='btn btn-flat btn-success' disabled={isSubmitting}>save</button>       
                            </div>
                        </Form>
                    )
                }
            </Formik>
          </div>
      </Card>
  );
}

export default LessonEdit;
