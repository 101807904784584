import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadCourseSelect } from '../../store/CourseSetup';
import {  filterPassageModelset, getTagParagraphToModelsetAll } from '../../store/QuizSetupStore';
import {NEPALIYEAR,ADYEAR} from '../Calendar/NPYear'
import Card from '../Card/Card';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ReactDataTable from '@ashvin27/react-datatable';
import * as Yup from 'yup';
import MathJax from 'mathjax3-react';
import TextError from '../../TextError';
import TagParagraphModelsetEdit from './TagParagraphModelsetEdit';
import ParagraphDelete from './ParagraphDelete';
import RestoreDeletedTagParagraph from './RestoreDeletedTagParagraph';
function ParagraphTagReport() {
    const [year,setYear] = useState(ADYEAR);
    const [courses,setCourse] = useState('');
    const [editOpen,setEditOpen] = useState(false);
    const [delOpen,setDeleteOpen] = useState(false);
    const [restoreOpen,setRestoreOpen] = useState(false);
    const [_,setParagraph] = useState('');
    const course = useSelector(state => state.entities.courses.result?.data);
    const modelset = useSelector(state => state.entities.quiz.filterparagraphModelset?.data)
    const loading = useSelector(state => state.entities.quiz.loading)
    const paragraph = useSelector(state => state.entities.quiz.paragraphModelset?.data)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])

    const handleCourseClick = (id)=>{
        setCourse(id)        
        if(year){
            let data = {
                'courses':id,
                'year':year
            }
            dispatch(filterPassageModelset(data))
        }

    }
    const onChange = ( bsDate ) => {
        setYear(bsDate);
       let  data = {
            'courses':courses,
            'year':bsDate
        }       
        if(courses)
            dispatch(filterPassageModelset(data));
	};
    const handleSubmit = (values,submitProps) =>{
        dispatch(getTagParagraphToModelsetAll(values));
        submitProps.setSubmitting(false);       
    }
    const initialValues = {
        modelset:'', 
        courses:courses       
    }
    const validationSchema = Yup.object().shape({
        courses:Yup.string().required(),
        modelset:Yup.string().required(),

    })
    const clickEditTagQuiz = (record) =>{
        setEditOpen(true);
        setParagraph(record);
    }
    const clickDelTagQuiz = (record) =>{
        setDeleteOpen(true);
        setParagraph(record);
    }
    const clickRestoreTagQuiz = (record) =>{
        setRestoreOpen(true);
        setParagraph(record);
    }
    const COLUMNS = [
        {
            key:'modelset_name',
            text:'Modelset',
            maxWidth:650,           
        },
        {
            key:'paragraph_title',
            text:'Passage Title',
            cell : record => {
                return(
                    <>                  
                        <MathJax.Html html={record?.paragraph_title}/>                  
                    </>
                )
            }
        },
        {
            key:'passage',
            text:'Passage',
            cell : record => {
                return(
                    <>                  
                        <MathJax.Html html={record?.passage}/>                  
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 250,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <button
                            className="btn btn-success btn-sm"                           
                            style={{marginRight: '5px'}}                         
                            onClick={()=> clickEditTagQuiz(record)}
                            >
                            <i className="fa fa-edit">&nbsp;edit Tag</i>
                        </button>
                       
                       {
                           record?.is_deleted_flag === 'n'?

                        <button 
                            
                            style={{marginLeft:5}}
                            className="btn btn-danger btn-sm" 
                            onClick={()=>clickDelTagQuiz(record)}
                            >                            
                            <i className="fa fa-trash">&nbsp;del</i>
                        </button> :
                        <button 
                            
                        style={{marginLeft:5}}
                        className="btn btn-primary btn-sm" 
                        onClick={()=>clickRestoreTagQuiz(record)}
                        >                            
                        <i className="fa fa-undo">&nbsp;restore</i>
                    </button> 

                       }
                        
                    </>
                );
            }
        }
    ]
  return (
    <BodyContainer pageTitle={`Paragraph Tag Report`}>
        <Card>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-4 form-group'>
                                        <label>Select Course<span className='errors'>*</span></label>:
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                            <option name="courses" value="">select Options</option>
                                            {
                                                course && course?.map((item,index)=>(
                                                    <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                        <ErrorMessage name="courses" component={TextError} />
                                    </div>
                                    <div className='form-group col-md-2'>
                                        <label>Year :</label>
                                        <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                            <option name="year" value="">select Options</option>
                                            {
                                                NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                                    <option key={index} value={item}>{item}</option>
                                                ))
                                            }
                                        </Field> 
                                    </div>
                                    <div className='form-group col-md-4'>
                                        <label className='required-label'>Select ModelSet<span className="errors">*</span></label>
                                        <Field as="select" name="modelset" id="modelset" placeholder="" className='form-control'>
                                            <option name="modelset" value="">select Options</option>
                                            {
                                                modelset && modelset?.map((item,index)=>(
                                                    <option key={item.iid} value={item.iid}>{item.modelset_name}</option>
                                                ))
                                            }
                                        </Field>
                                        <ErrorMessage name="modelset" component={TextError} />
                                    </div>
                                    <div className='form-group col-md-2'>
                                            <label>&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control' disabled={isSubmitting}>
                                                {isSubmitting?<Spinner/>:''}<i className='fa fa-search'/>&nbsp;search
                                            </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </Card>
        <Card>
            <div className='card-body'>
            <ReactDataTable
              records = {paragraph}
              columns = {COLUMNS}
              />
            </div>
        </Card>
        {
            loading &&
            <LoaderSpinner/>
        }
        {
            editOpen && <TagParagraphModelsetEdit openPopUp={editOpen} setOpenPopUp={setEditOpen} paragraph={paragraph}/>
        }
        {
            delOpen && <ParagraphDelete openPopup={delOpen} setOpenPopup={setDeleteOpen} modelset={paragraph}/>
        }
        {
            restoreOpen && <RestoreDeletedTagParagraph openPopup={restoreOpen} setOpenPopup={setRestoreOpen} modelset={paragraph}/>
        }
    </BodyContainer>
  )
}

export default ParagraphTagReport