import { Paper } from '@material-ui/core'
import React,{useEffect, useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Link, Route, Switch } from 'react-router-dom'
import { getMenuAll } from '../../store/MenuSetupStore'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import MenuAdd from './MenuAdd'
import MenuEdit from './MenuEdit'
import MenuTree from './MenuTree'

function MenuSetup() {
    const menu = useSelector(state => state.entities.menu?.result)
    const loading = useSelector(state => state.entities.menu?.loading)
    const [fetchData,setFetchData] = useState(true)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getMenuAll())
    },[fetchData])
    
    return (
        <BodyContainer pageTitle="Menu Setup">
            {loading && <LoaderSpinner/>}
            <div className="row">
                <div className="col-md-6">
                <Paper style={{padding:20}}>
                            <div>
                            <Link to="/menu/" type="button" className="btn btn-primary btn-sm btn-flat">Add Menu</Link>
                            </div>
                        </Paper>
                    <MenuTree data={menu} loading={loading}/>
                </div>
                <div className="col-md-6">
                    <Switch>
                        <Route path="/menu/addMenu/:slug">                
                            <MenuAdd fetchData={fetchData} setFetchData={setFetchData}/>
                        </Route>
                        <Route path="/menu/editMenu/:slug">                
                            <MenuEdit fetchData={fetchData} setFetchData={setFetchData}/>
                        </Route>
                        <Route path="/menu/">
                            <MenuAdd fetchData={fetchData} setFetchData={setFetchData}/>
                        </Route>

                    </Switch>
                </div>
            </div>
        </BodyContainer>
    )
}

export default MenuSetup
