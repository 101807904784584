import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import { editOnboardMobile } from '../../store/OnboardMobileSetupStore';
function OnboardEdit({openPopUp,setOpenPopUp,data}) {
    const dispatch = useDispatch();
    const errors = useSelector(state => state.entities.onboard?.error?.data);
    const loading = useSelector(state => state.entities.onboard?.loading);
    const initialValues = {
        banner_id:data?.iid,
        onboard_title:data?.onboard_title,        
        onboard_priority:data?.onboard_priority, 
        modified_remarks:''       
    }
    const handleSubmit = (values,submitProps) =>{        
        values.modified_by=localStorage.getItem('username');
        dispatch(editOnboardMobile(values));
        submitProps.setSubmitting(false);
       
    }
    
    const validationSchema = Yup.object().shape({
        onboard_title: Yup.string().required().min(4).max(30).label("onboard title"),
        onboard_priority:Yup.number().required().min(1).max(2).label("onboard priority"),
        modified_remarks:Yup.string().required().min(5).max(100).label('modified remarks')

    })
  return (
    <FormPopUp title="Onboard Mobile Banner Edit" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=> (
                    <Form>
                        <div className='form-group'>
                            <label>Onboard Title </label><span className='errors'>*</span>
                            <Field name="onboard_title" className='form-control' placeholder="onboard title"/>
                            <ErrorMessage name='onboard_title' component={TextError}/>
                            {errors?.onboard_title && <div className='errors'>{errors?.onboard_title}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Onboard priority </label><span className='errors'>*</span>
                            <Field type="number"  name="onboard_priority" className='form-control' placeholder="onboard priority"/>
                            <ErrorMessage name='onboard_priority' component={TextError}/>
                            {errors?.onboard_priority && <div className='errors'>{errors?.onboard_priority}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Onboard priority </label><span className='errors'>*</span>
                            <Field type="text"  name="modified_remarks" className='form-control' placeholder="modified remarks"/>
                            <ErrorMessage name='modified_remarks' component={TextError}/>
                            {errors?.modified_remarks && <div className='errors'>{errors?.modified_remarks}</div>}
                        </div>                                             
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/> &nbsp; save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default OnboardEdit