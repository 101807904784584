import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import TextError from '../../TextError'
import {editAlumni} from '../../store/AlumniSetupStore'
import { loadCourseSelect } from '../../store/CourseSetup'
function AlumniEdit({openPopUP,setOpenPopUp,alumni,setRefetch}) {
    const dispatch = useDispatch()
    const course = useSelector(state=> state.entities.courses.result?.data)
    const errors = useSelector(state=> state.entities.alumni.error?.data)
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[alumni])   
    const initialValues={
        alumni_id: alumni?.iid,
        alumni_name: alumni?.alumni_name,
        alumni_achievement:alumni?.alumni_achievement,        
        alumni_desc:alumni?.alumni_desc,
        rating:alumni?.rating,
        courses:alumni?.course_iid,
        modified_remarks:''
    }
    const validationSchema= Yup.object().shape({
        courses: Yup.string().required(),
        alumni_name: Yup.string().required().min(3).max(50).label("instructors name"),
       
        alumni_desc:Yup.string().required().min(10).max(255).label("about instructor"),
        // mobile_no:Yup.number().required().label("mobile no").min(10).max(11),
        alumni_achievement:Yup.string().min(4).max(100).required(),
        rating: Yup.number().required(),
        modified_remarks: Yup.string().max(100).min(4).required().label('modified remarks')

    })
    const handleSubmit = (values,submitProps)=>{        
        values.modified_by =localStorage.getItem('username');        
        dispatch(editAlumni(values));
        submitProps.setSubmitting(false) 
        setRefetch(prevState => !prevState)       
    }
    
  return (
    <FormPopUp openPopup={openPopUP} setOpenPopup={setOpenPopUp} title="Edit Alumni">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(                    
                    <Form>                        
                        <div className='form-group item'>
                                <label className='required-label'>Alumni Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="alumni_name" id="alumni_name" placeholder="alumni name" className='form-control'/>
                                <ErrorMessage name="alumni_name" component={TextError} />
                                {errors?.alumni_name && <div className="errors">{errors?.alumni_name}</div>}
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Alumni Achievement<span className="errors">*</span>:</label>
                                <FastField  name="alumni_achievement" id="alumni_achievement" placeholder="email" className='form-control'/>
                                <ErrorMessage name="alumni_achievement" component={TextError} />
                                {errors?.alumni_achievement && <div className="errors">{errors?.alumni_achievement}</div>}
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Rating<span className="errors">*</span>:</label>
                                <FastField type="text" name="rating" id="rating" placeholder="rating" className='form-control'/>
                                <ErrorMessage name="rating" component={TextError} />
                                {errors?.rating && <div className="errors">{errors?.rating}</div>}
                        </div>                        
                        <div className='form-group item'>
                                <label className='required-label'>About Alumni<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="alumni_desc" id="alumni_desc" placeholder="about alumni" className='form-control'/>
                                <ErrorMessage name="alumni_desc" component={TextError} />
                                {errors?.alumni_desc && <div className="errors">{errors?.alumni_desc}</div>}
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="courses" component={TextError} />
                                    {errors?.courses && <div className="errors">{errors?.courses}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Modified Remarks<span className="errors">*</span>:</label>
                                <FastField type="text" name="modified_remarks" id="modified_remarks" placeholder="modified remarks" className='form-control'/>
                                <ErrorMessage name="modified_remarks" component={TextError} />
                                {errors?.modified_remarks && <div className="errors">{errors?.modified_remarks}</div>}
                            </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}Save</button>                    
                    </Form>
                    
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default AlumniEdit