import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import Select from 'react-select';
import * as Yup from 'yup'
import TextError from '../../TextError';
import { ADYEAR, NEPALIYEAR } from '../Calendar/NPYear';
import { useDispatch, useSelector } from 'react-redux';
import { getBatchSelect,  updateTagBatch } from '../../store/EnrollStore';
import Spinner from '../Layout/Spinner';
function BatchTagEdit({course,studentList,course_id,years,batch_id}) {
    const [courses,setCourse] = useState(course_id);
    const filterBatch = useSelector(state=>state.entities.enroll?.batchSelect?.data)
    const isLoading = useSelector(state=>state.entities.enroll?.loading)
    const [year,setYear] = useState(years)
    const [batch,setBatch] = useState(batch_id)
    const dispatch = useDispatch()
    const onChange = (bsDate) => {
		setYear(bsDate);
        if(courses){
        dispatch(getBatchSelect({'year':bsDate,'courses':courses}))
        }        
	};
    const handleCourseClick = (id)=>{
        setCourse(id) 
        if(year){
        dispatch(getBatchSelect({'year':year,'courses':id}))
        }       
    }
    const initialValues = {
        year:year,
        courses:courses,
        group:batch,
        student_list:studentList
    }
    const validationSchema = Yup.object().shape({
        year: Yup.string().required(),
        courses: Yup.string().required(),
        group: Yup.string().required()
    })
    const handleBatch = (value)=>{
        setBatch(value)
    }
    const handleSubmit = (values)=>{
        dispatch(updateTagBatch(values))
    }
  return (
    
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    enableReinitialize
    >
        {
            ()=>(
                <Form>
                    <div className='row'>
                        <div className='form-group col-md-8'>
                        <label className='required-label'> select course<span className="errors">*</span></label>
                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                            <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                    }
                        </Field>
                        <ErrorMessage name="courses" component={TextError} />
                        </div>
                        </div>
                        <div className='row'>
                        <div className='form-group col-md-8'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="year" component={TextError}/>
                            </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-md-8'>
                        <label className='required-label'> select batch<span className="errors">*</span></label>
                        <Field as="select" name="group" id="group" placeholder="" className='form-control' value={batch}  onChange={(e)=>handleBatch(e.target.value)} >
                            <option name="group" value="">select Options</option>
                                    {
                                        filterBatch && filterBatch?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.batch_name}</option>
                                            ))
                                    }
                        </Field>
                        <ErrorMessage name="group" component={TextError} />
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-success btn-flat" type="submit" disabled={isLoading}>
                            {isLoading && <><Spinner/>&nbsp;</>} save
                        </button>
                    </div>
                </Form>
            )
        }
    </Formik>
  )
}

export default BatchTagEdit