import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  Route, Switch } from 'react-router-dom'
import { getDesignationAll } from '../../store/DesignationSetupStore'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import DesignationAdd from './DesignationAdd'
import DesignationEdit from './DesignationEdit'
import DesignationTree from './DesignationTree'

function DesignationSetup() {
    const designation = useSelector(state =>state.entities.designation.result.data)
    const loading = useSelector(state =>state.entities.designation.loading)
    const dispatch = useDispatch()
   
    useEffect(()=>{
        dispatch(getDesignationAll())
    },[]);
    return (
        <div>
            <BodyContainer
            pageTitle="Designation Setup"
            buttonTitle="Cancel"
            >
                <div className="row">
                    <div className="col-md-6">                    
                    <DesignationTree data={designation} loading={loading}/>
                    </div>
                    <div className="col-md-6">
                        <Switch>
                            <Route path="/designation/addDesignation/:slug">
                                    <DesignationAdd/>
                            </Route>
                            <Route path="/designation/editDesignation/:slug">
                                    <DesignationEdit/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </BodyContainer>
        </div>
    )
}

export default DesignationSetup
