import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loadCourseSelect } from '../../store/CourseSetup';
import { addFeatureTag } from '../../store/FeatureSetupStore';
import TextError from '../../TextError';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function TagFeature({openPopup,setOpenPopup,feature}) {
    const dispatch = useDispatch();
    const course = useSelector(state=>state.entities.courses?.result?.data);
    const errors = useSelector(state=>state.entities.feature?.error?.data);
    const loading = useSelector(state=>state.entities.feature?.loading);
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const initialValues = {
        features:feature?.iid,
        courses:''
    }
    const validationSchema = Yup.object().shape({
        courses:Yup.string().required()
    })
    const handleSubmit = (values,submitProps)=>{
            dispatch(addFeatureTag(values));
            submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp title="Tag Feature" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        {errors?.non_fields_errors && <div className='errors'>features has been already tagged with the courses if not found in the list please check the restore menu </div>}
                        <div className='form-group'>
                            <label>Select Course :</label><span className='errors'>*</span>
                            <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="courses" component={TextError} />
                                    {errors?.courses && <div className="errors">{errors?.courses}</div>}
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {
                                isSubmitting?<Spinner/>:''
                            }
                            <i className='fa fa-tag'/>&nbsp;Tag
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default TagFeature