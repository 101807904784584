import React, { useState } from 'react'
import SelectField from '../Form/SelectField'
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
// import * as Yup from 'yup';
import Card from '../Layout/Card';
import { ErrorMessage, FastField, Form, Formik } from 'formik';
import TextError from '../../TextError';
function ServiceIntegrationInfo({handleSubmit,prev,data}) {
    const [,setJoiningDateEng] = useState('')
    const [,setJoiningDateNep] = useState('')
    const [initialValue,setInitialValue] = useState(data)
    const [count,setCount] = useState(0)
    const handleDate = ({bsDate,adDate})=>{
        setCount(count+1);
            setJoiningDateEng(adDate);
            setJoiningDateNep(bsDate);
            if(count>0)
            setInitialValue({
                ...initialValue,                
                joining_date_nep:adDate,
                joining_date_eng:bsDate
            
            })
    }
    // const initialValues= {
    //     branch:"",
    //     designation: "",
    //     department:"",
    //     employment_status:"",
    //     joining_date_nep:joiningDateNep,
    //     joining_date_eng:joiningDateEng,
    // }
    // const validationSchema = Yup.object().shape({
    //     curr_branch_id: Yup.string().required().label("Branch"),
    //     curr_designation_id: Yup.string().required().label("Department"),
    //     curr_department_id:Yup.string().required().label("Designation"),
    //     employment_status:Yup.string().required().label("Employment Status"),
    //     joining_date_nep:Yup.string().required().label("Joining Date (BS)"),
    //     joining_date_eng:Yup.string().required().label("Joining Date English(A.D.)"),

    // })
   
    return (
        <Card cardTitle="Service Integration Info">
            
                <Formik 
                initialValues={initialValue}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                       {({values})=>(
                           <Form>
                        <div className="row">
                            <SelectField
                            divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                            label="Branch"
                            name="branch"
                            requiredField="required"
                            />
                            <SelectField
                            divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                            label="Department"
                            name="department"
                            requiredField="required"
                            />
                            <SelectField
                            divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                            label="Designation"
                            name="designation"
                            requiredField="required"
                            />
                            <SelectField
                            divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                            label="Employment Status"
                            name="employment_status"
                            requiredField="required"
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                                <label>Joining Date(B.S.)<span className="errors">*</span></label>
                                <Calendar
                                name="joining_date_nep"
                                theme="default"
                                language="en"
                                className="form-control"
                                onChange={handleDate}
                                placehoder="Joining Date(B.S.)"
                                hideDefaultValue= {true}
                                />
                            </div>
                            <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                                <label>Joining Date(A.D.):</label>
                                <FastField name="joining_date_eng" value={initialValue.joining_date_eng}  className="form-control" placehoder="Joining Date"/>
                                <ErrorMessage name="joining_date_eng" component={TextError}/>
                            </div>
                        </div>
                                          
                <button className="btn btn-success btn-flat" onClick={()=>prev(values)}>Prev</button>
                <button className="btn btn-success btn-flat" >Next</button>
                </Form>
                )}
                </Formik>
        </Card>
    )
}

export default ServiceIntegrationInfo
