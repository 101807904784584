import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BodyContainer from '../DashboardTemplate/BodyContainer';
import ReactDataTable from '@ashvin27/react-datatable';
import './QuizTagSetup.css';
import { quizUnTagReport, } from '../../store/QuizSetupStore';
import MathJax from 'mathjax3-react';
import { Link } from 'react-router-dom';
import QuizTag from './QuizTag';
import QuizDetails from './QuizDetails';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
// import { MathJaxContext,MathJax } from 'better-react-mathjax';
function UntaggedQuizReport() {   
    const [openPopUp,setOpenPopUp] = useState(false);
    const [openViewPopUp,setViewOpenPopUp] = useState(false);
    const [question,setQuestion] = useState();
    const dispatch = useDispatch();
    const quizData = useSelector(state => state.entities.quiz.untaggedQuiz?.data);
    const loading = useSelector(state => state.entities.quiz?.loading);
    useEffect(()=>{
        dispatch(quizUnTagReport())
    },[])

  
    const clickTagQuiz=(data)=>{
        setQuestion(data);
        setOpenPopUp(true);
    }
    const clickTagViewQuiz=(data)=>{        
        setQuestion(data);
        setViewOpenPopUp(true);
    }
    const COLUMNS = [
        {
            key:'question',
            text:'Question',
            maxWidth:650,
            cell : record => {
                return(
                    <>                   
                        <MathJax.Html html={record?.question}/>                    
                    </>
                )
            }
        },
        {
            key:'correct_option',
            text:'Correct Options',
            cell : record => {
                return(
                    <>                   
                        <MathJax.Html html={record?.correct_option}/>                      
                    </>
                )
            }
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <Link
                            className="btn btn-success btn-sm"                           
                            style={{marginRight: '5px'}}
                            to='#'
                            onClick={()=> clickTagQuiz(record)}
                            >
                            <i className="fa fa-plus">&nbsp;&nbsp;Tag Quiz</i>
                        </Link>
                        {/* <button 
                            className="btn btn-danger btn-sm" 
                            onClick={() => console.log(record)}>
                            <i className="fa fa-trash"></i>
                        </button>
                        */}
                        <Link 
                            to='#'
                            style={{marginLeft:5}}
                            className="btn btn-primary btn-sm" 
                            onClick={()=>clickTagViewQuiz(record)}
                            >                            
                            <i className="fa fa-eye">&nbsp;&nbsp;view</i>
                        </Link> 
                    </>
                );
            }
        }
    ]
   
  return (   
      
    <BodyContainer pageTitle="Untagged Quiz Details">
        {loading && <LoaderSpinner/>}
        <ReactDataTable
        columns = {COLUMNS}
        records={quizData}        
        />
        {
            openPopUp &&
        <QuizTag openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} question={question}/>
        }
        {
            openViewPopUp &&
        <QuizDetails openPopUp={openViewPopUp} setOpenPopUp={setViewOpenPopUp} question={question}/>
        }
    </BodyContainer>    
  )
}

export default UntaggedQuizReport