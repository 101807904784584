import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deactivateModelSet } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'

function DeactivateModelset({openPopup,setOpenPopup,modelset}) {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.quiz?.loading)

    const initialValues = {
        modelset_id: modelset?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(deactivateModelSet(values));
        submitProps.setSubmitting(false)
    }
  return (
    <FormPopUp setOpenPopup={setOpenPopup} openPopup={openPopup} title="Activate Model">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>
                        Deactivate this modelset {modelset?.modelset_name}?
                        </label><br/>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}&nbsp; <i className='fa fa-key'/> Deactivate
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default DeactivateModelset