import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector  } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAlumniDetails } from '../../store/AlumniSetupStore'
import Card from '../Card/Card'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import ImageViewer from '../Form/ImageViewer'
import AlumniImgUpdate from './AlumniImgUpdate'

function ViewAlumniDetails() {
    const [data,setData] = useState();
    const [openPopUp,setOpenPopUp] = useState(false);
    const params = useParams()
    const dispatch = useDispatch()
    const alumni = useSelector(state => state.entities.alumni.getDataById.data)
    
    useEffect(()=>{
      dispatch(getAlumniDetails(params.slug));
    },[params])

    const handleClick = (id)=>{
      setOpenPopUp(true);
      setData(id)
    }
  return (
    <>
    <BodyContainer pageTitle="View Alumni Details" buttonTitle="cancel"  route="/alumni">
     <Card cardTitle="View Alumni Details">
       <div className='card-body'>
          <label>Course Name</label>&nbsp; : &nbsp;<span>{alumni?.course_name?alumni?.course_name:'N/A'}</span><br/>
          <label>Alumni Name</label>&nbsp; : &nbsp;<span>{alumni?.alumni_name?alumni?.alumni_name:'N/A'}</span><br/>
          <label>Alumni Achievement</label>&nbsp; : &nbsp;<span>{alumni?.alumni_achievement?alumni?.alumni_achievement:'N/A'}</span><br/>
          <label>Alumni Img</label>&nbsp; : &nbsp;<ImageViewer image={alumni?.alumni_img}/><button className='btn btn-flat btn-success' type='button' onClick={()=>handleClick(alumni?.iid)}><i className='fa fa-edit'>&nbsp;Edit</i></button><br/>
          <label>Alumni Description</label>&nbsp; : &nbsp;<span>{alumni?.alumni_desc?alumni?.alumni_desc:'N/A'}</span><br/>
          <label>Rating</label>&nbsp; : &nbsp;<span>{alumni?.rating?alumni?.rating:'N/A'}</span><br/>
          <label>created by</label>&nbsp; : &nbsp;<span>{alumni?.created_by?alumni?.created_by:'N/A'}</span><br/>
          <label>created date</label>&nbsp; : &nbsp;<span>{alumni?.created_date?alumni?.created_date:'N/A'}</span><br/>
          <label>modified by</label>&nbsp; : &nbsp;<span>{alumni?.modified_by?alumni?.modified_by:'N/A'}</span><br/>
          <label>modified date </label>&nbsp; : &nbsp;<span>{alumni?.modified_date?alumni?.modified_date:'N/A'}</span><br/>
          <label>modified remarks</label>&nbsp; : &nbsp;<span>{alumni?.modified_remarks?alumni?.modified_remarks:'N/A'}</span><br/>
       </div>
    </Card>
    {
      openPopUp &&
      <AlumniImgUpdate openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} alumni={data}/>
    }
    </BodyContainer>
    </>
  )
}

export default ViewAlumniDetails