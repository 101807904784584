import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { editSubCourseIcon,getSubCourseDetails } from '../../store/SubCourseSetup'
import Card from '../Card/Card'
import ImageViewer from '../Form/ImageViewer'
import Edit from '@material-ui/icons/Edit'
import FormPopUp from '../popup/PopUp'
import { Form, Formik } from 'formik'
import ImageUploader from '../Form/ImageUploader'
import Spinner from '../Layout/Spinner';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function ViewSubCourseDetails() {
    const [openEditForm,setOpenEditForm] = useState(false); 
    const params = useParams();
    const dispatch = useDispatch();
    const subcourse = useSelector(state => state.entities.subcourse?.result?.data);
    const loading = useSelector(state => state.entities.subcourse?.loading);
    useEffect(()=>{
      dispatch(getSubCourseDetails(params.slug))
    },[params])
    console.log("subcourse",subcourse);
    const SUPPORTED_FORMATS = [
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];
    const handleClickEdit = ()=>{
      setOpenEditForm(true)
    }
    const initialBanner = {
      subcourse_icon:''
    }
    const validateBanner = Yup.object().shape({
      subcourse_icon: Yup.mixed().required().test(
        "fileFormat",
        "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
        value => value && SUPPORTED_FORMATS.includes(value.type))
    })
    const handleBannerSubmit = (values,submitProps)=>{
      let formData = new FormData()
      formData.append('subcourse_id',subcourse?.id)
      formData.append('subcourse_icon',values.subcourse_icon,values.subcourse_icon.name)
      dispatch(editSubCourseIcon(formData));
      submitProps.setSubmitting(false);
    }
  return (
    <>
    {loading && <LoaderSpinner/>}
    <Card cardTitle="Subcourse Details">
      <div className='card-body'>
        <label>Category Name</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.category_name}</span><br/>
        <label>Course Name</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.course_name}</span><br/>
        <label>Subcourse Name</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.subcourse_name}</span><br/>
        <label>Subcourse Code</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.subcourse_code}</span><br/>
        <label>Subcourse Preview Url</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.subcourse_preview_url}</span> <br/>
        <label>Subcourse Short Description</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.subcourse_short_description}</span><br/>
        <label>Subcourse Icon</label>&nbsp; : &nbsp;<ImageViewer image={subcourse && `${'/media/'+subcourse?.subcourse_icon}`}/><button className='btn btn-success btn-flat' onClick={handleClickEdit}><Edit/>Edit</button><br/>
        <label>Created By</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.created_by}</span><br/>
        <label>Created Date</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.created_date}</span><br/>
        <label>Modified By</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.modified_by?subcourse?.modified_by:'N/A'}</span><br/>
        <label>Modified Date</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.modified_date?subcourse?.modified_date:'N/A'}</span><br/>
        <label>Modified Remarks</label>&nbsp; : &nbsp;<span>{subcourse && subcourse?.modified_remarks?subcourse?.modified_remarks:'N/A'}</span><br/>
      </div>
    </Card>
    <FormPopUp title="Edit Subcourse Icon" openPopup={openEditForm} setOpenPopup={setOpenEditForm}>
      <Formik
      initialValues={initialBanner}
      validationSchema={validateBanner}
      onSubmit={handleBannerSubmit}
      enableReinitialize
      >
        {
          ({isSubmitting})=>(
            <Form>
              <ImageUploader
              name="subcourse_icon"
              label="SubCourse Icon"
              accept="image/jpg, image/gif,image/jpeg,image/png"
              required="required"
              />
             <button className='btn btn-success btn-flat' disabled={isSubmitting?'disabled':''}>{isSubmitting?<Spinner/>:''}Change</button>
            </Form>
          )
        }
      </Formik>
    </FormPopUp>
    </>
  )
}

export default ViewSubCourseDetails