var CryptoJS = require("crypto-js");
var encryptedData 
let decryptedData
export const getDecryptedData = ()=>{
    encryptedData = localStorage.getItem('Block:Usr')
    if(encryptedData){
        
        var bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SECRET);
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    return decryptedData
}
if(encryptedData){
   getDecryptedData()
}

export const is_admin =()=>{
    let decrypt = getDecryptedData()
    return decrypt?.is_admin
} 
export const get_user_mail =()=>{
    let decrypt = getDecryptedData()
    return decrypt?.email;
} 
export const is_staff =()=>{
    let decrypt = getDecryptedData()
    console.log("get",decrypt?.is_staff)
    return decrypt?.is_staff
} 
export const is_teacher =()=>{
    let decrypt = getDecryptedData()
    return decrypt?.is_teacher
}
export const get_user_name=()=>{
    let decrypt = getDecryptedData()
    let middle_name = decrypt?.middle_name?decrypt?.middle_name:''
    return decrypt?.first_name+" "+ middle_name +" "+decrypt?.last_name
} 

export const get_refresh_token=()=>{
    let decrypt = getDecryptedData();    
    return decrypt?.tokens?.refresh;
}
export const get_access_token=()=>{
    let decrypt = getDecryptedData();   
    return decrypt?.tokens?.access;
}