import {  Field, FieldArray, Form, Formik } from 'formik'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { SkillLevel } from '../../Constants';
import Card from '../Layout/Card';

function SkillInfo({handleSubmit,prev,data}) {
    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    // const initialValues = {
    //     skills : [
    //         {
    //             skill_name: "",
    //             skill_level: ""
    //         }
    //     ]
    // }
    return (
        <Card cardTitle="Skill Info">          
                                <div className="table-responsive">
                               
                                    <Formik
                                     initialValues={data}
                                     onSubmit={handleSubmit}
                                    >
                                    {
                                    ({values,errors})=>(
                                    <Form>
                                        <FieldArray
                                        name="skills"
                                        render = {arrayHelpers => (
                                            <>                                            
                                                {
                                                    <table className="table m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>S.N</th>
                                                                <th>Skills Name</th>
                                                                <th>Skill Level</th>
                                                            </tr>
                                                        </thead>
                                                   <tbody>
                                                       {
                                                    values.skills && values.skills.length > 0 ?(
                                                    values.skills.map((skill,index)=>(
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <Field 
                                                                name={`skills.[${index}].skill_name`}
                                                                className="form-control"
                                                                placeholder="Skills Name"                                                                
                                                                />                                                               
                                                            </td>
                                                            <td>
                                                                <Field
                                                                as="select"
                                                                name={`skills.[${index}].skill_level`}
                                                                className="form-control"
                                                                >
                                                                    {
                                                                        <>
                                                                        <option value="">Select Skill Level</option>
                                                                      { SkillLevel && SkillLevel.map((item,index)=>(
                                                                           <option key={item.value} value={item.value}>{item.label}</option>
                                                                       ))
                                                                      }
                                                                       </>
                                                                    }
                                                                </Field>                                                                
                                                            </td>
                                                            <td>
                                                            { 
                                                        index>0?
                       
                                                            <button
                                                                type="button"
                                                                //  className="btn btn-flat btn-sm btn-danger"
                                                                style={buttonStyles}
                                                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                            >                           
                                                                <DeleteIcon/>
                                                            </button>
                                                            :null
                                                                }
                                                            <button
                                                                type="button"
                                                                style={buttonStyles}
                                                                //  className="btn btn-flat btn-sm btn-success"
                                                                onClick={() => arrayHelpers.push({skill_name:'',skill_level:''})} // insert an empty string at a position
                                                            >
                                                                <AddIcon/>
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    ):(
                                                        <button className="btn btn-success btn-flat" type="button" onClick={() => arrayHelpers.push({skill_name:'',skill_level:''})}>
                                                          {/* show this when user has removed all company_contact from the list */}
                                                          Add Skills
                                                        </button>
                                                      )
                                                }
                                                      </tbody>
                                                      </table>
                                                }
                                            </>
                                        )
                                       
                                        }
                                        />
                                        <button className="btn btn-success btn-flat" onClick={prev}>Prev</button>
                                        <button className="btn btn-success btn-flat">Next</button>
                                        </Form>
                                    )
                                }
                                        </Formik>
                                   
                                </div>                           
                       
                
            
        </Card>
    )
}

export default SkillInfo
