import React from 'react'
import {Form,Field,Formik,FieldArray,ErrorMessage, FastField} from 'formik';
import CkeditorField from '../Form/CkeditorField';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import { useDispatch,useSelector } from 'react-redux';
import { addQuiz } from '../../store/QuizSetupStore';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import TextError from '../../TextError';
import * as Yup from 'yup';
function QuizAdd() {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.quiz.loading)
    const handleSubmit=(values)=>{
        values.created_by = localStorage.getItem('username');
        dispatch(addQuiz(values));
    }
    const initialValues={       
        question: '',
        correct_option:'',
        answer_desc:'',
        opiton_description:'',
        has_video_solution:'', 
        video_solution:'',
        app_video_soluttion:'',       
        quiz_options:[{
            options:'',
            correct:'',    
        }]
    }
    const validationSchema = Yup.object().shape({
        video_solution:Yup.string().when("has_video_solution",{
            is:(pageType)=> pageType === 'y',
            then:Yup.string().required().label('video url')
        }),
        app_video_solution:Yup.string().when("has_video_solution",{
            is:(pageType)=> pageType === 'y',
            then:Yup.string().required().label('app video url')
        }),

    })
    const has_video_solution = [
        {
            'value':'y',
            'label':'True',
        },
        {
            'value':'n',
            'label':'False',
        },
        
    ]
    return (
        <BodyContainer pageTitle="Quiz Add" buttonTitle="Cancel" route="/quizsetup">
            {loading && <LoaderSpinner/>}
            <div className="row">
        <div className="col-md-8">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Quiz Add</h3>
                </div>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {formik => (
                        <Form>
                            <div className="card-body">                            
                            <div className="form-group">
                                <label>Question<span className="errors">*</span>:</label>
                                <CkeditorField name="question"/>
                            </div> 
                            <div className="form-group">
                                <label>Correct Options<span className="errors">*</span>:</label>                                
                                <CkeditorField name={`correct_option`}/>
                            </div> 
                            <div className="form-group">
                                <label>Answer Explanation<span className="errors">*</span>:</label>                                
                                <CkeditorField name={`answer_desc`}/>
                            </div> 
                            <div className='form-group item'>
                                <label className='required-label'>has_video_solution?<span className='errors'>*</span> </label>
                                <Field as="select" name="has_video_solution" id="has_video_solution" placeholder="" className='form-control'>
                                    <option name="has_video_solution" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        has_video_solution && has_video_solution?.map((item,index)=>(
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="has_video_solution" component={TextError} />
                                {/* {errors?.preview_status && <div className='errors'>{errors?.preview_status}</div>} */}
                            </div>
                            {
                                formik.values?.has_video_solution === 'y'?
                                <>
                                <div className='form-group item'>
                                <label className='required-label'>video url<span className='errors'>*</span></label>
                                <FastField type="text" name="video_solution" id="video_solution" placeholder="url https://" className='form-control'/>
                                <ErrorMessage name="video_solution" component={TextError} />
                                {/* {errors?.video_url && <div className='errors'>{errors?.video_url}</div>} */}
                                </div>
                                <div className='form-group item'>
                                    <label className='required-label'>App video url<span className='errors'>*</span></label>
                                    <FastField type="text" name="app_video_solution" id="app_video_solution" placeholder="url https://" className='form-control'/>
                                    <ErrorMessage name="app_video_solution" component={TextError} />
                                    {/* {errors?.app_video_url && <div className='errors'>{errors?.mob_video_url}</div>} */}
                                </div>
                                </>
                                :''
                            }

                            {
                               formik.values.quiz_options && formik.values.quiz_options.map((_,index)=>(
                                   <FieldArray
                                   name="quiz_options"
                                   render={arrayHelpers=>(

                                    <div className="row">
                                        <div className=" col-md-5 col-xs-12 col-sm-12 form-group">
                                            <label>options{index+1}<span className="errors">*</span>:</label>
                                            <CkeditorField name={`quiz_options[${index}].options`}/>

                                        </div>
                                        <div className="col-md-4 col-xs-12 form-group">
                                            <label>Is Option?<span className="errors">*</span></label>
                                            <Field as="select" name={`quiz_options[${index}].correct`} className="form-control">
                                                    <option value="">Is Option?</option>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                            </Field>
                                        </div>
                                        <div className="form-group">
                                            <label>&nbsp;</label><br/>
                                            {
                                                index > 0 &&(
                                                    <button className="btn btn-flat btn-danger" type='button' onClick={()=>arrayHelpers.remove(index)}>-</button>
                                                )
                                            }
                                                <button className="btn btn-flat btn-success" type='button' onClick={()=>arrayHelpers.push({options:'',correct:''})}>
                                                +
                                                </button>
                                        </div>
                                    </div>
                                   )}
                                   
                                   />                                   
                                ))
                            }
                           

                            <button className="btn btn-flat btn-success">Save</button>
                            </div>                           
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
            </div>
        </BodyContainer>
    )
}

export default QuizAdd
