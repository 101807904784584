import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCategoryBySlug } from '../../store/CategorySetup'
import Card from '../Card/Card'

function ViewCourseCategory() {
    const params = useParams();
    const dispatch = useDispatch();
    const course_category = useSelector(state=>state.entities.categorys.result?.data)
    useEffect(()=>{
      dispatch(getCategoryBySlug(params.slug))
    },[params])
  return (
    <>
    <Card cardTitle="Course Category Details">
        <div className='card-body'>
            <label>Category Title</label>&nbsp;:&nbsp;<span>{course_category?.category_name}</span><br/>
            <label>Category Code</label>&nbsp;:&nbsp;<span>{course_category?.course_categories_code}</span><br/>
            <label>Created By</label>&nbsp;:&nbsp;<span>{course_category?.created_by}</span><br/>
            <label>Created Date</label>&nbsp;:&nbsp;<span>{course_category?.created_date}</span><br/>
            <label>Modified By</label>&nbsp;:&nbsp;<span>{course_category?.modified_by?course_category?.modified_by:'N/A'}</span><br/>
            <label>Modified Date</label>&nbsp;:&nbsp;<span>{course_category?.modified_date?course_category?.modified_date:'N/A'}</span><br/>
            <label>Modified Remarks</label>&nbsp;:&nbsp;<span>{course_category?.modified_remarks?course_category?.modified_remarks:'N/A'}</span><br/>
        </div>
    </Card>
    </>
  )
}

export default ViewCourseCategory