import React from 'react'
import { Link } from 'react-router-dom';

function BodyContainer({pageTitle,children,buttonTitle,route,onClick}) {
    return (
        <>
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">
                                {pageTitle}
                            </h1>
                        </div>
                        {buttonTitle && <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {
                                    onClick?
                                    <Link to='#' className="btn btn-flat btn-success" onClick={onClick}>{buttonTitle}</Link>:
                                <Link to={`${route}`} className="btn btn-flat btn-success">{buttonTitle}</Link>
                                }
                            </ol>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="content">
                {/* <div className="row">                    */}
                    {children}                    
                {/* </div> */}
            </div>
        </div>
        </>
    )
}

export default BodyContainer
