import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import requests from "../ApiRequests/Request";
import { AuthHeader } from "../Config/HeaderType";
import { toast } from "react-toastify";

const slice = createSlice({
    name:'quiz',
    initialState:{
        result : [],
        getData:[],
        getDataById:[],
        modelset:[],
        modelsetReport:[],
        passage:[],
        searchResult:[],
        liveQuizSearchResult:[],
        tagResult:[],
        untaggedQuiz:[],
        paragraphModelset:[],
        filterparagraphModelset:[],
        errors:[],
        quizLevel:[],
        batchModelset:[],
        modelsetSelect:[],
        modelsetFilter:[],
        loading:false
    },
    reducers:{
        quizRequested:(quiz,action) => {
            quiz.loading = true;
            quiz.searchResult=[];
            quiz.liveQuizSearchResult=[];
            quiz.result = [];
        },
        quizReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.result = action.payload;
        },
        quizDeleteRequested:(quiz)=>{
            quiz.loading = true;
        },
        passageFilterRequested:(quiz)=>{
            quiz.loading = true;
        },
        quizDeleteReceived: (quiz,action) => {
            quiz.loading = false;
            if (action.payload?.msg)
            toast.success(action.payload?.msg)
            quiz.result = quiz.result?.data?.filter(obj=> obj?.id !== action?.payload?.data)
        },
        quizTagReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.tagResult = action.payload;
        },
        quizTagSearchReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.searchResult = action.payload
        },
        passageSearchReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.filterparagraphModelset = action.payload
        },
        quizLiveSearchReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.liveQuizSearchResult = action?.payload
        },
        quizUnTagReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.untaggedQuiz = action.payload;
        },
        quizModelsetReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.modelset = action.payload;
        },
        quizModelsetReportReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.modelsetReport = action.payload;
            if (action.payload?.msg)
            toast.success(action.payload?.msg)
        },
        quizPassageReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.passage = action.payload;
        },
        quizLevelRequested: (quiz,action) => {
            quiz.loading = false;
            // quiz.quizLevel = action.payload;
        },
        quizLevelReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.quizLevel = action.payload;
        },
        quizPassageModelsetReceived: (quiz,action) => {
            quiz.loading = false;
            quiz.paragraphModelset = action.payload;
        },
        quizReceivedById:(quiz,action)=>{
            quiz.loading = false;            
            quiz.getDataById = action.payload;
        },
        quizRequestedFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors = action.payload
            toast.error(action.payload?.msg)
        },
        quizSearchFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.searchResult = []
            quiz.errors = action.payload        
        },
        quizAdded:(quiz,action)=>{
            quiz.loading = false;
            if(quiz.errors) quiz.errors=[]
            toast.success(action.payload?.msg)
        },
        batchModelsetTagRequested:(quiz)=>{
            quiz.loading = true;
            quiz.batchModelset = [];
        },
        batchModelsetTagReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.batchModelset = action.payload;
        },
        batchModelsetTagFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors = action.payload;
        },
        passageFiltereFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors = action.payload;
        },
        batchModelsetTagActionRequested:(quiz)=>{
            quiz.loading = true;
            
        },
        batchModelsetTagActionReceived:(quiz,action)=>{
            quiz.loading = false;
            toast.success(action.payload?.msg)
        },
        batchModelsetTagActionFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors = action.payload;
            toast.error(action.payload?.msg)
        },
        modelsetRequested:(quiz)=>{
            quiz.loading = true;
            quiz.modelsetSelect = [];
        },
        modelsetReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.modelsetSelect = action?.payload;
        },
        modelsetRequestFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors=action?.payload;
        },
        modelsetFilterRequested:(quiz)=>{
            quiz.loading = true;
            quiz.modelsetFilter = [];
        },
        modelsetFilterReceived:(quiz,action)=>{
            quiz.loading = false;
            quiz.modelsetFilter = action?.payload;
        },
        modelsetFilterRequestFailed:(quiz,action)=>{
            quiz.loading = false;
            quiz.errors=action?.payload;
        },
    }
})

const {
    quizRequested,
    quizReceived,
    quizReceivedById,
    quizTagSearchReceived,
    quizLiveSearchReceived,
    quizRequestedFailed,
    quizLevelRequested,
    quizAdded,
    quizSearchFailed,
    quizTagReceived,
    quizUnTagReceived,
    quizModelsetReceived,
    quizModelsetReportReceived,
    quizPassageReceived,
    quizPassageModelsetReceived,
    quizLevelReceived,
    batchModelsetTagActionFailed,
    batchModelsetTagActionReceived,
    batchModelsetTagActionRequested,
    batchModelsetTagFailed,
    batchModelsetTagReceived,
    batchModelsetTagRequested,
    modelsetRequested,
    modelsetReceived,
    modelsetRequestFailed,
    modelsetFilterReceived,
    modelsetFilterRequestFailed,
    modelsetFilterRequested,
    quizDeleteReceived,
    quizDeleteRequested,
    passageSearchReceived,
    passageFilterRequested,
    passageFiltereFailed
} = slice.actions;

export default slice.reducer;

export const getQuizAll = (offset,limit)=>apiCallBegan({
    url: requests.getQuizAll+'/'+offset+'/'+limit,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceived.type,
    onError: quizRequestedFailed.type
})

export const addQuiz = (data)=>apiCallBegan({
    url: requests.createQuiz,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const deleteQuiz = (data)=>apiCallBegan({
    url: requests.deleteQuiz +'/'+data,
    method:'DELETE',    
    headers:AuthHeader,
    onStart: quizDeleteRequested.type,
    onSuccess: quizDeleteReceived.type,
    onError: quizRequestedFailed.type
})
export const tagQuiz = (data)=>apiCallBegan({
    url: requests.quizTag,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const editQuiz = (data)=>apiCallBegan({
    url: requests.editQuiz,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const quizTagDelete = (data)=>apiCallBegan({
    url: requests.deleteTagToModelset,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const QuizTagDeleteToParagraph = (data)=>apiCallBegan({
    url: requests.deleteTagToParagraph,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const QuizTagRestoreToParagraph = (data)=>apiCallBegan({
    url: requests.restoreTagPassageModelset,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const editQuizTag = (data)=>apiCallBegan({
    url: requests.quizTagUpdate,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const activateLessonQuiz = (data)=>apiCallBegan({
    url: requests.activateLessonQuiz,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const deactivateLessonQuiz = (data)=>apiCallBegan({
    url: requests.deactivateLessonQuiz,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const searchQuiz = (data)=>apiCallBegan({
    url: requests.searchQuestion,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizTagSearchReceived.type,
    onError: quizSearchFailed.type
})
export const filterPassageModelset = (data)=>apiCallBegan({
    url: requests.filterPassageModelset,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: passageFilterRequested.type,
    onSuccess: passageSearchReceived.type,
    onError: passageFiltereFailed.type
})
export const viewQuizDetails = (data)=>apiCallBegan({
    url: requests.getQuizById+'/'+data,    
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizReceivedById.type,
    onError: quizRequestedFailed.type
})
export const viewTagQuizDetails = (data)=>apiCallBegan({
    url: requests.viewQuizTagDetails+'/'+data,    
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizReceivedById.type,
    onError: quizRequestedFailed.type
})

export const quizTagReport = (data)=>apiCallBegan({
    url: requests.quizTagReport,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizTagReceived.type,
    onError: quizRequestedFailed.type
})
export const quizUnTagReport = ()=>apiCallBegan({
    url: requests.getUntaggedQuizDetails,
    method:'GET',    
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizUnTagReceived.type,
    onError: quizRequestedFailed.type
})
export const lessonTagReport = (data)=>apiCallBegan({
    url: requests.filterLessonQuiz,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizTagReceived.type,
    onError: quizRequestedFailed.type
})

//modelset
export const getModelSetAll = ()=>apiCallBegan({
    url: requests.getModelsetAll,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizModelsetReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelSetById = (id)=>apiCallBegan({
    url: requests.getModelsetById+'/'+id,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError: quizRequestedFailed.type
})
export const getPassageById = (id)=>apiCallBegan({
    url: requests.getPassageById+'/'+id,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError: quizRequestedFailed.type
})
export const getPassageByCourse = (data)=>apiCallBegan({
    url: requests.filterPassageByCourse,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizPassageReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelsetByCourseYear = (data)=>apiCallBegan({
    url: requests.filterModelsetCourseyear,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizModelsetReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelsetReport = (data)=>apiCallBegan({
    url: requests.getModelsetReport,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizModelsetReportReceived.type,
    onError: quizRequestedFailed.type
})
export const addPassage = (data)=>apiCallBegan({
    url: requests.createPassage,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const editPassage = (data)=>apiCallBegan({
    url: requests.updatePassage,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const tagPassage = (data)=>apiCallBegan({
    url: requests.tagQuizToParagraph,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const addModelSet = (data)=>apiCallBegan({
    url: requests.createModelSet,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const editModelSet = (data)=>apiCallBegan({
    url: requests.updateModelSet,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const activateModelSet = (data)=>apiCallBegan({
    url: requests.activateModelSet,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const deactivateModelSet = (data)=>apiCallBegan({
    url: requests.deactivateModelSet,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const getModelSetByCourse = (data)=>apiCallBegan({
    url: requests.getModelsetByCourse,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizModelsetReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelSetByCourseYear = (data)=>apiCallBegan({
    url: requests.filterModelsetCourseyear,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizModelsetReceived.type,
    onError: quizRequestedFailed.type
})

export const getModelSetLiveAll = ()=>apiCallBegan({
    url: requests.getQuizLiveTagAll,
    onStart:quizRequested.type,
    onSuccess:quizReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelSetLiveById = (id)=>apiCallBegan({
    url: requests.getQuizLiveTagById+'/'+id,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceived.type,
    onError: quizRequestedFailed.type
})
export const getModelSetByYear = (year)=>apiCallBegan({
    url: requests.filterModelSetByYear+'/'+year,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError: quizRequestedFailed.type
})
export const getFilterModelset = (year)=>apiCallBegan({
    url: requests.filterModelSet+'/'+year,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError: quizRequestedFailed.type
})

export const addTagLive = (data)=>apiCallBegan({
    url: requests.tagQuizLive,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})

export const filterSearch = (data)=>apiCallBegan({
    url: requests.filterLiveQuizTag,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizLiveSearchReceived.type,
    onError: quizRequestedFailed.type
})
export const editTagLive = (data)=>apiCallBegan({
    url: requests.updatetagQuizLive,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const activateModelSetLive = (data)=>apiCallBegan({
    url: requests.setActiveLive,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})
export const deactivateModelSetLive = (data)=>apiCallBegan({
    url: requests.unsetLive,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: quizRequested.type,
    onSuccess: quizAdded.type,
    onError: quizRequestedFailed.type
})

export const tagParagraphToModelset = (data)=>apiCallBegan({
    url: requests.createTagPassageModelset,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const updateTagParagraphToModelset = (data)=>apiCallBegan({
    url: requests.updateTagPassageModelset,
    method:'PATCH',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const deleteTagParagraphToModelset = (data)=>apiCallBegan({
    url: requests.deleteTagPassageModelset,
    method:'PATCH',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const restoreTagParagraphToModelset = (data)=>apiCallBegan({
    url: requests.restoreTagPassageModelset,
    method:'PATCH',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const getTagParagraphToModelsetAll = (data)=>apiCallBegan({
    url: requests.getPassageTagModelset,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizPassageModelsetReceived.type,
    onError:quizRequestedFailed.type
})
export const getTagParagraphToModelsetById = (id)=>apiCallBegan({
    url: requests.getPassageTaggedModelsetById+'/'+id,
    method:'GET',
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError:quizRequestedFailed.type
})
export const getQuizModelsetDetails = (id)=>apiCallBegan({
    url: requests.getModelsetQuizDetails+'/'+id,
    method:'GET',
    headers:AuthHeader,
    onStart:quizReceivedById.type,
    onSuccess:quizModelsetReportReceived.type,
    onError:quizRequestedFailed.type
})
export const getKUQuizModelsetDetails = (id)=>apiCallBegan({
    url: requests.getModelsetKUQuizDetails+'/'+id,
    method:'GET',
    headers:AuthHeader,
    onStart:quizReceivedById.type,
    onSuccess:quizModelsetReportReceived.type,
    onError:quizRequestedFailed.type
})
export const createQuizLevel = (data)=>apiCallBegan({
    url: requests.createQuizLevel,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const updateQuizLevel = (data)=>apiCallBegan({
    url: requests.updateQuizLevel,
    method:'PATCH',
    data:data,
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizAdded.type,
    onError:quizRequestedFailed.type
})
export const getQuizLevelAll = ()=>apiCallBegan({
    url: requests.getQuizLevelAll,
    method:'GET',
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizLevelReceived.type,
    onError:quizRequestedFailed.type
})
export const getQuizLevelAllSelect = ()=>apiCallBegan({
    url: requests.getQuizLevelAllSelect,
    method:'GET',
    headers:AuthHeader,
    onStart:quizLevelRequested.type,
    onSuccess:quizLevelReceived.type,
    onError:quizRequestedFailed.type
})
export const getQuizLevelById = (id)=>apiCallBegan({
    url: requests.getQuizLevelById+'/'+id,
    method:'GET',
    headers:AuthHeader,
    onStart:quizRequested.type,
    onSuccess:quizReceivedById.type,
    onError:quizRequestedFailed.type
})
export const batchModelsetTag = (data)=>apiCallBegan({
    url: requests.createModelsetBatchTag,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:batchModelsetTagActionRequested.type,
    onSuccess:batchModelsetTagActionReceived.type,
    onError:batchModelsetTagActionFailed.type
})
export const batchModelsetTagDelete = (data)=>apiCallBegan({
    url: requests.deleteModelsetBatchTag+'/'+data,
    method:'DELETE',
    headers:AuthHeader,
    onStart:batchModelsetTagActionRequested.type,
    onSuccess:batchModelsetTagActionReceived.type,
    onError:batchModelsetTagActionFailed.type
})
export const batchModelsetTagUpdate = (data)=>apiCallBegan({
    url: requests.updateModelsetBatchTag,
    method:'PATCH',
    data:data,
    headers:AuthHeader,
    onStart:batchModelsetTagActionRequested.type,
    onSuccess:batchModelsetTagActionReceived.type,
    onError:batchModelsetTagActionFailed.type
})
export const getFilteredBatchModelset = (data)=>apiCallBegan({
    url: requests.getModelsetBatchTag,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:batchModelsetTagRequested.type,
    onSuccess:batchModelsetTagReceived.type,
    onError:batchModelsetTagFailed.type
})
export const getFilteredModelsetSelect = (data)=>apiCallBegan({
    url: requests.filterModelSet,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:modelsetRequested.type,
    onSuccess:modelsetReceived.type,
    onError:modelsetRequestFailed.type
})
export const getModelsetFilterByCourseYear = (data)=>apiCallBegan({
    url: requests.getModelSetByCoursesYear,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:modelsetFilterRequested.type,
    onSuccess:modelsetFilterReceived.type,
    onError:modelsetFilterRequestFailed.type
})
