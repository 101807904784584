import React, { useState } from 'react'
import SelectField from '../Form/SelectField'
import {MaritalStatus} from '../../Constants'
// import * as Yup from 'yup';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs'
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import Card from '../Layout/Card'
import { ErrorMessage, FastField, Form, Formik } from 'formik'
import TextError from '../../TextError'

function FamilyInfo({handleSubmit,prev,data}) {
    const [maritalStatus, setMaritalStatus] = useState('')
    const [count,setCount] = useState(0)
    // const [date, setDate] = useState("")
    const [initialValue,setInitialValue] = useState(data)
    const handleMaritalStatus = (value)=>{
        console.log(value)
        setMaritalStatus(value)

    }

    const handleDate = ({bsDate,adDate})=>{ 
        setCount(count+1) 
        if(count>0)      
        setInitialValue({
            ...initialValue,
            marriage_date_nep:bsDate,
            marriage_date_eng:adDate
        })
    }
    const handleSpouseDOBDate = ({bsDate,adDate}) => {  
        if(count>0)      
        setInitialValue({
            ...initialValue,
            spouse_dob_eng:bsDate,
            spouse_dob_nep:adDate
        })
    }
    // const initialValues={
    //     father_name:"",
    //     mother_name:"",
    //     grand_mother_name:"",
    //     grand_father_name:"",
    //     marital_status: maritalStatus,
    //     spouse_name:"",
    //     marriage_date_nep: '',
    //     marriage_date_eng: '',
    //     spouse_dob_eng:'',
    //     spouse_dob_nep:'',
    //     have_child:"",
    //     total_son:"",
    //     total_daughter:"",

    // }

    // const validationSchema=Yup.object().shape({
    //     father_name: Yup.string().required().min(2).max(50).label("Father Name"),
    //     mother_name: Yup.string().required().min(2).max(50).label("Mother Name"),
    //     grand_father_name: Yup.string().required().min(2).max(50).label("Grand Father Name")
    // })
    
    return (
        
                <Card cardTitle="Family Info">
                
                    <Formik
                    initialValues={data}
                    onSubmit={handleSubmit} 
                    enableReinitialize                   
                    >
                        {({values})=>(
                        <Form>
                <div className="row">
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Mother Name<span className="errors">*</span>:</label>
                        <FastField name={`family.mother_name`} placeholder="Mother's Name" className="form-control"/>
                        <ErrorMessage name={`initialValue?.family.mother_name`} component={TextError}/>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Father Name<span className="errors">*</span>:</label>
                        <FastField name={`family.father_name`} placeholder="Father's Name" className="form-control"/>
                        <ErrorMessage name={`family.father_name`} component={TextError}/>
                    </div>                   
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Grand Mother Name:</label>
                        <FastField name={`family.grand_mother_name`} placeholder="Grand Mother's Name" className="form-control"/>
                        <ErrorMessage name={`family.grand_mother_name`} component={TextError}/>
                    </div>                  
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Grand Father Name<span className="errors">*</span>:</label>
                        <FastField name={`family.grand_father_name`} placeholder="Grand Father's Name" className="form-control"/>
                        <ErrorMessage name={`family.grand_father_name`} component={TextError}/>
                    </div>                                                  
                </div>
                <div className="row">
                    <SelectField
                    divSize="col-md-3 col-xs-12 col-sm-12 form-group"
                    name={`family.marital_status`}
                    label="Marital Status"
                    data={MaritalStatus}
                    requiredField="required"
                    onChange={handleMaritalStatus}
                    />
                </div>
                {
                    maritalStatus === "Married" ?
                    <>
                    <div className="row">
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Spouse Name<span className="errors">*</span>:</label>
                        <FastField name={`family.spouse_name`} placeholder="Spouse Name" className="form-control"/>
                        <ErrorMessage name={`family.spouse_name`} component={TextError}/>
                    </div>
                    

                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                    <label>Marriage Date(B.S.)<span className="errors">*</span></label>
                    <Calendar
                    language="en"
                    theme="default"
                    name={`family.marriage_date`}
                    className="form-control"
                    onChange={handleDate}
                    defaultDate={initialValue.marriage_date_nep}
                    />
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Marriage Date(A.D.)<span className="errors">*</span>:</label>
                        <FastField name={`family.marriage_date_eng`} value={initialValue.marriage_date_eng} placeholder="Spouse Name" className="form-control"/>
                        <ErrorMessage name={`family.marriage_date_eng`} component={TextError}/>
                    </div>
                    
                    
                    </div>
                    <div className="row">
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                    <label>Spouse DOB(B.S.):</label>
                    <Calendar
                    language="en"
                    theme="default"
                    name="{`family.spouse_dob_nep`}"
                    className="form-control"
                    onChange={handleSpouseDOBDate}
                    defaultDate={initialValue.spouse_dob_nep}
                    />
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Spouse DOB(A.D.):</label>
                        <FastField name={`family.spouse_dob_eng`} value={initialValue.spouse_dob_eng} placeholder="Spouse DOB(A.D.)" className="form-control"/>
                        <ErrorMessage name={`family.spouse_dob_eng`} component={TextError}/>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Total Son:</label>
                        <FastField name={`family.total_son`} placeholder="Total Son" type="number" className="form-control"/>
                        <ErrorMessage name={`family.total_son`} component={TextError}/>
                    </div>
                    <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                        <label>Total Daughter:</label>
                        <FastField name={`family.total_daughter`} placeholder="Total Son" type="number" className="form-control"/>
                        <ErrorMessage name={`family.total_daughter`} component={TextError}/>
                    </div>                  
                    </div>
                    
                    </>
                    :null
                }
                <button className="btn btn-success btn-flat" onClick={()=>prev(values)}>Prev</button>
                <button className="btn btn-success btn-flat" >Next</button>
                </Form>
                )}
                </Formik>               
                </Card>
            
    )
}

export default FamilyInfo
