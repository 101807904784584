import React, { useEffect, useState } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer';
import {useDispatch,useSelector} from 'react-redux';
import { getQuizAll } from '../../store/QuizSetupStore';
import MathJax from 'mathjax3-react'
import { Link } from 'react-router-dom';
import Pagination from '../Pagination/Pagination';
import Card from '../Card/Card'
import QuizTag from '../QuizTagSetup.js/QuizTag';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import QuizDelete from './QuizDelete';

function QuizSetup() {
    const [, setCurrentPage] = useState(1)
    const [openPopUp,setOpenPopUp] = useState(false);
    const [openDeletePopUp,setOpenDeletePopUp] = useState(false);

    const [question,setQuestion] = useState();
    const [resPerPage] = useState(10)
    // Current active button number
    const [currentButton, setCurrentButton] = useState(1)
     // Array of buttons what we see on the page
     const [arrOfCurrButtons, setArrOfCurrButtons] = useState([])
    const dispatch = useDispatch()
    const quizData = useSelector(state => state.entities.quiz?.result)
    const loading = useSelector(state => state.entities.quiz.loading)
    useEffect(()=>{
        if (currentButton === 1)
        dispatch(getQuizAll(0,resPerPage));
    },[resPerPage])
    const clickTagQuiz=(data)=>{
        setQuestion(data);
        setOpenPopUp(true);
    }
    
    const clickDeleteQuiz = (data)=>{
        setQuestion(data);
        setOpenDeletePopUp(true)
    }
    
    // const indexOfLastPost = currentPage * resPerPage;
    // const indexOfFirstPost = indexOfLastPost - resPerPage;
    // const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)
    // const howManyPages = Math.ceil(posts.length/postsPerPage)
    const howManyPages = quizData?.len && Math.ceil(quizData?.len[0]?.len/resPerPage)   
    const numberOfPages = []
  for (let i = 1; i <= howManyPages; i++) {
    numberOfPages.push(i)
  }
    const nextPage = () =>{
        setCurrentButton(prev => prev >= numberOfPages.length ? prev : prev + 1)
        dispatch(getQuizAll(currentButton*resPerPage,resPerPage))
    }
    const prevPage = () =>{
        setCurrentButton(prev => prev <= 1 ? prev : prev - 1)
        dispatch(getQuizAll((currentButton-2)*resPerPage,resPerPage))
    }
    const currPage = (item) =>{
        setCurrentButton(item)
        dispatch(getQuizAll((item-1)*resPerPage,resPerPage))
    }
   
  
  return (
    <BodyContainer buttonTitle="Add Quiz" route="/quizsetup/add" pageTitle="Quiz Setup">
        {loading && <LoaderSpinner/>}      
        {
        (quizData?.data && quizData?.data?.length > 0)  && quizData?.data?.map((item,index)=>{
            
           return (
        <Card key={index}>
            <div className='card-body'>
                <div style={{display:'flex',justifyContent:'space-between',alignContent:'center'}}>
                <MathJax.Html html={item?.question}/>
                <MathJax.Html html={item?.correct_option}/>
                    <div style={{display:'flex',gap:1,justifyContent:'flex-end'}}>
                        <Link
                            className="btn btn-warning btn-sm"                           
                            style={{marginRight: '5px',height:35,minWidth:35}}
                            to={`#`}
                            onClick={()=> clickTagQuiz(item)}
                            >
                            <i className="fa fa-tag"> tag to ms</i>
                        </Link>
                        <Link
                            className="btn btn-primary btn-sm"                           
                            style={{marginRight: '5px',height:35,width:35}}
                            to={`/quizsetup/edit/${item.id}`}
                            >
                            <i className="fa fa-edit"></i>
                        </Link>
                        <button 
                            className="btn btn-danger btn-sm" 
                            onClick={()=>clickDeleteQuiz(item)}
                            style={{marginRight: '5px',height:35,width:35}}
                            >
                            <i className="fa fa-trash"></i>
                        </button>
                        <Link 
                            to={`/quizsetup/view/${item?.id}`}
                            style={{marginLeft:5,height:35,width:35}}
                            className="btn btn-success btn-sm" 
                            >                            
                            <i className="fa fa-eye"></i>
                        </Link>
                    </div>
                    </div>
            </div>
        </Card>
        )})
        }
        {
            (howManyPages >=1) ?
            <Pagination 
            numberOfPages={numberOfPages} 
            setCurrentPage={setCurrentPage} 
            currentButton={currentButton}
            setCurrentButton={setCurrentButton}
            arrOfCurrButtons={arrOfCurrButtons}
            setArrOfCurrButtons={setArrOfCurrButtons}
            nextPage={nextPage}
            prevPage={prevPage}
            currPage={currPage} 
            />
            :''
        }
         {
            openPopUp &&
        <QuizTag openPopUp={openPopUp} setOpenPopUp={setOpenPopUp} question={question}/>
        }
        {
            openDeletePopUp &&
            <QuizDelete openPopUp={openDeletePopUp} setOpenPopUp={setOpenDeletePopUp} question={question}/>
        }
    </BodyContainer>
  )
}

export default QuizSetup