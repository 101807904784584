import React from 'react'
import FormPopup from '../popup/PopUp' 
import { Form, Formik } from 'formik';
import Spinner from '../Layout/Spinner';
import { useDispatch } from 'react-redux';
import { deleteResource, } from '../../store/CourseSetup';
function ResourceDelete({openPopup,setOpenPopup,resource_id,resource,setRefetch}) {
    const dispatch = useDispatch()
    const initialValues = {
        resources_id:resource_id,       
    }
    
    const handleSubmit = (values,submitProps) => {
               
        dispatch(deleteResource(values))
        submitProps.setSubmitting(false)
        setRefetch(prevState => !prevState)
    }
    
  return (
    <FormPopup title="Delete Resources" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Formik
        initialValues={initialValues}        
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                       <p>Are you sure to delete {resource}? </p>
                        <button className='btn btn-flat btn-danger' disabled={isSubmitting}>
                           {isSubmitting && <Spinner/>} <i className='fa fa-trash'/>&nbsp;Delete
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopup>
  )
}

export default ResourceDelete