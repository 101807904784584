import { ErrorMessage, FastField, Form, Formik } from 'formik'
import React from 'react'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { updateQuizLevel } from '../../store/QuizSetupStore'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function QuizLevelEdit({openPopup,setOpenPopup,level}) {
    const errors = useSelector(state=>state.entities.quiz.errors?.data)
    const loading = useSelector(state=>state.entities.quiz.loading)
    const dispatch = useDispatch()
    const initialValues = {
        level_id:level?.iid,
        level_name:level?.level_name,
        modified_remarks:''
    }
    const validationSchema = Yup.object().shape({
        level_name: Yup.string().required().min(2).max(8).label('level name'),
        modified_remarks: Yup.string().required().min(5).max(100).label('modified remarks'),
    })
    const handleSubmit = (values,submitProps) => {
        values.modified_by = localStorage.getItem('username')
        dispatch(updateQuizLevel(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Quiz Level Add">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Level Name:</label><span className='errors'>*</span>
                            <FastField name="level_name" className="form-control" placeholder="level name"/>
                            <ErrorMessage name='level_name' component={TextError}/>
                            {
                                errors?.level_name && <div className='errors'>{errors?.level_name}</div>
                            }
                        </div>
                        <div className='form-group'>
                            <label>modified remarks:</label><span className='errors'>*</span>
                            <FastField name="modified_remarks" className="form-control" placeholder="modified remarks"/>
                            <ErrorMessage name='modified_remarks' component={TextError}/>
                            {
                                errors?.modified_remarks && <div className='errors'>{errors?.modified_remarks}</div>
                            }
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default QuizLevelEdit