import { ErrorMessage, FastField, Form, Formik } from 'formik'
import React from 'react'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux'
import { addInstructor } from '../../store/InstructorSetup'
import TextError from '../../TextError'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function InstructorEdit({openPopUP,setOpenPopUp,data}) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.instructor?.loading);    
    const initialValues={
        instructor_name:data?.instructor_name,       
        experience:data?.experience,
        mobile_no:data?.mobile_no,
        email:data?.email,
        modified_remarks:'',
        modified_by: localStorage.getItem('username')
    }
    const validationSchema= Yup.object().shape({
        instructor_name: Yup.string().required().min(3).max(50).label("instructors name"),
        experience:Yup.string().required().min(10).max(255).label("about instructor"),
        mobile_no:Yup.number().required().label("mobile no").min(10).max(10),
        email:'',
        modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks") 
    })
    const handleSubmit = (values,submitProps)=>{
        dispatch(addInstructor(values))
        submitProps.setSubmitting(false)
        // submitProps.resetForm();
    }
    
  return (
    <FormPopUp openPopup={openPopUP} setOpenPopup={setOpenPopUp} title="Instructor Edit">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(                    
                    <Form>                       
                        <div className='form-group item'>
                                <label className='required-label'>Instructor Name<span className="errors">*</span>:</label>
                                <FastField type="text" name="instructor_name" id="instructor_name" placeholder="instructor name" className='form-control'/>
                                <ErrorMessage name="instructor_name" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Email<span className="errors">*</span>:</label>
                                <FastField type="email" name="email" id="email" placeholder="email" className='form-control'/>
                                <ErrorMessage name="email" component={TextError} />
                        </div>
                        <div className='form-group item'>
                                <label className='required-label'>Mobile No<span className="errors">*</span>:</label>
                                <FastField type="text" name="mobile_no" id="mobile_no" placeholder="mobile no" className='form-control'/>
                                <ErrorMessage name="topic_title" component={TextError} />
                        </div>                       
                        <div className='form-group item'>
                                <label className='required-label'>About Instructors<span className="errors">*</span>:</label>
                                <FastField as="textarea" rows="7" name="experience" id="experience" placeholder="about program" className='form-control'/>
                                <ErrorMessage name="experience" component={TextError} />
                        </div>  
                        <div className='form-group item'>
                                <label className='required-label'>Modified Remarks<span className="errors">*</span>:</label>
                                <FastField type="text" name="modified_remarks" id="modified_remarks" placeholder="Modified Remarks" className='form-control'/>
                                <ErrorMessage name="modified_remarks" component={TextError} />
                        </div>                 
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}Save</button>
                    </Form>                   
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default InstructorEdit