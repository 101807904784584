import { ErrorMessage,  Field, Form, Formik } from 'formik'
import React from 'react';
import Card from '../Layout/Card';
import './Login.css';
import * as Yup from "yup";
import TextError from '../../TextError';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import axios from 'axios';
import { toast } from 'react-toastify';
function PasswordResetComplete() {
    const location = useLocation();
    const values = queryString.parse(location.search);
    console.log("values",values)
    console.log("values",values?.token)
    const initialValues = {
        password:"",
        repassword:"",
        uidb64:values?.uidb64,
        token:values?.token
    }

    const validationSchema = Yup.object().shape({
        password: Yup.string().required().label("Password"),
        repassword: Yup.string().required().when("password",{
            is: val=> (val && val.length > 0? true: false),
            then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Password didn't matches"
            )
        })
    }) 
    const handleSubmit = async values => {
        try{
            await axios.patch(`${process.env.REACT_APP_BASE_URL}/auth/password-reset-complete`,values)
            toast.success("Password changed Successfully!!")
            window.location.href="/login"
        }catch(errors){
            console.log("errors",errors?.response)
        }
    }
    return (
        <div className="login">
                <Card>
            <Formik
            initialValues={initialValues} 
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
                {
                    ()=>(
                            <Form>
                                <div className="form-group">
                                    <label>Password:</label>
                                    <Field className="form-control" type="password" name="password" placeholder="password"/>   
                                    <ErrorMessage name="password" component={TextError}/>                                 
                                </div>
                                <div className="form-group">
                                    <label>Re-Password:</label>
                                    <Field className="form-control" type="password" name="repassword" placeholder="re-password"/>                                    
                                    <ErrorMessage name="repassword" component={TextError}/>                                 
                                </div>
                                <button className="btn btn-success">Change Password</button>
                            </Form>
                    )
                }
            </Formik>
                </Card>
        </div>
    )
}

export default PasswordResetComplete
