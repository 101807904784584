import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteTagBatch } from '../../store/EnrollStore'

function BatchTagDeleteConfirm({studentList}) {
    const dispatch = useDispatch()
    const handleOk = ()=>{
        dispatch(deleteTagBatch({'student_list':studentList}))
    }
  return (
    <>
    <div className='row'>
        <h3>Are you sure to Delete</h3>
    </div>
    <button className='btn btn-flat btn-success' type='button' onClick={handleOk}>
        ok
    </button>
    </>
  )
}

export default BatchTagDeleteConfirm