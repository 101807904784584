import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InstructorAdd from './InstructorAdd';
import ReactDataTable from '@ashvin27/react-datatable';
import { loadInstructor } from '../../store/InstructorSetup';
import InstructorEdit from './InstructorEdit';
import InstructorTag from '../InstructorTagSetup/InstructorTag';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function InstructorSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  // const [openPopUpView,setOpenPopUpView] = useState(false)
  const [openPopUpTag,setOpenPopUpTag] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const instructor = useSelector(state => state.entities.instructor?.result?.data);
  const loading = useSelector(state => state.entities.instructor?.loading);
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  const handleTag = (data)=>{
    setEData(data);
    setOpenPopUpTag(true)
  }
  useEffect(()=>{
    dispatch(loadInstructor());
  },[openPopUp])
  const columns = [
    {
      key:'instructor_name',
      text:"Instructor Name"
    },
    {
      key:'email',
      text:"Email"
    },
    {
      key:'mobile_no',
      text:"Mobile No"
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleTag(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-tag"></i>
                  </button>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => console.log(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  <Link 
                  to={`/instructorsetup/view/${record?.iid}`}
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      >                      
                      <i className="fa fa-eye"></i>
                  </Link>
              </>
          );
      }
  }
  ]
  return (
    <>
    {loading && <LoaderSpinner/>} 
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Instructor Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add Instructor</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <ReactDataTable        
        columns = {columns}
        records = {instructor}
        />
        <InstructorAdd openPopUP={openPopUp} setOpenPopUp={setOpenPopUp}/>
        <InstructorEdit openPopUP={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} data={edata}/>
        {
          openPopUpTag &&
          <InstructorTag openPopUp={openPopUpTag} setOPenPopUp={setOpenPopUpTag} instructor={edata}/>
        }
    </div>
    </div>
    </>
  )
}

export default InstructorSetup