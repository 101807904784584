import React from 'react'
import FormPopUp from '../popup/PopUp'

function ViewQuizLevel({openPopup,setOpenPopup,level}) {
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Quiz Level Details">
        <label>Level Name:</label>&nbsp;<span>{level?.level_name?level?.level_name:'N/A'}</span><br/>
        <label>created by:</label>&nbsp;<span>{level?.created_by?level?.created_by:'N/A'}</span><br/>
        <label>created date:</label>&nbsp;<span>{level?.created_date?level?.created_date:'N/A'}</span><br/>
        <label>modified by:</label>&nbsp;<span>{level?.modified_by?level?.modified_by:'N/A'}</span><br/>
        <label>modified date:</label>&nbsp;<span>{level?.modified_date?level?.modified_date:'N/A'}</span><br/>
        <label>modified remarks:</label>&nbsp;<span>{level?.modified_remarks?level?.modified_remarks:'N/A'}</span><br/>
    </FormPopUp>
  )
}

export default ViewQuizLevel