import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editFeature } from '../../store/FeatureSetupStore';
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function FeatureEdit({openPopUp,setOpenPopUp,feature}) {
    const dispatch = useDispatch();
    const errors = useSelector(state => state.entities.feature?.error?.data);
    const loading = useSelector(state => state.entities.feature?.loading);
    const initialValues = {
        feature_id:feature?.iid,
        features_title:feature?.features_title,        
        features_desc:feature?.features_desc,
        user_get:feature?.user_get,
        modified_remarks:''
    }
    const handleSubmit = (values,submitProps) =>{
        values.modified_by = localStorage.getItem('username');
        dispatch(editFeature(values));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    
    const validationSchema = Yup.object().shape({
        features_title: Yup.string().required().min(4).max(30).label("feature title"),
        features_desc:Yup.string().required().min(15).max(200).label("feature desc"),
        user_get:Yup.string().required().min(15).max(200).label("details"),
        modified_remarks:Yup.string().required().min(5).max(100).label("modified remarks"),
       

    })
  return (
    <FormPopUp title="Feature Edit" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=> (
                    <Form>
                        <div className='form-group'>
                            <label>Feature Title </label><span className='errors'>*</span>
                            <Field name="features_title" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='features_title' component={TextError}/>
                            {errors?.features_title && <div className='errors'>{errors?.features_title}</div>}
                        </div>                        
                        <div className='form-group'>
                            <label>Feature Description </label><span className='errors'>*</span>
                            <Field as="textarea" rows="5" name="features_desc" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='features_desc' component={TextError}/>
                            {errors?.features_desc && <div className='errors'>{errors?.features_desc}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Details </label><span className='errors'>*</span>
                            <Field as="textarea" rows="5" name="user_get" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='user_get' component={TextError}/>
                            {errors?.user_get && <div className='errors'>{errors?.user_get}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Modified Remarks </label><span className='errors'>*</span>
                            <Field  name="modified_remarks" className='form-control' placeholder="modified remarks"/>
                            <ErrorMessage name='modified_remarks' component={TextError}/>
                            {errors?.modified_remarks && <div className='errors'>{errors?.modified_remarks}</div>}
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/> &nbsp; save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default FeatureEdit