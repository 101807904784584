import React from 'react'
import { Formik,Form } from 'formik'
import ImageViewer from '../Form/ImageViewer'
import FormPopUp from '../popup/PopUp'
import { useDispatch } from 'react-redux'
import { deleteAlumni } from '../../store/AlumniSetupStore'
import Spinner from '../Layout/Spinner'

function AlumniDelete({openPopUp,setOpenPopUp,alumni,setRefetch}) {
    const dispatch = useDispatch()
   const initialValues = {
       alumni_id: alumni?.iid
   }
   const handleSubmit = (values,submitProps)=>{
        dispatch(deleteAlumni(values));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
   }
  return (
    <>
    <FormPopUp title="delete Alumni" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
     
          <label>Course Name</label>&nbsp; : &nbsp;<span>{alumni?.course_name?alumni?.course_name:'N/A'}</span><br/>
          <label>Alumni Name</label>&nbsp; : &nbsp;<span>{alumni?.alumni_name?alumni?.alumni_name:'N/A'}</span><br/>
          <label>Alumni Achievement</label>&nbsp; : &nbsp;<span>{alumni?.alumni_achievement?alumni?.alumni_achievement:'N/A'}</span><br/>
          <label>Alumni Img</label>&nbsp; : &nbsp;<ImageViewer image={alumni?.alumni_img}/><br/>
          <label>Alumni Description</label>&nbsp; : &nbsp;<span>{alumni?.alumni_desc?alumni?.alumni_desc:'N/A'}</span><br/>
          <label>Rating</label>&nbsp; : &nbsp;<span>{alumni?.rating?alumni?.rating:'N/A'}</span><br/>
          <label>created by</label>&nbsp; : &nbsp;<span>{alumni?.created_by?alumni?.created_by:'N/A'}</span><br/>
          <label>created date</label>&nbsp; : &nbsp;<span>{alumni?.created_date?alumni?.created_date:'N/A'}</span><br/>
          <label>modified by</label>&nbsp; : &nbsp;<span>{alumni?.modified_by?alumni?.modified_by:'N/A'}</span><br/>
          <label>modified date </label>&nbsp; : &nbsp;<span>{alumni?.modified_date?alumni?.modified_date:'N/A'}</span><br/>
          <label>modified remarks</label>&nbsp; : &nbsp;<span>{alumni?.modified_remarks?alumni?.modified_remarks:'N/A'}</span><br/>
            <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <button type='submit' className='btn btn-flat btn-success' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''} <i className='fa fa-trash'></i>&nbsp;Delete</button>
                        </Form>
                    )
                }
            </Formik>
    </FormPopUp>
    </>
  )
}

export default AlumniDelete