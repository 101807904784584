import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCourseSelect } from '../../store/CourseSetup';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import DataTable from 'react-data-table-component';
import { getArticlesByCourse } from '../../store/ArticlesStore';
import { useState } from 'react';
import TextError from '../../TextError';
import {Link} from 'react-router-dom'
import ArticleBannerEdit from './ArticleBannerEdit';
import ArticleDelete from './ArticleDelete';

function ArticleSetup() {
    const [courses,setCourses] = useState()
    const [data,setData] = useState();
    const [addModal,setAddModal] = useState(false);
    const [editModal,setEditModal] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const course = useSelector(state => state.entities.courses?.result?.data);
    const articles = useSelector(state => state.entities.articles?.article?.data);
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues = {
        courses:courses
    }
    const validationSchema=Yup.object().shape({
        courses:Yup.string().required()
    })
    const handleSubmit = async (values,submitProps)=>{
        await dispatch(getArticlesByCourse(values))
        submitProps.setSubmitting(false)
    }
    const handleEditClick = (rowData)=>{
        setData(rowData);
        setEditModal(true);
    }
    const handleDeleteClick = (rowData)=>{
        setData(rowData);
        setDeleteModal(true);
    }
    const handleCourseClick = (id)=>{
        setCourses(id)
        
    }
    const handleAddClick = ()=>{
        setAddModal(true);
    }
    const COLUMNS = [
        {
            name:'sn',
            selector:'sn',
            width:'100px',
            cell:(row,index)=><>
            <div>{index+1}</div>
            </>
        },
        {
            name:'title',
            selector:row=>row.article_title
        },
        {
            name:'banner',
            selector:'banner',
            width:'300px',
            cell:row=>
            <div style={{display:'flex',flexDirection:'column'}}>
            {
                row.article_banner &&
            <>
            <img style={{height:150,width:290,}} src={`${process.env.REACT_APP_BASE_URL}${row.article_banner}`}/>
            <span style={{color:'#42a5ff',fontSize:16}} className='link' onClick={()=>handleEditClick(row)}>edit</span> </>
            }
            </div>
        },
        {
            name:'author',
            selector:row=>row.author_name
        },
        {
            name:'publish date',
            selector:row=>row.get_publish_date
        },
        {
            name:'actions',
            selector:'actions',
            cell:row=>
            <>
            <Link to={`/articleSetup/editArticles/${row.iid}`} className='btn-sm btn-primary'  style={{marginRight: '5px'}}>
                <i className='fa fa-edit'/>
            </Link>
            <Link to={`#`} className='btn-sm btn-danger'  onClick={()=>handleDeleteClick(row)}>
                <i className='fa fa-trash'/>
            </Link>
            </>
        }
    ]
  return (
    <BodyContainer pageTitle={`Article Setup`} buttonTitle={`Add Article`}  route={`/articleSetup/addArticle`}>
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                <div className='form-group col-md-4'>
                                    <label className='required-label'> select course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                        <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }
                                        </Field>
                                        <ErrorMessage name="courses" component={TextError} />
                                    </div>
                                    <div className='form-group col-md-4'>
                                        <label>&nbsp;&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control ' type='submit' disabled={isSubmitting}>
                                            {isSubmitting?<Spinner/>:''} <i className='fa fa-search'>&nbsp;&nbsp;Filter</i>
                                            </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
                 <DataTable
                 columns={COLUMNS}
                 data={articles}
                 fixedHeader
                 fixedHeaderScrollHeight='550px'
                 pagination
                 />
            </div>
        </div>
        {
            editModal &&
            <ArticleBannerEdit openPopup={editModal} setOpenPopup={setEditModal} data={data}/>
        }
        {
            deleteModal &&
            <ArticleDelete openPopup={deleteModal} setOpenPopup={setDeleteModal} data={data}/>
        }
    </BodyContainer>
  )
}

export default ArticleSetup