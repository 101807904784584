import React from 'react'
import { Link } from 'react-router-dom'
import Card from '../Card/Card'

function UnauthorizedAccess() {
  return (
    <div style={{
        height:'100vh',
        width:'100vw',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <Card>
            <div className='card-body'>
                <p>You don't have access permission to open this page </p>
                <p>please contact to the admin to get access permission </p>
                <p> <Link to={`/login`}>login</Link></p>
            </div>
        </Card>
    </div>
  )
}

export default UnauthorizedAccess