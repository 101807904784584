import React from 'react'
import FormPopUp from '../popup/PopUp'
import { useDispatch, useSelector } from 'react-redux'
import { batchModelsetTagDelete } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'

function ModelsetBatchTagDelete({openPopup,setOpenpopup,title,tagData}) {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.entities.quiz?.loading)
    const handleConfirm = async()=>{
        await dispatch(batchModelsetTagDelete(tagData?.tag_id))
        setOpenpopup(false);
    }
    return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenpopup} title={title}>
        <div className='form-group'>
            <label>Are you sure to delete?</label>
        </div>
        <div className='form-group'>
            <button className='btn btn-flat btn-success' type='button' onClick={handleConfirm} disabled={isLoading}>
                {isLoading && <><Spinner/> &nbsp;</>}ok
            </button>
        </div>
    </FormPopUp>
  )
}

export default ModelsetBatchTagDelete