import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCourseSelect } from '../../store/CourseSetup';
import {  editFeatureTag  } from '../../store/FeatureSetupStore';
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';

function EditFeatureTag({openPopUp,setOpenPopUp,feature}) {
    const dispatch = useDispatch();
    const course = useSelector(state=>state.entities.courses?.result?.data);
    const errors = useSelector(state=>state.entities.feature?.error?.data);
    const loading = useSelector(state => state.entities.feature?.loading)
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues = {
        tag_id:feature?.iid,
        features:feature?.feature_iid,
        courses:feature?.course_iid
    }
    const validationSchema = Yup.object().shape({
        courses:Yup.string().required()
    })
    const handleSubmit = (values,submitProps) =>{
        dispatch(editFeatureTag(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp title="Edit Feature Tag" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <label>Feature Title :</label>&nbsp;<span>{feature?.features_title}</span><br/>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                            <div className='form-group'>
                                <label>select Course :</label><span className='errors'>*</span>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' >
                                    <option name="courses" value="">select Options</option>
                                         {
                                        course && course?.map((item,index)=>(
                                         <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }
                                </Field>
                                 <ErrorMessage name="courses" component={TextError} />
                                 {errors?.courses && <div className="errors">{errors?.courses}</div>}
                            </div>
                        <button type='submit' className='btn btn-success btn-flat' disabled={isSubmitting}><i className='fa fa-edit'>&nbsp;edit</i></button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default EditFeatureTag