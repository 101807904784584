import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteInstructorTag } from '../../store/InstructorSetup';
import FormPopUp from '../popup/PopUp'

function InstructorTagDelete({openPopUp,setOpenPopUp,instructor}) {
    const dispatch = useDispatch();
    const initialValues = {
        tag_id:instructor?.iid
    }
    const handleSubmit = (values,submitProps) =>{
        dispatch(deleteInstructorTag(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp title="VIew Instructor Tag" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        <label>Instructor Name :</label>&nbsp;<span>{instructor?.instructor_name}</span><br/>
        <label>Course Name :</label>&nbsp;<span>{instructor?.course_name}</span><br/>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <button type='submit' className='btn btn-success btn-flat' disabled={isSubmitting}><i className='fa fa-trash'>&nbsp;Delete</i></button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default InstructorTagDelete