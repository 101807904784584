import { useFormikContext } from 'formik'
import React, { useRef, useState } from 'react'
import ErrorMessage from './ErrorMessage'
import './ImageUploadButton.css';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';


function FileUploader({label,name,required,...otherProps}) {
    const fileInputRef = useRef();
    const [fileName,setFileName] = useState('');
    const {setFieldTouched,setFieldValue,errors,touched} = useFormikContext()
   const handleFileUpload = ()=>{
       fileInputRef.current.click();
       console.log(fileInputRef.current)
        
   }

const handleChange = (event)=> {
setFieldValue(name,event.target.files[0])
setFileName(event.target.files[0].name)
}
    return (
        <div className="form-group item  ">
            {label && <label>{label}{required && <span className='errors'>*</span>}</label>}
            <input
            ref={fileInputRef}
            type="file"
            onBlur={()=>setFieldTouched(name)}
            // onChange={(event)=> setFieldValue(name,event.target.files[0])}
            onChange={handleChange}
            {...otherProps}
            className="form-control"
            style={{display:'none'}}
            />
            <div className="row">
            <button type="button" onClick={handleFileUpload}><FolderOpenIcon/></button>
            {fileName && <p>&nbsp;&nbsp;&nbsp;{fileName}</p>}
            </div>
            {
                required &&
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
            }
        </div>
    )
}

export default FileUploader
