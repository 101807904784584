import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import ImageViewer from '../Form/ImageViewer';
import AddMobileBanner from './AddMobileBanner';
import EditMobileBanner from './EditMobileBanner';
import { loadBanner } from '../../store/BannerSetupStore';
import MobileBannerImgUpdate from './MobileBannerImgUpdate';
import DeleteMobileBanner from './DeleteMobileBanner';
function MobileBannerSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  // const [openPopUpView,setOpenPopUpView] = useState(false)
  const [openPopUpImg,setOpenPopUpImg] = useState(false)
  const [openPopUpDelete,setOpenPopUpDelete] = useState(false)
  const [refetch,setRefetch] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const banner = useSelector(state => state.entities.banner.result.data);
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  // const handleView = (data)=>{
  //   setEData(data);
  //   setOpenPopUpView(true)
  // }
  const handleImg = (data)=>{
    setEData(data);
    setOpenPopUpImg(true)
  }
  const handleDelete = (data)=>{
    setEData(data);
    setOpenPopUpDelete(true)
  }
  useEffect(()=>{
    dispatch(loadBanner());
  },[refetch])
  const columns = [
    {
      key:'course_name',
      text:"Courses Name"
    },
    {
      key:'banner_tag_line',
      text:"Banner Title"
    },
    {
      key:'banner_desc',
      text:"Banner Desc"
    },   
    {
      key:'banner_img',
      text:"Banner Img",
      cell: record=>{
          return(
              <>
              <ImageViewer image={record?.banner_img}/><br/>
              <Link to="#" onClick={()=>handleImg(record)}><i className='fa fa-edit'/>edit banner img</Link>
              </>

          )
      }
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleImg(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-tag"></i>
                  </button>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => handleDelete(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  {/* <Link 
                    to='#'
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      onClick={()=>handleView(record)}
                      >                      
                      <i className="fa fa-eye"></i>
                  </Link> */}
              </>
          );
      }
  }
  ]
  return (
    <>
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Mobile Banner Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add Banner</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <ReactDataTable        
        columns = {columns}
        records = {banner}
        />
        {
            openPopUp &&
            <AddMobileBanner openPopup={openPopUp} setOpenPopup={setOpenPopUp} setRefetch={setRefetch}/>
        }
        {
            openPopUpEdit &&
        <EditMobileBanner openPopup={openPopUpEdit} setOpenPopup={setOpenPopUpEdit} banner={edata} setRefetch={setRefetch}/>
        }
        {
          <DeleteMobileBanner openPopup={openPopUpDelete} setOpenPopup={setOpenPopUpDelete} banner={edata} setRefetch={setRefetch}/>
        }
        {/* {
            openPopUpView &&
            <ViewFeatureDetails openPopup={openPopUpView} setOpenPopup={setOpenPopUpView} feature={edata}/>
        } */}
        {
            openPopUpImg &&
            <MobileBannerImgUpdate openPopup={openPopUpImg} setOpenPopup={setOpenPopUpImg} banner={edata}/>
        }
       
    </div>
    </div>
    </>
  )
}

export default MobileBannerSetup