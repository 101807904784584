import React from 'react';
import * as Yup from 'yup';
import FormPopUp from '../popup/PopUp';
import ImageUploader from '../Form/ImageUploader';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import Spinner from '../Layout/Spinner';
import { editInstructorImg } from '../../store/InstructorSetup';
function UpdateInstructorImg({openPopUp,setOpenPopUp,instructor}) {
    const initialValues = {        
        image:''
    }
    const dispatch = useDispatch()
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const validationSchema = Yup.object().shape({
        image:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("image"),
    })
    const handleSubmit = (values,submitProps)=>{
            let formData = new FormData()
            formData.append('instructor_id',instructor)
            formData.append('image',values.image,values.image.name) 
            dispatch(editInstructorImg(formData))
            submitProps.setSubmitting(false);
    } 
  return (
    <FormPopUp openPopup={openPopUp} setOpenPopup={setOpenPopUp} title="Alumni Image Update">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
          {
            ({isSubmitting})=>(
              <Form>
                  <ImageUploader
                  name="image"
                  label="Instructor image"
                  required="required"
                  />
                  <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}<i className='fa fa-save'></i>&nbsp;Save</button>
              </Form>
            )
          }
        </Formik>
    </FormPopUp>
  )
}

export default UpdateInstructorImg