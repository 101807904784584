import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loadSubCourse } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import Card from '../Card/Card';
import * as Yup from 'yup';
import { editMainTopic, getMainTopicByIds } from '../../store/MainTopicSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function MainTopicEdit({fetchData,setFetchData}) {
    const params = useParams()
    const subcourse = useSelector(state => state.entities.subcourse?.result?.data)
    const errors = useSelector(state=>state.entities.mainTopic?.error?.data)
    const mainTopic = useSelector(state=> state.entities.mainTopic?.getDataById?.data)
    const loading = useSelector(state => state.entities.mainTopic?.loading)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadSubCourse())
        dispatch(getMainTopicByIds(params.slug))
    },[params])
    const initialValues = {
        topic_title: mainTopic?.topic_title,
        subcourse:mainTopic?.subcourse_iid,
        lecture_hours: mainTopic?.lecture_hours,
        modified_remarks:'',
        modified_by:localStorage.getItem('username'),
        main_topic_id:mainTopic?.iid
    }   
    const validationSchema = Yup.object().shape({
        topic_title:Yup.string().required().max(50).min(4).label('Topic Title'),
        subcourse:Yup.string().required(),
        lecture_hours:Yup.string().required().label('Lecture hours'),
        modified_remarks:Yup.string().min(4,"must be more than 4 characters").max(100,"must be less than 100 characters").required().label("Modified Remarks") 
    })
    const changeData = (prevData) => setFetchData(!prevData)
    const handleSubmit = (values,submitProps)=>{
        dispatch(editMainTopic(values))
        submitProps.setSubmitting(false)
        changeData(fetchData);
    }
  return (
      <Card cardTitle="Main Topic Add">
          {loading && <LoaderSpinner/>}
          <div className='card-body'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='form-group item'>
                                <label className='required-label'>Main Topic Title<span className="errors">*</span>:</label>
                                <FastField type="text" name="topic_title" id="topic_title" placeholder="" className='form-control'/>
                                <ErrorMessage name="topic_title" component={TextError} />
                                {errors?.topic_title && <div className="errors">{errors?.topic_title}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Lecture Hours<span className="errors">*</span>:</label>
                                <FastField type="text" name="lecture_hours" id="lecture_hours" placeholder="lecture hours" className='form-control'/>
                                <ErrorMessage name="lecture_hours" component={TextError} />
                                {errors?.lecture_hours && <div className="errors">{errors?.lecture_hours}</div>}
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Parent<span className="errors">*</span>:</label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" className='form-control'>
                                    <option name="subcourse" value="">select Options</option>
                                    {
                                        // console.log("subcourse",subcourse)
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse" component={TextError} />
                                {errors?.subcourse && <div className="errors">{errors?.subcourse}</div>}
                            </div>
                            <div className="form-group item">
                            <label className="required-label">Modified Remarks <span className="errors">*</span>:</label>
                            <Field type="text" name = "modified_remarks" id="modified_remarks" placeholder = "modified remarks"  className="form-control" />
                            <ErrorMessage name="modified_remarks" component={TextError}/>
                            {errors?.data?.modified_remarks && <div className="errors">{errors?.data?.modified_remarks}</div>}
                            </div> 
                            <div className='card-footer'>
                                 <button className='btn btn-flat btn-success' disabled={isSubmitting}>save</button>       
                            </div>
                        </Form>
                    )
                }
            </Formik>
          </div>
      </Card>
  );
}

export default MainTopicEdit;
