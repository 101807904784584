import React, { useEffect, useState } from 'react'
import { useDispatch} from 'react-redux';
import{Link} from 'react-router-dom';
import { logout } from '../../store/UserSetup';
import axios from 'axios'
import requests from '../../ApiRequests/Request';
import { refreshToken } from '../../Config/HeaderType';
function Header() {
  
  const [notification] = useState([])
  // const users = useSelector(state => state.entities.users?.result)
  const dispatch = useDispatch()

  useEffect(()=>{

  },[notification])  
  const token = refreshToken()
  const url = `ws://localhost:8000/ws/chat/?${token}`
  // let ws = new WebSocket(url)
  let message = JSON.stringify([{
      'message': "just check"
  }])
  // console.log("values", values, message)
  // ws.onopen = () => {
  //     console.log("open connection")
  //     // ws.send(message)
  // }
  // ws.onmessage = (event) => {
  //     let data = JSON.parse(event.data);
  //     // if (data.type === "chat")
  //     //     chatData.push(data)
  //     setNotification(data)
  //     console.log("message", data)
  //     // console.log("chatdata 1", chatData)
  // }
  // ws.onerror = (event) => {
  //     console.log("error", event.type)
  //     ws.close()
  // }
  // ws.close = ()=>{
  //   const refresh = localStorage.getItem('ref')
  //   axios.post(requests.refreshToken,{refresh:refresh}).then(response =>{
  //       localStorage.setItem('acs',response.data?.access);
  //       let ws1 = new WebSocket(url)
  //       ws1.onopen = () => {
  //           console.log("open connection")
  //           ws.send(message)
  //       }
        // ws1.onmessage = (event) => {
        //     let data = JSON.parse(event.data);
        //     if (data.type === "chat")
        //         chatData.push(data)
        //     setChatMessage(chatData)
        //     console.log("message", data)
        //     console.log("chatdata 1", chatData)
        // }
  //       }).catch(err =>{
  //           console.log("err",err)
  //           if (err.response?.status === 401) {
  //           window.location = '/login'
  //           }
  //       })
  // }
  const handleClick = ()=>{
    const data = {
      'refresh': refreshToken()
    }
    // localStorage.removeItem('tkn');
    // history.push('/test');
    dispatch(logout(data))
  }
    return (
        <>
    {/* Navbar */}
<nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <Link className="nav-link" data-widget="pushmenu" to="#" role="button"><i className="fas fa-bars" /></Link>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
      <Link to="index3.html" className="nav-link">Home</Link>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
      <Link href="#" className="nav-link">Contact</Link>
    </li>
  </ul>
  {/* SEARCH FORM */}
  <form className="form-inline ml-3">
    <div className="input-group input-group-sm">
      <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
      <div className="input-group-append">
        <button className="btn btn-navbar" type="submit">
          <i className="fas fa-search" />
        </button>
      </div>
    </div>
  </form>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
    <Link to="#" onClick={handleClick}>Logout</Link>
    {/* Messages Dropdown Menu */}
    <li className="nav-item dropdown">
      <Link className="nav-link" data-toggle="dropdown" to="#">
        <i className="far fa-comments" />
        <span className="badge badge-danger navbar-badge">3</span>
      </Link>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <Link to="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                Brad Diesel
                <span className="float-right text-sm text-danger"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">Call me whenever you can...</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </Link>
        <div className="dropdown-divider" />
        <Link  to="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="dist/img/user8-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                John Pierce
                <span className="float-right text-sm text-muted"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">I got your message bro</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item">
          {/* Message Start */}
          <div className="media">
            <img src="dist/img/user3-128x128.jpg" alt="User Avatar" className="img-size-50 img-circle mr-3" />
            <div className="media-body">
              <h3 className="dropdown-item-title">
                Nora Silvester
                <span className="float-right text-sm text-warning"><i className="fas fa-star" /></span>
              </h3>
              <p className="text-sm">The subject goes here</p>
              <p className="text-sm text-muted"><i className="far fa-clock mr-1" /> 4 Hours Ago</p>
            </div>
          </div>
          {/* Message End */}
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item dropdown-footer">See All Messages</Link>
      </div>
    </li>
    {/* Notifications Dropdown Menu */}
    <li className="nav-item dropdown">
      <Link className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">15</span>
      </Link>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        {
          notification && notification.map((item)=>{
            return(
        <Link to={`${item?.notification_route}`} className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> {item?.message}
          <span className="float-right text-muted text-sm">3 mins</span>
        </Link>
        )
          })
        }
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </Link>
        <div className="dropdown-divider" />
        <Link to="#" className="dropdown-item dropdown-footer">See All Notifications</Link>
      </div>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-widget="fullscreen" to="#" role="button">
        <i className="fas fa-expand-arrows-alt" />
      </Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" data-widget="control-sidebar" data-slide="true" to="#" role="button">
        <i className="fas fa-th-large" />
      </Link>
    </li>
  </ul>
</nav>
{/* /.navbar */}
 
        </>
    )
}

export default Header
