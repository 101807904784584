import React from 'react';
import BodyContainer from '../DashboardTemplate/BodyContainer'
import ChangePassword from './ChangePassword';


function MainSettings() {
  return (
    <BodyContainer pageTitle={`Settings`}>
    <div className='row'>
    <div className='col-md-4'>
        <ChangePassword/>
    </div>
    </div>
    </BodyContainer>
  )
}

export default MainSettings