import React from 'react'

function Footer() {
    return (
        <>
          {/* Main Footer */}
<footer className="main-footer">
  <strong>Copyright © 2014-2020 VeedhyaPeeth&nbsp;</strong>
  All rights reserved.
  <div className="float-right d-none d-sm-inline-block">
    <b>Version</b> 3.1.0-rc
  </div>
</footer>

        </>
    )
}

export default Footer
