import { Field, Form, Formik } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBanner } from '../../store/BannerSetupStore';
import { loadCourseSelect } from '../../store/CourseSetup'
import ImageUploader from '../Form/ImageUploader';
import Spinner from '../Layout/Spinner';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
function AddMobileBanner({openPopup,setOpenPopup,setRefetch}) {
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result.data);
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const initialValues = {
        banner_img:'',
        banner_tag_line:'',
        banner_desc:'',
        has_enroll:'',
        courses:''
    }
    const validationSchema = Yup.object().shape({
        banner_img:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("banner image"),
    })
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData();
        formData.append('banner_img',values.banner_img,values.banner_img.name);
        formData.append('banner_tag_line',values.banner_tag_line);
        formData.append('banner_desc',values.banner_desc);
        formData.append('has_enroll',values.has_enroll);
        formData.append('courses',values.courses);
        dispatch(addBanner(formData));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
    }
    
    const HAS_Enroll = [
        {
            'value':'y',
            'label':'YES'
        },
        {
            'value':'n',
            'label':'NO'
        },
    ]
  return (
    <FormPopUp title="Add Mobile Banner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <ImageUploader
                            name="banner_img"
                            label="Banner Img"
                            required="required"
                            />                            
                        </div>
                        <div className='form-group'>
                            <label>Banner Title :</label>
                            <Field name="banner_tag_line" className="form-control" placeholder="banner title"/>
                        </div>
                        <div className='form-group'>
                            <label>Banner Desc :</label>
                            <Field as="textarea" rows="2" name="banner_desc" className="form-control" placeholder="banner description"/>
                        </div>
                        <div className='form-group'>
                        <label className='required-label'>Select course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }
                                 </Field>
                        </div>
                        <div className='form-group'>
                        <label className='required-label'>has enroll option?<span className="errors">*</span></label>
                                <Field as="select" name="has_enroll" id="has_enroll" placeholder="" className='form-control'  >
                                    <option name="has_enroll" value="">select Options</option>
                                        {
                                            HAS_Enroll && HAS_Enroll?.map((item,index)=>(
                                            <option key={index} value={item?.value}  >{item?.label}</option>
                                            ))
                                        }
                                 </Field>
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default AddMobileBanner