import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { restoreOnboardMobile } from '../../store/OnboardMobileSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'

function OnboardRestore({openPopup,setOpenPopup,feature,setRefetch}) {
    const dispatch = useDispatch()
    const initialValues = {
        banner_id:feature?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(restoreOnboardMobile(values));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
    }
    
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Restore Onboard Mobile">
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>Onboard title:&nbsp;</label><span>{feature?.onboard_title}</span><br/>
                       <label> Do you want to Restore ?</label><br/>
                        <button className='btn btn-flat btn-success ' type='submit' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''} <i className='fa fa-undo'/>&nbsp;Restore
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default OnboardRestore