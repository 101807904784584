import React from 'react'
import FormPopUp from '../popup/PopUp'

function ViewInstructorTag({openPopUp,setOpenPopUp,instructor}) {
  return (
    <FormPopUp title="VIew Instructor Tag" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        <label>Instructor Name :</label>&nbsp;<span>{instructor?.instructor_name}</span><br/>
        <label>Course Name :</label>&nbsp;<span>{instructor?.course_name}</span><br/>
    </FormPopUp>
  )
}

export default ViewInstructorTag