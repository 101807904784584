import React, { useEffect, useState } from 'react'
import { useDispatch,useSelector  } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getinstructorByIds } from '../../store/InstructorSetup'
import Card from '../Card/Card'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import ImageViewer from '../Form/ImageViewer';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import UpdateInstructorImg from './UpdateInstructorImg';

function ViewInstructorsDetails() {
    const params = useParams()
    const dispatch = useDispatch()
    const [openPopUp,setOPenPopUp] = useState(false);
    const [data,setData] = useState();
    const instructor = useSelector(state => state.entities.instructor?.getDataById?.data);
    const loading = useSelector(state => state.entities.instructor?.loading);
    const handleClick = (id)=>{
      setOPenPopUp(true);
      setData(id)
    }
    useEffect(()=>{
      dispatch(getinstructorByIds(params.slug));
    },[params])
  return (
    <BodyContainer pageTitle="Instructor Details" buttonTitle="cancel" route="/instructorsetup">
     {loading && <LoaderSpinner/>}
     <Card cardTitle="Instructor Details">
       <div className='card-body'>
          <label>Instructor Name</label>&nbsp; : &nbsp;<span>{instructor?.instructor_name?instructor?.instructor_name:'N/A'}</span><br/>
          <label>Instructor Email</label>&nbsp; : &nbsp;<span>{instructor?.email?instructor?.email:'N/A'}</span><br/>
          <label>Instructor Image</label>&nbsp; : &nbsp;<ImageViewer image={instructor?.image}/><br/><button className='btn btn-success btn-flat' type='button' onClick={()=>handleClick(instructor?.iid)}><i className='fa fa-edit'></i>&nbsp;edit</button><br/>
          <label>Instructor Mobile No</label>&nbsp; : &nbsp;<span>{instructor?.mobile_no?instructor?.mobile_no:'N/A'}</span><br/>
          <label>Experience</label>&nbsp; : &nbsp;<span>{instructor?.experience?instructor?.experience:'N/A'}</span><br/>
          <label>created by</label>&nbsp; : &nbsp;<span>{instructor?.created_by?instructor?.created_by:'N/A'}</span><br/>
          <label>created date</label>&nbsp; : &nbsp;<span>{instructor?.created_date?instructor?.created_date:'N/A'}</span><br/>
          <label>modified by</label>&nbsp; : &nbsp;<span>{instructor?.modified_by?instructor?.modified_by:'N/A'}</span><br/>
          <label>modified date </label>&nbsp; : &nbsp;<span>{instructor?.modified_date?instructor?.modified_date:'N/A'}</span><br/>
          <label>modified remarks</label>&nbsp; : &nbsp;<span>{instructor?.modified_remarks?instructor?.modified_remarks:'N/A'}</span><br/>
       </div>
    </Card>
    {
      openPopUp &&
      <UpdateInstructorImg openPopUp={openPopUp} setOpenPopUp={setOPenPopUp} instructor={data}/>
    }
    </BodyContainer>
  )
}

export default ViewInstructorsDetails