import React from 'react'
import FormPopUp from '../popup/PopUp'
import { useDispatch, useSelector } from 'react-redux'
import { activateCourse } from '../../store/CategorySetup'
import Spinner from '../Layout/Spinner'

function ConfirmCourseActiveModal({openPopup,setOpenPopup,title,data}) {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.entities?.categorys?.loading)
    const handleActivate = async()=>{
        await dispatch(activateCourse({'courses':data}))
        setOpenPopup(false)
    }
    return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={title}>
        <label>Are you sure to activate course?</label>
        <button className='float-sm-right btn btn-flat btn-success' type="button" onClick={handleActivate} disabled={isLoading}>
           {isLoading && <><Spinner/>&nbsp;</>} yes
        </button>
    </FormPopUp>
  )
}

export default ConfirmCourseActiveModal