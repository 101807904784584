import React,{useEffect,useState} from 'react'
import {Formik,Form,Field,ErrorMessage} from 'formik'
import * as Yup from 'yup'
import TextError from '../../TextError'
import axios from '../../axios'
import { Button} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseBySlug } from '../../store/CourseSetup'
import { useParams } from 'react-router-dom'
import { addSubCourse } from '../../store/SubCourseSetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import ImageUploader from '../Form/ImageUploader'
function SubCourseAdd(props) {
    const {setFetchData,fetchData} = props
    const [course,setCourse] = useState([]); 
    const dispatch = useDispatch();
    const params = useParams();
    const course_data_slug = useSelector(state => state.entities.courses.result)
    const errors = useSelector(state=> state.entities.subcourse.error);
    // const subcourse = useSelector(state=> state.entities.subcourse.result);
    const isLoading = useSelector(state=> state.entities.subcourse.loading);
    useEffect(()=>{
        dispatch(getCourseBySlug(params.slug));
        async function getCourse(){
            const result = await axios.get('/course_category/getCourseSelect')           
            setCourse(result?.data?.data);
        }
        getCourse();
    },[params])
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
    const validationSchema = Yup.object().shape({
        subcourse_name: Yup.string().min(3,'must contain more than 3 letters').max(50,'must have less than 50 charactes').required('This field is required'),
        subcourse_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required'),
        courses: Yup.string().required('courses must be selected '),
        subcourse_icon: Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Subcourse Icon"),
        subcourse_preview_url:Yup.string().url().required().label('subcourse preview url'),
        subcourse_short_description:Yup.string().required('Banner description is required').min(30).label('subcourse short description'),
        lecture_hours:Yup.string().required('lecture hours is required').min(1).max(3).label('lecture hours'),

    })   
    const initialValues = {
        subcourse_name: '',
        subcourse_code:'',
        courses: course_data_slug?.data?.iid,
        subcourse_preview_url:'',
        subcourse_icon:'',
        lecture_hours:'',
        subcourse_short_description:'',
        created_by:localStorage.getItem('username')
    }
    const changeData = (prevData) => setFetchData(!prevData);
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData();
        formData.append('subcourse_name',values.subcourse_name);
        formData.append('subcourse_code',values.subcourse_code);
        formData.append('course_id',values.courses);
        formData.append('subcourse_preview_url',values.subcourse_preview_url);
        formData.append('subcourse_icon',values.subcourse_icon,values.subcourse_icon.name);
        formData.append('lecture_hours',values.lecture_hours);
        formData.append('subcourse_short_description',values.subcourse_short_description)
        formData.append('created_by',localStorage.getItem('username'))
        dispatch(addSubCourse(formData));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
        changeData(fetchData);        

    }
    return (
        <>
        <div className="row">
            <div className="col-md-12">
                <div className="card"> 
                <div className="card-header with-border">
                    <h3 className="card-title">SubCourse Add</h3>
                </div>               
        <Formik 
        initialValues={initialValues} 
        onSubmit={handleSubmit} 
        validationSchema={validationSchema} 
        enableReinitialize
        >
          {(formik)=>(              
                <Form>
                    <div className="card-body">                                  
                            <div className="form-group item">
                            <label className="required-label">SubCourse Title <span className="errors">*</span>:</label>
                            <Field type="text" name = "subcourse_name" id="subcourse_name" placeholder = "subcourse title"  className="form-control"  />
                            <ErrorMessage name="subcourse_name" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_name && errors?.data.subcourse_name}</div>
                            </div> 
                             <div className="form-group item">
                            <label className="required-label">SubCourse Code <span className="errors">*</span>:</label>
                            <Field type="text" name = "subcourse_code" id="subcourse_code" placeholder = "subcourse code"  className="form-control"  />
                            <ErrorMessage name="subcourse_code" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_code && errors?.data.subcourse_code}</div>
                            </div> 
                           
                            <ImageUploader
                            name="subcourse_icon"
                            label="Subcourse Icon"
                            requred = "required"
                            accept="image/jpg, image/gif,image/jpeg,image/png"
                            />
                        
                             <div className="form-group item">
                            <label>Select Course</label>
                            <Field as="select" id="courses" name="courses" placeholder="select option" className="form-control">
                            <option value=''>select option</option>
                             {course.map(option=>{
                                return(
                            <option key={option.iid} value={option.iid}>{option.course_name}</option>
                             )
                             })}          
                        </Field>
                            <ErrorMessage name="courses" component={TextError}/>
                             <div className="errors">{errors?.data?.courses && errors?.data.courses}</div>
                        </div>
                        <div className="form-group item">
                            <label className="required-label">SubCourse Preview Url <span className="errors">*</span>:</label>
                            <Field type="text" name = "subcourse_preview_url" id="subcourse_preview_url" placeholder = "url https://"  className="form-control"  />
                            <ErrorMessage name="subcourse_preview_url" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_preview_url && errors?.data.subcourse_preview_url}</div>
                         </div> 
                        <div className="form-group item">
                            <label className="required-label">Lecture Hours <span className="errors">*</span>:</label>
                            <Field type="text" name = "lecture_hours" id="lecture_hours" placeholder = "lecture hours"  className="form-control"  />
                            <ErrorMessage name="lecture_hours" component={TextError}/>
                             <div className="errors">{errors?.data?.lecture_hours && errors?.data.lecture_hours}</div>
                         </div> 
                        <div className="form-group item">
                            <label className="required-label">SubCourse Description <span className="errors">*</span>:</label>
                            <Field as="textarea" rows="6" name = "subcourse_short_description" id="subcourse_short_description" placeholder = "short description"  className="form-control"  />
                            <ErrorMessage name="subcourse_short_description" component={TextError}/>
                             <div className="errors">{errors?.data?.subcourse_short_description && errors?.data.subcourse_short_description}</div>
                         </div> 
                        <div className="card-footer">
                            <Button type="submit" color="primary" variant="contained" disabled={formik.isSubmitting}>Save</Button>
                        </div>
                    </div>
                </Form>              
             )} 
          </Formik> 
                </div>
            </div>
        </div>
        {isLoading?<LoaderSpinner/>:null}
        
        </>
    )
}

export default SubCourseAdd
