import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { addBatch } from '../../store/EnrollStore';
import TextError from '../../TextError';
import { NEPALIYEAR,ADYEAR } from '../Calendar/NPYear';
import { useState } from 'react';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import Spinner from '../Layout/Spinner';
import { get_user_name } from '../../EncDec';
function BatchAdd({course}) {
    const [year,setYear] = useState(ADYEAR)
    const [courses,setCourse] = useState('');
    const [valueEnd, onChangeEnd] = useState(new Date());
    const [valueEndNep, setValueEndNep] = useState();
    const [valueStartNep, setValueStartNep] = useState();
    const [valueStart, onChanges] = useState(new Date());
    const isLoading = useSelector(state=>state.entities.enroll.loading)
    const dispatch = useDispatch()
    const initialValues = {
        batch_name:'',
        created_by: get_user_name(),
        batch_year:year,
        courses:courses,
        batch_start_date:valueStart,
        batch_start_date_nep: valueStartNep,
        batch_end_date:valueEnd,
        batch_end_date_nep:valueEndNep,
    }
    const validationSchema = Yup.object().shape({
        batch_name: Yup.string().required().label('Batch Name'),
        batch_year: Yup.string().required().label('Batch Year'),
        courses: Yup.string().required().label('Courses'),
        batch_start_date: Yup.string().required().label('Batch Start Date'),
        batch_start_date_nep: Yup.string().required().label('Batch Start Date Nep'),
        batch_end_date: Yup.string().required().label('Batch End Date'),
        batch_end_date_nep: Yup.string().required().label('Batch End Date Nep'),
    })
    const onSubmit = (values,submitProps)=>{
        values.batch_start_date_nep = valueStartNep
        values.batch_end_date_nep = valueEndNep
        values.batch_start_date = valueStart
        values.batch_end_date = valueEnd
        dispatch(addBatch(values))
        submitProps.resetForm()
    }
    const onChange = (bsDate) => {
		setYear(bsDate);
    }
    const handleDate = ({bsDate,adDate})=>{
        setValueStartNep(bsDate)
        onChanges(adDate) 
    }
    const handleCourseClick = (id)=>{
        setCourse(id)
        
    }
    const handleEndDate = ({bsDate,adDate})=>{
        setValueEndNep(bsDate)
        onChangeEnd(adDate) 
    }
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
    enableReinitialize
    >
        {
            ()=>(
                <Form>
                    <div className='form-group item'>
                        <label className='required-label'>Batch Name<span className='errors'>*</span></label>
                        <Field className='form-control' placeholder="batch name" name="batch_name"/>
                        <ErrorMessage name='batch_name' component={TextError}/>
                    </div>
                    <div className='form-group item'>
                    <label>Select Batch year<span className='errors'>*</span>:</label>
                    <Field as="select" name="batch_year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="batch_year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                    </Field> 
                    <ErrorMessage name="batch_year" component={TextError}/>
                    </div>
                    <div className='form-group'>
                                    <label className='required-label'> select course<span className="errors">*</span></label>
                                    <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="courses" component={TextError} />
                    </div>
                    <div className='form-group'>
                        <label>Batch start Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`batch_start_date_nep`}
                            className="form-control"
                            onChange={handleDate}
                            defaultDate={initialValues.batch_start_date_nep}
                            />
                            <ErrorMessage name='batch_start_date_nep' component={TextError}/>
                        </div>
                        <div className='form-group'>
                        <label>Batch End Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`batch_end_date_nep`}
                            className="form-control"
                            onChange={handleEndDate}
                            defaultDate={initialValues.batch_end_date_nep}
                            />
                            <ErrorMessage name='batch_end_date_nep' component={TextError}/>
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isLoading}>
                           {isLoading && <><Spinner/>&nbsp;</>} <i className='fa fa-save'/>&nbsp;Save
                        </button>
                </Form>
            )
        }
    </Formik>
  )
}

export default BatchAdd