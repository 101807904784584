import { FastField, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import requests from '../../ApiRequests/Request';
import axios from '../../axios';
function WebSocketChatApp() {

    const [chatMessage, setChatMessage] = useState()
    let chatData = []
    const initialValues = {
        message: ''
    }
    useEffect(() => {
        console.log("chat", chatMessage)
        console.log("hello")
        
  
        let message = JSON.stringify({
            'message': "just check"
        })
        // console.log("values", values, message)
        let ws = connectWS()
        ws.onopen = () => {
            console.log("open connection")
            ws.send(message)
        }
        ws.close = ()=>{
            console.log("hello")
            const refresh = localStorage.getItem('ref')
            axios.post(requests.refreshToken,{refresh:refresh}).then(response =>{
                localStorage.setItem('acs',response.data?.access);
                let ws1 = connectWS()
        ws1.onopen = () => {
            console.log("open connection")
            ws.send(message)
        }
        ws1.onmessage = (event) => {
            let data = JSON.parse(event.data);
            if (data.type === "chat")
                chatData.push(data)
            setChatMessage(chatData)
            console.log("message", data)
            console.log("chatdata 1", chatData)
        }
        }).catch(err =>{
            console.log("err",err)
            if (err.response?.status === 401) {
            window.location = '/login'
            }
        })
        }
         ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            if (data.type === "chat")
                chatData.push(data)
            setChatMessage(chatData)
            console.log("message", data)
            console.log("chatdata 1", chatData)
             }
            ws.onerror = (event) => {
                console.log("err",event)
                ws.close()
              
            
        }
    }, [])
    function connectWS(){
        const token = localStorage.getItem('acs')
        const url = `${process.env.REACT_APP_WS_URL}/ws/chat/?token=${token}&id=1`
        let ws = new WebSocket(url)
        return ws
    }
    // const handleSubmit = (values, submitProps) => {
        // submitProps.resetForm()
    // }
    return (
        <>
            <div className='card'>
                <div className='card-body' style={{ minHeight: 30 }}>
                    <ul>
                        {
                            chatMessage && chatMessage.map((item, index) => (
                                <li key={index}>
                                    {item?.text}
                                </li>
                            ))
                        }

                    </ul>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                // onSubmit={handleSubmit}
            >
                {
                    ({ }) => (
                        <Form>

                            <div className='row'>
                                <div className='form-group col-md-4'>
                                    <FastField className="form-control" name="message" placeholder="type message here ..." />
                                </div>
                                <div className='form-group col-md-2'>
                                    <button className='btn btn-primary' type="submit">
                                        <i className='fa fa-paper-plane' />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </>
    )
}

export default WebSocketChatApp
