import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {loadCourseSelect} from '../../store/CourseSetup'
import CkeditorField from '../Form/CkeditorField'
import TextError from '../../TextError'
import Spinner from '../Layout/Spinner'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import * as Yup from 'yup';
import { addPassage } from '../../store/QuizSetupStore'
function PassageAdd( ){
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data);
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const initialValues={
        courses:'',
        paragraph_title:'',
        paragraph:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        paragraph_title: Yup.string().min(4).max(100).required().label('paragraph title'),
        paragraph: Yup.string().required().min(20)
    })
    const handleSubmit = (values,submitProps) => {
        values.created_by = localStorage.getItem('username');
        dispatch(addPassage(values))
        submitProps.setSubmitting(false);
    }
  return (
    <BodyContainer pageTitle="Add Passage" buttonTitle="cancel" route="/paragraphSetup">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group' >
                            <label> Select Courses:</label><span className='errors'>*</span>
                            <Field as="select" name="courses" className="form-control">
                                <option value="">Select Options</option>
                                {
                                    course && course.map((item)=>(
                                        <option key={item.iid} value={item.iid}>{item.course_name}</option>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name='courses' component={TextError}/>
                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                        </div>
                        <div className='form-group'>
                            <label>Paragraph Title:</label><span className='errors'>*</span>
                            <FastField name="paragraph_title" className="form-control" placeholder="paragraph title"/>
                            <ErrorMessage name='paragraph_title' component={TextError}/>
                        </div>
                        <div className='form-group'>
                            <label>Paragraph:</label><span className='errors'>*</span>
                                <CkeditorField
                                name="paragraph"                                
                                />
                                <ErrorMessage name='paragraph' component={TextError}/>
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting} >
                                {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </BodyContainer>
  )
}

export default PassageAdd