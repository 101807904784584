import React, { useEffect } from 'react';
import FormField from '../Form/FormField';
import WebForm from '../Form/WebForm';
import Card from '../Layout/Card';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createDepartment, getDepartmentAll } from '../../store/DepartmentSetupStore';
import { FastField} from 'formik';
import { useParams } from 'react-router-dom';
function DepartmentAdd({fetchData,setFetchData}) {
    const department = useSelector(state => state.entities.department.getData.data)
    const params = useParams()
    const errors = useSelector(state => state.entities.department.errors)
    const dispatch = useDispatch()
    const initialValues = {
        department_name: '',
        department_code:'',
        parent:params.slug
    }
    
    const validationSchema = Yup.object().shape({
        department_name: Yup.string().required().min(3).max(50).label('Department Name'),
        department_code: Yup.string().required().min(2).max(7).label("Department Code"),
        })
        const changeData = (prevData )=> setFetchData(!prevData)
        const handleSubmit = (values,submitProps)=>{
            dispatch(createDepartment(values))            
            changeData(fetchData); 
            submitProps.resetForm()          
            submitProps.isSubmitting = false;
        }
   

    useEffect(()=>{
        dispatch(getDepartmentAll())
    },[])
   
   
       
    return (
        <div>
            <WebForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
                <Card cardTitle="Create Department">
                <div>
                <FormField
                divSize="form-group"
                label="Department Name"
                name="department_name"
                requiredField="required"
                placeholder="Department Name"
                />
                <div className="errors">{errors?.data?.department_name && errors?.data.department_name}</div>
                </div>
                <div>                    
                <FormField
                divSize="form-group"
                label="Department Code"
                name="department_code"
                requiredField="required"
                placeholder="Department Code"
                />   
                <div className="errors">{errors?.data?.department_code && errors?.data.department_code}</div>            
                </div>
                <div className="form-group">
                    <label>Parent Department</label>
                <FastField as="select" name="parent" className="form-control">
                    <option value="">Select Parent Department</option>
                    {department && department.map((item)=>(
                        <option key={item.department_code} value={item.id}>{item.department_name+'-'+item.department_code}</option>
                    ))}
                </FastField>
                </div>
                <button className="btn btn-flat btn-success" >Save</button>
                </Card>
            </WebForm>
        </div>
    )
}

export default DepartmentAdd
