import React from 'react'
import FormPopup from '../popup/PopUp' 
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FileUploader from '../Form/FileUploader'
import Spinner from '../Layout/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from '../ProgressBar/ProgressBar';
import { editResource } from '../../store/CourseSetup';
function ResourceEdit({openPopup,setOpenPopup,resource_id,setRefetch}) {
    const dispatch = useDispatch()
    const progress = useSelector(state=>state.entities.courses?.progress)
    const initialValues = {
        resources_id:resource_id,
        resources:''
    }
    const validationSchema = Yup.object().shape({
        resources:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports */pdf)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    })
    const handleSubmit = (values,submitProps) => {
        let formData = new FormData()
        formData.append('resources_id',values.resources_id)
        formData.append('resources',values.resources,values.resources.name)        
        dispatch(editResource(formData))
        submitProps.setSubmitting(false)
        setRefetch(prevState => !prevState)
    }
    const SUPPORTED_FORMATS = [
        "application/pdf"
      ];
  return (
    <FormPopup title="Edit Resources" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <FileUploader name="resources" required="required" label="resources" accept=".pdf"/>
                        {
                            progress &&
                            <ProgressBar percentage={progress}/>
                        }
                        <button className='btn btn-flat btn-success' disabled={isSubmitting}>
                           {isSubmitting && <Spinner/>} <i className='fa fa-edit'/>&nbsp;edit
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopup>
  )
}

export default ResourceEdit