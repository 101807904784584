
import { Paper, Step, StepLabel, Stepper } from '@material-ui/core';
// import { Field, Form, Formik,ErrorMessage } from 'formik';
import React,{ useState } from 'react';
// import * as Yup from 'yup';
import FamilyInfo from './FamilyInfo';
import Generalnfo from './Generalnfo';
import IdentificationInfo from './IdentificationInfo'
import ServiceIntegrationInfo from './ServiceIntegrationInfo';
import AcademicInfo from './AcademicInfo';
import TrainingInfo from './TrainingInfo';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import SkillInfo from './SkillInfo';
import LanguageSkillInfo from './LanguageSkillInfo';
import { useDispatch } from 'react-redux';
import { addEmployee } from '../../store/EmployeeSetup';

export default function MultistepForm(){
    const dispatch = useDispatch();
    const [data,setData] = useState({
    emp_first_name :"",
    emp_middle_name:"",
    emp_last_name:"",
    nationality:"",
    gender:"",
    emp_dob_nep:"",
    emp_dob_eng:"",
    blood_group_id:"",
    id_card_issue_status:"",
    id_card_no:"",
    phone_no:"",
    mobile_no:"",
    mobile_no_official:"",
    emp_email:"",
    email_official:"",
    religion_id:"",
    p_address:[{
    country:"",
    zone:"",
    district:"",
    vdc_municipality:"",
    ward_no:"",
    tole:"",
    house_no:"",
    }],
    t_address:[{
    t_country:"",
    t_zone:"",
    t_district:"",
    t_vdc_municipality:"",
    t_ward_no:"",
    t_tole:"",
    t_house_no:"",
}],
emergency_contact:[{
    emg_name:"",
    contact_no:"",
    relation:"",
    address:"",
    }],
    curr_branch_id:"",
    curr_designation_id: "",
    curr_department_id:"",
    employment_status:"",
    joining_date_nep:"",
    joining_date_eng:"",
    family:[{
        father_name:"",
        mother_name:"",
        grand_mother_name:"",
        grand_father_name:"",
        marital_status: "",
        spouse_name:"",
        marriage_date_nep: "",
        marriage_date_eng: "",
        spouse_dob_eng:"",
        spouse_dob_nep:"",
        have_child:"",
        total_son:"",
        total_daughter:"",
    }],
    academic_info:
    [{
        degree_type:"",
        degree_name:"",
        specialization:"",
        institute_name:"",
        university_board_name:"",
        passed_status:"",
        percentage_gpa:"",
        passed_year_eng:"",
        passed_year_nep:"",        
    }],
    training_info:
    [{
        
        training_name:"",
        training_institute:"",        
        duration:"",
        start_date_nep:"",
        end_date_nep:""        
    }],
    identification:[{
        citizenship_no:"",
        citizenship_issued_date_nep:"",
        citizenship_issued_date_eng:"",
        citizenship_issued_address:"",
        passport_no:"",
        passport_expiry_date:"",
        driving_license_no:"",
        pan_no:"",
        provident_fund_acc_no:"",
        bank_name:"",
        bank_branch:"",
        bank_acc_no:"",
        bank_acc_holder_name:""
      }],
      reference:[{
        ref_person_name:'',
        ref_contact_no:'',
        ref_designation:'',
        ref_company_name:''
    }],
    skills : [
        {
            skill_name: "",
            skill_level: ""
        }
    ],
    language:[

    ]
    });
    const makeRequest=(formdata)=>{
       
        dispatch(addEmployee(formdata))
    }
    const [currentStep,setCurrentStep] = useState(0);
    const handleNextStep = (newData,final=false)=>{
        setData(prev=> ({...prev,...newData}))
        
        if(final){
            makeRequest(newData)
            return
        }
        setCurrentStep(prev=> prev + 1)
    }
    const handlePrevStep = (newData)=>{
        
        setData(prev=> ({...prev,...newData}))
        setCurrentStep(prev=> prev - 1)
    }
   
    const steps = [
    // <StepOne next={handleNextStep} data={data} label="example"/>,
    <GeneralInfoStep next={handleNextStep}  data={data} label="General Info"/>,
    // <SkillInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Skill Info"/>,
    <IdentificationInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Identification Info"/>,
    <FamilyInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Family Info"/>,
    <ServiceIntegrationInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Service Integration"/>,
    <AcademicInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Academic Info"/>,
    <TrainingInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Training Info"/>,
    <SkillInfoStep next={handleNextStep} prev={handlePrevStep} data={data} label="Skill Info"/>,
    <LanguageSkillInfoStep next={makeRequest} prev={handlePrevStep} data={data} label="LanguageSkill Info"/>,
    // <StepTwo next={handleNextStep} prev={handlePrevStep} data={data} label="last"/>
]
    return(
        <>
            <BodyContainer>
                <Paper style={{width:"100%"}}>
             <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((child,index) => (
            <Step key={index}>
            <StepLabel>{child.props.label}</StepLabel>
            </Step>
            ))}
             </Stepper>
             </Paper>
            {steps[currentStep]}
           
            </BodyContainer>
        </>
    )
}

// const StepOneValidation = Yup.object({
//     first_name: Yup.string().required().min(3),
//     last_name: Yup.string().required().min(3),
// })
// const StepOne = (props)=>{
//     const handleSubmit = (values)=>{
//         console.log("value",values)
//         props.next(values)
        
//     }
//     return(
//         <Formik
//         initialValues={props.data}
//         onSubmit = {handleSubmit}
//         validationSchema={StepOneValidation}
//         enableReinitialize
//         >
//             {()=>(
//                 <Form>
//                     <p>First Name</p>
//                     <Field name="first_name"/>
//                     <ErrorMessage name="first_name"/>
//                     <p>Last Name</p>
//                     <Field name="last_name"/>
//                     <button type="submit">Next</button>
//                 </Form>
//             )}
//         </Formik>
//     )
// }

// const StepTwoValidation = Yup.object({
//     email: Yup.string().required().email()
// })

// const StepTwo = (props)=>{
//     const handleSubmit = (values)=>{
//         props.next(values,true)
//     }
//     return(
//         <Formik
//         initialValues={props.data}
//         onSubmit={handleSubmit}
//         validationSchema={StepTwoValidation}
//         enableReinitialize
//         >
//             {({values})=>(
//                 <Form>
//                     <p>Email</p>
//                     <Field name="email"/>
//                     <p>Password</p>
//                     <Field name="password"/>
//                     <button type="button" onClick={()=>props.prev(values)}>Back</button>
//                     <button type="submit" >submit</button>
//                 </Form>
//             )}
//         </Formik>
//     )
// }

const GeneralInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        console.log(values,"values")
        props.next(values)
    }    
    return(
        <>
        <Generalnfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const IdentificationInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }    
    return(
        <>
        <IdentificationInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const FamilyInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }   
    return(
        <>
        <FamilyInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const ServiceIntegrationInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }
    
    return(
        <>
        <ServiceIntegrationInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const AcademicInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }
   
    return(
        <>
        <AcademicInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const TrainingInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }
   
    return(
        <>
        <TrainingInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const SkillInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values)
    }
    
    return(
        <>
        <SkillInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}
const LanguageSkillInfoStep = (props)=>{
    const handleSubmit = (values) =>{
        props.next(values,true)
    }   
    return(
        <>
        <LanguageSkillInfo handleSubmit={handleSubmit} prev={props.prev} data={props.data}/>
        </>
    )
}