import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCourseSelect } from '../../store/CourseSetup'
import * as Yup from 'yup';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import Card from '../Card/Card';
import { ErrorMessage, FastField, Field, Form, Formik } from 'formik';
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner';
import {  editFAQ,  loadFAQById,  } from '../../store/CourseProgramSetup';
import { useParams } from 'react-router-dom';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function FAQEdit() {
    const params = useParams()
    const dispatch = useDispatch()
    const course = useSelector(state=>state.entities.courses?.result?.data)
    const errors = useSelector(state=>state.entities.courses?.error?.data)
    const faq = useSelector(state=>state.entities.course_program?.getData?.data)
    const loading = useSelector(state=>state.entities.course_program?.loading)
    useEffect(()=>{
        dispatch(loadCourseSelect());
        dispatch(loadFAQById(params.slug))
    },[params])
    const initialValues = {
        faq_title:faq?.faq_title,
        faq_desc:faq?.faq_desc,        
        courses:faq?.course_iid,
        priority_order:faq?.priority_order,
        modified_remarks: ''

    }
    const validationSchema = Yup.object().shape({
        faq_title: Yup.string().required().min(10).max(200).label('faq title'),
        faq_desc: Yup.string().required().min(10).max(200).label('faq description'),
        courses: Yup.string().required().label('courses'),
        priority_order: Yup.number().required().label('priority order'),
        modified_remarks:Yup.string().required().min(5).max(100).label('modified remarks')
    })
    const handleSubmit = (values,submitProps)=>{
        values.faq_id = faq?.iid
        values.modified_by = localStorage.getItem('username');
        dispatch(editFAQ(values));
        submitProps.setSubmitting(false);
        
    }
  return (
    <BodyContainer pageTitle="FAQ Edit" buttonTitle='cancel' route='/faq'>
        {loading && <LoaderSpinner/>}
        <div className='row'>
            <div className='col-md-7'>
            <Card cardTitle="FAQ Edit">
                
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                            <div className='card-body'>
                            <div className='form-group'>
                                <label>FAQ Title</label><span className='errors'>*</span>:
                                <FastField name="faq_title" className='form-control' placeholder='faq title'/>
                                <ErrorMessage name='faq_title' component={TextError}/>
                                {errors?.faq_title && <div className="errors">{errors?.faq_title}</div>}
                            </div>
                            <div className='form-group'>
                                <label>Priority Order</label><span className='errors'>*</span>:
                                <FastField name="priority_order" className='form-control' placeholder='priority order'/>
                                <ErrorMessage name='priority_order' component={TextError}/>
                                {errors?.priority_order && <div className="errors">{errors?.priority_order}</div>}
                            </div>
                          
                            <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control'  >
                                    <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                ))
                                            }
                                        </Field>
                                    <ErrorMessage name="courses" component={TextError} />
                                    {errors?.courses && <div className="errors">{errors?.courses}</div>}
                            </div>
                            <div className='form-group'>
                                <label>FAQ Description</label><span className='errors'>*</span>:
                                <Field as="textarea" rows="9" name="faq_desc" className='form-control' placeholder='faq description'/>
                                <ErrorMessage name='faq_desc' component={TextError}/>
                                {errors?.faq_desc && <div className="errors">{errors?.faq_desc}</div>}
                            </div>
                            <div className='form-group'>
                                <label>Modified Remarks</label><span className='errors'>*</span>:
                                <FastField name="modified_remarks" className='form-control' placeholder='modified remarks'/>
                                <ErrorMessage name='modified_remarks' component={TextError}/>
                                {errors?.modified_remarks && <div className="errors">{errors?.modified_remarks}</div>}
                            </div>
                            </div>
                            <div className='card-footer'>
                                <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>{isSubmitting?<Spinner/>:''}<i className='fa fa-save'></i>save</button>
                            </div>
                            </Form>
                        )
                    }
                </Formik>
            </Card>
            </div>
        </div>
    </BodyContainer>
  )
}

export default FAQEdit