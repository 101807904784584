import React from 'react';
import Tree from 'react-animated-tree';
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import VisibilityIcon from '@material-ui/icons/Visibility';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import {Link } from 'react-router-dom';
import './style.css';
import { useState } from 'react';
import ConfirmCourseActiveModal from './ConfirmCourseActiveModal';
import ConfirmCourseInActiveModal from './ConfirmCourseInActiveModal';
import UpdatedDateCourseModal from './UpdatedDateCourseModal';

function MultiSelectTreeView({course}) {
  const [livepopup,setOpenPopupLive] = useState(false);
  const [inactivepopup,setOpenPopupInactive] = useState(false);
  const [datePopup,setOpenDatePopup] = useState(false);
  const [data,setData] = useState()
  const treeStyles = {
  color: 'white',
  fill: 'white',
  width: '100%'
}

let category,courses,subcourses,main_topic,lessons,sub_topic;

  const handleCourseActive = (data)=>{
    console.log("clicked data",data)
    setOpenPopupLive(true);
    setData(data);
  } 
  const handleCourseInActive=(data)=>{
    setOpenPopupInactive(true);
    setData(data)
  } 

  const handleDatePopup=(data)=>{
    setOpenDatePopup(true);
    setData(data)
  }
  return (
  
    <div className="card" style={{backgroundColor:'#343a40',padding:15,minHeight:300}}>
    <div style={{padding:'10'}}>
      {
        Object.keys(course).map((key,index)=>{
          courses = course[key]['courses']
          console.log("courses",courses)
          return (       
              <Tree
             key={key} 
             content={course[key]['category_name']+'-'+ course[key]['course_categories_code']}
             type={[
              <Link key={course[key]['category_slug']}  to={`/coursesetup/add_course/${course[key]['category_slug']}`} > <AddRoundedIcon key={course[key]['course_categories_code']}/></Link>,
              <Link key={course[key]['category_id']} to={`/coursesetup/category_edit/${course[key]['category_slug']}`}><EditRoundedIcon  /></Link>,
              <Link key={course[key]['category_id']} to={`/coursesetup/viewCategoryDetails/${course[key]['category_slug']}`}><VisibilityIcon  /></Link>
             ]}
             style={treeStyles}
             >
               {
                 Object.keys(courses).map((courseItm,index)=>{
                  console.log("courrses a sas ads",courses[courseItm]['course_id'])
                  let course_id = courses[courseItm]['course_id']
                  subcourses= courses[courseItm]['subcourse']                  
                  return(
                    <Tree
                    key={courses['course_name']} 
                    content={courses[courseItm]['course_name']+'-'+ courses[courseItm]['course_code']}
                    type={[
                      <Link key={courses[courseItm]['course_slug']}  to={`/coursesetup/add_subcourse/${courses[courseItm]['course_slug']}`} > <AddRoundedIcon/></Link>,
                      <Link key={courses[courseItm]['course_id']} to={`/coursesetup/edit_course/${courses[key]['course_slug']}`}><EditRoundedIcon  /></Link>,
                      <Link key={courses[courseItm]['course_id']} to={`#`}><EditCalendarIcon onClick={()=>handleDatePopup(course_id)}/></Link>,
                      <Link key={courses[courseItm]['course_id']} to={`#`}>{courses[courseItm]['is_course_live'] === 'y'?<AirplanemodeActiveIcon onClick={()=>handleCourseInActive(course_id)}/>:<AirplanemodeInactiveIcon onClick={(e)=>handleCourseActive(course_id)}/>}</Link>,
                      <Link key={courses[courseItm]['course_id']} to={`/coursesetup/viewCourseDetails/${courses[courseItm]['course_slug']}`}><VisibilityIcon  /></Link>
                    ]}
                    style={treeStyles}
                    >
                      {
                        Object.keys(subcourses).map((key,index)=>{
                          main_topic = subcourses[key]['main_topic']
                          return(
                            <Tree
                            key={subcourses['subcourse_name']} 
                            content={subcourses[key]['subcourse_name']+'-'+ subcourses[key]['subcourse_code']}
                            type={[
                              <Link key={subcourses[key]['subcourse_slug']}  to={`/coursesetup/addMainTopic/${subcourses[key]['subcourse_id']}`} > <AddRoundedIcon/></Link>,
                              <Link key={subcourses[key]['subcourse_id']} to={`/coursesetup/edit_subcourse/${subcourses[key]['subcourse_slug']}`}><EditRoundedIcon  /></Link>,
                              <Link key={subcourses[key]['subcourse_id']} to={`/coursesetup/viewSubCourseDetails/${subcourses[key]['subcourse_slug']}`}><VisibilityIcon  /></Link>
                            ]}
                            style={treeStyles}
                            >
                              {
                                Object.keys(main_topic).map((key,index)=>{
                                  lessons = main_topic[key]['lesson']
                                  return(
                                    <Tree
                                    key={main_topic['main_topic']} 
                                    content={main_topic[key]['main_topic']}
                                    type={[
                                      <Link key={main_topic[key]+main_topic[key]['main_topic']}  to={`/coursesetup/addLessons/${main_topic[key]['main_topic_id']}`} > <AddRoundedIcon/></Link>,
                                      <Link key={main_topic[key]['main_topic_id']} to={`/coursesetup/editMainTopic/${main_topic[key]['main_topic_id']}`}><EditRoundedIcon  /></Link>,
                                      <Link key={main_topic[key]['main_topic_id']} to={`/coursesetup/viewMainTopicDetails/${main_topic[key]['main_topic_id']}`}><VisibilityIcon  /></Link>
                                    ]}
                                    style={treeStyles}
                                    >
                                      {
                                        Object.keys(lessons).map((key,index)=>{
                                          sub_topic = lessons[key]['sub_topic']
                                          return(
                                            <Tree
                                            key={lessons['lesson_title']} 
                                            content={lessons[key]['lesson_title']}
                                            type={[
                                              <Link key={lessons[key]+lessons[key]['lesson_title']}  to={`/coursesetup/addSubTopic/${lessons[key]['lesson_id']}`} > <AddRoundedIcon/></Link>,
                                              <Link key={lessons[key]['lessons_id']} to={`/coursesetup/editLessons/${lessons[key]['lesson_id']}`}><EditRoundedIcon  /></Link>,
                                              <Link key={lessons[key]['lessons_id']} to={`/coursesetup/viewLessonsDetails/${lessons[key]['lesson_id']}`}> <VisibilityIcon /></Link>,
                                              <Link key={lessons[key]['lessons_id']} to={`/coursesetup/addResources/${lessons[key]['lesson_id']}`}><InsertDriveFileOutlined /></Link>
                                            ]}
                                            style={treeStyles}
                                            >
                                              {
                                                Object.keys(sub_topic).map((skey)=>{
                                                  return(
                                                    <Tree
                                                    key={sub_topic[skey]['topic_title']} 
                                                    content={sub_topic[skey]['topic_title']}
                                                    type={[
                                                      // <Link key={sub_topic[key]+sub_topic[key]['topic_title']}  to={`/coursesetup/add_course/${sub_topic[key]['sub_topic_id']}`} > <AddRoundedIcon/></Link>,
                                                      <Link key={sub_topic[skey]['sub_topic_id']} to={`/coursesetup/editSubTopic/${sub_topic[skey]['sub_topic_id']}`}> <EditRoundedIcon  /></Link>,
                                                      <Link key={sub_topic[skey]['sub_topic_id']} to={`/coursesetup/viewSubTopicDetails/${sub_topic[skey]['sub_topic_id']}`}><VisibilityIcon  /></Link>
                                                      
                                                    ]}
                                                    style={treeStyles}
                                                    
                                                    />
                                                  )
                                                })
                                              }
                                              </Tree>
                                          )
                                        })
                                      }
                                      </Tree>
                                  )
                                })
                              }
                              </Tree>
                          )
                        })
                      }
                    </Tree>
                  )
                 })
               }
               </Tree>
          )
        })
      }        
      </div>
      {
        livepopup &&
        <ConfirmCourseActiveModal openPopup={livepopup} setOpenPopup={setOpenPopupLive} title={`Activate Course`} data={data}/>        
      }
      {
        inactivepopup&& 
        <ConfirmCourseInActiveModal openPopup={inactivepopup} setOpenPopup={setOpenPopupInactive} title={'Deactivate Course'} data={data}/>
      }
      {
        datePopup &&
        <UpdatedDateCourseModal openPopup={datePopup} setOpenPopUp={setOpenDatePopup} title={'update course date'} data={data}/>
      }
    </div>  
  )
}

export default MultiSelectTreeView
