import { Form, Formik } from 'formik'
import MathJax from 'mathjax3-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { activateLessonQuiz } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'

function ActivateLessonQuiz({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.quiz?.loading)
    const initialValues = {
        'tag_id': data?.tag_id,        
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(activateLessonQuiz(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Activate Lesson Quiz">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>Question:</label>
                        <MathJax.Html html={data?.question}/>                        
                        <label>Do you want to Activate this quiz?</label><br/>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-key'/>&nbsp;activate
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ActivateLessonQuiz