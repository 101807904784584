import React, { useState } from 'react'
import * as Yup from 'yup';
import TextError from '../../TextError';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { getBatchSelect } from '../../store/EnrollStore';
import { ADYEAR, NEPALIYEAR } from '../Calendar/NPYear';
import Spinner from '../Layout/Spinner';
import { createLectureRecording, updateLectureRecording } from '../../store/CourseSetup';
function LiveRecordingEdit({course,rowData}) {
    const [courses,setCourse] = useState(rowData?.course_iid);
    const [nepDate,setNepDate] = useState(rowData?.class_date_nep);
    const [engDate,setEngDate] = useState(rowData?.class_date)    
    const filterBatch = useSelector(state=>state.entities.enroll?.batchSelect?.data)
    const is_Loading = useSelector(state=>state.entities.courses?.loading)
    const isLoading = useSelector(state=>state.entities.enroll?.loading)
    const [year,setYear] = useState(rowData?.batch_year)
    const [batch,setBatch] = useState(rowData?.group_iid)
    const dispatch = useDispatch()
    const onChange = (bsDate) => {
		setYear(bsDate);
        if(courses){
        dispatch(getBatchSelect({'year':bsDate,'courses':courses}))
        }        
	};
    const handleCourseClick = (id)=>{
        setCourse(id) 
        if(year){
        dispatch(getBatchSelect({'year':year,'courses':id}))
        }       
    }
    const handleBatch = (value)=>{
        setBatch(value)
    }
    const handleDate = ({bsDate,adDate})=>{
        setNepDate(bsDate)
        setEngDate(new Date(adDate)) 
    }
    const initialValues = {
        iid:rowData?.iid,
        year:year,
        courses:courses,
        group:batch,
        topics:rowData?.topics,
        class_date_nep:nepDate,
        video_url:rowData?.video_url,
        modified_remarks:''
    }
    const validationSchema = Yup.object().shape({
        year: Yup.string().required(),
        courses: Yup.string().required(),
        group: Yup.string().required(),
        topics: Yup.string().required(),
        class_date_nep: Yup.date().required('Date is required'),
        video_url: Yup.string().url().required(),
        modified_remarks: Yup.string().required().min(5).max(100).label('modified remarks')
    })
    const handleSubmit = (values)=>{
        values.class_date = engDate
        dispatch(updateLectureRecording(values))
    }
  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
    enableReinitialize
    >
        {
            ()=>(
                <Form>
                    <div className='row'>
                        <div className='form-group col-md-8'>
                        <label className='required-label'> select course<span className="errors">*</span></label>
                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                            <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                    }
                        </Field>
                        <ErrorMessage name="courses" component={TextError} />
                        </div>
                        </div>
                        <div className='row'>
                        <div className='form-group col-md-8'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="year" component={TextError}/>
                            </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-md-8'>
                        <label className='required-label'> select batch<span className="errors">*</span></label>
                        <Field as="select" name="group" id="group" placeholder="" className='form-control' value={batch}  onChange={(e)=>handleBatch(e.target.value)} >
                            <option name="group" value="">select Options</option>
                                    {
                                        filterBatch && filterBatch?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.batch_name}</option>
                                            ))
                                    }
                        </Field>
                        <ErrorMessage name="group" component={TextError} />
                        </div>
                    </div>
                    <div className='row'>
                    <div className='form-group col-md-8'>
                        <label>class Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`class_date_nep`}
                            className="form-control"
                            onChange={handleDate}
                            defaultDate={initialValues.class_date_nep}
                            />
                            <ErrorMessage name="class_date_nep" component={TextError}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-12'>
                            <label>topics:<span className='errors'>*</span></label>
                            <Field name="topics" className="form-control" placeholder="topics with coma separated eg:abc,cdc,"/>
                            <ErrorMessage name='topics' component={TextError}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-8'>
                            <label>video url:<span className='errors'>*</span></label>
                            <Field name="video_url" className="form-control" placeholder="https://www.example.com"/>
                            <ErrorMessage name='video_url' component={TextError}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='form-group col-md-12'>
                            <label>modified remarks:<span className='errors'>*</span></label>
                            <Field name="modified_remarks" className="form-control" placeholder="modified remarks"/>
                            <ErrorMessage name='modified_remarks' component={TextError}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-success btn-flat" type="submit" disabled={isLoading}>
                            {is_Loading && <><Spinner/>&nbsp;</>} save
                        </button>
                    </div>
                </Form>
            )
        }
    </Formik>
  )
}

export default LiveRecordingEdit