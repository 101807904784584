import React, { useEffect, useState } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { loadCourseSelect } from '../../store/CourseSetup';
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner';
import {ADYEAR,NEPALIYEAR} from '../Calendar/NPYear'
import DataTable from 'react-data-table-component';
import UpdateModelsetBatchTag from './UpdateModelsetBatchTag';
import { getFilteredBatchModelset } from '../../store/QuizSetupStore';
import ModelsetBatchTagDelete from './ModelsetBatchTagDelete';
import TagLiveQuiz from '../QuizLiveTag/TagLiveQuiz';
function ModelsetBatchTagSetup() {
    const [year,setYear] = useState(ADYEAR);
    const [editPopup,setEditPopup] = useState(false)
    const [deletePopup,setDeletePopup] = useState(false)
    const [tagLivePopup,setTagLivePopup] = useState(false)
    const [tagData,setTagData] = useState()
    const [courses,setCourse] = useState('');
    const dispatch = useDispatch()
    const batchData = useSelector(state => state.entities.quiz.batchModelset?.data);
    const isLoading = useSelector(state => state.entities?.quiz?.loading)
    const course = useSelector(state => state.entities.courses.result.data);
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const handleActions = (item)=>{
        setTagData(item);
        setEditPopup(true);
    }
    const onChange = (bsDate) => {
		setYear(bsDate);
        
	};
    const handleCourseClick = (id)=>{
        setCourse(id)
        
    }
    const handleModelsetBatch = (data)=>{
        setTagData(data);
        setDeletePopup(true);
    }
    const handleModelsetBatchLive = (data)=>{
        setTagData(data);
        setTagLivePopup(true);
    }
    const COLUMNS = [
        {
            name:'sn',
            selector: 'sn',
            sortable:true,
            cell: (row,index)=>
                <div>
                    {index+1}
                </div>
        },
        {
            name:'Modelset Name',
            selector: row => row.modelset_name,
            
        },
        {
            name:'Batch Name',
            selector: row => row.batch_name,
            
        },
        {
            name:'Year',
            selector: row => row.year,
            
        },
        {
            name:'Actions',
            selector: 'Actions',
            cell:(row)=>
            <>
            <button className='btn-sm btn-warning' type='button' onClick={()=>handleModelsetBatchLive(row)} style={{marginRight: '5px'}}>
                <i className='fa fa-stream'/>
            </button>
            <button className='btn-sm btn-primary' type='button' onClick={()=>handleActions(row)} style={{marginRight: '5px'}}>
                <i className='fa fa-edit'/>
            </button>
            <button className='btn-sm btn-danger' type='button' onClick={()=>handleModelsetBatch(row)}>
                <i className='fa fa-trash'/>
            </button>
            </>
        },
    ]
    const initialState = {
        year:year,
        courses:courses
    }
    const validationScema = Yup.object().shape({
            courses:Yup.string().required(),
            year:Yup.string().required()
    })
    const handleSubmit = (values) =>{
        dispatch(getFilteredBatchModelset(values))
    }
  return (
    <BodyContainer pageTitle={`Modelset Batch Tag Setup`}>
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialState}
                validationSchema={validationScema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ()=>(
                            <Form>
                                <div className='row'>
                                    
                                    <div className='col-md-4'>
                                        <div className='form-group item'>
                                        <label className='required-label'>Select Course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)}  >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            
                                    </div>
                                    </div>
                                    <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label>Year</label>:<span className='errors'>*</span>
                                        <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                          <option name="year" value="">select Options</option>
                                          {
                                              NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                                  <option key={index} value={item}>{item}</option>
                                              ))
                                          }
                                        </Field> 
                                        <ErrorMessage name='year' component={TextError}/>
                                    </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <div className='form-group'>
                                                <label>&nbsp;</label>
                                                <button type='submit' className='btn btn-success btn-flat form-control' disabled={isLoading}>
                                                    {isLoading?<Spinner/>:''}&nbsp;<i className='fa fa-search'/>&nbsp;Search
                                                    </button>
                                        </div>
                                    </div>
                                    </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
                <DataTable
                columns={COLUMNS}
                data={batchData}
                fixedHeader={true}
                fixedHeaderScrollHeight='450px'
                pagination
                
                />
            </div>
        </div>
        {
            editPopup &&
            <UpdateModelsetBatchTag openPopup={editPopup} setOpenPopup={setEditPopup} batchData={tagData} course={course} title={`Update Modelset Batch Tag`}/>
        }
        {
            deletePopup &&
            <ModelsetBatchTagDelete openPopup={deletePopup} setOpenpopup={setDeletePopup} tagData={tagData} title={`Delete Modelset Batch Tag`}/>
            
        }
        {
            tagLivePopup &&
            <TagLiveQuiz openPopup={tagLivePopup} setOpenPopup={setTagLivePopup} modelset={tagData}/>
        }
    </BodyContainer>
  )
}

export default ModelsetBatchTagSetup