import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFeature } from '../../store/FeatureSetupStore';
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import ImageUploader from '../Form/ImageUploader';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function FeatureAdd({openPopUp,setOpenPopUp}) {
    const dispatch = useDispatch();
    const errors = useSelector(state => state.entities.feature?.error?.data);
    const loading = useSelector(state => state.entities.feature?.loading);
    const initialValues = {
        features_title:'',
        features_icon:'',
        features_desc:'',
        user_get:''
    }
    const handleSubmit = (values,submitProps) =>{
        let formData = new FormData()
        formData.append('features_title',values.features_title);
        if(values.features_icon){

            formData.append('features_icon',values.features_icon,values.features_icon.name);
        }else{
            formData.append('features_icon',values.features_icon);

        }
        formData.append('features_desc',values.features_desc);
        formData.append('user_get',values.user_get);
        formData.append('created_by',localStorage.getItem('username'));
        dispatch(addFeature(formData));
        submitProps.setSubmitting(false);
        submitProps.resetForm();
    }
    
    const validationSchema = Yup.object().shape({
        features_title: Yup.string().required().min(4).max(30).label("feature title"),
        features_desc:Yup.string().required().min(15).max(200).label("feature desc"),
        user_get:Yup.string().required().min(15).max(200).label("details"),
        // features_icon:Yup.mixed().test(
        //     "fileFormat",
        //     "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
        //     value => value && SUPPORTED_FORMATS.includes(value.type)
        // ).label("features icon"),

    })
  return (
    <FormPopUp title="Feature Add" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=> (
                    <Form>
                        <div className='form-group'>
                            <label>Feature Title </label><span className='errors'>*</span>
                            <Field name="features_title" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='features_title' component={TextError}/>
                            {errors?.features_title && <div className='errors'>{errors?.features_title}</div>}
                        </div>
                        <ImageUploader
                        name="features_icon"
                        required="required"
                        label="Features Icon"
                        />
                        <div className='form-group'>
                            <label>Feature Description </label><span className='errors'>*</span>
                            <Field as="textarea" rows="5" name="features_desc" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='features_desc' component={TextError}/>
                            {errors?.features_desc && <div className='errors'>{errors?.features_desc}</div>}
                        </div>
                        <div className='form-group'>
                            <label>Details </label><span className='errors'>*</span>
                            <Field as="textarea" rows="5" name="user_get" className='form-control' placeholder="features title"/>
                            <ErrorMessage name='user_get' component={TextError}/>
                            {errors?.user_get && <div className='errors'>{errors?.user_get}</div>}
                        </div>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/> &nbsp; save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default FeatureAdd