import React from "react";
import FormPopUp from "../popup/PopUp";
import MathJax from 'mathjax3-react'
import { useDispatch, useSelector } from "react-redux";
import { deleteQuiz } from "../../store/QuizSetupStore";
import Spinner from "../Layout/Spinner";
function QuizDelete({openPopUp,setOpenPopUp,question}){
    
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.quiz?.loading);
    const handleConfirm=async()=>{
        await dispatch(deleteQuiz(question?.id))
        setOpenPopUp(false);
    }
    return(
        <FormPopUp openPopup={openPopUp} setOpenPopup={setOpenPopUp} title={`Delete Quiz Confirmation`} >
            <label>Are you sure to delete quiz?</label><br/>
            <span>Note: it will delete the quiz permanently.</span>
            <MathJax.Html html={question?.question}/>
            <button type="button" className="btn btn-success" onClick={handleConfirm}>
               {
                loading && <Spinner/> 
               }&nbsp; confirm
            </button>
        </FormPopUp>
    )
}
export default QuizDelete;