import { Form, Formik } from 'formik'
import React from 'react';
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { editBannerImg } from '../../store/BannerSetupStore'
import ImageUploader from '../Form/ImageUploader'
function MobileBannerImgUpdate({openPopup,setOpenPopup,banner}) {
    const dispatch = useDispatch();
    const initialValues = {
        banner_img:''
    }
    const handleSubmit = (values,submitProps)=>{
        let formData = new FormData()
        formData.append('banner_id',banner?.iid);
        formData.append('banner_img',values.banner_img,values.banner_img.name);
        dispatch(editBannerImg(formData))
        submitProps.setSubmitting(false);
    }
    const validationSchema = Yup.object().shape({
        banner_img:Yup.mixed().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("banner image"),
    })
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
      ]; 
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Banner Img Update">
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <ImageUploader
                        name="banner_img"
                        label="Banner Img"
                        required = "required"
                        />
                        <button className='btn btn-success btn-flat' type='submit'>
                            {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>Save
                        </button>
                    </Form>
                    )
            }
        </Formik>
    </FormPopUp>
  )
}

export default MobileBannerImgUpdate