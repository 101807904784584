import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import CkeditorField from '../Form/CkeditorField'
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import TextError from '../../TextError';
import { createDiscussion, getDiscussionList } from '../../store/DiscussionStore';
import { useDispatch } from 'react-redux';
import { get_user_name } from '../../EncDec';
function ReplyDiscussion({parentId,subtopic_id,lesson_id,main_topic_id,subcourse_slug,handleCloseSingleReplies}) {
    const dispatch = useDispatch()
    const initialValues = {
        parent:parentId,
        topic_id:subtopic_id,
        lesson_id:lesson_id,
        main_topic:main_topic_id,
        subcourse_slug:subcourse_slug,
        comments:''
        
    }
    const validationSchema = Yup.object().shape({
        topic_id:Yup.string().required().label('topic'),
        lesson_id:Yup.string().required().label('lesson'),
        main_topic:Yup.string().required().label('main topic'),
        comments:Yup.string().required().label('comment'),
    }) 
    const handleSubmit = async(values,submitProps)=>{
        console.log("values",values)
        await dispatch(createDiscussion(values));
           initialValues.comments = ''
        await dispatch(getDiscussionList(subtopic_id))
        handleCloseSingleReplies()
        submitProps.resetForm();
        submitProps.setSubmitting(false)
    }
  return (
    <div className='row'>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='row'>
                        <div style={{height:50,width:50,borderRadius:999,borderWidth:3,borderColor:'#42a5ff',backgroundColor:'greenyellow',marginRight:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <p style={{fontSize:28,fontWeight:'600'}}>{get_user_name()?get_user_name().charAt(0):'A'}</p>
                        </div>
                        <CkeditorField name={`comments`}/>

                        </div>
                        <ErrorMessage name='comments' component={TextError}/>
                        <div className='float-sm-right' style={{marginTop:10}}>
                        <button className='btn btn-flat btn-success ' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} Reply
                        </button>
                        </div>
                    </Form>
                )
            }
        </Formik>
    </div>
  )
}

export default ReplyDiscussion