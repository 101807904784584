import React from 'react'
import FormPopUp from '../popup/PopUp'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Layout/Spinner';
import { deactivateCourse } from '../../store/CategorySetup';

function ConfirmCourseInActiveModal({openPopup,setOpenPopup,title,data}) {
    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.entities?.categorys?.loading);
    const handleActivate = async ()=>{
        await dispatch(deactivateCourse({'courses':data}))
        setOpenPopup(false);
    }
    return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={title}>
        <label>Are you sure to activate course?</label>
        <button className='float-sm-right btn btn-flat btn-success' type="button" disabled={isLoading} onClick={handleActivate}>
           {isLoading && <><Spinner/>&nbsp;</>} yes
        </button>
    </FormPopUp>
  )
}

export default ConfirmCourseInActiveModal