import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { restoreFeatureTag } from '../../store/FeatureSetupStore';
import FormPopUp from '../popup/PopUp'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function RestoreFeature({openPopUP,setOPenPopUP,tagData}) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.feature?.loading)
    const initialValues = {
        tag_id:tagData?.iid
    }
    const handleSubmit = (values,submitProps) => {
        dispatch(restoreFeatureTag(values));
        submitProps.setSubmitting(false);
    }
  return (
    <FormPopUp title="Feature Restore" openPopup={openPopUP} setOpenPopup={setOPenPopUP}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>Are you sure to restore {tagData?.features_title} for course {tagData?.course_name}?</label>
                        <button className='btn btn-success btn-flat' disabled={isSubmitting}><i className='fa fa-undo'></i>&nbsp;Restore</button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default RestoreFeature