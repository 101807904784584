import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteBatch } from '../../store/EnrollStore'
import Spinner from '../Layout/Spinner'

function BatchDeleteConfirm({batchData}) {
    const dispatch= useDispatch()
    const isLoading = useSelector(state=>state.entities.enroll.loading)

    const handleDelete = (id)=>{
        dispatch(deleteBatch(id))
    }
  return (
    <>
    <div className='row'>
        <p>Are you sure to delete batch?</p>

    </div>
    <div className='row'>
        <label >Batch Name</label>:<label>{batchData?.batch_name}</label><br/>
    </div>
    <div className='row'>
        <label >Batch Start Date</label>:<label>{batchData?.batch_start_date_nep}</label><br/>
    </div>
    <div className='row'>
        <label >Batch End Date</label>:<label>{batchData?.batch_end_date_nep}</label>
    </div>
    <div className='float-sm-right'>
        <button className='btn btn-flat btn-success' type='button' onClick={()=>handleDelete(batchData?.iid)} disabled={isLoading}>
           {isLoading && <><Spinner/>&nbsp;</>} delete
        </button>
    </div>
    </>
  )
}

export default BatchDeleteConfirm