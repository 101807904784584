import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDataTable from '@ashvin27/react-datatable';
import RestoreFeature from './RestoreFeature';
import { loadCourseSelect } from '../../store/CourseSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function RestoreFeatureTagSetup() {
  
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)  
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const feature = useSelector(state => state.entities.feature?.result?.data);
  const loading = useSelector(state => state.entities.feature?.loading);
  
  const handleEdit = (data)=>{     
    setEData(data);
    setOpenPopUpEdit(true)
  } 
   
  useEffect(()=>{
    dispatch(loadCourseSelect());
  },[openPopUpEdit])
  const columns = [
    {
      key:'features_title',
      text:"Feature Title"
    },
    {
      key:'course_name',
      text:"Course Name"
    },    
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}
                      key={record?.iid}
                      >
                      <i className="fa fa-undo"></i>
                  </button>                  
              </>
          );
      }
  }
  ]
  return (
    <>
    {loading && <LoaderSpinner/>}
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0"> Restore Feature Setup</h1>
                 </div>
           
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
      
        <ReactDataTable        
        columns = {columns}
        records = {feature}
        />
        {/* <InstructorAdd openPopUP={openPopUp} setOpenPopUp={setOpenPopUp}/> */}
        {
            openPopUpEdit &&
            <RestoreFeature openPopUP={openPopUpEdit} setOPenPopUP={setOpenPopUpEdit} tagData={edata}/>
        }
       
    </div>
    </div>
    </>
  )
}

export default RestoreFeatureTagSetup