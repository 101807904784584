import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'feature',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: []
    },
    reducers:{
        featureRequested:(feature,action)=>{
            feature.loading = true;
        },
        featureReceived:(feature,action)=>{
            feature.loading = false;
            feature.result = action.payload;
        },
        featureRequestFailed:(feature,action)=>{
            feature.loading = false;
            feature.error = action.payload;
            toast.error(action.payload?.msg)
        },
        featureAddedEdited:(feature,action)=>{
            feature.loading = false;
            toast.success(action.payload?.msg)
        },
        getFeature:(feature,action)=>{
            feature.loading = false;
            feature.getData = action.payload
        },
        getFeatureById:(feature,action)=>{
            feature.loading = false;
            feature.getDataById = action.payload
        },
    }
})

const {
    featureAddedEdited,
    getFeature,
    getFeatureById,
    featureRequested,
    featureRequestFailed,
    featureReceived
} = slice.actions

export default slice.reducer;

export const loadFeature = () => apiCallBegan({
    url:requests.fetchFeatureAll,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureReceived.type,
    onError: featureRequestFailed.type
})
export const getFeatureSelect = () => apiCallBegan({
    url:requests.fetchFeatureSelect,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:getFeature.type,
    onError: featureRequestFailed.type
})

export const getFeatureByIds = (id) => apiCallBegan({
    url:requests.fetchFeatureById+'/'+id,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:getFeatureById.type,
    onError: featureRequestFailed.type
})
export const addFeature = (data) => apiCallBegan({
    url:requests.createFeature,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const editFeature = (data) => apiCallBegan({
    url:requests.editFeature,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const deleteFeatures = (data) => apiCallBegan({
    url:requests.deleteFeature,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const editFeatureImg = (data) => apiCallBegan({
    url:requests.updateFeatureIcon,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const addFeatureTag = (data) => apiCallBegan({
    url:requests.createFeatureTag,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const editFeatureTag = (data) => apiCallBegan({
    url:requests.editFeatureTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const deleteFeatureTag = (data) => apiCallBegan({
    url:requests.deleteFeatureTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})
export const restoreFeatureTag = (data) => apiCallBegan({
    url:requests.restoreFeatureTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})


export const loadFeatureTag = (data) => apiCallBegan({
    url:requests.fetchFeatureTagAll,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureReceived.type,
    onError: featureRequestFailed.type
})
export const loadFeatureDeleteTag = () => apiCallBegan({
    url:requests.getDeletedFeatureTag,
    headers:AuthHeader,
    onStart:featureRequested.type,
    onSuccess:featureReceived.type,
    onError: featureRequestFailed.type
})

export const getFeatureByTagId = (id) => apiCallBegan({
    url:requests.fetchFeatureTagById+'/'+id,
    onStart:featureRequested.type,
    onSuccess:getFeatureById.type,
    onError: featureRequestFailed.type
})
export const getFeatureTagByCourse = (data) => apiCallBegan({
    url:requests.fetchFeatureTagByCourseId,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:featureRequested.type,
    onSuccess:featureAddedEdited.type,
    onError: featureRequestFailed.type
})