import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader, MultiPartHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'onboard',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: [],
        progress:[]
    },
    reducers:{
        onboardMobileRequested:(onboard,action)=>{
            onboard.loading = true;
        },
        onboardMobileReceived:(onboard,action)=>{
            onboard.loading = false;
            onboard.result = action.payload;
        },
        onboardMobileRequestFailed:(onboard,action)=>{
            onboard.loading = false;
            onboard.error = action.payload;
            toast.error(action.payload.msg)
        },
        onboardMobileAddedEdited:(onboard,action)=>{
            onboard.loading = false;
            toast.success(action.payload.msg)
        },
        getOnboardMobile:(onboard,action)=>{
            onboard.loading = false;
            onboard.getData = action.payload
        },
        getOnboardMobileById:(onboard,action)=>{
            onboard.loading = false;
            onboard.getDataById = action.payload
        },
        fileUpload: (onboard,action)=>{        
            onboard.progress = action.payload;
        }
    }
})

const {
    onboardMobileAddedEdited,
    getOnboardMobile,
    getOnboardMobileById,
    onboardMobileRequested,
    onboardMobileRequestFailed,
    fileUpload,
    onboardMobileReceived
} = slice.actions

export default slice.reducer;

export const loadOnboardMobile = () => apiCallBegan({
    url:requests.getOnboardBannerAll,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileReceived.type,
    onError: onboardMobileRequestFailed.type
})
export const getOnboardMobileSelect = () => apiCallBegan({
    url:requests.fetchOnboardMobileSelect,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:getOnboardMobile.type,
    onError: onboardMobileRequestFailed.type
})

export const getOnboardMobileByIds = (id) => apiCallBegan({
    url:requests.fetchOnboardMobileById+'/'+id,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:getOnboardMobileById.type,
    onError: onboardMobileRequestFailed.type
})
export const addOnboardMobile = (data) => apiCallBegan({
    url:requests.createOnboardBanner,
    method:"post",
    headers:MultiPartHeader,
    data:data,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type,
    onUpload:fileUpload.type,
    options:true
})
export const editOnboardMobile = (data) => apiCallBegan({
    url:requests.updateOnboardBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})
export const deleteOnboardMobiles = (data) => apiCallBegan({
    url:requests.deleteOnboardBanner,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})
export const editOnboardMobileImg = (data) => apiCallBegan({
    url:requests.updateOnboardBannerImg,
    method:"patch",
    data:data,
    headers:MultiPartHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type,
    onUpload:fileUpload.type,
    options:true
})
export const addOnboardMobileTag = (data) => apiCallBegan({
    url:requests.createOnboardMobileTag,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})
export const editOnboardMobileTag = (data) => apiCallBegan({
    url:requests.editOnboardMobileTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})
export const deleteOnboardMobileTag = (data) => apiCallBegan({
    url:requests.deleteOnboardMobileTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})
export const restoreOnboardMobile = (data) => apiCallBegan({
    url:requests.restoreOnboardImg,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})


export const loadOnboardMobileTag = (data) => apiCallBegan({
    url:requests.fetchOnboardMobileTagAll,
    method:'POST',
    data:data,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileReceived.type,
    onError: onboardMobileRequestFailed.type
})
export const loadOnboardMobileDeleteTag = () => apiCallBegan({
    url:requests.getDeletedOnboardMobileTag,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileReceived.type,
    onError: onboardMobileRequestFailed.type
})

export const getOnboardMobileByTagId = (id) => apiCallBegan({
    url:requests.fetchOnboardMobileTagById+'/'+id,
    headers:AuthHeader,
    onStart:onboardMobileRequested.type,
    onSuccess:getOnboardMobileById.type,
    onError: onboardMobileRequestFailed.type
})
export const getOnboardMobileTagByCourse = (data) => apiCallBegan({
    url:requests.fetchOnboardMobileTagByCourseId,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:onboardMobileRequested.type,
    onSuccess:onboardMobileAddedEdited.type,
    onError: onboardMobileRequestFailed.type
})