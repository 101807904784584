
import { ErrorMessage, Field, } from 'formik';
import React from 'react'
import TextError from '../../TextError';
function SelectField({data = [],divSize,name,label,requiredField,onChange,...otherProps}) {
    // const [update,setUpdate] = useState('');
    // const {handleChange} = useFormik()s
    return (
        <>
        <div className={divSize}>
        {label && <label>{label}{requiredField && <span className="errors">*</span>}</label>}
        {onChange ?<Field as="select" id={name} name={name} className="form-control" onChange={(e)=> onChange(e.target.value)} {...otherProps} >
        <option value="">{`Select ${label}`}</option>
        {data && data.map((item)=>(
            <option key={item.value} value={item.value}>{item.label}</option>
    ))}
        </Field>:
        <Field as="select" id={name} name={name} className="form-control" {...otherProps} >
        <option value="">{`Select ${label}`}</option>
        {data && data.map((item)=>(
            <option key={item.value} value={item.value}>{item.label}</option>
    ))}
        </Field>
        }
        <ErrorMessage name={name} component={TextError}/>
        </div>
         
        </>
    )
}

export default SelectField
