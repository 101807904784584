import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import { toast } from "react-toastify";
import requests from "../ApiRequests/Request";
import { AuthHeader } from "../Config/HeaderType";

const slice = createSlice({
    name:'enquiry',
    initialState:{
        enquiry:[],
        enquiryNotes:[],
        errors:[],
        // enquiryData:[],
        loading:false
    },
    reducers:{
        enquiryRequested:(enquiry)=>{
            enquiry.loading = true;
            enquiry.enquiry = [];
        },
        enquiryReceived:(enquiry,action)=>{
            enquiry.loading = false;
            enquiry.enquiry = action.payload
        },
        enquiryRequestFailed:(enquiry,action)=>{
            enquiry.loading = false;
            enquiry.errors = action.payload?.data
            if(action.payload?.msg){
                toast.error(action.payload?.msg);
            }
        },
        enquiryUpdateRequested:(enquiry)=>{
            enquiry.loading = true;            
        },
        enquiryUpdateReceived:(enquiry,action)=>{
            enquiry.loading = false;
            toast.success(action.payload?.msg)
        },
        enquiryNotesRequested:(enquiry)=>{
            enquiry.enquiryNotes = []
        },
        enquiryNotesReceived:(enquiry,action)=>{
            enquiry.enquiryNotes = action?.payload
        },
        enquiryRequesteById:(enquiry)=>{
            enquiry.loading = true;
            enquiry.enquiryData = [];
        },
        enquiryReceivedById:(enquiry,action)=>{
            enquiry.loading = false;
            enquiry.enquiryData = action.payload;
        }
    
    }
})

const {
    enquiryReceived,
    enquiryRequested,
    enquiryRequestFailed,
    enquiryUpdateReceived,
    enquiryUpdateRequested,
    enquiryNotesRequested,
    enquiryNotesReceived,
    enquiryRequesteById,
    enquiryReceivedById
} = slice.actions

export default slice.reducer;


export const getenquirysByCourse = (course)=>apiCallBegan({
    url: requests.getEnquiryFilter,
    method:'POST',
    data: course,
    headers:AuthHeader,
    onStart: enquiryRequested.type,
    onSuccess: enquiryReceived.type,
    onError: enquiryRequestFailed.type
})

// export const createenquirys = (data)=>apiCallBegan({
//     url: requests.createenquirys,
//     method:'POST',
//     data: data,
//     headers:AuthHeader,
//     onStart: enquiryActionRequested.type,
//     onSuccess: enquiryActionReceived.type,
//     onError: enquiryRequestFailed.type
// })
export const updateenquirys = (data)=>apiCallBegan({
    url: requests.updateEnquiryStatus,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: enquiryUpdateRequested.type,
    onSuccess: enquiryUpdateReceived.type,
    onError: enquiryRequestFailed.type
})
export const createEnquiryNotes = (data)=>apiCallBegan({
    url: requests.createEnquiryNotes,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: enquiryUpdateRequested.type,
    onSuccess: enquiryUpdateReceived.type,
    onError: enquiryRequestFailed.type
})
export const getEnquiryNotes = (data)=>apiCallBegan({
    url: requests.getEnquiryNotes+'/'+data,
    method:'GET',    
    headers:AuthHeader,
    onStart: enquiryNotesRequested.type,
    onSuccess: enquiryNotesReceived.type,
    onError: enquiryRequestFailed.type
})
// export const deleteenquirys = (data)=>apiCallBegan({
//     url: requests.deleteenquirys+'/'+data,
//     method:'DELETE',
//     headers:AuthHeader,
//     onStart: enquiryActionRequested.type,
//     onSuccess: enquiryActionReceived.type,
//     onError: enquiryRequestFailed.type
// })
// export const getenquirysById = (data)=>apiCallBegan({
//     url: requests.getenquirysById+'/'+data,
//     method:'GET',
//     headers:AuthHeader,
//     onStart: enquiryRequesteById.type,
//     onSuccess: enquiryReceivedById.type,
//     onError: enquiryRequestFailed.type
// })