import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react';
// import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
// import HtmlEmbed from './CustomHtmlEmbedPlugin';
import ClassicEditor from 'ckeditor5-classic-with-mathtype'
import { useFormikContext } from 'formik';

import "./CKeditorField.css"
function CkeditorField({name,data}) {
    const{setFieldValue} = useFormikContext();
    // const [data,setData] = useState('');

    return (
         <>   
            <CKEditor                    
                    editor={ClassicEditor}
                             config={{
                                allowedContent:true,
                                toolbar: {
                                items: [
                                    'source',
                                'heading', 'MathType', 'ChemType',
                                '|',
                                'bold',
                                'italic',
                                '-',
                                'JustifyLeft',
                                'JustifyCener',
                                '-',
                                'alignment',
                                'strikethrough',
                                'subscript',
                                'superscript',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'indent',
                                'outdent',
                                
                                'imageUpload',
                                'imageResize',
                                'mediaEmbed',
                                'insertTable',
                                'blockQuote',
                                'undo',
                                'redo',
                                'FullPage',
                                'htmlEmbed'
                                ]
                            }, 
                            image:{
                                toolbar:[
                                    'imageStyle:full',
                                    'imageStyle:side',
                                    'imageStyle:center',
                                    '|',
                                    'imageTextAlternative',
                                    'imageResize'
                                ]
                            },
                            language:'en' ,  
                            
                            htmlEmbed: {
                                showPreviews: true,
                                sanitizeHtml: (inputHtml) => {
                                  // Custom HTML sanitizer to ensure iframe security
                                  const outputHtml = inputHtml;
                                  // Here you can add further sanitization if needed
                                  return {
                                    html: outputHtml,
                                    hasChanged: false
                                  };
                                }
                              }, 
                              mediaEmbed: {
                                previewsInData: true
                            },                      
                            table: {
                                contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                              },
                            ckfinder:{
                                uploadUrl:`${process.env.REACT_APP_BASE_URL}/quiz/ckuploads`
                            },                            
                              plugin:['ImageResize']
                              
                        }}
                            data={data}
                            onInit={editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log( 'Editor is ready to use!', editor );
                            }}
                            onReady={editor => {
                         // You can store the "editor" and use when it is needed.
                        //  console.log( 'Editor is ready to use!', editor );
                        }}

                        onChange={(e,editor)=>setFieldValue(name,editor.getData())}
                        // onBlur={()=>setFieldTouched(name)}
                                />
                                {/* <ErrorMessage error={errors[name]} visible={touched[name]}/> */}
                                
        </>
    )
}

export default CkeditorField
