import TreeView from './TreeView'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartment } from '../../store/DepartmentSetupStore'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import {  Paper } from '@material-ui/core'
import DepartmentAdd from './DepartmentAdd'
import { Link, Route, Switch } from 'react-router-dom'
import DepartmentEdit from './DepartmentEdit'


function DepartmentSetup() {
    const [fetchData,setFetchData] = useState(true)
    const department = useSelector(state => state.entities.department.result)
    const loading = useSelector(state => state.entities.department.loading);
    const dispatch = useDispatch()

    useEffect(()=>(
        dispatch(getDepartment())
    ),[fetchData])
    
    return (
        <div>
          <BodyContainer pageTitle="Department Setup">
              <div className="row">
                    <div className="col-md-6">
                        <Paper style={{padding:20}}>
                            <div>
                            <Link to="/department/" type="button" className="btn btn-primary btn-sm btn-flat">Add Department</Link>
                            </div>
                        </Paper>
                        <TreeView data={department} loading={loading} />
                    </div>
                    <div className="col-md-6">
                        <Switch>
                        <Route path="/department/editDepartment/:slug">
                                <DepartmentEdit/>
                            </Route>
                            <Route path="/department/addDepartment/:slug">
                                <DepartmentAdd fetchData={fetchData} setFetchData={setFetchData}/>
                            </Route>
                            <Route path="/department/">
                                <DepartmentAdd fetchData={fetchData} setFetchData={setFetchData}/>
                            </Route>
                            
                        </Switch>
                    </div>
              </div>
            </BodyContainer>  
        </div>
    )
}

export default DepartmentSetup
