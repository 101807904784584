import { Field, Form, Formik,ErrorMessage } from 'formik'
import React from 'react'
import Card from '../Layout/Card'
import './Login.css';
import * as Yup from 'yup';
import TextError from '../../TextError';
import axios from 'axios'
import { toast } from "react-toastify";
import queryString from 'query-string';
import {useLocation} from 'react-router-dom'
 
function ResetPassword() {
    const location = useLocation()
    const initialValues = {
        email:'',
        redirect_url: `${process.env.REACT_APP_URL}`
    }
    const values = queryString.parse(location.search);
    console.log("loc",values)
    const validationSchema = Yup.object().shape({
        email: Yup.string().email().required().label('Email')
    });
    const handleSubmit =async  (values) => {
        try{
            const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/reset_password`,values)
            toast.success(result.data?.success)
            
        }catch(errors){
            errors?.response?.data?.error && toast.error( errors?.response?.data?.error)
        }
    }
    return (
        <div className="login">
        <Card>
            {values && values.token_valid?<p>Your token has been expired resend a link to reset password</p>:null}
            <p>Please enter a registered mail to reset your password</p>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            >
                {
                    ()=>(
                        <Form>
                            
                            <div className="form-group">
                                <Field type="email" className="form-control" name="email" placeholder="email"/>
                                <ErrorMessage name="email" component={TextError}/>
                            </div>
                            <div>
                                <ul>
                                    <li>Check the email to get reset password link</li>
                                </ul>
                            </div>
                            <button className="btn btn-danger">Send Reset Link</button>
                            
                        </Form>
                    )
                }
            </Formik>
        </Card>
        </div>
    )
}

export default ResetPassword
