import { FastField, Field, Form, Formik } from 'formik';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  editHomeBanner } from '../../store/BannerSetupStore';
import Spinner from '../Layout/Spinner';
import FormPopUp from '../popup/PopUp';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function EditHomeBanner({openPopup,setOpenPopup,banner}) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.banner?.loading)
    const initialValues = {
        banner_id:banner?.iid,
        banner_tag_line:banner?.banner_tag_line,
        banner_desc:banner?.banner_desc,
        courses:banner?.course_iid,
        banner_intro:banner?.banner_intro,
        modified_remarks:''
    }   
    const validationSchema = Yup.object().shape({
        modified_remarks:Yup.string().min(5).max(100).required().label('modified remarks'),
        banner_intro:Yup.lazy(value=>{ if(value) 
            return Yup.string().url().label('video intro url')
            else
            return Yup.string().notRequired()
        }),
    })
    const handleSubmit = (values,submitProps)=>{       
        dispatch(editHomeBanner(values));
        submitProps.setSubmitting(false);
    }
    
  return (
    <FormPopUp title="Edit Home Banner" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit} 
        validationSchema={validationSchema}      
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>                       
                        <div className='form-group'>
                            <label>Banner Title :</label>
                            <Field name="banner_tag_line" className="form-control" placeholder="banner title"/>
                        </div>
                        <div className='form-group'>
                            <label>Banner Desc :</label>
                            <Field as="textarea" rows="2" name="banner_desc" className="form-control" placeholder="banner description"/>
                        </div>
                        <div className='form-group'>
                            <label>Video Intro Url :</label>
                            <Field  name="banner_intro" className="form-control" placeholder="Url: https://"/>
                        </div> 
                        <div className='form-group'>
                            <label>Modified Remarks </label><span className='errors'>*</span>
                            <FastField name="modified_remarks" placeholder="modified remarks*" className="form-control"/>
                        </div>
                       
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default EditHomeBanner