import { AddBoxRounded } from '@material-ui/icons'
import React,{useState,useEffect} from 'react'
import {Switch, Link, Route} from 'react-router-dom'
import axios from '../../axios'
import { AuthHeader } from '../../Config/HeaderType'
import CourseCategoryAdd from '../CourseCategorySetup/CourseCategoryAdd'
import CourseCategoryEdit from '../CourseCategorySetup/CourseCategoryEdit'
import ViewCourseCategory from '../CourseCategorySetup/ViewCourseCategory'
import LessonAdd from '../LessonSetup/LessonAdd'
import LessonEdit from '../LessonSetup/LessonEdit'
import LessonViewDetail from '../LessonSetup/LessonViewDetail'
import MainTopicAdd from '../MainTopicSetup/MainTopicAdd'
import MainTopicEdit from '../MainTopicSetup/MainTopicEdit'
import VIewMainTopicDetails from '../MainTopicSetup/VIewMainTopicDetails'
import SubCourseAdd from '../SubcourseSetup/SubCourseAdd'
import SubCourseEdit from '../SubcourseSetup/SubCourseEdit'
import ViewSubCourseDetails from '../SubcourseSetup/ViewSubCourseDetails'
import SubTopicAdd from '../SubTopicSetup/SubTopicAdd'
import SubTopicEdit from '../SubTopicSetup/SubTopicEdit'
import ViewSubTopicDetails from '../SubTopicSetup/ViewSubTopicDetails'
import CourseSetupAdd from './CourseSetupAdd'
import CourseSetupEdit from './CourseSetupEdit'
import MultiSelectTreeView from './MultiSelectTreeView'
import ResourceAdd from './ResourceAdd'
import ViewCourseDetails from './ViewCourseDetails'



function CourseSetupTree() {
    const [treeData,setTreeData] = useState([]);
    const [fetchData,setFetchData] = useState(true);

    useEffect(()=>{      
        async function getTreeData(){ 
          let config = {
            headers: AuthHeader
          }         
            const result = await axios.get('/course_category/getCourseTree',config)
            setTreeData(result?.data)
        }
        getTreeData()
    },[fetchData])
    return (
        <>
          <div className="content-wrapper">
      <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Course Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success" >Add Course</Link>
            </ol>
        </div>{/* /.col */}
      </div>{/* /.row */}
    </div>{/* /.container-fluid */}
  </div>
  
  <div className="content">
      <div className="row">
          <div className="col-md-6">
              <div className="box box-success">
                  <div className="box-body" style={{padding:20}}> 
                  <Link to="/coursesetup/" className="btn btn-success btn-flat" style={{marginBottom:15}}> <AddBoxRounded/> Add Category</Link>                   
                    <MultiSelectTreeView course={treeData} />
                  </div>
              </div>
          </div>
          <div className="col-md-6">
            <div className="box box-success">
              <div className="box-body">
              {/* <Router> */}
                <Switch>
                <Route path='/coursesetup/viewSubTopicDetails/:slug'>
                    <ViewSubTopicDetails/>
                </Route>
                <Route path='/coursesetup/editSubTopic/:slug'>
                  <SubTopicEdit fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path='/coursesetup/addSubTopic/:slug'>
                  <SubTopicAdd fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>               
                <Route path='/coursesetup/addResources/:slug'>
                  <ResourceAdd/>
                </Route>
                <Route path='/coursesetup/viewLessonsDetails/:slug'>
                  <LessonViewDetail/>
                </Route>
                <Route path='/coursesetup/editLessons/:slug'>
                    <LessonEdit fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path='/coursesetup/addLessons/:slug'>
                  <LessonAdd fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path='/coursesetup/viewMainTopicDetails/:slug'>
                  <VIewMainTopicDetails/>
                </Route>
                <Route path='/coursesetup/editMainTopic/:slug'>
                  <MainTopicEdit fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path='/coursesetup/addMainTopic/:slug'>
                  <MainTopicAdd fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path="/coursesetup/viewSubCourseDetails/:slug">
                <ViewSubCourseDetails/>                
                </Route>
                <Route path="/coursesetup/edit_subcourse/:slug">
                <SubCourseEdit fetchData={fetchData} setFetchData={setFetchData}/>                
                </Route>
                <Route path="/coursesetup/add_subcourse/:slug">
                <SubCourseAdd fetchData={fetchData} setFetchData={setFetchData}/>                
                </Route>
                <Route path="/coursesetup/viewCourseDetails/:slug">
                  <ViewCourseDetails/>
                </Route>
                <Route path="/coursesetup/edit_course/:slug">
                  <CourseSetupEdit fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                <Route path="/coursesetup/add_course/:slug">
                <CourseSetupAdd fetchData={fetchData} setFetchData={setFetchData}/>                
                </Route>
                <Route path="/coursesetup/viewCategoryDetails/:slug" >
                    <ViewCourseCategory/>
                </Route>
                <Route path="/coursesetup/category_edit/:slug" >
                    <CourseCategoryEdit fetchData={fetchData} setFetchData={setFetchData}/>
                </Route>
                  <Route path="/coursesetup/" >
                    <CourseCategoryAdd fetchData={fetchData} setFetchData={setFetchData}/>                    
                  </Route>                  
                </Switch>
                {/* </Router> */}
              </div>
            </div>
          </div>
      </div>
  </div> 
  </div>  
        </>
    )
}

export default CourseSetupTree
