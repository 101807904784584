import React from 'react'
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux';
import { Field, Form, Formik,ErrorMessage } from 'formik';
import TextError from '../../TextError';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import { activateModelSetLive } from '../../store/QuizSetupStore';
function ChangeStatusDialog({openPopup,setOpenPopup,status}) {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.quiz.loading)
    const initialValues = {
        live_exam_status:status?.live_exam_status
    }
    const validationSchema = Yup.object().shape({
        live_exam_status: Yup.string().required()
    })
    const handleSubmit = (values,submitProps) =>{
        values.tag_id = status?.tag_id
        dispatch(activateModelSetLive(values));
        submitProps.setSubmitting(false);
    }
    const exam_status = [
        {
            'key':'pending',
            'value':'PENDING',            
        },
        {
            'key':'running',
            'value':'RUNNING',            
        },
        {
            'key':'completed',
            'value':'COMPLETED',            
        },
    ]
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Status Update">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Select Status:</label><span className='errors'>*</span>
                            <Field as="select" className="form-control" name="live_exam_status">
                                <option value=''>Select Options</option>
                                {
                                    exam_status && exam_status.map((item)=>(
                                        <option key={item?.key} value={item?.key}>{item?.value}</option>
                                    ))
                                }
                            </Field>
                            <ErrorMessage name="live_exam_status" component={TextError}/>
                        </div>
                        <button type="submit" className='btn btn-success btn-flat' disabled={isSubmitting}>
                                {isSubmitting?<Spinner/>:''}<i className='fa fa-save'/>&nbsp;save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default ChangeStatusDialog