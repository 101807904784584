import { ErrorMessage, Form, Formik } from 'formik'
import React, { useState } from 'react'
import DateTimePicker from 'react-datetime-picker'
import TimePicker from 'react-time-picker';
import TextError from '../../TextError';
import FormPopUp from '../popup/PopUp'
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux';
import { addTagLive } from '../../store/QuizSetupStore';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
function TagLiveQuiz({openPopup,setOpenPopup,modelset}) {
    const [value, onChange] = useState(new Date());
    const [valueEnd, onChangeEnd] = useState(new Date());
    const [valueEndNep, setValueEndNep] = useState();
    const [valueStartNep, setValueStartNep] = useState();
    const [values, onChanges] = useState(new Date());

    const dispatch = useDispatch();
    const loading = useSelector(state => state.entities.quiz.loading)
    const handleSubmit = (values,submitProps) => {
        values.live_start_date_time =  (new Date(value-new Date().getTimezoneOffset()*60000)).toISOString();      
        values.live_end_date_time =  (new Date(valueEnd-new Date().getTimezoneOffset()*60000)).toISOString();  
        dispatch(addTagLive(values));
        submitProps.setSubmitting(false);
    }
    const initialValues = {
        modelset:modelset?.modelset_id,
        batch:modelset?.batch_id,
        live_start_nep_date:valueStartNep,
        live_end_nep_date:valueEndNep,
        live_start_date_time:value,
        live_end_date_time:valueEnd,
        exam_time_duration:values
    }
    const validationSchema = Yup.object().shape({
        modelset: Yup.string().required(),
        live_start_date_time: Yup.date().min(new Date(),'live start date must be after current date time').required().label('live start time'),
        exam_time_duration: Yup.string().required(),
        live_end_date_time: Yup.date().when("live_start_date_time", {
            is: (startDate) => startDate,
            then: Yup.date().min(Yup.ref("live_start_date_time"), "End date must be after start date"),
          }),
    })
    const handleDate = ({bsDate,adDate})=>{
        setValueStartNep(bsDate)
        onChange(new Date(adDate)) 
    }
    const handleEndDate = ({bsDate,adDate})=>{
        setValueEndNep(bsDate)
        onChangeEnd(new Date(adDate)) 
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Tag Modelset">
       {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='form-group'>
                            <label>Modelset : &nbsp;</label><span>{modelset?.modelset_name}</span>
                            <ErrorMessage name='modelset' component={TextError}/>
                        </div>
                        <div className='form-group'>
                        <label>live start Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`live_start_nep_date`}
                            className="form-control"
                            onChange={handleDate}
                            defaultDate={initialValues.live_start_nep_date}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Live Start From:</label><span className='errors'>*</span>
                            <DateTimePicker name='live_start_date_time' onChange={onChange} value={value} className='form-control'/>
                            <ErrorMessage name='live_start_date_time' component={TextError}/>
                        </div>
                        <div className='form-group'>
                        <label>live end Date(BS)<span className="errors">*</span></label>
                            <Calendar
                            language="en"
                            theme="default"
                            name={`live_end_nep_date`}
                            className="form-control"
                            onChange={handleEndDate}
                            defaultDate={initialValues.live_end_nep_date}
                            />
                        </div>
                        <div className='form-group'>
                            <label>Live End :</label><span className='errors'>*</span>
                            <DateTimePicker name='live_end_date_time' onChange={onChangeEnd} value={valueEnd} className='form-control'/>
                            <ErrorMessage name='live_end_date_time' component={TextError}/>
                        </div>
                        <div className='form-group'>
                            <label>Quiz Duration :</label><span className='errors'>*</span>
                            <TimePicker name='exam_time_duration' onChange={onChanges} value={values} className='form-control' format='hh:mm:ss'/>
                            <ErrorMessage name='exam_time_duration' component={TextError}/>
                        </div>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                            <i className='fa fa-save'/>&nbsp;Save
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default TagLiveQuiz