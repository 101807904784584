import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Layout/Spinner';
import { loadCourseSelect } from '../../store/CourseSetup';
import TextError from '../../TextError';
import { getPassageByCourse } from '../../store/QuizSetupStore';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ViewParagraph from './ViewParagraph';
import MathJax from 'mathjax3-react';
import TagParagraphModelset from './TagParagraphModelset';
function QuizParagraphSetup() {
    const [openView,setOpenView] = useState(false);
    const [openTag,setOpenTag] = useState(false);
    const [data,setData] = useState('')
    const handleView = (record)=>{
        setData(record);
        setOpenView(true);
    }
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data);
    const passage = useSelector(state => state.entities.quiz.passage?.data);
    const loading = useSelector(state=> state.entities.quiz.loading);
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const COLUMNS = [
        {
            'key':'course_name',
            'text':'Course'
        },        
        {
            'key':'paragraph_title',
            'text':'Passage Title'
        },
        {
            'key':'paragraph',
            'text':'Passage Title',
            cell:record =>{
                return(
                    <MathJax.Provider>
                        <MathJax.Html html={record?.paragraph}/>
                    </MathJax.Provider>
                )
            }
        },
        {
            'key':'actions',
            'text':'Actions',
            cell:record=>{
                return(
                    <>
                    <Link to={`/paragraphSetup/edit/${record?.iid}`} className="btn btn-primary btn-sm">
                        <i className='fa fa-edit'/>
                    </Link>
                    <Link to={`#`} className="btn btn-success btn-sm" style={{marginLeft:'5px'}} onClick={()=>handleView(record)}>
                        <i className='fa fa-eye'/>
                    </Link>
                    <Link to={`/paragraphSetup/tag/${record?.iid}`} className="btn btn-secondary btn-sm" style={{marginLeft:'5px'}} >
                        <i className='fa fa-tag'/>
                    </Link>
                    <Link to={`#`} className="btn btn-warning btn-sm" style={{marginLeft:'5px'}} onClick={()=>handleModelSet(record)}>
                        <i className='fa fa-tag'/> modelset
                    </Link>
                    </>
                )
            }
        },

    ]
    const initialValues = {
        courses:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required()
    })
    const handleSearch = (values,submitProps)=>{
        dispatch(getPassageByCourse(values));
        submitProps.setSubmitting(false);
    }
    const handleModelSet = (record) =>{
        setOpenTag(true);
        setData(record);
    }
  return (
      <>
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Passage Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="/paragraphSetup/add" className="btn btn-flat btn-success"  >Add Passage</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'> 
    <div className='card'>
        <div className='card-body'>
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSearch}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <div className='row'>
                                <div className='col-md-4'>
                                <div className='form-group' >
                                    <label> Select Courses:</label><span className='errors'>*</span>
                                    <Field as="select" name="courses" className="form-control">
                                        <option value="">Select Options</option>
                                        {
                                            course && course.map((item)=>(
                                                <option key={item.iid} value={item.iid}>{item.course_name}</option>
                                            ))
                                        }
                                    </Field>
                                    <ErrorMessage name='courses' component={TextError}/>
                                    {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                </div>
                                </div>
                                <div className='form-group col-md-2'>
                                    <label>&nbsp;</label>
                                    <button className='btn btn-success btn-flat form-control' disabled={isSubmitting}>
                                        {isSubmitting?<Spinner/>:''}<i className='fa fa-search'/>&nbsp;search
                                        </button>
                                </div>
                            </div>
                        </Form>
                    )
                }
            </Formik>
            {loading && <LoaderSpinner/>}
        </div>
    </div>
        <ReactDataTable
        columns = {COLUMNS}
        records = {passage}
        />
      </div>
      </div>
      {
          openView && <ViewParagraph openPopup={openView} setOpenPopup={setOpenView} data={data}/>
      }
      {
          openTag && <TagParagraphModelset openPopUp={openTag} setOpenPopUp={setOpenTag} paragraph={data}/>
      }
      </>
  )
}

export default QuizParagraphSetup