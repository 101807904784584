import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'instructor',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: []
    },
    reducers:{
        instructorRequested:(instructor,action)=>{
            instructor.loading = true;
        },
        instructorReceived:(instructor,action)=>{
            instructor.loading = false;
            instructor.result = action.payload;
        },
        instructorRequestFailed:(instructor,action)=>{
            instructor.loading = false;
            instructor.error = action.payload;
            toast.error(action.payload.msg)
        },
        instructorAddedEdited:(instructor,action)=>{
            instructor.loading = false;
            toast.success(action.payload.msg)
        },
        getInstructor:(instructor,action)=>{
            instructor.loading = false;
            instructor.getData = action.payload
        },
        getInstructorById:(instructor,action)=>{
            instructor.loading = false;
            instructor.getDataById = action.payload
        },
    }
})

const {
    instructorAddedEdited,
    getInstructor,
    getInstructorById,
    instructorRequested,
    instructorRequestFailed,
    instructorReceived
} = slice.actions

export default slice.reducer;

export const loadInstructor = () => apiCallBegan({
    url:requests.fetchInstructor,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorReceived.type,
    onError: instructorRequestFailed.type
})
export const getInstructorSelect = () => apiCallBegan({
    url:requests.fetchInstructorSelect,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:getInstructor.type,
    onError: instructorRequestFailed.type
})

export const getinstructorByIds = (id) => apiCallBegan({
    url:requests.getInstructorById+'/'+id,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:getInstructorById.type,
    onError: instructorRequestFailed.type
})
export const viewInstructorDetails = (id) => apiCallBegan({
    url:requests.viewinstructorDetails+'/'+id,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:getInstructorById.type,
    onError: instructorRequestFailed.type
})
export const addInstructor = (data) => apiCallBegan({
    url:requests.createInstructor,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const editInstructor = (data) => apiCallBegan({
    url:requests.updateinstructor,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const editInstructorImg = (data) => apiCallBegan({
    url:requests.editInstructorImg,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const addInstructorTag = (data) => apiCallBegan({
    url:requests.createInstructorTag,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const editInstructorTag = (data) => apiCallBegan({
    url:requests.editInstructorTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const deleteInstructorTag = (data) => apiCallBegan({
    url:requests.deleteInstructorTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})
export const restoreInstructorTag = (data) => apiCallBegan({
    url:requests.restoreDeletedTag,
    method:"patch",
    data:data,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorAddedEdited.type,
    onError: instructorRequestFailed.type
})


export const loadInstructorTag = () => apiCallBegan({
    url:requests.fetchInstructorTagAll,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorReceived.type,
    onError: instructorRequestFailed.type
})
export const loadInstructorDeleteTag = () => apiCallBegan({
    url:requests.getAllDeletedTag,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:instructorReceived.type,
    onError: instructorRequestFailed.type
})

export const getinstructorByTagId = (id) => apiCallBegan({
    url:requests.fetchInstructorTagByID+'/'+id,
    headers:AuthHeader,
    onStart:instructorRequested.type,
    onSuccess:getInstructorById.type,
    onError: instructorRequestFailed.type
})
export const getInstructorTagByCourse = (data) => apiCallBegan({
    url:requests.fetchInstructorTagByCourse,
    method:"post",
    headers:AuthHeader,
    data:data,
    onStart:instructorRequested.type,
    onSuccess:instructorReceived.type,
    onError: instructorRequestFailed.type
})