var CryptoJS = require('crypto-js')
let access = null;
let refresh = null;

// if (localStorage.getItem('uu')){
// var cipherte = localStorage.getItem('Block:Usr')
// console.log("cipherte",cipherte)
// CryptoJS.AES.decrypt(cipherte,process.env.REACT_APP_SECRET)
// var bytes = CryptoJS.AES.decrypt(cipherte, process.env.REACT_APP_SECRET);
// var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// access = decryptedData?.tokens?.access;
// console.log("decry",decryptedData?.tokens?.access)
// }
export const getToken = () =>{
    if (localStorage.getItem('Block:Usr')){
           
    try{
        if (localStorage.getItem('Block:Usr')){
        var cipherte = localStorage.getItem('Block:Usr')    
        CryptoJS.AES.decrypt(cipherte,process.env.REACT_APP_SECRET)
        var bytes = CryptoJS.AES.decrypt(cipherte, process.env.REACT_APP_SECRET);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        access = decryptedData?.tokens?.access;
        }
        return access
        }catch(error){
            localStorage.clear()
            window.location = '/login'
        }
   
    }
    return access
}
export const refreshToken = () =>{
    if (localStorage.getItem('Block:Usr')){
    var cipherte = localStorage.getItem('Block:Usr')    
    CryptoJS.AES.decrypt(cipherte,process.env.REACT_APP_SECRET)
    var bytes = CryptoJS.AES.decrypt(cipherte, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
    var decryptedData = JSON.parse(bytes);
    refresh = decryptedData?.tokens?.refresh;
    }
    return refresh
}
export const NormalHeader = {
    'Content-Type':'application/json'
}


export const AuthHeader = {
    'Content-Type':'application/json',   
    'Authorization': `Bearer ${getToken()}`,
    'Accept': 'application/json'
}

export const MultiPartHeader = {
    'Content-Type':'multipart/form-data',
    'Authorization': `Bearer ${getToken()}`,
    'Accept': 'application/json'
}
