import React,{useEffect,useState} from 'react'
import {Formik,Form,Field,ErrorMessage} from 'formik'
import * as Yup from 'yup'
import TextError from '../../TextError'
import axios from '../../axios'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryBySlug, loadCategory } from '../../store/CategorySetup';
import { addCourse } from '../../store/CourseSetup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ImageUploader from '../Form/ImageUploader'

function CourseSetupAdd(props) {
       const {setFetchData,fetchData} = props

        const [category,setCategory] = useState([])
        const params = useParams()
        const category_data = useSelector(state => state.entities.categorys.result);
        const errors = useSelector(state => state.entities.courses.error);
        const isLoading = useSelector(state => state.entities.courses.loading);
        const dispatch = useDispatch();  
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "image/png"
        ];     
       useEffect(()=>{
         dispatch(loadCategory());
        //  setCategory(data?.data);
         dispatch(getCategoryBySlug(params.slug))         
        async function getCategory(){
            const result = await axios.get('/course_category/getCategorySelect')           
            setCategory(result?.data?.data)
        }
         getCategory()
        // setSlug(params.slug)
       },[params])  
      
        const validationSchema = Yup.object().shape({
          course_name: Yup.string().min(3,'must have more than 3 characters').required('This field is required'),
          course_code: Yup.string().min(2,'must have more than 3 characters').max(7,'cannot exceeds from 7 characters').required('This field is required'),
          course_categories: Yup.string().required('Course Category must selected'),
          course_preview_url:Yup.string().url().required().label('course preview url'),
          course_preview_img: Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Course Preview Img"),
          course_banner_img: Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Course Banner Img"),
        course_banner_description:Yup.string().required('Banner description is required').min(30).label('Banner Description'),
        program_overview:Yup.string().required('Banner description is required').min(20).label('Program Overview'),
        has_exam :Yup.string().required().label('has exam'),
        has_certification :Yup.string().required().label('has certification'),
        certificate_img: Yup.mixed().when('has_certification',{
          is:(certification)=>certification==='y',
          then:Yup.mixed().required().test(
            "fileFormat",
            "Unsupported Format(supports *jpg/*jpeg/*png/*gif)",
            value => value && SUPPORTED_FORMATS.includes(value.type)
        ).label("Certificate")
        })
        })
        const initialValues = {
          course_name : '',
          course_code: '',
          course_banner_description:'',
          course_banner_img:'',
          course_preview_url:'',
          course_preview_img:'',
          program_overview:'',
          program_success_path:'',
          lecture_hours:'',
          has_exam:'',
          has_certification:'',
          certificate_img:'',
          course_categories:category_data?.data?.iid
        }
        const changeData = (prevData) => setFetchData(!prevData);
        const handleSubmit = async (values,submitProps)=>{
          let formData = new FormData();
          formData.append('course_name',values.course_name)
          formData.append('course_code',values.course_code)
          formData.append('course_banner_description',values.course_banner_description)
          formData.append('course_banner_img',values.course_banner_img,values.course_banner_img.name)
          formData.append('course_preview_url',values.course_preview_url)
          formData.append('course_preview_img',values.course_preview_img,values.course_preview_img.name)
          formData.append('program_overview',values.program_overview)
          formData.append('lecture_hours',values.lecture_hours)
          formData.append('has_exam',values.has_exam)
          formData.append('has_certification',values.has_certification)
          values.certificate_img && formData.append('certification_img',values.certification_img,values.certificate_img.name)
          formData.append('course_category',values.course_categories)
          formData.append('created_by',localStorage.getItem('username'))
          await dispatch(addCourse(formData));
          submitProps.setSubmitting(false);
          changeData(fetchData);
          submitProps.resetForm();
        }

       const CHOICES = [
         {
           'key':'y',
           'value':'YES'
         },
         {
           'key':'n',
           'value':'NO'
         },
       ]
    
    return (
        <>            
  <div className="row">
  <div className="col-md-12">
  <div className="card"> 
      <div className="card-header">
        <h3 className="card-title ">Course Add</h3>
      </div>
    <Formik 
    initialValues={initialValues} 
    onSubmit={handleSubmit} 
    validationSchema={validationSchema} 
    enableReinitialize>
    {(formik)=>(
      
    <Form>    
      <div className="card-body">            
        <div className="form-group item">
          <label className="required-label">Course Title <span className="errors">*</span>:</label>
          <Field type="text" name = "course_name" id="course_name" placeholder = "category title"  className="form-control" />
          <ErrorMessage name="course_name" component={TextError}/>
          <div className="errors">{errors?.data?.course_name && errors?.data.course_name}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Course Code <span className="errors">*</span>:</label>
          <Field type="text" name = "course_code" id="course_code" placeholder = "course code"  className="form-control" />
          <ErrorMessage name="course_code" component={TextError}/>
          <div className="errors">{errors?.data?.course_code && errors?.data?.course_code}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Lecture Hrs <span className="errors">*</span>:</label>
          <Field type="text" name = "lecture_hours" id="lecture_hours" placeholder = "lecture hours"  className="form-control" />
          <ErrorMessage name="lecture_hours" component={TextError}/>
          <div className="errors">{errors?.data?.lecture_hours && errors?.data?.lecture_hours}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Course Preview Url <span className="errors">*</span>:</label>
          <Field type="text" name = "course_preview_url" id="course_preview_url" placeholder = "url https://"  className="form-control" />
          <ErrorMessage name="course_preview_url" component={TextError}/>
          <div className="errors">{errors?.data?.course_preview_url && errors?.data?.course_preview_url}</div>
        </div> 
        <ImageUploader
        name="course_preview_img"
        label="Course Preview Img"
        accept="image/jpg, image/gif,image/jpeg,image/png"
        required="required"        
        />
        <div className="form-group item">
          <label className="required-label">Course Banner Description <span className="errors">*</span>:</label>
          <Field as="textarea" rows="7" name = "course_banner_description" id="course_banner_description" placeholder = "banner description"  className="form-control" />
          <ErrorMessage name="course_banner_description" component={TextError}/>
          <div className="errors">{errors?.data?.course_banner_description && errors?.data?.course_banner_description}</div>
        </div> 
        <div className="form-group item">
          <label className="required-label">Program Overview <span className="errors">*</span>:</label>
          <Field as="textarea" rows="7" name = "program_overview" id="program_overview" placeholder = "program overview"  className="form-control" />
          <ErrorMessage name="program_overview" component={TextError}/>
          <div className="errors">{errors?.data?.program_overview && errors?.data?.program_overview}</div>
        </div> 
        <ImageUploader
        name="course_banner_img"
        label="Course Banner"
        accept="image/jpg, image/gif,image/jpeg,image/png"
        />
        <div className="form-group item">
            <label>Has Exam?</label>
            <Field as="select" id="has_exam" name="has_exam" placeholder="select option" className="form-control">
              <option value=''>select option</option>
              {CHOICES && CHOICES.map(option=>{
                return(
                  <option key={option.key} value={option.key}>{option.value}</option>
                )
              })}
           onChange={value=>{props.setFieldValue('has_exam',value.value)}}
              </Field>
            
           
            <ErrorMessage name="has_exam" component={TextError}/>
            <div className="errors">{errors?.data?.has_exam && errors?.data?.has_exam}</div>
            </div>      
        <div className="form-group item">
            <label>Has Certification?</label>
            <Field as="select" id="has_certification" name="has_certification" placeholder="select option" className="form-control">
              <option value=''>select option</option>
              {CHOICES && CHOICES.map(option=>{
                return(
                  <option key={option.key} value={option.key}>{option.value}</option>
                )
              })}
           onChange={value=>{props.setFieldValue('has_certification',value.value)}}
              </Field>         
           
            <ErrorMessage name="has_certification" component={TextError}/>
            <div className="errors">{errors?.data?.has_certification && errors?.data?.has_certification}</div>
            </div>
            {
              formik.values.has_certification ==='y'?
              <>
              <ImageUploader
              name="certificate_img"
              label="Certificate"
              required="required"
              />
              </>
              :''
            }      
        <div className="form-group item">
            <label>Course Category</label>
            <Field as="select" id="course_categories" name="course_categories" placeholder="select option" className="form-control">
              <option value=''>select option</option>
              {category && category.map(option=>{
                return(
                  <option key={option.iid} value={option.iid}>{option.category_name}</option>
                )
              })}
           onChange={value=>{props.setFieldValue('course_categories',value.value)}}
              </Field>
            
           
            <ErrorMessage name="course_categories" component={TextError}/>
            <div className="errors">{errors?.data?.course_categories && errors?.data?.course_categories}</div>
            </div>      
      </div>
      <div className="card-footer">
        <button type="submit" className="btn btn-flat btn-success" name="save" id="save" disabled={formik.isSubmitting}>Save </button>
      </div>
    </Form>
    )}
    </Formik>
  </div>
</div>
 </div> 
 {isLoading?<LoaderSpinner/>:null} 
 
       
 </>
    )
}

export default CourseSetupAdd
