import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import ImageViewer from '../Form/ImageViewer';
import OnboardAdd from './OnboardAdd';
import { loadOnboardMobile } from '../../store/OnboardMobileSetupStore';
import OnboardEdit from './OnboardEdit';
import OnboardImgUpdate from './OnboardImgUpdate';
import OnboardDelete from './OnboardDelete';
import OnboardRestore from './OnboardRestore';
function OnBoardMobileSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  const [openImgUpdate,setOpenImgUpdate] = useState(false)
  const [openRestore,setOpenRestore] = useState(false)
  const [openPopUpDel,setOpenPopUpDel] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const onboard = useSelector(state => state.entities.onboard?.result?.data);
  const loading = useSelector(state => state.entities.onboard?.loading);
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  const handleImgUpdate = (data)=>{    
    setEData(data);
    setOpenImgUpdate(true)
  }
  const restoreOnboardMobile = (data)=>{
    setEData(data);
    setOpenRestore(true)
  }
  const deleteOnboardMobile = (data)=>{
    setEData(data);
    setOpenPopUpDel(true)
  }
  useEffect(()=>{
    dispatch(loadOnboardMobile());
  },[openPopUp,openPopUpEdit])
  const columns = [
    {
      key:'onboard_title',
      text:"Title"
    },
    {
      key:'onboard_priority',
      text:"Onboard Sequence"
    },
    {
      key:'onboard_img',
      text:"Banner Img",
      cell:record =>(
          <>
          <ImageViewer image={record?.onboard_img} key={record?.iid}/>
          <Link to={`#`} onClick = {()=>handleImgUpdate(record)}>
            <i className='fa fa-edit' />&nbsp;update img
          </Link>
          </>
      )

    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>                 
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  {
                    record?.is_deleted_flag === 'n'?
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => deleteOnboardMobile(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                    :
                  <button 
                      className="btn btn-sucess btn-sm" 
                      onClick={() => restoreOnboardMobile(record)}>
                      <i className="fa fa-undo"></i>
                  </button>
                  }
                 
              </>
          );
      }
  }
  ]
  return (
    <>
    {loading && <LoaderSpinner/>}
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Onboard Mobile Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add Onboard</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <ReactDataTable        
        columns = {columns}
        records = {onboard}
        />
         {
            openPopUp &&
            <OnboardAdd openPopUp={openPopUp} setOpenPopUp={setOpenPopUp}/>
        }
       {
            openPopUpEdit &&
            <OnboardEdit openPopUp={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} data={edata}/>
        }
        {
            openImgUpdate &&
            <OnboardImgUpdate openPopUp={openImgUpdate} setOpenPopUp={setOpenImgUpdate} banner={edata}/>
        }
        {
            openRestore &&
            <OnboardRestore openPopup={openRestore} setOpenPopup={setOpenRestore} feature={edata}/>
        }
        {
          openPopUpDel &&
          <OnboardDelete openPopup={openPopUpDel} setOpenPopup={setOpenPopUpDel} feature={edata}/>
        }
        {/* <InstructorEdit openPopUP={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} data={edata}/> */}
        {/* {
          openPopUpTag &&
          <InstructorTag openPopUp={openPopUpTag} setOPenPopUp={setOpenPopUpTag} instructor={edata}/>
        } */}
    </div>
    </div>
    </>
  )
}

export default OnBoardMobileSetup