import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteLectureRecording } from '../../store/CourseSetup'

function ConfirmDelete({rowData}) {
    const dispatch = useDispatch()
    const handleClick = ()=>{
        dispatch(deleteLectureRecording({'iid':rowData?.iid}))
    }
  return (
    <>
    <div className='row'>
        <h3>Are you sure to Delete??</h3>
    </div>
    <button className='btn btn-flat btn-success' type='button' onClick={handleClick}>
        ok
    </button>
    </>
  )
}

export default ConfirmDelete