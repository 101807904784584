import React from 'react';
import Header from './Header';
import RightMenu from './RightMenu';
import BodyContainer from './BodyContainer';
import Menu from './Menu';
import Footer from './Footer';
function Dashboard({pageTitle,childComponent,buttonTitle,route}) {
    return (
        <>
           <Header/> 
           <Menu/>
           <RightMenu/>
           {/* <BodyContainer pageTitle={pageTitle} childComponent={childComponent} buttonTitle={buttonTitle} route={route}/> */}
           {childComponent}
           <Footer/>
        </>
    )
}

export default Dashboard
