import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteBanner } from '../../store/BannerSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'

function DeleteMobileBanner({openPopup,setOpenPopup,banner,setRefetch}) {
    const dispatch = useDispatch()
    const initialValues = {
        banner_id:banner?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(deleteBanner(values));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Delete Banner">
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>Course Banner:</label><span>{banner?.course_name}</span>
                        Do you want to delete ?
                        <button className='btn btn-flat btn-success' type='submit' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''} <i className='fa fa-trash'/>&nbsp;Delete
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default DeleteMobileBanner