import {createSlice} from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import requests from '../ApiRequests/Request';
import { AuthHeader } from '../Config/HeaderType';

const slice = createSlice({
    name:'discussion',
    initialState:{
        discussion:[],
        error:[],
        loading:false,
        submitLoading:false,
    },
    reducers:{
        discussionRequested:(discussion,action)=>{
            discussion.loading = true;            
            discussion.error = []
        },
        commentRequested:(discussion,action)=>{
            discussion.submitLoading = true;            
            discussion.error = []
        },
        discussionReceived: (discussion,action)=>{           
            discussion.loading = false;
            discussion.discussion = action.payload
            
        }, 
        commentReceived: (discussion,action)=>{           
            discussion.submitLoading = false;
            // discussion.discussion = action.payload
            
        }, 
        discussionRequestFailed:(discussion,action)=>{
            discussion.loading = false;
            discussion.submitLoading = false;
            discussion.error = action.payload;
        }

    }
})

const {
    discussionRequested,
    discussionReceived,
    discussionRequestFailed,
    commentReceived,
    commentRequested
} = slice.actions

export default slice.reducer

export const getDiscussionList = (id) => apiCallBegan({
    url:requests.discussion+'/'+id,
    headers:AuthHeader,
    onStart:discussionRequested.type,
    onSuccess:discussionReceived.type,
    onError:discussionRequestFailed.type
})
export const createDiscussion = (data) => apiCallBegan({
    url:requests.createDiscussion,
    data:data,
    method:'POST',
    headers:AuthHeader,
    onStart:commentRequested.type,
    onSuccess:commentReceived.type,
    onError:discussionRequestFailed.type
})
export const updateDiscussion = (data) => apiCallBegan({
    url:requests.updateDiscussion,
    data:data,
    method:'PATCH',
    headers:AuthHeader,
    onStart:commentRequested.type,
    onSuccess:commentReceived.type,
    onError:discussionRequestFailed.type
})