import React,{useState} from 'react'
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
// import * as Yup from 'yup';
import Card from '../Layout/Card';
import { ErrorMessage, FastField, Form, Formik } from 'formik';
import TextError from '../../TextError';

function IdentificationInfo({handleSubmit,prev,data}) {    
    const [initialValue,setInitialValue] = useState(data);
    const [count,setCount] = useState(0)
    const handleDateChange=({ bsDate, adDate })=>{  
      setCount(count+1)  
      if(count>0)
    setInitialValue({
      ...initialValue,
      citizenship_issued_date_eng:adDate,
      citizenship_issued_date_nep:bsDate
    })
  }
  // const cardTitle = {
  //   backgroundColor:"grey"
  // }
  // const initialValues = {
  //   citizenship_no:"",
  //   citizenship_issued_date_nep:"",
  //   citizenship_issued_date_eng:"",
  //   citizenship_issued_address:"",
  //   passport_no:"",
  //   passport_expiry_date:"",
  //   driving_license_no:"",
  //   pan_no:"",
  //   provident_fund_acc_no:"",
  //   bank_name:"",
  //   bank_branch:"",
  //   bank_acc_no:"",
  //   bank_acc_holder_name:""
  // }
  // const validationSchema={
  //   citizenship_no: Yup.string().required().label("Citizenship No"),
  //   citizenship_issued_date_nep: Yup.date().required().label("Citizenship Issued Date B.S."),
  //   citizenship_issued_date_eng: Yup.date().required().label("Citizenship Issued Date A.D"),
  //   citizenship_issued_address: Yup.string().min(3).max(150).required().label("Citizenship Issued. Address")
  // }
    return (
        
         <Formik
         initialValues={initialValue}
         onSubmit={handleSubmit}
         enableReinitialize
         >
           {({values})=>(
             <>
             <Form>
            <Card cardTitle="Identification Info">
            
                <div className="row">
                  <div className="col-md-3 col-sm-12 col-xs-12 form-group "> 
                      <label>Citizenship No<span className="errors">*</span>:</label>
                      <FastField name="citizenship_no" className="form-control" placeholder="Citizenship No"/>
                      <ErrorMessage name="citizenship_no" component={TextError}/>
                  </div>
                 
                
                <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                    <label>Citizenship Issued Date(B.S.)<span className="errors">*</span></label>
                    <Calendar 
                    name="citizenship_issued_date" 
                    language="en" 
                    theme="default"
                    onChange={handleDateChange}
                    className="form-control"
                    placeholder="Citizenship Issued Date B.s."
                    hideDefaultValue={true}
                    />
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12 form-group "> 
                      <label>Citizenship Issued Date(A.D.)<span className="errors">*</span>:</label>
                      <FastField name="citizenship_issued_date_eng" value={initialValue.citizenship_issued_date_eng} className="form-control" placeholder="Citizenship Issued Date"/>
                      <ErrorMessage name="citizenship_issued_date_eng" component={TextError}/>
                  </div>
                <div className="col-md-3 col-sm-12 col-xs-12 form-group "> 
                      <label>Issued Address(A.D.)<span className="errors">*</span>:</label>
                      <FastField name="citizenship_issued_place" className="form-control" placeholder="Citizenship Issued Address"/>
                      <ErrorMessage name="citizenship_issued_place" component={TextError}/>
                  </div>                
                              
                </div>
                <div className="row">
                <div className="col-md-3 col-sm-12 col-xs-12 form-group "> 
                    <label>Passport No:</label>
                    <FastField name="passport_no" className="form-control" placeholder="Passport No"/>
                  </div> 
                
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Driving License No:</label>
                      <FastField name="driving_license_no" className="form-control" placeholder="Driving License No"/>
                  </div>
                
                <div className="col-md-3 col-xs-12 col-sm-12 form-group">
                    <label>License Expiry Date</label>
                    <Calendar 
                    name="citizenship_issued_date" 
                    language="en" 
                    theme="default"
                    onChange={handleDateChange}
                    className="form-control"
                    hideDefaultValue={true}
                    placeholder="License Expiry(B.S.)"
                    />
                </div>               
                </div>

                <div className="row">
                <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Bank Name:</label>
                      <FastField name="bank_name" className="form-control" placeholder="Bank Name"/>
                  </div>
                <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Bank Account No:</label>
                      <FastField name="bank_acc_no" className="form-control" placeholder="Bank Acc No"/>
                  </div>
                <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Bank Branch:</label>
                      <FastField name="bank_branch" className="form-control" placeholder="Bank Branch"/>
                  </div>
                <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Account Hoder Name:</label>
                      <FastField name="bank_acc_holder_name" className="form-control" placeholder="Bank Account Holder Name"/>
                  </div>
                    
                    </div>
                    <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Pan No:</label>
                      <FastField name="pan_no" type="number" className="form-control" placeholder="PAN No"/>
                    </div>
                    <div className="col-md-3 col-sm-12 col-xs-12 form-group">
                      <label>Provident Fund:</label>
                      <FastField name="provident_fund_acc_no" className="form-control" placeholder="Provident Fund"/>
                    </div>             
                </div>            
        </Card>
              <button className="btn btn-success btn-flat" onClick={()=>prev(values)}>Prev</button>
              <button className="btn btn-success btn-flat" >Next</button>
                </Form>
               </>

                )}
                </Formik>
    
    )}

export default IdentificationInfo
