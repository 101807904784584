import React, { useState } from 'react'
import FormPopUp from '../popup/PopUp'
import { useDispatch } from 'react-redux'
import { deleteArticles } from '../../store/ArticlesStore'
import Spinner from '../Layout/Spinner'

function ArticleDelete({openPopup,setOpenPopup,data}) {
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const handleClick = async()=>{
        setLoading(true);
        await dispatch(deleteArticles(data?.iid))
        setLoading(false);
    }
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title={`Confirm Delete`}>
        <label>Are you sure to delete?</label>
        <button className='btn btn-flat btn-success' disabled={loading} type='button' onClick={handleClick}>
            {loading && <><Spinner/>&nbsp;</>}yes
        </button>
    </FormPopUp>
  )
}

export default ArticleDelete