import React from 'react'
import FormPopUp from '../popup/PopUp';
function ViewOverviewDetails({openPopup,setOpenPopup,overview}) {
  return (
    <FormPopUp title="Overview Details" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <label>Overview Title </label>&nbsp;:&nbsp;<span>{overview?.overview_title?overview?.overview_title:'N/A'}</span><br/>
        <label>Overview Description </label>&nbsp;:&nbsp;<span>{overview?.overview_desc?overview?.overview_desc:'N/A'}</span><br/>
        <label>Overview Video Url </label>&nbsp;:&nbsp;<span>{overview?.overview_video_url?overview?.overview_video_url:'N/A'}</span><br/>
        <label>Course Name </label>&nbsp;:&nbsp;<span>{overview?.course_name?overview?.course_name:'N/A'}</span><br/>
        <label>Priority Order </label>&nbsp;:&nbsp;<span>{overview?.priority_order?overview?.priority_order:'N/A'}</span><br/>
        <label>Created By </label>&nbsp;:&nbsp;<span>{overview?.created_by?overview?.created_by:'N/A'}</span><br/>
        <label>Created Date </label>&nbsp;:&nbsp;<span>{overview?.created_date?overview?.created_date:'N/A'}</span><br/>
        <label>Modified By </label>&nbsp;:&nbsp;<span>{overview?.modified_by?overview?.modified_by:'N/A'}</span><br/>
        <label>Modified Date </label>&nbsp;:&nbsp;<span>{overview?.modified_date?overview?.modified_date:'N/A'}</span><br/>        
        <label>Modified Remarks </label>&nbsp;:&nbsp;<span>{overview?.modified_remarks?overview?.modified_remarks:'N/A'}</span><br/>        
    </FormPopUp>
  )
}

export default ViewOverviewDetails