import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import {NormalHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';


const slice = createSlice({
    name: 'alumni',
    initialState: {
        result: [],
        loading: false, 
        getData:[],
        getDataById:[],      
        error: []
    },
    reducers:{
        alumniRequested:(alumni,action)=>{
            alumni.loading = true;
        },
        alumniReceived:(alumni,action)=>{
            alumni.loading = false;
            alumni.result = action.payload;
        },
        alumniRequestFailed:(alumni,action)=>{
            alumni.loading = false;
            alumni.error = action.payload;
            toast.error(action.payload.msg)
        },
        alumniAddedEdited:(alumni,action)=>{
            alumni.loading = false;
            toast.success(action.payload.msg)
        },
        getAlumni:(alumni,action)=>{
            alumni.loading = false;
            alumni.getData = action.payload
        },
        getAlumniById:(alumni,action)=>{
            alumni.loading = false;
            alumni.getDataById = action.payload
        },
    }
})

const {
    alumniAddedEdited,   
    getAlumniById,
    alumniRequested,
    alumniRequestFailed,
    alumniReceived
} = slice.actions

export default slice.reducer;

export const loadAlumni = () => apiCallBegan({
    url:requests.fetchAlumniAll,
    onStart:alumniRequested.type,
    onSuccess:alumniReceived.type,
    onError: alumniRequestFailed.type
})

export const getAlumniDetails = (id) => apiCallBegan({
    url:requests.fetchAlumniById+'/'+id,
    onStart:alumniRequested.type,
    onSuccess:getAlumniById.type,
    onError: alumniRequestFailed.type
})
export const addAlumni = (data) => apiCallBegan({
    url:requests.createAlumni,
    method:"post",
    headers:NormalHeader,
    data:data,
    onStart:alumniRequested.type,
    onSuccess:alumniAddedEdited.type,
    onError: alumniRequestFailed.type
})
export const editAlumni = (data) => apiCallBegan({
    url:requests.editAlumni,
    method:"patch",
    data:data,
    headers:NormalHeader,
    onStart:alumniRequested.type,
    onSuccess:alumniAddedEdited.type,
    onError: alumniRequestFailed.type
})
export const editAlumniImg = (data) => apiCallBegan({
    url:requests.updateAlumniImg,
    method:"patch",
    data:data,
    headers:NormalHeader,
    onStart:alumniRequested.type,
    onSuccess:alumniAddedEdited.type,
    onError: alumniRequestFailed.type
})
export const deleteAlumni = (data) => apiCallBegan({
    url:requests.deleteAlumni,
    method:"patch",
    data:data,
    headers:NormalHeader,
    onStart:alumniRequested.type,
    onSuccess:alumniAddedEdited.type,
    onError: alumniRequestFailed.type
})