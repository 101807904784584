import NepaliDate from 'nepali-date-converter'

const date = new NepaliDate()
export const ADYEAR = date.getYear()
let year = []
for (let i=ADYEAR;i>=2020;i--){
    year.push(i)
}

export const NEPALIYEAR = year


