import React, { useEffect } from 'react'
import ReactDataTable from '@ashvin27/react-datatable';
import { Route } from 'react-router-dom'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { ErrorMessage, Form, Formik,Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getBatch } from '../../store/EnrollStore';
import TextError from '../../TextError';
import { loadCourseSelect } from '../../store/CourseSetup';
import { NEPALIYEAR,ADYEAR } from '../Calendar/NPYear';
import Spinner from '../Layout/Spinner';
import { useState } from 'react';
import PopupModal from '../Material-uiControls/PopupModal';
import BatchAdd from './BatchAdd';
import BatchEdit from './BatchEdit';
import BatchDeleteConfirm from './BatchDeleteConfirm';


function BatchSetup() {
    const [openModal,setOpenModal] = useState(false)
    const [openEditModal,setOpenEditModal] = useState(false)
    const [openDeleteModal,setOpenDeleteModal] = useState(false)
    const [editdata,setEditData] = useState('')
    const [deletedata,setDeleteData] = useState('')
    const [year,setYear] = useState(ADYEAR)
    const [refreshData,setRefreshData] = useState(false);
    const [courses,setCourse] = useState('');
    const batchData = useSelector(state => state.entities.enroll?.batchData?.data)
    const isLoading = useSelector(state => state.entities.enroll?.loading)
    const course = useSelector(state => state.entities.courses.result.data);
    const colHeaders = [
        'Batch Name','Batch Year','Courses','Batch Start Date','Batch End Date','Action'
    ]
    const colName = ['batch_name','batch_year','course_name','batch_start_date_nep','batch_end_date_nep']
    const initialValues = {
        year:year,
        course_id:courses
    }
    const validationSchema = Yup.object().shape({
        year: Yup.string().required(),
        course_id: Yup.string().required().label('courses')
    })
    const dispatch = useDispatch()
    const handleSubmit = (values)=>{
        dispatch(getBatch(values))
    }
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const handleButtonAdd = ()=>{
        setOpenModal(true)
    }
    const handleCourseClick = (id)=>{
        setCourse(id)
        
    }
    const onChange = (bsDate) => {
		setYear(bsDate);
        
	};
    const editData = (data)=>{
        setEditData(data)
        // dispatch(getCompanyDetailsById(id));
        setOpenEditModal(true)
    }
    const deleteData = (data)=>{
        setDeleteData(data)
        setOpenDeleteModal(true)
    }
    const columns = [
        {
            key:'batch_name',
            text:'Batch Name'
        },
        {
            key:'batch_year',
            text:'Batch Year'
        },
        {
            key:'course_name',
            text:'Courses'
        },
        {
            key:'batch_start_date_nep',
            text:'Batch Start Date'
        },
        {
            key:'batch_end_date_nep',
            text:'Batch End Date'
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 200,
            align: "left",
            sortable: false,
            cell: record => { 
                return (
                    <>
                        <button
                            className="btn btn-primary btn-sm"
                            style={{marginRight: '5px'}}
                            key={record?.iid}
                            onClick={()=>editData(record)}
                            >
                            <i className="fa fa-edit"></i>
                        </button>
                        <button 
                            className="btn btn-danger btn-sm" 
                            onClick={()=>deleteData(record)}            
                            >
                                
                            <i className="fa fa-trash"></i>
                        </button>                        
                    </>
                );
            }
        }
    ]
    return (
    <>    
        <BodyContainer
        pageTitle={'Batch Setup'}
        buttonTitle={'Add Batch'}        
        onClick={handleButtonAdd}

        >
            <div className='card'>
                <div className='card-body'>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    >
                     {
                        ()=>(
                            <Form>
                                <div className='row'>
                                <div className='form-group col-md-4'>
                                    <label className='required-label'> select course<span className="errors">*</span></label>
                                    <Field as="select" name="course_id" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="course_id" component={TextError} />
                                </div>
                                <div className='form-group col-md-3'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field> 
                                <ErrorMessage name="year" component={TextError}/>
                            </div>
                            <div className='form-group item col-md-2 float-sm-right'>
                              <label>&nbsp;&nbsp;</label>
                            <button className='btn btn-success btn-flat form-control ' type='submit' disabled={isLoading}>
                               {isLoading?<Spinner/>:''} <i className='fa fa-search'>&nbsp;&nbsp;Filter</i>
                            </button>
                            </div>
                            </div>
                            </Form>
                        )
                     }   
                    </Formik>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                     <ReactDataTable
                     columns = {columns}
                     records = {batchData}
                     />
                </div>
            </div>
        </BodyContainer>
    
    {
        openModal && <PopupModal
        title={"Batch Add"}
        openPopup={openModal}
        setOpenPopup={setOpenModal}
        setRefreshData={setRefreshData}
        >
            <BatchAdd course={course}/>

        </PopupModal>
    }
    {
        openEditModal && <PopupModal
        title={"Batch Edit"}
        openPopup={openEditModal}
        setOpenPopup={setOpenEditModal}
        setRefreshData={setRefreshData}
        >
            <BatchEdit course={course} editData={editdata}/>

        </PopupModal>
    }
    {
        openDeleteModal && <PopupModal
        title={"Batch Delete"}
        openPopup={openDeleteModal}
        setOpenPopup={setOpenDeleteModal}
        setRefreshData={setRefreshData}
        >
            <BatchDeleteConfirm batchData={deletedata}/>

        </PopupModal>
    }
    </>
  )
}

export default BatchSetup