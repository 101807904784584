import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import requests from "../ApiRequests/Request";
import { AuthHeader} from "../Config/HeaderType";
import { toast } from "react-toastify";

const slice = createSlice({
    name:'menu',
    initialState:{
        result:[],
        getData:[],
        getDataById:[],
        errors:[],
        loading: false
    },
    reducers:{
        menuRequested:(menu,action)=>{
            menu.loading = true;
        },
        menuReceived: (menu,action) => {
            menu.loading = false;
            menu.result = action.payload;
        },
        menuReceivedById: (menu,action)=>{
            menu.loading = false;
            menu.getDataById = action.payload;
        },
        menuRequestFailed:(menu,action)=>{
            menu.loading = false;
            menu.errors = action.payload;
        },
        menuAdded:(menu,action) => {
            menu.loading = false;
            if(menu.errors) menu.errors = []
            toast.success(action.payload.msg)
        },
        outerMenuReceived:(menu,action)=>{
            menu.loading = false;
            menu.getData = action.payload
        }
    }
})

const {
    menuRequested,
    menuReceived,
    menuReceivedById,
    menuRequestFailed,
    menuAdded,
    outerMenuReceived
} = slice.actions;

export default slice.reducer;

export const getMenuAll = ()=>apiCallBegan({
    url: requests.getTreeMenu,
    headers:AuthHeader,
    onStart: menuRequested.type,
    onSuccess: menuReceived.type,
    onError: menuRequestFailed.type
})

export const addMenu = (data)=>apiCallBegan({
    url: requests.addMenu,
    method:'POST',
    data: data,
    headers:AuthHeader,
    onStart: menuRequested.type,
    onSuccess: menuAdded.type,
    onError: menuRequestFailed.type
})
export const updateMenu = (data)=>apiCallBegan({
    url: requests.editMenu,
    method:'PATCH',
    data: data,
    headers:AuthHeader,
    onStart: menuRequested.type,
    onSuccess: menuAdded.type,
    onError: menuRequestFailed.type
})
export const getMenuById = (id)=>apiCallBegan({
    url: requests.getMenuById+'/'+id,
    onStart: menuRequested.type,
    headers:AuthHeader,
    onSuccess: menuReceivedById.type,
    onError: menuRequestFailed.type
})
export const getOuterMenuAll = ()=>apiCallBegan({
    url: requests.getOuterMenuAll,
    headers:AuthHeader,
    onStart: menuRequested.type,
    onSuccess: outerMenuReceived.type,
    onError: menuRequestFailed.type
})