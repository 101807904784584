import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { quizTagDelete } from '../../store/QuizSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'
import MathJax from 'mathjax3-react'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
function QuizTagDelete({openPopup,setOpenPopup,modelset}) {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.entities.quiz.loading)
    const initialValues = {
        tag_id: modelset?.tag_id
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(quizTagDelete(values));
        submitProps.setSubmitting(false)
    }
  return (
    <FormPopUp setOpenPopup={setOpenPopup} openPopup={openPopup} title="Delete Tag">
        {loading && <LoaderSpinner/>}
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>
                        Do you want to delete a tag <MathJax.Html html={modelset?.question}/>?
                        </label><br/>
                        <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                        {isSubmitting?<Spinner/>:''}&nbsp; <i className='fa fa-trash'/> Delete
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default QuizTagDelete