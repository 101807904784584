import React from 'react';
import * as Yup from 'yup';
import { useDispatch,useSelector } from 'react-redux';
import { addCompany} from '../../store/CompanySetup';

import { ErrorMessage, FastField, Field, FieldArray, Form, Formik} from 'formik';

import TextError from '../../TextError';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import {array,object} from 'yup'
import Card from '../Layout/Card';
// import { number, object } from 'yup/lib/locale';
function CompanyAdd() {
    const errorss = useSelector(state=> state.entities.company.error)
    const dispatch = useDispatch(); 

    const buttonStyles = {       
        outlineColor:'white',
        border:0,
        margin:2
    }
    const initialValues = {
        company_name:'',
        company_address:'',
        company_email:'',
        company_contact:[{company_contact_no:''}],
        company_logo:''
    }

    // const SUPPORTED_FORMATS = [
    //   "image/jpg",
    //   "image/jpeg",
    //   "image/gif",
    //   "image/png"
    // ]
    const validationSchema = Yup.object().shape({
        company_name: Yup.string().required().label('Company Name'),
        company_email:Yup.string().required().email().label("company Email"),
        company_contact: array(
          object({
          company_contact_no: Yup.number().required().label("Company Contact No").min(7,"Contact no should be greater than 7").max(10,"Contact no shouldnot be more than 10 digits")
        })
        ).min(1)
    });
    const onSubmit = (values) => {
        dispatch(addCompany(values));        
    }
    return (
        <div className="col-md-6">
           <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            >
                {({values})=>(
                  <Card cardTitle="Create Company">
                <Form>
                <div className="form-group item">
                    <label>Company Name<span className="errors">*</span></label>
                    <FastField type="text" name="company_name" className="form-control"/>
                    <ErrorMessage name="company_name" component={TextError}/>
                    {errorss?.data?.company_name && <div className="errors">{errorss?.data?.company_name}</div>}
                </div>
                <div className="form-group item">
                    <label>Company Email<span className="errors">*</span></label>
                    <FastField type="email" name="company_email" className="form-control"/>
                    <ErrorMessage name="company_email" component={TextError}/>
                    {errorss?.data?.company_email && <div className="errors">{errorss?.data?.company_email}</div>}
                </div>
                <div className="form-group item">
                    <label>Company Address</label>
                    <FastField type="text" name="company_address" className="form-control"/>
                </div>
               
                <div className="form-group item">
                <label>Contact No<span className="errors">*</span></label> 
                <FieldArray
                 name="company_contact"
                 
                 render={arrayHelpers => (
                 <div>
                 {values.company_contact && values.company_contact.length > 0 ? (
                   values.company_contact.map((friend, index) => (
                     <div key={index}>
                       <Field name={`company_contact.[${index}].company_contact_no`} />&nbsp;&nbsp;
                       { 
                       index>0?
                       
                       <button
                         type="button"
                        //  className="btn btn-flat btn-sm btn-danger"
                        style={buttonStyles}
                         onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                       >                           
                         <DeleteIcon/>
                       </button>
                       :null
                        }
                       <button
                         type="button"
                         style={buttonStyles}
                        //  className="btn btn-flat btn-sm btn-success"
                         onClick={() => arrayHelpers.push({company_contact_no:''})} // insert an empty string at a position
                       >
                         <AddIcon/>
                       </button>
                       <ErrorMessage name={`company_contact.[${index}].company_contact_no`} component={TextError}/>
                       {errorss?.data?.company_contact[index].company_contact_no && <div className="errors">{errorss?.data?.company_contact[index].company_contact_no}</div>}
                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push('')}>
                     {/* show this when user has removed all company_contact from the list */}
                     Add a contact
                   </button>
                 )}
                
               </div>
               
             )}
           />
           </div>
           <div className="form-group item">
                <button className="btn btn-flat btn-sm btn-success">Save</button>
                 </div>

                </Form>
                </Card>
            )}
           </Formik>
            
        </div>
    )
}

export default CompanyAdd
