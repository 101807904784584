import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { MultiPartHeader, AuthHeader} from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name : 'enroll',
    initialState : {
        result: [],
        batchData:[],
        batchTagData:[],
        batchFilter:[],
        filterStudent:[],
        batchSelect:[],
        error:[],        
        loading: false,  
        batchLoading: false,  
             
    },
    reducers:{
        batchRequested: (enroll,action) => {
            enroll.loading = true;
            enroll.batchData = [];
        },
        batchReceived:  (enroll,action) =>{
            enroll.loading = false;
            enroll.batchData = action.payload;
        },
        enrollRequestFailed: (enroll,action)=>{
            enroll.loading = false;
            enroll.batchLoading=false;
            enroll.error = action.payload;
        },
        batchActionRequested: (enroll,action)=>{
            enroll.loading = true;
        },
        batchActionReceived: (enroll,action)=>{
            enroll.loading = false;
            enroll.error=[]
            toast.success(action.payload.msg);
        
        },
        batchTagRequested:(enroll)=>{
            enroll.batchLoading = true;
            
        },
        batchTagReceived:(enroll,action)=>{
            enroll.batchLoading=false;
            toast.success(action.payload?.msg)
        },
        filterStudentRequested:(enroll)=>{
            enroll.loading = true;
            enroll.filterStudent = [];

        },
        filterStudentReceived:(enroll,action)=>{
            enroll.loading = false;
            enroll.filterStudent=action.payload
        },
        batchSelectRequest:(enroll)=>{
            enroll.batchSelect = [];
            enroll.loading = true;
        },
        batchSelectReceived:(enroll,action)=>{
            enroll.loading = false;
            enroll.batchSelect = action.payload
        },
        batchFilterRequest:(enroll)=>{
            enroll.loading = true;
            enroll.batchFilter = [];
        },
        batchFilterReceived:(enroll,action)=>{
            enroll.loading = false;
            enroll.batchFilter = action.payload;
        },

    }
})

const {
    batchRequested,
    batchActionRequested,
    batchReceived,
    batchActionReceived,
    batchTagReceived,
    batchTagRequested,
    enrollRequestFailed,
    filterStudentRequested,
    filterStudentReceived ,
    batchSelectReceived,
    batchSelectRequest,
    batchFilterReceived,
    batchFilterRequest
       
} = slice.actions;

export default slice.reducer;

export const addBatch = (batchData) => apiCallBegan({
    url: requests.createBatch,
    method:'POST',
    data: batchData,
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
});

export const getBatch = (filterData)=> apiCallBegan({
    url: requests.getBatchAll,
    method: 'POST',
    data: filterData,
    headers:AuthHeader,
    onStart: batchRequested.type,
    onSuccess: batchReceived.type,
    onError: enrollRequestFailed.type
});

export const updateBatch= (batchData) => apiCallBegan({
    url: requests.updateBatch,
    method: 'PATCH',
    data: batchData,
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
})
export const deleteBatch= (batchData) => apiCallBegan({
    url: requests.deleteBatch+'/'+batchData,
    method: 'DELETE',    
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
})
export const getFilterStudent = (filterData)=>apiCallBegan({
    url: requests.filterStudent,
    method: 'POST',  
    data:filterData,  
    headers:AuthHeader,
    onStart: filterStudentRequested.type,
    onSuccess: filterStudentReceived.type,
    onError: enrollRequestFailed.type
})
export const getBatchSelect = (filterData)=>apiCallBegan({
    url: requests.filterBatch,
    method: 'POST',  
    data:filterData,  
    headers:AuthHeader,
    onStart: batchSelectRequest.type,
    onSuccess: batchSelectReceived.type,
    onError: enrollRequestFailed.type
})
export const tagBatch = (filterData)=>apiCallBegan({
    url: requests.tagBatch,
    method: 'POST',  
    data:filterData,  
    headers:AuthHeader,
    onStart: batchTagRequested.type,
    onSuccess: batchTagReceived.type,
    onError: enrollRequestFailed.type
})
export const getFilterBatch = (filterData)=>apiCallBegan({
    url: requests.filterBatchTag,
    method: 'POST',  
    data:filterData,  
    headers:AuthHeader,
    onStart: batchFilterRequest.type,
    onSuccess: batchFilterReceived.type,
    onError: enrollRequestFailed.type
})
export const updateTagBatch = (filterData)=>apiCallBegan({
    url: requests.updateBatchTag,
    method: 'PATCH',  
    data:filterData,  
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
})
export const deleteTagBatch = (filterData)=>apiCallBegan({
    url: requests.deleteBatchTag,
    method: 'PATCH',  
    data:filterData,  
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
})
export const recoverTagBatch = (filterData)=>apiCallBegan({
    url: requests.recoverBatchTag+'/'+filterData,
    method: 'PATCH',       
    headers:AuthHeader,
    onStart: batchActionRequested.type,
    onSuccess: batchActionReceived.type,
    onError: enrollRequestFailed.type
})
