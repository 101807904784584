import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import { loadFeature } from '../../store/FeatureSetupStore';
import FeatureAdd from './FeatureAdd';
import FeatureEdit from './FeatureEdit';
import ViewFeatureDetails from './ViewFeatureDetails';
import TagFeature from '../FeatureTagSetup/TagFeature';
import DeleteFeature from './DeleteFeature';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function FeatureSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  const [openPopUpView,setOpenPopUpView] = useState(false)
  const [openPopUpTag,setOpenPopUpTag] = useState(false)
  const [openPopUpDel,setOpenPopUpDel] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const feature = useSelector(state => state.entities.feature?.result?.data);
  const loading = useSelector(state => state.entities.feature?.loading);
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
    setEData(data);
    setOpenPopUpEdit(true)
  }
  const handleView = (data)=>{
    setEData(data);
    setOpenPopUpView(true)
  }
  const handleTag = (data)=>{
    setEData(data);
    setOpenPopUpTag(true)
  }
  const deleteFeature = (data)=>{
    setEData(data);
    setOpenPopUpDel(true)
  }
  useEffect(()=>{
    dispatch(loadFeature());
  },[openPopUp,openPopUpEdit])
  const columns = [
    {
      key:'features_title',
      text:"Feature Title"
    },
    {
      key:'features_desc',
      text:"Feature Desc"
    },
    {
      key:'user_get',
      text:"Details"
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleTag(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-tag"></i>
                  </button>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => deleteFeature(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  <Link 
                    to='#'
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      onClick={()=>handleView(record)}
                      >                      
                      <i className="fa fa-eye"></i>
                  </Link>
              </>
          );
      }
  }
  ]
  return (
    <>
    {loading && <LoaderSpinner/>}
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Feature Setup</h1>
                 </div>{/* /.col */}
            <div className="col-sm-6">
             <ol className="breadcrumb float-sm-right">
               <Link to="#" className="btn btn-flat btn-success"  onClick={openAdd}>Add feature</Link>
            </ol>
            </div>{/* /.col */}
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
        <ReactDataTable        
        columns = {columns}
        records = {feature}
        />
        {
            openPopUp &&
            <FeatureAdd openPopUp={openPopUp} setOpenPopUp={setOpenPopUp}/>
        }
        {
            openPopUpEdit &&
            <FeatureEdit openPopUp={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} feature={edata}/>
        }
        {
            openPopUpView &&
            <ViewFeatureDetails openPopup={openPopUpView} setOpenPopup={setOpenPopUpView} feature={edata}/>
        }
        {
            openPopUpTag &&
            <TagFeature openPopup={openPopUpTag} setOpenPopup={setOpenPopUpTag} feature={edata}/>
        }
        {
          openPopUpDel &&
          <DeleteFeature openPopup={openPopUpDel} setOpenPopup={setOpenPopUpDel} feature={edata}/>
        }
        {/* <InstructorEdit openPopUP={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} data={edata}/> */}
        {/* {
          openPopUpTag &&
          <InstructorTag openPopUp={openPopUpTag} setOPenPopUp={setOpenPopUpTag} instructor={edata}/>
        } */}
    </div>
    </div>
    </>
  )
}

export default FeatureSetup