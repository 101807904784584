import React, { useEffect, useState } from 'react';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import ReactDataTable from '@ashvin27/react-datatable';
import {useDispatch,useSelector} from 'react-redux';
import { getProgramOverviewByCourseId, loadFAQ } from '../../store/CourseProgramSetup';
import { Link } from 'react-router-dom';
// import ViewOverviewDetails from './ViewOverviewDetails';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import TextError from '../../TextError';
import { loadCourseSelect } from '../../store/CourseSetup';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import ViewFAQ from './ViewFAQ';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function FAQSetup() {
    const [courses,setCourses] = useState('');
    const [openPopup,setOpenPopup] = useState(false)
    const [faq,setFAQ] = useState()
    const dispatch = useDispatch();
    const faqData = useSelector(state=>state.entities.course_program?.result?.data)
    const loading = useSelector(state=>state.entities.course_program?.loading)
    const errors = useSelector(state=>state.entities.course_program?.error?.data)
    const course = useSelector(state=>state.entities.courses?.result?.data)
    const hanldeClick = (records)=>{
        setOpenPopup(true);
        setFAQ(records)
    }

    const COLUMNS = [
        {
            key: 'faq_title',
            text: 'FAQ Title'
        },
        {
            key: 'course_name',
            text: 'Course'
        },
        {
            key: 'priority_order',
            text: 'Priority Order'
        },
        {
            key:'action',
            text:'Action',
            cell:records=>{
                return(
                    <>
                    <Link
                    to={`/faq/edit/${records?.iid}`}
                    className='btn btn-sm btn-primary'
                    >
                    <i className='fa fa-edit'>&nbsp;edit</i>
                    </Link>
                    <button
                    style={{marginLeft:5}}
                    className='btn btn-sm btn-success'
                    onClick={()=>hanldeClick(records)}
                    type='button'
                    >
                        <i className='fa fa-eye'>&nbsp;view</i>
                    </button>
                    </>
                )
            }
        }
        
    ]
    useEffect(()=>{
        dispatch(loadFAQ())
        dispatch(loadCourseSelect());
    },[])
    const initialValues = {
        courses: courses
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required()
    })
    const handleSubmit = (values,submitProps)=>{
        dispatch(getProgramOverviewByCourseId(values));
        submitProps.setSubmitting(false);
    }
    console.log("data",faqData)
  return (
    <>
    {loading && <LoaderSpinner/>}
    <BodyContainer pageTitle="FAQ Setup" buttonTitle="Add FAQ" route="/faq/add">
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='form-group item'>
                                        <label className='required-label'>Select Course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses} onChange={(e)=>setCourses(e.target.value)} >
                                            <option name="courses" value="">select Options</option>
                                                {
                                                    course && course?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                                        ))
                                                    }
                                                </Field>
                                            <ErrorMessage name="courses" component={TextError} />
                                            {errors?.courses && <div className="errors">{errors?.courses}</div>}
                                         </div>
                                    </div>
                                    <div className='col-md-2'>
                                        <button className='btn btn-flat btn-success' type='submit' disabled={isSubmitting}>
                                            {isSubmitting?<Spinner/>:''}<i className='fa fa-search'>&nbsp;search</i>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
            <ReactDataTable
            columns = {COLUMNS}
            records = {faqData}
            />
            </div>
        </div>
        {
            openPopup &&
            <ViewFAQ openPopup={openPopup} setOpenPopup={setOpenPopup} faq={faq}/>
        }
    </BodyContainer>
    </>
  )
}

export default FAQSetup