import { ErrorMessage, FastField, Field, Form, Formik } from 'formik'
import React, { useEffect} from 'react'
import Card from '../Layout/Card';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDepartmentAll } from '../../store/DepartmentSetupStore';
import { getDesignationById, updateDesignation } from '../../store/DesignationSetupStore';
import TextError from '../../TextError';

function DesignationEdit() {
    const department = useSelector(state => state.entities.department.getData.data)
    const errors = useSelector(state => state.entities.designation.errors.data)
    const designation = useSelector(state=> state.entities.designation.getDataById.data)
    const dispatch = useDispatch();
    const params = useParams()    
    
    useEffect(()=>{
        dispatch(getDesignationById(params.slug))        
        dispatch(getDepartmentAll())
    },[params.slug])
    
    const initialValues={
        designation:designation?.designation,
        designation_code:designation?.designation_code,
        department:designation?.department,
        modified_remarks:'',
        designation_id:designation?.id
    }
    const validationSchema= Yup.object().shape({
        designation: Yup.string().required().min(4).max(30).label('Department Name'),
        designation_code: Yup.string().min(2).max(7).required().label('Department Code'),
        department: Yup.string().required().label('Department'),
        modified_remarks: Yup.string().required().min(5).max(50).label('Modified Remarks')
    })
    const handleSubmit = (values,submitProps) => {
        dispatch(updateDesignation(values))
        submitProps.setSubmitting(false);
    }
    return (        
            <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    ({isSubmitting})=>(
                        <Form>
                            <Card cardTitle="Edit Designation">
                            <div className="form-group">
                                <label>Designation Name<span className="errors">*</span>:</label>
                                <FastField name="designation" className="form-control" placeholder="Designation Name"/>
                                <ErrorMessage name="designation" component={TextError}/>
                                <div className="errors">{errors?.designation_name && errors?.designation_name}</div>
                            </div>
                            <div className="form-group">
                                <label>Designation Code<span className="errors">*</span>:</label>
                                <FastField name="designation_code" className="form-control" placeholder="Designation Code"/>
                                <ErrorMessage name="designation_code" component={TextError}/>
                                <div className="errors">{errors?.designation_code && errors?.designation_code}</div>
                            </div>
                            <div className="form-group">
                                <label>Department<span className="errors">*</span>:</label>
                                <Field as="select" name="department" className="form-control">
                                    <option value="">Select Department</option>
                                    {
                                        department && department.map((item,index)=>(
                                            <option key={item.id} value={item.id}>{item.department_name+'-'+item.department_code}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="department" component={TextError}/>
                                <div className="errors">{errors?.department && errors?.department}</div>
                            </div>
                            <div className="form-group">
                                <label>Modified Remarks<span className="errors">*</span>:</label>
                                <FastField name="modified_remarks" className="form-control" placeholder="Modified Remarks"/>
                                <ErrorMessage name="modified_remarks" component={TextError}/>
                                <div className="errors">{errors?.modified_remarks && errors?.modified_remarks}</div>
                            </div>
                                <button className="btn btn-success" disabled={isSubmitting}>{ isSubmitting &&<Spinner/>}Save</button>
                            </Card>
                            
                        </Form>
                    )
                }
            </Formik>
       
    )
}

export default DesignationEdit
