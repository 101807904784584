import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from './api';
import requests from "../ApiRequests/Request";
import { AuthHeader } from "../Config/HeaderType";
import { toast } from "react-toastify";
console.log(AuthHeader)
const slice = createSlice({
    name:'courseprice',
    initialState:{
        result : [],
        getData:[],
        getDataById:[],
        tagResult:[],
        errors:[],
        priceSelect:[],
        loading:false
    },
    reducers:{
        coursepriceRequested:(courseprice,action) => {
            courseprice.loading = true;
        },
        coursepriceReceived: (courseprice,action) => {
            courseprice.loading = false;
            courseprice.result = action.payload;
        },
        coursepriceTagReceived: (courseprice,action) => {
            courseprice.loading = false;
            courseprice.tagResult = action.payload;
        },
        coursepriceReceivedById:(courseprice,action)=>{
            courseprice.loading = false;            
            courseprice.getDataById = action.payload;
        },
        coursepriceRequestedFailed:(courseprice,action)=>{
            courseprice.loading = false;
            courseprice.errors = action.payload
            toast.error(action.payload?.msg)
        },
        coursepriceSearchFailed:(courseprice,action)=>{
            courseprice.loading = false;
            courseprice.errors = action.payload        
        },
        coursepriceAdded:(courseprice,action)=>{
            courseprice.loading = false;
            if(courseprice.errors) courseprice.errors=[]
            toast.success(action.payload.msg)
        },
        coursePriceSelectRequested:(coursePrice,action)=>{
            coursePrice.loading = true;
            coursePrice.priceSelect = [];
        },
        coursePriceSelectReceived:(coursePrice,action)=>{
            coursePrice.loading = false;
            coursePrice.priceSelect = action?.payload
        }

    }
})

const {
    coursepriceRequested,
    coursepriceReceived,
    coursepriceReceivedById,
    coursepriceRequestedFailed,
    coursepriceAdded,
    coursePriceSelectReceived,
    coursePriceSelectRequested    
} = slice.actions;

export default slice.reducer;

export const getCoursePriceAll = ()=>apiCallBegan({
    url: requests.getCoursePriceAll,
    headers:AuthHeader,
    onStart:coursepriceRequested.type,
    onSuccess:coursepriceReceived.type,
    onError: coursepriceRequestedFailed.type
})
export const getCoursePriceById = (id)=>apiCallBegan({
    url: requests.getCoursePriceById +'/'+id,
    headers:AuthHeader,
    onStart:coursepriceRequested.type,
    onSuccess:coursepriceReceivedById.type,
    onError: coursepriceRequestedFailed.type
})


export const addCoursePrice = (data)=>apiCallBegan({
    url: requests.createCoursePrice,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const filterCoursePrice = (data)=>apiCallBegan({
    url: requests.filterCoursePrice,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceReceived.type,
    onError: coursepriceRequestedFailed.type
})

export const editCoursePrice = (data)=>apiCallBegan({
    url: requests.updateCoursePrice,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const activateCoursePrice = (data)=>apiCallBegan({
    url: requests.activateCoursePrice,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const deactivateCoursePrice = (data)=>apiCallBegan({
    url: requests.deactivateCoursePrice,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const deleteCoursePrice = (data)=>apiCallBegan({
    url: requests.deleteCoursePrice,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const getCouponAll = ()=>apiCallBegan({
    url: requests.getCouponAll,
    headers:AuthHeader,
    onStart:coursepriceRequested.type,
    onSuccess:coursepriceReceived.type,
    onError: coursepriceRequestedFailed.type
})
export const getCouponById = (id)=>apiCallBegan({
    url: requests.getCouponById +'/'+id,
    headers:AuthHeader,
    onStart:coursepriceRequested.type,
    onSuccess:coursepriceReceivedById.type,
    onError: coursepriceRequestedFailed.type
})


export const addCoupon = (data)=>apiCallBegan({
    url: requests.createCoupon,
    method:'POST',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})

export const editCoupon = (data)=>apiCallBegan({
    url: requests.updateCoupon,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const activateCoupon = (data)=>apiCallBegan({
    url: requests.activateCoupon,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const deactivateCoupon = (data)=>apiCallBegan({
    url: requests.deactivateCoupon,
    method:'PATCH',
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})
export const deleteCoupon = (data)=>apiCallBegan({
    url: requests.deleteCoupon,
    data: data,
    headers: AuthHeader,
    onStart: coursepriceRequested.type,
    onSuccess: coursepriceAdded.type,
    onError: coursepriceRequestedFailed.type
})

export const getCoursePriceSelect = (courses)=>apiCallBegan({
    url: requests.getCoursePriceSelect+'/'+courses,
    headers: AuthHeader,
    onStart: coursePriceSelectRequested.type,
    onSuccess: coursePriceSelectReceived.type,
    onError: coursepriceRequestedFailed.type
})



