import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { AuthHeader } from '../Config/HeaderType';

const menuSlice = createSlice({
    name:'menus',
    initialState:{
        result:[],
        loading:false,
    },
    reducers:{
        menuRequested: (menus,aciton) => {
            menus.loading = true;
        },
        menusReceived: (menus,action) => {
            menus.loading = false;
            menus.result = action.payload;           
        },
        menuRequestFailed: (menus,action) => {
            menus.loading = false;
        },
        menuAdded: (menus,action) => {

        },
        menuEdited: (menus,action)=>{

        }
     }
})

const {
    menuRequested,
    menusReceived,
    menuRequestFailed,       
} = menuSlice.actions

export default menuSlice.reducer;

//Action Creators
// const url = requests.fetchMenu;

export const loadMenu = () => apiCallBegan({
    url:requests.fetchMenu,
    headers:AuthHeader,
    onStart: menuRequested.type,
    onSuccess: menusReceived.type,
    onError: menuRequestFailed.type
});
