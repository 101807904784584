import React from 'react'
import Tree from 'react-animated-tree';
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import {Link } from 'react-router-dom';
 import '../CourseSetup/style.css';
import { Paper } from '@material-ui/core';
import Spinner from '../Layout/Spinner';
const treeStyles = {
    color: 'white',
    fill: 'white',
    width: '100%'
  }
function TreeView({data,loading}) {
    
    return (
       <Paper style={{backgroundColor:'#343a40',padding:15,minHeight:300}}>
           {
               loading?<Spinner/>:
               data && data.map((item,index) => (
                   <Tree
                   key={item.id}
                   content={item.department_name+'-'+item.department_code}
                   style={treeStyles}
                   type={[
                       <Link to={`/department/addDepartment/${item.id}`}><AddRoundedIcon/></Link>,
                       <Link to={`/department/editDepartment/${item.id}`}><EditRoundedIcon/></Link>
                   ]}
                  >
                      {console.log("item",item.children[index]?.children,item.children[index]?.children)}
                       {item?.children[index]?.children ?<ParentTree data={item.children}/> : <ChildTree data={item.children}/>}
                   </Tree>
               ))
           }
       </Paper>
    )
}
const ChildTree=({data})=>{
    return(
        <>
    {data && data.map((items) => (
        <Tree 
        key={items.id_name} 
        content={items.department_name+'-'+items.department_code}
        type={[
            <Link to={`/department/addDepartment/${items.id}`}><AddRoundedIcon/></Link>,
            <Link to={`/department/editDepartment/${items.id}`}><EditRoundedIcon/></Link>
    ]}
    style={treeStyles}
      />
    ))}
    </>
    )
}

const ParentTree = ({data})=>(
    <>
     {
               data && data.map((item,index) => (
                   <Tree
                   key={item.id}
                   content={item.department_name+'-'+item.department_code}
                   style={treeStyles}
                   type={[
                       <Link to={`/department/addDepartment/${item.id}`}><AddRoundedIcon/></Link>,
                       <Link to={`/department/editDepartment/${item.id}`}><EditRoundedIcon/></Link>
                   ]}
                  >
                       {item?.children[index]?.children?<ParentTree data={item.children}/>: <ChildTree data={item.children}/>}
                   </Tree>
               ))
           }
    </>
)
export default TreeView
