import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactDataTable from '@ashvin27/react-datatable';
import AlumniAdd from './AlumniAdd';
import AlumniEdit from './AlumniEdit';
import BodyContainer from '../DashboardTemplate/BodyContainer';
import { loadAlumni } from '../../store/AlumniSetupStore';
import AlumniDelete from './AlumniDelete';
function AlumniSetup() {
  const [openPopUp,setOpenPopUp] = useState(false)
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)
  const [openPopUpView,setOpenPopUpView] = useState(false)
  const [refetch,setRefetch] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const alumni = useSelector(state => state.entities.alumni.result.data);
  const openAdd = ()=>{
    setOpenPopUp(true);
  }
  const handleEdit = (data)=>{
      setEData(data);
      setOpenPopUpEdit(true)
    }   
  const handleDelete = (data)=>{
    setEData(data);
    setOpenPopUpView(true)
  }
  useEffect(()=>{
    dispatch(loadAlumni());
  },[refetch])
  const columns = [
    {
      key:'alumni_name',
      text:"ALumni Name"
    },
    {
      key:'alumni_achievement',
      text:"Alumni Achievement"
    },
    {
      key:'course_name',
      text:"Course Name"
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 140,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}>
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => handleDelete(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  <Link
                  to={`/alumni/viewDetails/${record?.iid}`} 
                      style={{marginLeft:5}}
                      className="btn btn-success btn-sm" 
                      >
                      <i className="fa fa-eye"></i>
                  </Link>
              </>
          );
      }
  }
  ]
  return (
    <>
    <BodyContainer pageTitle="Alumni Setup" buttonTitle="Add Alumni" onClick={openAdd}>
        <ReactDataTable        
        columns = {columns}
        records = {alumni}
        />
        {
            openPopUp &&
            <AlumniAdd openPopUP={openPopUp} setOpenPopUp={setOpenPopUp} setRefetch={setRefetch}/>
        }
        {
            openPopUpEdit &&
            <AlumniEdit openPopUP={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} alumni={edata} setRefetch={setRefetch}/>
        }
        {
          openPopUpView &&
          <AlumniDelete openPopUp={openPopUpView} setOpenPopUp={setOpenPopUpView} alumni={edata} setRefetch={setRefetch}/>
        }
    </BodyContainer>
    </>
  )
}

export default AlumniSetup
