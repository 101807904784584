import {createSlice} from '@reduxjs/toolkit';
import {apiCallBegan} from './api';
import requests from '../ApiRequests/Request';
import { NormalHeader } from '../Config/HeaderType';
import { toast } from 'react-toastify';

const slice = createSlice({
    name:'employee',
    initialState:{
        result:[],
        loading:false,
        errors:[]
    },
    reducers:{
        employeeRequested:(employee,action)=>{
            employee.loading = true;
        },
        employeeReceived:(employee,action)=>{
            employee.loading = false;
            employee.result = action.payload
        },
        employeeRequestFailed:(employee,action)=>{
            employee.loading = false;
            employee.errors = action.payload;
            toast.error(action.payload.status)
        },
        employeeAdded:(employee,action) => {
            employee.loading = false;
            employee.result = action.payload;
            if(employee.errors) employee.errors = [];
            toast.success(action.payload.msg); 
        },
        getEmployee:(employee,action)=>{

        }

    }
})

const{
    employeeRequested,    
    employeeRequestFailed,
    employeeAdded,

} = slice.actions

export default slice.reducer;

export const addEmployee = (employeeData)=> apiCallBegan({
    url: requests.addEmployee,
    method:"post",
    headers:NormalHeader,
    data: employeeData,
    onStart:employeeRequested.type,
    onSuccess:employeeAdded.type,
    onError:employeeRequestFailed.type
})