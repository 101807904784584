import React,{useEffect, useState} from 'react';
import {Formik,Form,Field,ErrorMessage} from 'formik';
import {useDispatch,useSelector} from 'react-redux';
import FormPopUp from '../popup/PopUp';
import MathJax from 'mathjax3-react';
import { loadCourseSelect } from '../../store/CourseSetup';
import TextError from '../../TextError';
import * as Yup from 'yup'; 
import { loadSubCourseSelectById } from '../../store/SubCourseSetup';
import { loadLessonSelectById } from '../../store/LessonSetup';
import { getMainTopicSelectById } from '../../store/MainTopicSetup';
import { tagQuiz,getModelSetByCourseYear, getQuizLevelAllSelect } from '../../store/QuizSetupStore';
import {NEPALIYEAR,ADYEAR} from '../Calendar/NPYear';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
function QuizTag({openPopUp,setOpenPopUp,question}) {
    const [year,setYear] = useState(ADYEAR)
    const [courses,setCourse] = useState('');
    const [subcourses,setSubCourse] = useState('');
    const [mainTopic,setMainTopic] = useState('');
    // const [lessons,setLessons] = useState('');
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data);
    const courseLoading = useSelector(state => state.entities.courses.loading);
    const subcourse = useSelector(state => state.entities.subcourse.result?.data);
    const subcourseLoading = useSelector(state => state.entities.subcourse.loading);
    const mainTopics = useSelector(state => state.entities.mainTopic.getData?.data);
    const mainTopicsLoading = useSelector(state => state.entities.mainTopic.loading);
    const lesson = useSelector(state => state.entities.lesson.getData?.data);
    const lessonLoading = useSelector(state => state.entities.lesson.loading);
    const modelset = useSelector(state => state.entities.quiz.modelset?.data);
    const quiz_level = useSelector(state => state.entities.quiz.quizLevel?.data);
    const errors = useSelector(state => state.entities.quiz.errors?.data);
    const loading = useSelector(state => state.entities.quiz?.loading);
    useEffect(()=>{
        dispatch(loadCourseSelect());
        dispatch(getQuizLevelAllSelect())
    },[])
    const handleCourseClick = (id)=>{
        setCourse(id)
        if(id !== '')
        dispatch(loadSubCourseSelectById(id))
        if(year){
           let data = {
                'courses':id,
                'year':year
            }
            dispatch(getModelSetByCourseYear(data))
        }
    }
    const handleSubCourseClick = (id)=>{
        setSubCourse(id)
        if(id !== '')
        dispatch(getMainTopicSelectById(id))
    }
    const handleMainTopicClick = (id)=>{
        setMainTopic(id)
        if(id !== '')
        dispatch(loadLessonSelectById(id))
    }
   const onChange = (bsDate ) => {
		setYear(bsDate);
        if(courses){
           let data = {
                'courses':courses,
                'year':bsDate
            }
            dispatch(getModelSetByCourseYear(data))
        }
	};
    const handleSubmit = (values,submitProps) =>{
        values.created_by = localStorage.getItem('username');        
        dispatch(tagQuiz(values))
        submitProps.setSubmitting(false);
        
    }
    const initialValues = {
        quiz:question?.id,
        courses:courses,
        subcourse:subcourses,
        subcourse_main_topic:mainTopic,
        lesson:'',
        modelset:'',
        quiz_level:'',
        modelset_order_priority:'',
        marks_weightage:''
    }
    console.log("quiz",initialValues,question)
    const validationSchema = Yup.object().shape({
        quiz:Yup.string().required().label('quiz question'),
        courses:Yup.string().required().label('course'),
        subcourse:Yup.string().required().label('subcourse'),
        subcourse_main_topic:Yup.string().required().label('main topic'),
        lesson:Yup.string().required().label('lesson'),
        modelset_order_priority:Yup.number().when("modelset",{
            is: data => data !== '',
            then:Yup.number().required().label('qsn no')
        }),
        marks_weightage:Yup.number().when("modelset",{
            is: data => data !== '',
            then:Yup.number().required().label('marks weightage')
        }),        

    })
  return (
    <>
    <FormPopUp title="Tag Quiz" openPopup={openPopUp} setOpenPopup={setOpenPopUp}>
        {loading && <LoaderSpinner/>}
        {courseLoading && <LoaderSpinner/>}
       {subcourseLoading && <LoaderSpinner/>}
       {mainTopicsLoading && <LoaderSpinner/>}
       {lessonLoading && <LoaderSpinner/>}
        <label>
            Question:&nbsp;&nbsp;            
                <MathJax.Html html={question?.question}/>
           
        </label>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema} 
        onSubmit={handleSubmit}       
        enableReinitialize 
        >
            {
                ({isSubmitting,values})=>(
                    <Form>
                         <div className='form-group item'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="courses" component={TextError} />
                            </div>
                         <div className='form-group item'>
                                <label className='required-label'>Select SubCourse<span className="errors">*</span></label>
                                <Field as="select" name="subcourse" id="subcourse" placeholder="" value={subcourses} className='form-control' onChange={(e)=>handleSubCourseClick(e.target.value)}>
                                    <option name="subcourse" value="">select Options</option>
                                    {
                                        subcourse && subcourse?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item?.subcourse_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse" component={TextError} />
                            </div>
                         <div className='form-group item'>
                                <label className='required-label'>Select SubCourse MainTopic<span className="errors">*</span></label>
                                <Field as="select" name="subcourse_main_topic" id="subcourse_main_topic" placeholder="" className='form-control' value={mainTopic} onChange={(e)=>handleMainTopicClick(e.target.value)}>
                                    <option name="subcourse_main_topic" value="">select Options</option>
                                    {
                                        mainTopics && mainTopics?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.topic_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="subcourse_main_topic" component={TextError} />
                            </div>
                         <div className='form-group item'>
                                <label className='required-label'>Select Lesson<span className="errors">*</span></label>
                                <Field as="select" name="lesson" id="lesson" placeholder="" className='form-control'>
                                    <option name="lesson" value="">select Options</option>
                                    {
                                        lesson && lesson?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.lesson_title}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="lesson" component={TextError} />
                            </div>
                            <div className='form-group'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                    <option name="year" value="">select Options</option>
                                    {
                                        NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                            <option key={index} value={item}>{item}</option>
                                        ))
                                    }
                                </Field>                                
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select ModelSet<span className="errors">*</span></label>
                                <Field as="select" name="modelset" id="modelset" placeholder="" className='form-control'>
                                    <option name="modelset" value="">select Options</option>
                                    {
                                        modelset && modelset?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.modelset_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="modelset" component={TextError} />
                            </div>
                            <div className='form-group item'>
                                <label className='required-label'>Select Quiz Level<span className="errors">*</span></label>
                                <Field as="select" name="quiz_level" id="quiz_level" placeholder="" className='form-control'>
                                    <option name="quiz_level" value="">select Options</option>
                                    {
                                        quiz_level && quiz_level?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.level_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="quiz_level" component={TextError} />
                            </div>
                            {
                                values.modelset && 
                                <>
                                <div className='form-group'>
                                    <label>Qsn no:</label><span className='errors'>*</span>
                                    <Field type="number" name="modelset_order_priority" className="form-control" placeholder="qsn no"/>
                                    <ErrorMessage name='modelset_order_priority' component={TextError}/>
                                </div>
                                <div className='form-group'>
                                    <label>marks weightage:</label><span className='errors'>*</span>
                                    <Field type="number" name="marks_weightage" className="form-control" placeholder="marks weightage"/>
                                    <ErrorMessage name='marks_weightage' component={TextError}/>
                                </div>
                                </>
                            }
                            {
                                errors?.error && <div className='errors'>Quiz already added in this modelset</div>
                            }
                            <button className='btn btn-success btn-flat' type='submit' disabled={isSubmitting}>
                                <i className='fa fa-save'>&nbsp;&nbsp;Save</i>
                            </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
    </>
  )
}

export default QuizTag