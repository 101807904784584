import {createSlice} from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import requests from '../ApiRequests/Request';
import { AuthHeader, } from '../Config/HeaderType';
import {toast} from 'react-toastify';

const slice = createSlice({
    name:'department',
    initialState:{
        result:[],
        getData:[],
        getDataById:[],
        errors:[],
        loading: false,
    },
    reducers:{
        departmentRequested: (department,action)=>{
            department.loading = true;
        },
        departmentReceived: (department,action)=>{
            department.loading = false;
            department.result = action.payload;
        },
        departmentReceivedAll: (department,action)=>{
            department.loading = false;
            department.getData = action.payload;
        },
        departmentReceivedById: (department,action)=>{
            department.loading = false;
            department.getDataById = action.payload;
        },
        departmentRequestFailed: (department,action)=>{
            department.loading = false;
            department.errors = action.payload
            // toast.error(action.payload.status)
        },
        departmentAdded: (department,action)=>{
            department.loading = false;
            if(department.errors) department.errors=[]
            toast.success(action.payload.msg)
        }

    }

   
})

const {
    departmentRequested,
    departmentReceived,
    departmentReceivedAll,
    departmentReceivedById,
    departmentRequestFailed,
    departmentAdded
} = slice.actions;

export default slice.reducer;

export const getDepartment = ()=> apiCallBegan({
    url: requests.getDepartment,
    headers:AuthHeader,
    onStart: departmentRequested.type,
    onSuccess: departmentReceived.type,
    onError: departmentRequestFailed.type
})
export const getDepartmentById = (id)=> apiCallBegan({
    url: requests.getDepartmentById+'/'+id,
    headers:AuthHeader,
    onStart: departmentRequested.type,
    onSuccess: departmentReceivedById.type,
    onError: departmentRequestFailed.type
})

export const getDepartmentAll = ()=> apiCallBegan({
    url:requests.getDepartmentAll,
    headers:AuthHeader,
    onStart: departmentRequested.type,
    onSuccess: departmentReceivedAll.type,
    onError: departmentRequestFailed.type
})

export const createDepartment = (department) => apiCallBegan({
    url: requests.createDepartment,
    method: 'POST',
    data: department,
    headers:AuthHeader,
    onStart: departmentRequested.type,
    onSuccess: departmentAdded.type,
    onError: departmentRequestFailed.type
})
export const updateDepartment = (department) => apiCallBegan({
    url: requests.updateDepartment,
    method: 'PATCH',
    data: department,
    headers:AuthHeader,
    onStart: departmentRequested.type,
    onSuccess: departmentAdded.type,
    onError: departmentRequestFailed.type
})
