import { ErrorMessage, Field, Form, Formik } from 'formik'
import MathJax from 'mathjax3-react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactDataTable from '@ashvin27/react-datatable';
import {NEPALIYEAR,ADYEAR} from '../Calendar/NPYear'
import { loadCourseSelect } from '../../store/CourseSetup';
import { loadSubCourseSelectById } from '../../store/SubCourseSetup';
import TextError from '../../TextError';
import BodyContainer from '../DashboardTemplate/BodyContainer'
import {  getModelSetByCourseYear, getModelsetByCourseYear, getModelsetReport} from '../../store/QuizSetupStore';
import * as Yup from 'yup';
import Spinner from '../Layout/Spinner';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';


function QuizModelsetReport() {    
    const [courses,setCourse] = useState('');
    const [year,setYear] = useState(ADYEAR);
    const [,setQuestion] = useState('');
    const [,setOpenEditTag] = useState(false);
    const [,setOpenViewTag] = useState(false);
    const dispatch = useDispatch();
    const course = useSelector(state => state.entities.courses.result?.data);
    const quizData = useSelector(state => state.entities.quiz.modelsetReport?.data);
    const modelset = useSelector(state => state.entities.quiz.modelset?.data);
    const loading = useSelector(state => state.entities.quiz.loading);
    useEffect(()=>{
        dispatch(loadCourseSelect());
    },[])
    const handleCourseClick = (id)=>{
        setCourse(id)
        if(id !== '')
        dispatch(loadSubCourseSelectById(id)) 
        if(year){
            let data = {
                'courses':id,
                'year':year
            }
            dispatch(getModelsetByCourseYear(data))
        }     
                 
    }
    const onChange = (bsDate ) => {
		setYear(bsDate);
        if(courses){
           let data = {
                'courses':courses,
                'year':bsDate
            }
            dispatch(getModelSetByCourseYear(data))
        }
	};
    const handleSubmit = (values,submitProps) =>{     
        dispatch(getModelsetReport(values))
        submitProps.setSubmitting(false);        
    }
    
    const initialValues = {             
        courses:courses,
        modelset:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        modelset: Yup.string().required(),       

    })
    const clickTagQuiz =(record)=>{
        setQuestion(record);
        setOpenEditTag(true);
    }
    const clickTagViewQuiz = (record)=>{
        setQuestion(record);
        setOpenViewTag(true);
    }
    
    const COLUMNS = [
      {
          key:'question',
          text:'Question',
          maxWidth:650,
          cell : record => {
              return(
                  <>                  
                      <MathJax.Html html={record?.question}/>                  
                  </>
              )
          }
      },
      {
          key:'correct_option',
          text:'Correct Options',
          cell : record => {
              return(
                  <>                  
                      <MathJax.Html html={record?.correct_option}/>                  
                  </>
              )
          }
      },
      {
          key:'modelset_order_priority',
          text:'Qsn No',
          
      },
      {
          key:'marks_weightage',
          text:'Marks Weightage',
          
      },
      {
          key:'level_name',
          text:'Level',
          cell: record =>{
              return (
                  <>
                  <span>{record?.level_name?record?.level_name:'N/A'}</span>
                  </>
              )
          }
          
      },
      {
          key: "action",
          text: "Action",
          className: "action",
          width: 200,
          align: "left",
          sortable: false,
          cell: record => { 
              return (
                  <>
                      <button
                          className="btn btn-success btn-sm"                           
                          style={{marginRight: '5px'}}                         
                          onClick={()=> clickTagQuiz(record)}
                          >
                          <i className="fa fa-edit">&nbsp;edit Tag</i>
                      </button>
                      {/* <button 
                          className="btn btn-danger btn-sm" 
                          onClick={() => console.log(record)}>
                          <i className="fa fa-trash"></i>
                      </button>
                      */}
                      <button 
                          
                          style={{marginLeft:5}}
                          className="btn btn-primary btn-sm" 
                          onClick={()=>clickTagViewQuiz(record)}
                          >                            
                          <i className="fa fa-eye">&nbsp;view</i>
                      </button> 
                  </>
              );
          }
      }
  ]
 
  return (
    <>
    <BodyContainer pageTitle="Modelset Report">
      <div className='card'>
        <div className='card-body'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {
              ({isSubmitting})=>(
                <Form>
                  <div className='row'>
                    <div className='form-group item col-md-4'>
                                <label className='required-label'>Select Course<span className="errors">*</span></label>
                                <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                    <option name="courses" value="">select Options</option>
                                    {
                                        course && course?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="courses" component={TextError} />
                            </div>
                            <div className='form-group col-md-3'>
                                <label>Select year:</label>
                                <Field as="select" name="year" id="year" placeholder="" className='form-control' value={year} onChange={(e)=>onChange(e.target.value)}>
                                          <option name="year" value="">select Options</option>
                                          {
                                              NEPALIYEAR && NEPALIYEAR?.map((item,index)=>(
                                                  <option key={index} value={item}>{item}</option>
                                              ))
                                          }
                                </Field> 
                            </div>
                            <div className='form-group item col-md-4'>
                                <label className='required-label'>Select ModelSet<span className="errors">*</span></label>
                                <Field as="select" name="modelset" id="modelset" placeholder="" className='form-control'>
                                    <option name="modelset" value="">select Options</option>
                                    {
                                        modelset && modelset?.map((item,index)=>(
                                            <option key={item.iid} value={item.iid}>{item.modelset_name}</option>
                                        ))
                                    }
                                </Field>
                                <ErrorMessage name="modelset" component={TextError} />
                            </div>
                            <div className='form-group item col-md-2 float-sm-right'>
                              <label>&nbsp;</label>
                            <button className='btn btn-success btn-flat form-control ' type='submit' disabled={isSubmitting}>
                               {isSubmitting?<Spinner/>:''} <i className='fa fa-search'>&nbsp;&nbsp;Search</i>
                            </button>
                            </div>
                            </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
      <div className='card'>
            <div className='card-body'>
              <ReactDataTable
              records = {quizData}
              columns = {COLUMNS}
              />
            </div>
      </div>
     
      {
          loading && <LoaderSpinner/>
      }
    </BodyContainer>
    </>
  )
}

export default QuizModelsetReport