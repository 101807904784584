import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDataTable from '@ashvin27/react-datatable';
import { loadFeatureTag } from '../../store/FeatureSetupStore';
import DeleteFeatureTag from './DeleteFeatureTag';
import EditFeatureTag from './EditFeatureTag';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { loadCourseSelect } from '../../store/CourseSetup';
import Card from '../Card/Card';
import Spinner from '../Layout/Spinner';
import * as Yup from 'yup';
import LoaderSpinner from '../UtilityComponents/LoaderSpinner';
import TextError from '../../TextError';
function FeatureTagSetup() {  
  const [openPopUpEdit,setOpenPopUpEdit] = useState(false)  
  const [openPopUpDelete,setOpenPopUpDelete] = useState(false)
  const [edata,setEData] = useState()
  const dispatch = useDispatch();
  const feature = useSelector(state => state.entities.feature.result?.data);
  const loading = useSelector(state => state.entities.feature.loading);
  const course = useSelector(state => state.entities.courses.result?.data);
  
  
  const handleEdit = (data)=>{     
    setEData(data);
    setOpenPopUpEdit(true)
  }  
  
  const handleDelete = (data)=>{     
    setEData(data);
    setOpenPopUpDelete(true)
  } 
  const initialValues = {
    courses:''
  }
  const handleSubmit = (values,submitProps) => {
    dispatch(loadFeatureTag(values))
    submitProps.setSubmitting(false);
  }
  const validationSchema = Yup.object().shape({
    courses: Yup.string().required()
  }) 
  useEffect(()=>{
    dispatch(loadCourseSelect());
  },[openPopUpDelete,openPopUpEdit])
  const columns = [
    {
      key:'features_title',
      text:"Feature Title"
    },
    {
      key:'course_name',
      text:"Course Name"
    },  
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: record => { 
          return (
              <>
                  <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEdit(record)}
                      style={{marginRight: '5px'}}
                      key={record?.iid}
                      >
                      <i className="fa fa-edit"></i>
                  </button>
                  <button 
                      className="btn btn-danger btn-sm" 
                      onClick={() => handleDelete(record)}>
                      <i className="fa fa-trash"></i>
                  </button>
                  
              </>
          );
      }
  }
  ]
  return (
    <>
    <div className='content-wrapper'>
    <div className="content-header">
             <div className="container-fluid">
            <div className="row mb-2">
            <div className="col-sm-6">
            <h1 className="m-0">Tag Feature Setup</h1>
                 </div>{/* /.col */}
            
            </div>{/* /.row */}
            </div>{/* /.container-fluid */}
    </div>
    <div className='content'>
    <Card>
        <div className='card-body'>
          <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          >
            {
              ({isSubmitting})=>(
                <Form>
                  <div className='row'>
                      <div className='col-md-4'>
                        <label>select courses:</label>
                        <Field as="select" className='form-control' name="courses" >
                        <option value=''>select courses</option>
                            {
                              course && course?.map((item,index)=>(
                                <option key={index} value={item?.iid}>{item?.course_name}</option>
                              ))
                            }
                        </Field>
                        <ErrorMessage name='courses' component={TextError}/>
                      </div>
                      <div className='col-md-2'>
                            <label>&nbsp;</label>
                            <button className='btn btn-flat btn-success form-control' disabled={isSubmitting}>
                                {
                                  isSubmitting && <Spinner/>
                                }
                                &nbsp;<i className='fa fa-search'/>&nbsp;search
                            </button>
                      </div>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </Card>
        <ReactDataTable        
        columns = {columns}
        records = {feature}
        />
        {/* <InstructorAdd openPopUP={openPopUp} setOpenPopUp={setOpenPopUp}/> */}
        {
            openPopUpEdit &&
            <EditFeatureTag openPopUp={openPopUpEdit} setOpenPopUp={setOpenPopUpEdit} feature={edata}/>
        }
        {/* {
            openPopUpView &&
            <ViewInstructorTag openPopUp={openPopUpView} setOpenPopUp={setOpenPopUpView} instructor={edata}/>
        } */}
        {
            openPopUpDelete &&
            <DeleteFeatureTag openPopUp={openPopUpDelete} setOpenPopUp={setOpenPopUpDelete} feature={edata}/>
        }
        {
          loading &&
          <LoaderSpinner/>
        }
    </div>
    </div>
    </>
  )
}

export default FeatureTagSetup