import React from 'react'
import Progressbar from 'react-js-progressbar'

function ProgressBar({percentage}) {
  return (
    <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',margin:15}}>
        <div style={{height:100,width:100}}>
        <Progressbar
          input={percentage?.percent?percentage?.percent:0}
          pathWidth={10}
          pathColor={['#56ab2f', '#a8e063']} // use an array for gradient color.
          trailWidth={10}
          trailColor='#363636' // use a string for solid color.
          textStyle={{ fill: 'blue' }} // middle text style
          dashed={true}
          
                />
                </div>  
        <div >
            {
                percentage?.loaded &&
                <span>{`${percentage?.loaded/1000000} MB of ${percentage?.total/1000000} MB` }</span>
            }
            </div>  
        </div>
  )
}

export default ProgressBar