import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteFeatures } from '../../store/FeatureSetupStore'
import Spinner from '../Layout/Spinner'
import FormPopUp from '../popup/PopUp'

function DeleteFeature({openPopup,setOpenPopup,feature,setRefetch}) {
    const dispatch = useDispatch()
    const initialValues = {
        feature_id:feature?.iid
    }
    const handleSubmit = (values,submitProps)=>{
        dispatch(deleteFeatures(values));
        submitProps.setSubmitting(false);
        setRefetch(prevState => !prevState)
    }
    
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="Delete Feature">
        <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <label>Feature:</label><span>{feature?.features_title}</span><br/>
                       <label> Do you want to delete ?</label><br/>
                        <button className='btn btn-flat btn-success ' type='submit' disabled={isSubmitting}>
                            {isSubmitting?<Spinner/>:''} <i className='fa fa-trash'/>&nbsp;Delete
                        </button>
                    </Form>
                )
            }
        </Formik>
    </FormPopUp>
  )
}

export default DeleteFeature