import React, { useState } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { ErrorMessage, Form, Formik,Field } from 'formik'
import TextError from '../../TextError'
import { useEffect } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { loadCourseSelect } from '../../store/CourseSetup'
import { NEPALIYEAR } from '../Calendar/NPYear'
import makeAnimated from 'react-select/animated'
import Select from 'react-select';
import { getCoursePriceSelect } from '../../store/CoursePriceStore'
import Spinner from '../Layout/Spinner'
import * as Yup from 'yup'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { getFilterStudent } from '../../store/EnrollStore'
import DataTable from 'react-data-table-component';
import PopupModal from '../Material-uiControls/PopupModal'
import BatchTagAdd from './BatchTagAdd'
const animatedComponents = makeAnimated();
function BatchTagSetup() {
    const [openCreateBatch,setOpenCreateBatch] = useState(false)
    const [refreshData,setRefreshData] = useState(false);
    const [selectedData,setSelectedData] = useState([])
    const [select,setSelect] = useState([])
    const [value, onChange] = useState([new Date(), new Date()])
    const [courses,setCourse] = useState('');
    const course = useSelector(state => state.entities.courses?.result?.data);
    const coursePrice = useSelector(state=>state.entities.courseprice?.priceSelect?.data)
    const isLoading = useSelector(state=>state.entities.enroll?.loading)
    const filterData = useSelector(state=>state.entities.enroll?.filterStudent?.data)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(loadCourseSelect())
    },[])
    const onInputChange = (setSelectedOptions)=>{
        console.log("selected",setSelectedOptions.map(item=>item.value))
        setSelect(setSelectedOptions.map(item=>item.value))
    }
    const handleCourseClick = (id)=>{
        setCourse(id)
        dispatch(getCoursePriceSelect(id))
    }
    const initialValues = {
        courses: courses,
        courseprice:select,
        enroll_date: value,
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        courseprice:Yup.array()
        .min(1, 'Please select at least one package')
        .required('Please select at least one package'),
        enroll_date: Yup.array().of(Yup.date().required()).required()        
    }) 
    const handleSubmit = (values)=>{
        console.log("values",values)
        dispatch(getFilterStudent(values))
    }
    const handleSelect=(date)=>{
        console.log(date); // native Date object
      }
      const columns = [
        {
            name:'Student Name',
            selector: row=>row.full_name,
            sortable:true
        },
        {
            name:'course',
            selector: row=>row.course_name,
            sortable:true
        },
        {
            name:'package',
            selector: row=>row.package_name,
            sortable:true
        },
        {
            name:'enrolled date',
            selector: row=>row.enrolled_date,
            sortable:true
        },
        {
            name:'enrolled end date',
            selector: row=>row.enroll_end_date,
            sortable:true
        },
      ]
      const handleSelectedRow = (row)=>{
        console.log("selected row",row)
        setSelectedData(row.selectedRows)
      }
      const handleBatchCreate = ()=>{
        setOpenCreateBatch(true)
      }
  return (
    <>
        <BodyContainer
        pageTitle={`Batch Tag Setup`}        
        >
            <div className='card'>
                <div className='card-body'>
                    <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit} 
                    enableReinitialize
                    >
                        {
                            ({values,handleBlur})=>(
                                <Form>
                                    <div className='row'>
                                        <div className='form-group col-md-4'>
                                        <label className='required-label'> select course<span className="errors">*</span></label>
                                        <Field as="select" name="courses" id="courses" placeholder="" className='form-control' value={courses}  onChange={(e)=>handleCourseClick(e.target.value)} >
                                        <option name="courses" value="">select Options</option>
                                        {
                                            course && course?.map((item,index)=>(
                                                <option key={item.iid} value={item.iid}  >{item.course_name}</option>
                                            ))
                                        }
                                        </Field>
                                        <ErrorMessage name="courses" component={TextError} />
                                        </div> 
                                        <div className='form-group col-md-4'>
                                            <label>Select Package:</label>
                                            <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={coursePrice}
                                            isClearable
                                            isSearchable
                                            onChange={onInputChange}
                                            // value={select}
                                            name="courseprice"
                                            onBlur={handleBlur('courseprice')}
                                            /> 
                                            <ErrorMessage name="courseprice" component={TextError}/>
                                        </div>
                                        <div className="form-group col-md-4">
                                            <label>select enroll date range:</label>
                                            <DateRangePicker 
                                            onChange={onChange} 
                                            value={value} 
                                            className={`form-control`}
                                            format="y-MM-dd" 
                                            />
                                            <ErrorMessage name='enroll_date' component={TextError}/>
                                        </div>
                                    </div>
                                        <div className='form-group item col-md-2 float-sm-right'>
                                            {/* <label>&nbsp;&nbsp;</label> */}
                                            <button className='btn btn-success btn-flat form-control ' type='submit' disabled={isLoading}>
                                            {isLoading?<Spinner/>:''} <i className='fa fa-search'>&nbsp;&nbsp;Filter</i>
                                            </button>
                                        </div>             
                                </Form>
                            )
                        }
                    </Formik>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div>
                        <button className='float-sm-rignt btn btn-flat btn-success' type='button' disabled={selectedData?.length>0?false:true} onClick={handleBatchCreate}>
                            Tag Batch
                        </button>                        
                    </div>                    
                    
                    <DataTable
                    title="Student List"
                    columns={columns}
                    data={filterData}
                    highlightOnHover
                    selectableRows
                    fixedHeader
                    fixedHeaderScrollHeight='450px'
                    pagination
                    selectableRowsHighlight
                    onSelectedRowsChange={handleSelectedRow}
                    // actions={<button className='btn btn-sm btn-info'>Tag</button>}
                    />
                </div>
            </div>
            <PopupModal
            title={`Create Batch`}
            openPopup={openCreateBatch}
            setOpenPopup={setOpenCreateBatch}
            setRefreshData={setRefreshData}
            >
                <BatchTagAdd 
                course={course} 
                studentList={selectedData}               

                />
            </PopupModal>
        </BodyContainer>
    </>
  )
}

export default BatchTagSetup