
import React from 'react'
import { Link,useHistory } from 'react-router-dom'



function MenuDisplay({menu_route,menuId,menuname,childItems,pIndex}) {    
   const history = useHistory()
    const onMenuClick = ()=>{
      
    }
    return (
        <>
         <li className={`nav-item `} key={menuname}>          
          <Link to={menu_route ==='#'?`#`:`/${menu_route}/`} className="nav-link">
            <i className="nav-icon fa fa-book" style={{fontSize:14}} />
            <p style={{fontSize:14}}>
              {menuname}
              <i className="right fas fa-angle-left" />
            </p>
          </Link>
          {childItems? <SubMenu key={menuId} subMenuData={childItems}/> : null }
          </li>
        </>
    )
}

function SubMenu({subMenuData}){
  
    return(
        <>
        {
            subMenuData.map((item,index) => 
            
        <ul className="nav nav-treeview" key={item.id}>
            <li className="nav-item" key={index} style={{padding:2,marginBottom:-10}}>
              <Link to={`/${item.menu_route}/`} className="nav-link" >
                <i className={`${item.menu_icon} nav-icon`} style={{fontSize:11}}/>
                <p style={{fontSize:12,padding:2}}>{item.menu_name}</p>
              </Link>
            </li>
            </ul>
            
            )}
        </>
    )
}



export default MenuDisplay
