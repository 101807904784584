import React from 'react'
import FormPopUp from '../popup/PopUp'

function ViewCoursePrice({openPopup,setOpenPopup,data}) {
  return (
    <FormPopUp openPopup={openPopup} setOpenPopup={setOpenPopup} title="View Course Price Details">
        <label>Course Name :</label>&nbsp;<span>{data?.course_name}</span><br/>
        <label>Package Name :</label>&nbsp;<span>{data?.package_name}</span><br/>
        <label>Course Price :</label>&nbsp;<span>{data?.course_price}</span><br/>
        <label>Course Exam Fee :</label>&nbsp;<span>{data?.course_exam_fee}</span><br/>
        <label>Access Days :</label>&nbsp;<span>{data?.access_days?data?.access_days:'N/A'}</span><br/>
        <label>Has Course Offer? :</label>&nbsp;<span>{data?.has_course_offer?'True':'False'}</span><br/>
        <label>Discount Offer % :</label>&nbsp;<span>{data?.discount_offer?data?.discount_offer+'%':'0%'}</span><br/>
        <label>Course Price After Discount :</label>&nbsp;<span>{data?.cp_after_discount?data?.cp_after_discount:'N/A'}</span><br/>
        <label>Has Exam Fee Offer :</label>&nbsp;<span>{data?.has_exam_fee_offer?'True':'False'}</span><br/>
        <label>Exam Fee Discount Offer % :</label>&nbsp;<span>{data?.exam_fee_discount_offer?data?.exam_fee_discount_offer+'%':'0%'}</span><br/>
        <label>Exam Fee After Disc :</label>&nbsp;<span>{data?.fee_price_after_disc?data?.fee_price_after_disc:'N/A'}</span><br/>
        <label>Has Life Time Access? :</label>&nbsp;<span>{data?.is_life_time?'True':'False'}</span><br/>
        <label>Is Active? :</label>&nbsp;<span>{data?.is_active?'True':'False'}</span><br/>
        <label>Created Date :</label>&nbsp;<span>{data?.created_date?data?.created_date:'N/A'}</span><br/>
        <label>Created By :</label>&nbsp;<span>{data?.created_by?data?.created_by:'N/A'}</span><br/>
        <label>Modified Date :</label>&nbsp;<span>{data?.modified_date?data?.modified_date:'N/A'}</span><br/>
        <label>Modified By :</label>&nbsp;<span>{data?.modified_by?data?.modified_by:'N/A'}</span><br/>
        <label>Modified Remarks :</label>&nbsp;<span>{data?.modified_remarks?data?.modified_remarks:'N/A'}</span><br/>
    </FormPopUp>
  )
}

export default ViewCoursePrice