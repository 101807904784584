import React, { useEffect } from 'react'
import BodyContainer from '../DashboardTemplate/BodyContainer'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import TextError from '../../TextError';
import { Link } from 'react-router-dom';
import { useState } from 'react'
import { loadSubCourseSelectById } from '../../store/SubCourseSetup'
import Spinner from '../Layout/Spinner'
import MathJax from "mathjax3-react";
import ReactVideoPlayers from './ReactVideoPlayers'
import { getCoursePlayList, getInstructorCourseSelect } from '../../store/CourseSetup'
import LoaderSpinner from '../UtilityComponents/LoaderSpinner'
import { getMainTopicSelectById } from '../../store/MainTopicSetup'
import Discussion from './Discussion'
import { getDiscussionList } from '../../store/DiscussionStore'
import CourseResources from './CourseResources'
import LessonQuiz from './LessonQuiz'
function CoursePlayListSetup() {
    const [courseId,setCourseId] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const [activeTab,setActiveTab] = useState('Description')
    const [subcourses,setSubCourse] = useState();
    const [playListIndex,setPlayListIndex] = useState(0);
    const [lessonIndex,setLessonIndex] = useState(0);    
    const course=useSelector(state=>state.entities.courses?.courseSelect?.data);
    const subcourse = useSelector(state => state.entities.subcourse.result?.data);
    const mainTopics = useSelector(state => state.entities.mainTopic.getData?.data);
    const playList = useSelector(state => state.entities.courses?.coursePlaylist?.data)
    const discussionData = useSelector(state => state.entities.discussion?.discussion?.data)
    const dispatch= useDispatch()
    useEffect(()=>{
        dispatch(getInstructorCourseSelect())
    },[])
    const initialValues = {
        courses:courseId,
        subcourse:subcourses,
        subcourse_main_topic:''
    }
    const validationSchema = Yup.object().shape({
        courses: Yup.string().required(),
        subcourse: Yup.string().required(),
        subcourse_main_topic: Yup.string().required().label('maintopic'),
    })
    const handleSubmit = async(values,submitProps)=>{
        await dispatch(getCoursePlayList(values.subcourse_main_topic))
        submitProps.setSubmitting(false);
    }
    const handleCourseClick = async(id)=>{
        console.log("course",id)
        setIsLoading(true)
        setCourseId(id)
        if(id !== '')
        await dispatch(loadSubCourseSelectById(id))

        setIsLoading(false)
    }
    const handleSubCourseClick = (id)=>{
        setSubCourse(id)
        if(id !== '')
        dispatch(getMainTopicSelectById(id))
    }
    let playListData,lessonPlayList,playlist,lesson_quiz,quiz,options,lesson_subtopic,lesson_resources,resources;
  playList &&  Object.keys(playList).map((item)=>{        
        playListData = playList[item]
        Object.keys(playListData).map((lessonItem)=>{
            
            lessonPlayList = Object.keys(playListData[lessonItem]).map((itm)=>{
            playlist = playListData[lessonItem][itm]
            lesson_quiz =  playListData[lessonItem][itm]['lesson_quiz']
            lesson_subtopic =  playListData[lessonItem][itm]['sub_topic']
            lesson_resources =  playListData[lessonItem][itm]['lesson_resources']
            return{
                lesson_id: playlist?.lesson_id,
                lecture_hours: playlist?.lecture_hours,
                lesson_title: playlist?.lesson_title,
                lesson_quiz_count: playlist?.result_quiz_count,
                subtopic: lesson_subtopic && Object.keys(lesson_subtopic).map((subItem)=>{
                    return{
                        page_type: lesson_subtopic[subItem]?.page_type,
                        comments_count: lesson_subtopic[subItem]?.comments_count,
                        lesson_id: playlist?.lesson_id,
                        maintopic_id: lesson_subtopic[subItem]?.maintopic_id,
                        course_id: lesson_subtopic[subItem]?.course_id,
                        subcourse_id: lesson_subtopic[subItem]?.subcourse_id,
                        subcourse_slug: lesson_subtopic[subItem]?.subcourse_slug,
                        topic_id: lesson_subtopic[subItem]?.topic_id,
                        topic_title: lesson_subtopic[subItem]?.topic_title,
                        video_duration: lesson_subtopic[subItem]?.video_duration,
                        video_url: lesson_subtopic[subItem]?.video_url,
                        description: lesson_subtopic[subItem]?.description,
                        has_completed: lesson_subtopic[subItem]?.has_completed,
                        ratings: lesson_subtopic[subItem]?.ratings,
                        avg_rating: lesson_subtopic[subItem]?.avg_rating,
                    }
                }),
                lesson_quiz: lesson_quiz && Object.keys(lesson_quiz).map((quizItem)=>{
                    quiz = lesson_quiz[quizItem]['quiz']
                    return{
                        quizs: quiz && Object.keys(quiz).map((qzItm)=>{
                            options = quiz[qzItm]['quiz_options']
                            return{
                                lesson_id:quiz[qzItm]?.lesson_id,
                                quiz_id:quiz[qzItm]?.quiz_id,
                                answer_id:quiz[qzItm]?.answer_id,
                                answer_status:quiz[qzItm]?.answer_status,
                                question:quiz[qzItm]?.question,
                                correct_option:quiz[qzItm]?.correct_option,
                                answer_desc:quiz[qzItm]?.answer_desc,
                                options:options && Object.keys(options).map((oitem)=>{
                                return {
                                    option_id:options[oitem]?.option_id,
                                    option:options[oitem]?.options,
                                    correct:options[oitem]?.correct
                                }
                                })
                            }
                        })
                    }
                    
                }),
                lesson_resources: lesson_resources && Object.keys(lesson_resources).map((resrcItem)=>{
                    resources = lesson_resources[resrcItem]['resources']
                    return{
                        resource: resources && Object.keys(resources).map((resrcItm)=>{
                            
                            return{
                                resource_id:resources[resrcItm]?.resource_id,
                                resources:resources[resrcItm]?.resources,
                                
                            }
                        })
                    }
                    
                }),
                
            }            

            })
        })
    })
    
    const [subtopic,setSubtopic] = useState(lessonPlayList && lessonPlayList[0]['subtopic']?lessonPlayList[0]['subtopic'][0]:'')
    const [lessonResources,setLessonResources] = useState(lessonPlayList && lessonPlayList[0]?.lesson_resources[0]?.resource)
    const [lessonQuiz,setLessonQuiz] = useState()
    const handlePlayListChange = (lesson_idx,idx) =>{
        setPlayListIndex(idx)
        setLessonIndex(lesson_idx)
        setSubtopic(lessonPlayList[lesson_idx]['subtopic'][idx])
        setLessonResources(lessonPlayList[lesson_idx]?.lesson_resources[0]?.resource)          
    }
    const handleLessonListChange = (idx) =>{
        setLessonIndex(idx)
        setPlayListIndex('')
        setSubtopic(null)
        setLessonQuiz(lessonPlayList[idx]?.lesson_quiz && lessonPlayList[idx]?.lesson_quiz[0]?.quizs)
        // setSubtopic(lessonPlayList[0]['subtopic'][idx])
        
    }
   
    const handleTabChange = async(tabName)=>{
        setActiveTab(tabName)
        console.log("tabName",tabName)
        if(tabName === `Comments`){
            await dispatch(getDiscussionList(subtopic?.topic_id))
        }
    }
  return (  
    <BodyContainer>
        {
            isLoading && <LoaderSpinner/>
        }
        <div className='card'>
            <div className='card-body'>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
                >
                    {
                        ({isSubmitting})=>(
                            <Form>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Select Course:</label><span className='errors'>*</span>
                                            <Field as="select" name="courses" className="form-control" value={courseId} onChange={(e)=>handleCourseClick(e.target.value)}>
                                                <option value="">select options</option>
                                                {
                                                  course && course.map((item)=>(
                                                    <option key={item?.courses} value={item?.courses}>{item?.course_name}</option>
                                                ))  
                                                }
                                            </Field>
                                            <ErrorMessage name='courses' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label>Select Subcourse:</label><span className='errors'>*</span>
                                            <Field as="select" name="subcourse" value={subcourses} className='form-control' onChange={(e)=>handleSubCourseClick(e.target.value)}>
                                                <option value="">select options</option>
                                                {
                                                  subcourse && subcourse.map((item)=>(
                                                    <option key={item?.iid} value={item?.iid}>{item?.subcourse_name}</option>
                                                ))  
                                                }
                                            </Field>
                                            <ErrorMessage name='subcourse' component={TextError}/>
                                            {/* {errors?.courses && <div className='errors'>{errors?.courses}</div>} */}
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                    <div className='form-group item'>
                                            <label className='required-label'>Select SubCourse MainTopic<span className="errors">*</span></label>
                                            <Field as="select" name="subcourse_main_topic" id="subcourse_main_topic" placeholder="" className='form-control' >
                                                <option name="subcourse_main_topic" value="">select Options</option>
                                                {
                                                    mainTopics && mainTopics?.map((item,index)=>(
                                                        <option key={item.iid} value={item.iid}>{item.topic_title}</option>
                                                    ))
                                                }
                                            </Field>
                                            <ErrorMessage name="subcourse_main_topic" component={TextError} />
                                        </div>
                                    </div>
                                        <div className='col-md-2'>
                                            <div className='form-group'>
                                            <label>&nbsp;</label>
                                            <button className='btn btn-success btn-flat form-control'  disabled={isSubmitting}>
                                                {isSubmitting?<Spinner/>:''} view playlist
                                            </button>
                                            </div>
                                        </div>
                                                                    
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </div>
        </div>
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-3'>
                        <ul className='nav nav-pills nav-sidebar flex-column' data-widget="treeview" role="menu" data-accordion="false">
                            {
                                lessonPlayList && lessonPlayList.map((item,lindex)=>(
                                    <li className='nav-item' key={lindex}>
                                        <Link to="#" className="nav-link">
                                            <i className="nav-icon fa fa-book" style={{fontSize:14}} />
                                            <p style={{fontSize:14}}>
                                                {item?.lesson_title}
                                            <i className="right fas fa-angle-left" />
                                            </p>
                                        </Link>
                                        {
                                            item?.subtopic && item?.subtopic.map((subItem,index)=>(
                                            <ul className="nav nav-treeview" key={`${index}-H`}>
                                                <li className="nav-item"  style={{padding:2,marginBottom:-10}}>
                                                <Link to={`#`} className={(lindex === lessonIndex && index=== playListIndex)?`nav-link active`:'nav-link'} onClick={()=>handlePlayListChange(lindex,index) } >
                                                    <i className={subItem?.page_type === 'multimedia'?`fa fa-play`:`fa fa-book`} style={{fontSize:11}}/>
                                                    <p style={{fontSize:12,padding:2}}>{`${subItem?.topic_title} ${subItem?.video_duration?subItem?.video_duration:''}`}</p>
                                                </Link>
                                                </li>   
                                            </ul>

                                            ))
                                        }
                                        {/* {
                                        item?.lesson_quiz && item?.lesson_quiz[0]?.quizs.length > 0? */}
                                        <ul className="nav nav-treeview" >
                                                <li className="nav-item"  style={{padding:2,marginBottom:-10}}>
                                                <Link to={`#`} className={(lindex === lessonIndex && playListIndex === '')?`nav-link active`:'nav-link'} onClick={()=>handleLessonListChange(lindex) } >
                                                    <i className={`fa fa-question`} style={{fontSize:11}}/>
                                                    <p style={{fontSize:12,padding:2}}>{`Knowledge Test`}</p>
                                                </Link>
                                                </li>   
                                        </ul>
                                        {/* :'' */}
                                        {/* } */}
                                    </li>

                                ))
                            }
                            
                        </ul>
                    </div>
                    <div className='col-md-9'>
                        {subtopic?.page_type === 'multimedia'?
                        <>
                        <div className='row'>
                            <ReactVideoPlayers
                            videoUrl={`https://player.vimeo.com/video/677324350?transparent=0`}
                            />
                        </div>
                        <div className='row'>
                            <div className='col-md-12 '>
                                <div className='card card-primary card-tabs card-outline'>
                                {/* <div class="card  card-tabs"> */}
                                        <div class="card-header p-0 border-bottom-0">
                                        <ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                        <li class="nav-item">
                                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill" href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home" aria-selected="true" onClick={()=>handleTabChange('Description')}>Description</a>
                                        </li>
                                        <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill" href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile" aria-selected="false" onClick={()=>handleTabChange('Comments')}>Comments{`(${subtopic?.comments_count})`}</a>
                                        </li>
                                        <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-four-messages-tab" data-toggle="pill" href="#custom-tabs-four-messages" role="tab" aria-controls="custom-tabs-four-messages" aria-selected="false" onClick={()=>handleTabChange('Resources')}>Resources</a>
                                        </li>
                                       
                                        </ul>
                                        {/* </div> */}
                                        </div>
                                <div className='card-body'>
                                    <div className='tab-content' id='custom-tabs-four-tabContent'>
                                        {
                                            activeTab === 'Description'?
                                            <div className='tab-pane fade show active' id='custome-tabs-four-home' role='tabpanel' aria-labelledby='custom-tabs-four-home-tab'>
                                            <MathJax.Html html={subtopic?.description}/>
                                            </div>
                                            :
                                            activeTab === 'Comments'?
                                            <div className='tab-pane fade show active' id='custome-tabs-four-profile' role='tabpanel' aria-labelledby='custom-tabs-four-profile-tab'>
                                                <Discussion 
                                                discussionData={discussionData}
                                                subcourse_slug={subtopic?.subcourse_slug}
                                                subtopic_id={subtopic?.topic_id}
                                                lesson_id={subtopic?.lesson_id}
                                                maintopic_id={subtopic?.maintopic_id}

                                                />
                                            </div>
                                            :
                                            activeTab === 'Resources'?
                                            <div className='tab-pane fade show active' id='custome-tabs-four-profile' role='tabpanel' aria-labelledby='custom-tabs-four-profile-tab'>
                                                <CourseResources resources={lessonResources}/>
                                            </div>
                                            :''

                                        }
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </>
                        :
                        subtopic?.page_type === 'plain_text'?

                        
                        <div>
                            <MathJax.Html html={subtopic?.description}/>
                            
                        </div>
                        :
                        <LessonQuiz lessonQuiz={lessonQuiz}/>
                        }
                    </div>
                    <div className='row'>
                            <ReactVideoPlayers
                            videoUrl={`https://vz-3d9e065d-9ae.b-cdn.net/36c9baa5-b2ef-4c8c-b07b-8cadd33bc5a9/playlist.m3u8`}
                            />
                        </div>
                </div>
            </div>
        </div>
    </BodyContainer>
  )
}

export default CoursePlayListSetup