import {combineReducers} from '@reduxjs/toolkit';
import categorysReducer from './CategorySetup';
import MenuReducer from './MenuSetup';
import CourseReducers from './CourseSetup';
import SubCourseReducers from './SubCourseSetup';
import UserReducers from './UserSetup';
import CompanyReducers from './CompanySetup';
import EmployeeReducers from './EmployeeSetup';
import DepartmentReducers from './DepartmentSetupStore';
import DesignationReducers from './DesignationSetupStore';
import MenuReducers from './MenuSetupStore';
import MainTopicReducers from './MainTopicSetup';
import LessonReducers from './LessonSetup';
import SubTopicReducers from './SubTopicSetup';
import InstructorReducers from './InstructorSetup';
import QuizReducers from './QuizSetupStore';
import CourseProgramReducers from './CourseProgramSetup';
import AlumniReducers from './AlumniSetupStore';
import FeatureSetupReducer from './FeatureSetupStore';
import BannerSetupReducer from './BannerSetupStore';
import CoursePriceReducer from './CoursePriceStore';
import OnboardMobileSetupReducer from './OnboardMobileSetupStore';
import EnrollReducer from './EnrollStore'
import ArticleReducer from './ArticlesStore'
import DiscussionReducer from './DiscussionStore';
import EnquiryReducer from './EnquiryStore';
export default combineReducers({
    menus: MenuReducer,
    categorys: categorysReducer,
    courses: CourseReducers,   
    subcourse:SubCourseReducers,
    users: UserReducers,
    company: CompanyReducers,
    employee: EmployeeReducers,
    department: DepartmentReducers,
    designation: DesignationReducers,
    menu: MenuReducers,
    mainTopic:MainTopicReducers,
    subTopic:SubTopicReducers,
    lesson:LessonReducers,
    instructor:InstructorReducers,
    quiz: QuizReducers,
    course_program:CourseProgramReducers,
    alumni:AlumniReducers,
    feature:FeatureSetupReducer,
    banner:BannerSetupReducer,
    courseprice:CoursePriceReducer,
    onboard:OnboardMobileSetupReducer,
    enroll:EnrollReducer,
    articles:ArticleReducer,
    discussion:DiscussionReducer,
    enquiry:EnquiryReducer,
})