import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import CkeditorField from '../Form/CkeditorField'
import Spinner from '../Layout/Spinner'
import { getDiscussionList, updateDiscussion } from '../../store/DiscussionStore'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import TextError from '../../TextError'
import { get_user_name } from '../../EncDec'
function UpdateDiscussion({item,subtopic_id}) {
    const dispatch = useDispatch()
    const initialValues = {
    discussion:item?.iid,    
    comments:item?.comments
    }

    const validationSchema = Yup.object().shape({       
        discussion:Yup.string().required().label('discussion'),        
        comments:Yup.string().required().label('comment'),
    })
    const handleSubmit = async (values,submitProps)=>{
        try{
            await dispatch(updateDiscussion(values));
            initialValues.comments = ''
            await dispatch(getDiscussionList(subtopic_id))
            submitProps.resetForm();

        }catch(error){

        }
    }
  return (
    <div className='row'>
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        >
            {
                ({isSubmitting})=>(
                    <Form>
                        <div className='row'>
                        <div style={{height:50,width:50,borderRadius:999,borderWidth:3,borderColor:'#42a5ff',backgroundColor:'greenyellow',marginRight:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <p style={{fontSize:28,fontWeight:'600'}}>{get_user_name()?get_user_name().charAt(0):'A'}</p>
                        </div>
                        <CkeditorField name={`comments`} data={item?.comments}/>

                        </div>
                        <ErrorMessage name='comments' component={TextError}/>
                        <div className='float-sm-right' style={{marginTop:10}}>
                        <button className='btn btn-flat btn-success ' disabled={isSubmitting}>
                            {isSubmitting && <><Spinner/>&nbsp;</>} Edit
                        </button>
                        </div>
                    </Form>
                )
            }
        </Formik>
    </div>
  )
}

export default UpdateDiscussion